import styled from 'styled-components';

const Text = styled.div`
  overflow-y: auto;
  text-align: left;
  font-family: 'Roboto Mono';
  font-weight: 300;
  font-size: 16px;
  letter-spacing: 0.28px;
  color: #a0a0a0;
  opacity: 1;
  padding: ${(props) => (props.p ? props.p : '40px')};
`;
Text.displayName = 'Text';

export default Text;
