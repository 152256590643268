import React from 'react';
import styled from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '32px';

const TrashIcon = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: ${(props) => props.color || '#898989'};
  &:hover {
    fill: #e06c75;
  }
`;
TrashIcon.displayName = 'TrashCanIcon-TrashIcon';

export default function TrashCanIcon(props) {
  return (
    <TrashIcon viewBox="0 0 32 32" {...props}>
      <path
        d="M7,6V3A1,1,0,0,1,8,2h8a1,1,0,0,1,1,1V6h5V8H20V21a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8H2V6Zm6.414,8,1.768-1.768-1.414-1.414L12,12.586l-1.768-1.768L8.818,12.232,10.586,14,8.818,15.768l1.414,1.414L12,15.414l1.768,1.768,1.414-1.414ZM9,4V6h6V4Z"
        transform="translate(4 4)"
      />
      <rect fill="none" width="32" height="32" />
    </TrashIcon>
  );
}
