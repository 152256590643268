import React from 'react';
import styled, { css, withTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Flex } from 'reflexbox';
import { QuestionIcon as QuestionMark, CrossIcon } from 'boss-ui';
import { CheckMark } from '../icons';
import { DEFAULT_BOX_BORDER_STYLE, SKELTELON_STYLE } from '../../libs/constants';

const Row = styled.tr`
  margin: 6px;
  height: 20px;
  ${(props) =>
    props.correct &&
    css`
      color: ${props.theme.color.table.row.correct};
    `}
  ${(props) =>
    props.incorrect &&
    css`
      color: ${props.theme.color.table.row.incorrect};
    `}
`;

const Cell = styled.td`
  ${(props) =>
    props.right &&
    css`
      text-align: right;
    `}
`;
const TableHead = styled.thead``;
const Table = styled.table`
  border: ${DEFAULT_BOX_BORDER_STYLE.border};
  font-family: 'Roboto Mono';
  font-size: 14px;
  color: ${(props) => props.theme.color.text.regular.color};
  width: 200px;
  border-collapse: collapse;
  ${TableHead} {
    background-color: ${(props) => props.theme.color.table.header.background};
    color: ${(props) => props.theme.color.text.light.color};
  }
  ${Cell} {
    padding: ${(props) => props.theme.margin.xs};
  }
`;

const IconInLine = ({ icon }) => <Flex mr="6px">{icon}</Flex>;
const LoadingPoint = <Skeleton circle width="15px" height="15px" />;

const StatusTable = ({ loading, score = {}, theme }) => {
  const { total, unanswered, correct, incorrect } = score;
  return (
    <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
      <Table>
        <TableHead>
          <Row>
            <Cell>Total Questions:</Cell>
            <Cell right> {loading ? LoadingPoint : total}</Cell>
          </Row>
        </TableHead>
        <tbody>
          <Row>
            <Cell>
              <Flex alignItems="center">
                <IconInLine icon={<QuestionMark />} />
                Unanswered:
              </Flex>
            </Cell>
            <Cell right>{loading ? LoadingPoint : unanswered}</Cell>
          </Row>
          <Row correct>
            <Cell>
              <Flex alignItems="center">
                <IconInLine icon={<CheckMark />} />
                Correct:
              </Flex>
            </Cell>
            <Cell right>{loading ? LoadingPoint : correct}</Cell>
          </Row>
          <Row incorrect>
            <Cell>
              <Flex alignItems="center">
                <IconInLine icon={<CrossIcon color={theme.color.table.row.incorrect} />} />
                Incorrect:
              </Flex>
            </Cell>
            <Cell right>{loading ? LoadingPoint : incorrect}</Cell>
          </Row>
        </tbody>
      </Table>
    </SkeletonTheme>
  );
};

export default withTheme(StatusTable);
