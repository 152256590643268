export const TEAM_MEMBER_ROLE = { CAPTAIN: 'CAPTAIN', REGULAR: 'MEMBER' };
export const TEAM_VISIBILITY = { PUBLIC: 'PUBLIC', PRIVATE: 'PRIVATE' };
export const TEAM_ACCESSIBILITY = { OPEN: 'OPEN', CLOSED: 'CLOSED' };
export const USER_TEAM_STATE = {
  AVAILABLE: 'available',
  INVITED: 'invited',
};
export const USER_INVITE_STATE = {
  PENDING: 'PENDING',
  ACCEPTED: 'ACCEPTED',
  CANCELLED: 'CANCELLED',
  REJECTED: 'REJECTED',
};
export const USER_ACKSTATEMENT = {
  ACCEPTED: 'ACP',
  REJECTED: 'REJ',
};
export const RESOURCE_URL_REGEXP =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
export const RESOURCE_CREDENTIALS_REGEXP =
  /(user: )[a-zA-Z0-9-]{2,29}, (password: )[a-zA-Z0-9]{2,29}/;
export const RESOURCE_TEXT_REGEXP = /^(?! )[a-zA-Z0-9-]{2,29}[^\W]$/;
export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const EMAIL_MAX_IMPORT = 50;
export const RESOURCE_TYPE = {
  SERVER: 'server',
  URL: 'url',
  CREDENTIALS: 'credentials',
};
export const RESOURCE_STATE = {
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  PROVISIONING: 'PROVISIONING',
};

export const TEAM_NAME_REGEXP = /^(?! )[a-zA-Z0-9 _]{2,29}[^\W]$/;
export const CONFIRMATION_NUMBER_REGEXP = /^[0-9]{3,}$/;
export const DISPLAY_NAME_REGEXP = /^(?! )[a-zA-Z0-9 _]{2,29}[^\W]$/;
export const EVENT_TYPE = {
  GAME: 'GAME',
  WORKSHOP: 'WORKSHOP',
};
export const EVENT_DELIVERY = {
  ON_DEMAND: 'ON_DEMAND',
  NORMAL: 'NORMAL',
};
export const EVENT_REGISTRATION_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
};
export const EVENT_ACCESS_TYPE = {
  RESTRICTED: 'RESTRICTED',
};
export const BOTS_TYPE = {
  SOC: 'SOC',
  NOC: 'NOC',
};
export const DEFAULT_BOX_BORDER_STYLE = { border: '1px solid #2C2C2C' };
export const SKELTELON_STYLE = {
  COLOR: '#202020',
  EFFECT: '#444',
};
export const DEFAULT_BONUS_MULTIPLIER = 2;
export const SCENARIO_HINTS_STRATEGIES = {
  PURCHASE: 'PURCHASE',
  TIME: 'TIME',
  DEFAULT: 'TIME',
};
export const EVENT_WORDING = {
  SCENARIO: {
    SOC: 'Scenarios',
    NOC: 'Episodes',
  },
};
export const USER_ROLES = {
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
  PLAYER: 'PLAYER',
};
export const NUMBERS = {
  ZERO: 0,
  ONE: 1,
};
export const NAV_MOBILE_MAXWIDTH = '600px';
export const RESOURCES_COLORS = ['#61ADA1', '#81A2BE', '#E5C07B', '#A088A4'];
export const REMOVE_QUESTION_CONFIRMATION_DIALOG = 'Are you sure on removing this question?';
export const MAXLEN_BIGCARD_TEXT_COLLAPSE = 109;
export const DEFAULT_ON_DEMAND_EVENT_DURATION = 120;
export const ONE_MINUTE_MS = 60000;
export const SURVEY_QUESTIONS_TYPE = {
  JUST_OPTIONS: 'Just Options',
  OPTIONS_AND_TEXT: 'Options and text',
  NUMERIC_SCALE: 'Numeric scale [0-10]',
  SMILE_SCALE: 'Smiles scale [Sadness...Total joy]',
  JUST_TEXT: 'Just text',
  STAR_SCALE: 'Stars scale [1...5]',
};
export const SURVEY_QUESTION_OPTION_OTHER = '-1';
export const MAX_INT32 = 0x7fffffff;
export const WS_SCENARIO_MESSAGES_TYPES = {
  ACTIVATE: 'SCENARIO_ACTIVATE_TASK',
  DEACTIVATE: 'SCENARIO_DEACTIVATE_TASK',
};
export const DISPLAY_QUESTIONS_MODE = { GRID: 'GRID', LIST: 'LIST' };
export const DEFAULT_FORM_INPUT_TYPE = 'input';
export const INPUT_FORM_TYPE_TEXTAREA = 'textarea';
export const MENU_BAR_HEIGHT = 50;