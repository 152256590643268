import SplunkRum from '@splunk/otel-web';

export default function rumConfig(config) {
  SplunkRum.init({
    applicationName: config.applicationName,
    beaconUrl: config.beaconUrl,
    rumAccessToken: config.rumAccessToken,
    version: config.version,
    deploymentEnvironment: config.deploymentEnvironment,
  });
}
