import React from 'react';
import styled, { css } from 'styled-components';
import svgBase from '../StyledSVGBase';

const FileSVG = styled(svgBase)`
  width: ${(props) => props.size || '20px'};
  height: ${(props) => props.size || '20px'};
  fill: ${(props) => props.color || '#A5D6A7'};
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
    `};
`;
FileSVG.displayName = 'FileIcon-FileSVG';

export default function FileIcon(props) {
  return (
    <FileSVG viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path d="M21,9V20.993A1,1,0,0,1,20.007,22H3.993A.993.993,0,0,1,3,21.008V2.992A1,1,0,0,1,4,2H14V8a1,1,0,0,0,1,1Zm0-2H16V2ZM8,7V9h3V7Zm0,4v2h8V11Zm0,4v2h8V15Z" />
    </FileSVG>
  );
}
