import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import groupBy from 'lodash/groupBy';
import map from 'lodash.map';
import capitalize from 'lodash.capitalize';
import { Flex } from 'reflexbox';
import Media from 'react-media';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { RegularText, LinkedText, CopyToClipboard } from '../../index';
import { RESOURCE_STATE, RESOURCE_TYPE, RESOURCES_COLORS } from '../../constants';

const UNASSIGNED_GROUP_KEY = 'undefined';

const Row = styled(Flex)`
  font-size: 14px;
  padding: 4px;
  color: #d0d0d0;
  word-break: break-all;
  font-family: Roboto Mono;
  ${(props) =>
    props.border &&
    css`
      padding: 6px 4px 6px 4px;
      border-top: 1px solid #2c2c2c;
    `};
`;
Row.displayName = 'ResourcesList-Row';

const Table = styled(Flex)`
  border: 1px solid #2c2c2c;
  width: 100%;
  flex-direction: column;
  color: ${(props) => props.theme.color.text.light.color};
`;
Table.displayName = 'ResourcesList-Table';

const ResourcesGroup = ({
  resources = [],
  dependencyObj = {},
  matches = {},
  showFinishedLabel = false,
}) => {
  let colorIdx = 0;
  let styleFlexDirection = {};

  if (matches.extraSmall || matches.small) {
    styleFlexDirection = { flexDirection: 'column', alignItems: 'flex-start' };
  }

  const stateMessage = showFinishedLabel ? 'Finished' : 'Pending assignment...';

  const resourcesLines = resources.map((r) => {
    colorIdx++;
    return (
      <Row style={{ styleFlexDirection }} key={r.resourceSpecSrn} className="ResourcesList-Row">
        <Flex ml="5px" mr="10px">
          {capitalize(r.name)}:
        </Flex>
        <Flex className="ResourcesList-UriItem" ml={(matches.extraSmall || matches.small) && '5px'}>
          {r.url ? (
            <LinkedText
              onClick={() => window.open(r.url, '_blank')}
              color={RESOURCES_COLORS[colorIdx]}
            >
              {r.url}
            </LinkedText>
          ) : (
            <Flex className="ResourcesList-StateMessage" color={RESOURCES_COLORS[colorIdx]}>
              {stateMessage}
            </Flex>
          )}
        </Flex>
      </Row>
    );
  });
  return (
    <Flex width={1} mb="10px">
      <Table className="ResourcesList-Table">
        {resourcesLines}
        {dependencyObj && dependencyObj.type && (
          <Row border className="ResourcesList-Row">
            {dependencyObj.type === RESOURCE_TYPE.CREDENTIALS && (
              <Flex
                className="ResourcesList-DependencyCredentials"
                style={{ wordBreak: 'break-all', ...styleFlexDirection }}
              >
                <Flex ml="5px" mr="10px">
                  Credentials:
                </Flex>
                {dependencyObj.state === RESOURCE_STATE.ASSIGNED ? (
                  <>
                    {matches.extraSmall ? (
                      <Flex ml="5px" style={{ flexDirection: 'column', alignItems: 'flex-start' }}>
                        <Flex>
                          <RegularText fontSize="14px" color="#A5D6A7" mediumWeight>
                            {dependencyObj.user}
                          </RegularText>
                          <CopyToClipboard value={dependencyObj.user} />
                        </Flex>
                        <Flex>
                          <RegularText fontSize="14px" color="#A5D6A7" mediumWeight>
                            {dependencyObj.password}
                          </RegularText>
                          <CopyToClipboard value={dependencyObj.password} />
                        </Flex>
                      </Flex>
                    ) : (
                      <Flex ml={matches.small && '5px'}>
                        <RegularText fontSize="14px" color="#A5D6A7" mediumWeight>
                          {dependencyObj.user}
                        </RegularText>
                        <CopyToClipboard value={dependencyObj.user} />
                        <Flex mr="10px">/</Flex>
                        <RegularText fontSize="14px" color="#A5D6A7" mediumWeight>
                          {dependencyObj.password}
                        </RegularText>
                        <CopyToClipboard value={dependencyObj.password} />
                      </Flex>
                    )}
                  </>
                ) : (
                  <Flex className="ResourcesList-StateMessage" color="#A5D6A7">
                    {stateMessage}
                  </Flex>
                )}
              </Flex>
            )}
            {(dependencyObj.type === RESOURCE_TYPE.SERVER ||
              dependencyObj.type === RESOURCE_TYPE.URL) && (
              <Flex
                className="ResourcesList-DependencyServer"
                style={{ wordBreak: 'break-all', ...styleFlexDirection }}
              >
                <Flex ml="5px" mr="10px">
                  Server:
                </Flex>
                <Flex
                  className="ResourcesList-UriItem "
                  ml={(matches.extraSmall || matches.small) && '5px'}
                >
                  {dependencyObj.state === RESOURCE_STATE.ASSIGNED ? (
                    <>
                      <RegularText fontSize="14px" color="#A5D6A7" mediumWeight>
                        {dependencyObj.url}
                      </RegularText>
                      <CopyToClipboard value={dependencyObj.url} />
                    </>
                  ) : (
                    <Flex className="ResourcesList-StateMessage" color="#A5D6A7">
                      {stateMessage}
                    </Flex>
                  )}
                </Flex>
              </Flex>
            )}
          </Row>
        )}
      </Table>
    </Flex>
  );
};

const getGeneralItems = (generalResources = [], resourceCollection = {}) => {
  const filteredGeneralItems = generalResources.filter(
    (resource) => !(resource.resourceSpecSrn in resourceCollection)
  );
  return {
    generalResources: filteredGeneralItems.filter(
      (resource) =>
        (resource.type === RESOURCE_TYPE.SERVER || resource.type === RESOURCE_TYPE.URL) &&
        !resource.isDependency
    ),
    generalDependency: filteredGeneralItems.find(
      (resource) => resource.type === RESOURCE_TYPE.CREDENTIALS && !resource.isDependency
    ),
  };
};

export default function ResourcesList({
  loading = false,
  resources = [],
  showFinishedLabel = false,
}) {
  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };
  if (loading) {
    return (
      <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
        <Skeleton count={3} />
      </SkeletonTheme>
    );
  }
  const resourcesGroupedByDependency = groupBy(resources, 'resourceDependencyId');
  // clean the unassigned resources index, check if there is only dependencyResources
  if (
    resourcesGroupedByDependency[UNASSIGNED_GROUP_KEY] &&
    resourcesGroupedByDependency[UNASSIGNED_GROUP_KEY].length > 0
  ) {
    // only get the resources that has not resourceDependencyId
    const unAssignedRes = resourcesGroupedByDependency[UNASSIGNED_GROUP_KEY].filter(
      (r) => !r.isDependency
    );
    if (unAssignedRes.length === 0) {
      delete resourcesGroupedByDependency[UNASSIGNED_GROUP_KEY];
    }
  }
  return (
    <Media
      queries={{
        extraSmall: '(max-width: 510px)',
        small: '(min-width: 511px) and (max-width: 566px)',
      }}
    >
      {(matches) => (
        <>
          {map(resourcesGroupedByDependency, (groupedResources, groupKey, resourceCollection) => {
            if (groupKey === UNASSIGNED_GROUP_KEY) {
              const { generalResources, generalDependency } = getGeneralItems(
                groupedResources,
                resourceCollection
              );
              return (
                <ResourcesGroup
                  key={groupKey}
                  resources={generalResources}
                  dependencyObj={generalDependency}
                  matches={matches}
                  showFinishedLabel={showFinishedLabel}
                />
              );
            }
            const resourceObj = resources.find((r) => r.resourceSpecSrn === groupKey);
            return (
              <ResourcesGroup
                key={groupKey}
                resources={groupedResources}
                dependencyObj={resourceObj}
                matches={matches}
                showFinishedLabel={showFinishedLabel}
              />
            );
          })}
        </>
      )}
    </Media>
  );
}
