import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { RegularText, RefreshIcon } from 'boss-ui';
import { fadeIn } from '../../libs/animations-lib';
import { useRefreshScore } from '../../libs/hooks-lib';
import WebSocketClient from '../websocket';

const Box = styled(Flex)`
  width: 210px;
  justify-content: flex-start;
  padding: 8px;
  z-index: 100;
  cursor: default;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.3s;
  border: 1px solid #1d1d1d;
  box-shadow: -3px 3px 3px #00000029;
  background-color: #121212;
  filter: ${(props) => (props.$refreshing ? 'blur(1px)' : 'none')};
  white-space: break-spaces;
`;
Box.displayName = 'PointsCardBox';

const BoxMobile = styled(Flex)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;
BoxMobile.displayName = 'PointsCardBoxMobile';

const wsClient = new WebSocketClient();

export default function PointsCard({
  teamPoints = 0,
  loading = false,
  mobile = false,
  onRefresh = () => {},
  isOnDemandEvent = false,
  showRefresh = true,
  sessionId,
}) {
  const [theScore, setTheScore] = useState(teamPoints);
  const [refreshHover, setRefreshHover, getLastUpdatedLabel, resetLastUpdate] =
    useRefreshScore(loading);

  const scoreUpdateHandler = useCallback((update) => {
    if (isOnDemandEvent && update.sessionId !== sessionId) {
      return;
    }
    setTheScore(update.total);
    resetLastUpdate();
  }, []);

  useEffect(() => {
    if (isOnDemandEvent) {
      wsClient.addListener('USER_SCORE_UPDATE', scoreUpdateHandler);
    } else {
      wsClient.addListener('TEAM_SCORE_UPDATE', scoreUpdateHandler);
    }
    return () => {
      if (isOnDemandEvent) {
        wsClient.clearListener('USER_SCORE_UPDATE', scoreUpdateHandler);
      } else {
        wsClient.clearListener('TEAM_SCORE_UPDATE', scoreUpdateHandler);
      }
    };
  }, [scoreUpdateHandler, isOnDemandEvent]);

  useEffect(() => {
    setTheScore(teamPoints);
  }, [teamPoints]);

  return (
    <>
      {mobile ? (
        <BoxMobile>
          <Flex>
            <RegularText medium fontSize="13px">
              Team score:
            </RegularText>
            <RegularText light fontSize="13px" ml="6px">
              {theScore}
            </RegularText>
          </Flex>
          {showRefresh && (
            <Flex
              pr="12px"
              alignItems="center"
              onClick={() => onRefresh()}
              style={{ cursor: 'pointer' }}
              onMouseEnter={() => setRefreshHover(true)}
              onMouseLeave={() => setRefreshHover(false)}
            >
              <RefreshIcon rotate={loading} hover={refreshHover} />
            </Flex>
          )}
        </BoxMobile>
      ) : (
        <Box $refreshing={loading}>
          <RegularText fontSize="12px" color="#D0D0D0">
            {isOnDemandEvent ? 'Your score: ' : 'Your team score: '}
            <Flex ml="auto">
              <RegularText fontSize="12px" color="#D0D0D0" mediumWeight>
                {theScore}
              </RegularText>
            </Flex>
          </RegularText>
          {showRefresh && (
            <>
              <Flex my="4px" style={{ borderBottom: '0.3px solid #2C2C2C' }} />
              <Flex
                alignItems="center"
                onClick={() => onRefresh()}
                style={{ cursor: 'pointer' }}
                onMouseEnter={() => setRefreshHover(true)}
                onMouseLeave={() => setRefreshHover(false)}
              >
                <RefreshIcon rotate={loading} hover={refreshHover} />
                <RegularText fontSize="12px" marginLeft="3px">
                  {refreshHover ? (
                    <RegularText fontSize="12px" color="#66bb6a">
                      Refresh score
                    </RegularText>
                  ) : (
                    getLastUpdatedLabel()
                  )}
                </RegularText>
              </Flex>
            </>
          )}
        </Box>
      )}
    </>
  );
}
