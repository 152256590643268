import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormLabel } from 'boss-ui';
import { FormInput } from './index';
import { useAppContext } from '../libs/context-lib';
import { useFormFields } from '../libs/hooks-lib';
import { onError } from '../libs/error-lib';

export default function VerifyEmailForm(props) {
  const [fields, handleFieldChange] = useFormFields({
    confirmationCode: '',
  });
  const [loading, setLoading] = useState(false);
  const { userSession } = useAppContext();
  const history = useHistory();
  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setLoading(true);
      await Auth.confirmSignUp(props.email.toLowerCase(), fields.confirmationCode);
      const user = await Auth.signIn(props.email.toLowerCase(), props.password);
      userSession(user);
      history.push('/firstLogin');
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        setLoading(false);
      } else {
        onError(e);
        setLoading(false);
      }
    }
  }

  async function resendVerifyCode(event) {
    event.preventDefault();
    // resend the verification code
    try {
      setLoading(true);
      await Auth.resendSignUp(props.email);
      setLoading(false);
    } catch (e) {
      onError(e);
      setLoading(false);
    }
  }

  function validate() {
    return fields.confirmationCode.length > 0;
  }

  return (
    <Flex justifyContent="center" align="center" mt="100px" mb="16px">
      <Form onSubmit={handleSubmit}>
        <Box px="60px" py="40px">
          <Box mb="20px">
            <FormLabel>Verification code</FormLabel>
            <FormInput
              autoFocus
              placeholder="Enter the code"
              value={fields.confirmationCode}
              id="confirmationCode"
              onChange={handleFieldChange}
            />
          </Box>
          <Box mb="20px">
            <Button isLoading={loading} onClick={resendVerifyCode} style={{ border: 0 }}>
              resend
            </Button>
          </Box>
          <Button type="submit" isLoading={loading} disabled={!validate()}>
            Verify
          </Button>
        </Box>
      </Form>
    </Flex>
  );
}
