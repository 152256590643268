import React from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Breadcrumb } from 'boss-ui';

const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
  padding: 0 0 0 20px;
  white-space: nowrap;
  height: 50px;
  background-color: #1d1d1d;
  box-shadow: 0px 3px 6px #00000029;
`;

function Menu(props) {
  const { crumbs } = props;
  return (
    <MenuContainer>
      <Breadcrumb crumbs={crumbs} />
    </MenuContainer>
  );
}

export default withRouter(Menu);
