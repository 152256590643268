import React from 'react';
import { Flex } from 'reflexbox';
import { UnregularButton, RegularText } from 'boss-ui';
import { AsteriskIcon } from '../../icons';
import { SURVEY_QUESTION_OPTION_OTHER } from '../../../libs/constants';

export default function MixScaleQuestion({
  value = { text: '', option: '' },
  options = [],
  onChange = () => {},
}) {
  return (
    <>
      {options && (
        <Flex
          className="MixScaleQuestion-FlexContainer"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          width="100%"
        >
          {options.map((row) => {
            return (
              <Flex
                key={row}
                onClick={() => {
                  onChange({ option: row });
                }}
                style={{ cursor: 'pointer' }}
                className="MixScaleQuestion-Option"
              >
                <UnregularButton>
                  {value.option === row ? (
                    <AsteriskIcon color="#81C784" className="MixScaleQuestion-AsteriskSelected" />
                  ) : (
                    <AsteriskIcon
                      style={{ visibility: 'hidden' }}
                      color="#81C784"
                      className="MixScaleQuestion-AsteriskNotSelected"
                    />
                  )}
                </UnregularButton>
                <RegularText
                  color="#81C784"
                  align="left"
                  style={{ letterSpacing: '0px', paddingTop: '6px' }}
                  mediumWeight
                >
                  {row}
                </RegularText>
              </Flex>
            );
          })}
          <Flex
            onClick={() => {
              onChange({ option: SURVEY_QUESTION_OPTION_OTHER });
            }}
            style={{ cursor: 'pointer' }}
            className="MixScaleQuestion-Option MixScaleQuestion-OptionOther"
          >
            <UnregularButton>
              {value.option === SURVEY_QUESTION_OPTION_OTHER ? (
                <AsteriskIcon color="#81C784" />
              ) : (
                <AsteriskIcon style={{ visibility: 'hidden' }} color="#81C784" />
              )}
            </UnregularButton>
            <RegularText
              color="#81C784"
              align="left"
              style={{ letterSpacing: '0px', paddingTop: '6px' }}
              mediumWeight
            >
              Other
            </RegularText>
          </Flex>
          {value.option === SURVEY_QUESTION_OPTION_OTHER && (
            <Flex width="100%" p="10px">
              <textarea
                placeholder="Enter specification"
                style={{
                  backgroundColor: '#2c2c2c',
                  border: '1px solid #535353',
                  width: '100%',
                  height: '100px',
                  color: '#D0D0D0',
                  resize: 'none',
                  fontSize: '14px',
                  fontFamily: 'Roboto Mono',
                  padding: '5px',
                  letterSpacing: '0px',
                }}
                className="MixScaleQuestion-text"
                onChange={(e) => {
                  onChange({ option: SURVEY_QUESTION_OPTION_OTHER, text: e.target.value });
                }}
                value={value.text}
              />
            </Flex>
          )}
        </Flex>
      )}
    </>
  );
}
