import { BOTS_TYPE, EVENT_REGISTRATION_STATUS } from './constants';
import { isTodayBetween } from './dates-lib';

export const getEventShortName = (type) => {
  if (type === BOTS_TYPE.SOC) {
    return 'BOTS';
  }
  return 'BOO';
};

export const isOnDemandEventInsidePlayWindow = (joinDate, duration) => {
  if (!joinDate || !duration) {
    return false;
  }
  const inProgressStartDate = new Date(joinDate);
  const inProgressEndDate = new Date(inProgressStartDate.getTime() + duration * 60000);
  return isTodayBetween(inProgressStartDate, inProgressEndDate);
};

export const isNormalEventInsideActiveWindow = (
  activeStartDate,
  activeEndDate,
  registrationOpenDate,
  registrationCloseDate,
  registrationStatus
) => {
  if (registrationStatus === EVENT_REGISTRATION_STATUS.CLOSED) {
    return false;
  }
  if (isTodayBetween(new Date(registrationOpenDate), new Date(registrationCloseDate))) {
    return isTodayBetween(new Date(activeStartDate), new Date(activeEndDate));
  }
  return false;
};

export const getNoImageUrl = (type, size) => {
  if (type === BOTS_TYPE.SOC) {
    switch (size) {
      case 'large':
        return `${process.env.PUBLIC_URL}/bots/android-chrome-766x432.png`;
      case 'medium':
        return `${process.env.PUBLIC_URL}/bots/android-chrome-550x310.png`;
      default:
        return `${process.env.PUBLIC_URL}/bots/android-chrome-330x186.png`;
    }
  } else {
    switch (size) {
      case 'large':
        return `${process.env.PUBLIC_URL}/boo/android-chrome-766x432.png`;
      case 'medium':
        return `${process.env.PUBLIC_URL}/boo/android-chrome-550x310.png`;
      default:
        return `${process.env.PUBLIC_URL}/boo/android-chrome-330x186.png`;
    }
  }
};

export const getSrnGroupId = (eventId) => {
  return `srn:group::${eventId}`;
}
export const getConsumerGroupSrn = (eventId, userId) => {
  return `srn:group:${userId}:${eventId}`;
}
const exportObj = { getEventShortName, getNoImageUrl, getSrnGroupId };
export default exportObj;
