import { useContext, createContext } from 'react';

export const AppContext = createContext(null);

export function useAppContext() {
  return useContext(AppContext);
}

export const TeamContext = createContext(null);
export function useTeamContext() {
  return useContext(TeamContext);
}

export const HomeContext = createContext(null);
export function useHomeContext() {
  return useContext(HomeContext);
}

export const WorkshopContext = createContext(null);
export function useWorkshopContext() {
  return useContext(WorkshopContext);
}

export const ScenarioContext = createContext(null);
export function useScenarioContext() {
  return useContext(ScenarioContext);
}
export const AdminQuestionContext = createContext(null);
export function useAdminQuestionContext() {
  return useContext(AdminQuestionContext);
}
