import React, { useRef, useLayoutEffect, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import capitalize from 'lodash/capitalize';
import { RegularText, Tooltip, GameIcon } from 'boss-ui';
import { WorkshopIcon, InviteOnlyIcon } from '../icons';
import { getImageURLSized, removePlural } from '../../libs/utils-lib';
import CardBody from './CardBody';
import CardDescription from './CardDescription';
import CardIconContainer from './CardIconContainer';
import CardTitle from './CardTitle';
import CardImage from './CardImage';
import CardDate from './CardDate';
import CardLabel from './CardLabel';
import BossLabel from '../BossLabel';
import { isTodayBetween, formatMinutes } from '../../libs/dates-lib';
import {
  isOnDemandEventInsidePlayWindow,
  getNoImageUrl,
  isNormalEventInsideActiveWindow,
} from '../../libs/event-lib';
import { EVENT_TYPE, EVENT_DELIVERY } from '../../libs/constants';

const ICON_SIZE = 26;
const CARD_MARGIN = 20;
const DESCRIPTION_LINE_HEIGHT = 23;

const Container = styled(Flex)`
  width: 330px;
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
  &:hover {
    background-color: ${(props) => props.theme.color.card.classic.background.hover};
    cursor: pointer;
  }
`;
Container.displayName = 'Card-Container';

const calculateDescriptionLines = (titleHeight) => {
  return Math.round((300 - titleHeight - ICON_SIZE - CARD_MARGIN * 2) / DESCRIPTION_LINE_HEIGHT);
};

const getDurationLabel = (duration) => {
  const durationFormated = formatMinutes(duration);
  return (
    <RegularText light fontSize="14px" className="Card-DurationLabel">
      {`Duration: ${durationFormated.hour}h ${durationFormated.minutes}m `}
    </RegularText>
  );
};

/**
 * @param  {String} activeEndDate event active end date
 * @param  {String} activeStartDate event active start date
 * @param  {String} delivery NORMAL / ON_DEMAND
 * @param  {String} description card description
 * @param  {String} duration event duration in minutes
 * @param  {Boolean} enrolled
 * @param  {String} eventType WORKSHOP / GAME for icons
 * @param  {boolean} history
 * @param  {String} id event id
 * @param  {String} imageURL image url of card
 * @param  {boolean} isScenario
 * @param  {String} joinDate date of joining to the event
 * @param  {function} onCardClick on click function
 * @param  {String} registrationClosedDate event registration end date
 * @param  {String} registrationOpenDate event registration start date
 * @param  {String} registrationStatus OPEN / CLOSED
 * @param  {Boolean} restricted
 * @param  {String} scenarioIdx scenario idx to use with label
 * @param  {String} scenarioLabel scenario label
 * @param  {boolean} showDate
 * @param  {String} startDate event start date
 * @param  {object} stats workshop progress stats
 * @param  {String} title card title
 * @param  {String} type SOC/NOC for labels
 */
export default function Card({
  activeEndDate,
  activeStartDate,
  delivery,
  description,
  duration = 90,
  enrolled = false,
  eventType,
  history = false,
  id,
  imageURL,
  isScenario = false,
  joinDate,
  onCardClick,
  registrationClosedDate,
  registrationOpenDate,
  registrationStatus,
  restricted,
  scenarioIdx,
  scenarioLabel,
  showDate,
  startDate,
  stats = {},
  title,
  type,
}) {
  const isEventRunning =
    eventType === EVENT_TYPE.WORKSHOP
      ? isTodayBetween(new Date(activeStartDate), new Date(activeEndDate))
      : delivery === EVENT_DELIVERY.NORMAL
      ? isNormalEventInsideActiveWindow(
          activeStartDate,
          activeEndDate,
          registrationOpenDate,
          registrationClosedDate,
          registrationStatus
        )
      : isOnDemandEventInsidePlayWindow(joinDate, duration);
  const imgSize = 'small';
  const imageSrc = getImageURLSized(imageURL, imgSize);
  const titleRef = useRef(title);
  const [descriptionLines, setdescriptionLines] = useState(0);
  const showEnrolledLabel = enrolled && !isScenario;
  const isWorkshopCompleted =
    eventType === EVENT_TYPE.WORKSHOP && stats && stats.isWorkshopCompleted;

  const showProgressLabel =
    (eventType === EVENT_TYPE.WORKSHOP && stats.progressPercentage) ||
    (isEventRunning &&
      eventType === EVENT_TYPE.GAME &&
      delivery === EVENT_DELIVERY.ON_DEMAND &&
      enrolled);

  useLayoutEffect(() => {
    if (titleRef.current) {
      setdescriptionLines(calculateDescriptionLines(titleRef.current.offsetHeight));
    }
  }, []);

  useEffect(() => {
    if (!title && !description) {
      throw new Error('missing title/description');
    }
  }, []);

  return (
    <Container
      maxWidth="330px"
      flexDirection="column"
      onClick={onCardClick}
      className="Card-Container"
    >
      <CardImage
        src={imageSrc}
        alt="card image"
        onError={(e) => {
          e.target.src = getNoImageUrl(type, imgSize);
        }}
      />
      <Flex justifyContent="flex-end" width={1}>
        <CardLabel
          eventType={eventType}
          history={history}
          stats={stats}
          isEventRunning={isEventRunning}
          delivery={delivery}
          showProgressLabel={showProgressLabel}
          enrolled={enrolled}
          joinDate={joinDate}
          duration={duration}
          showEnrolledLabel={showEnrolledLabel}
          restricted={restricted}
        />
      </Flex>
      <CardBody
        active={
          isEventRunning || (!enrolled && delivery === EVENT_DELIVERY.ON_DEMAND) || isScenario
        }
        alignItems="start"
        flexDirection="column"
        height="300px"
        restricted={restricted}
        completed={
          isWorkshopCompleted ||
          (!isEventRunning && delivery === EVENT_DELIVERY.ON_DEMAND && enrolled)
        }
        inprogress={showProgressLabel}
        history={history}
      >
        <Flex alignItems="center" width={1}>
          {isScenario ? (
            <RegularText light mediumWeight fontSize="14px" className="Card-ScenarioLabel">
              {capitalize(removePlural(scenarioLabel))} {scenarioIdx}
            </RegularText>
          ) : (
            <>
              <CardIconContainer>
                {eventType === EVENT_TYPE.WORKSHOP ? <WorkshopIcon /> : <GameIcon />}
              </CardIconContainer>
              {restricted && (
                <>
                  <Flex data-tip data-for="inviteOnlyIcon" pl="8px" pr="4px">
                    <InviteOnlyIcon />
                  </Flex>
                  <Tooltip id="inviteOnlyIcon">INVITE ONLY</Tooltip>
                </>
              )}
              <BossLabel type={type} ml="6px" />
              <Flex ml="auto">
                {delivery === EVENT_DELIVERY.ON_DEMAND
                  ? getDurationLabel(duration)
                  : showDate && startDate && <CardDate date={startDate} />}
              </Flex>
            </>
          )}
        </Flex>
        <div ref={titleRef} style={{ display: 'content' }}>
          <CardTitle
            active={
              isEventRunning || (!enrolled && delivery === EVENT_DELIVERY.ON_DEMAND) || isScenario
            }
            id={id}
            restricted={restricted}
            completed={
              isWorkshopCompleted ||
              (!isEventRunning && delivery === EVENT_DELIVERY.ON_DEMAND && enrolled)
            }
            history={history}
            inprogress={showProgressLabel}
          >
            {title}
          </CardTitle>
        </div>
        <Flex mt="auto">
          <CardDescription climpAfterLine={descriptionLines}>{description}</CardDescription>
        </Flex>
      </CardBody>
    </Container>
  );
}
