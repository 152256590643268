import React from 'react';
import { Flex } from 'reflexbox';
import { UnregularButton, RegularText } from 'boss-ui';
import { AsteriskIcon } from '../../icons';

export default function NominalScaleQuestion({ value = '', options = [], onChange = () => {} }) {
  return (
    <>
      {options && (
        <Flex
          className="NominalScaleQuestion-FlexContainer"
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {options.map((row) => {
            return (
              <Flex
                key={row}
                onClick={() => {
                  onChange(row);
                }}
                style={{ cursor: 'pointer' }}
              >
                <UnregularButton>
                  {value === row ? (
                    <AsteriskIcon color="#81C784" />
                  ) : (
                    <AsteriskIcon style={{ visibility: 'hidden' }} color="#81C784" />
                  )}
                </UnregularButton>
                <RegularText
                  color="#81C784"
                  align="left"
                  style={{ letterSpacing: '0px', paddingTop: '6px' }}
                  mediumWeight
                >
                  {row}
                </RegularText>
              </Flex>
            );
          })}
        </Flex>
      )}
    </>
  );
}
