import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import { Button, UnregularButton, GameIcon } from 'boss-ui';
import { getImageURLSized } from '../../libs/utils-lib';
import CardBody from './CardBody';
import CardIconContainer from './CardIconContainer';
import CardDescription from './CardDescription';
import CardDate from './CardDate';
import CardTitle from './CardTitle';
import BossLabel from '../BossLabel';
import { WorkshopIcon } from '../icons';
import { EVENT_TYPE, MAXLEN_BIGCARD_TEXT_COLLAPSE, EVENT_DELIVERY } from '../../libs/constants';
import { useAppContext } from '../../libs/context-lib';
import { isTodayBetween } from '../../libs/dates-lib';
import { getNoImageUrl } from '../../libs/event-lib';

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 310px;
`;
Image.displayName = 'BigCardImage';

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
  height: fit-content;
`;
Container.displayName = 'BigCardContainer';

const CollapsableDescription = ({ children = [] }) => {
  const [visible, setVisible] = useState(children.length < MAXLEN_BIGCARD_TEXT_COLLAPSE);
  return (
    <>
      {visible ? (
        <CardDescription animate minHeight="38px">
          {children}
        </CardDescription>
      ) : (
        <CardDescription climpAfterLine={2} minHeight="38px">
          {children}
        </CardDescription>
      )}
      {children.length > MAXLEN_BIGCARD_TEXT_COLLAPSE && (
        <Flex mt="12px" justifyContent="center" width={1}>
          <UnregularButton onClick={() => setVisible((visible) => !visible)}>
            {visible ? 'Show less' : 'Show more'}
          </UnregularButton>
        </Flex>
      )}
    </>
  );
};

export default function BigCard({ event, mobile }) {
  const {
    imageURL,
    type,
    eventType,
    name,
    description,
    startDate,
    activeStartDate,
    activeEndDate,
    delivery,
  } = event;
  const { setEvent } = useAppContext();
  const imgSize = 'medium';
  const imageSrc = getImageURLSized(imageURL, imgSize);
  const history = useHistory();
  const activeEvent = isTodayBetween(new Date(activeStartDate), new Date(activeEndDate));
  const showDate = delivery === EVENT_DELIVERY.NORMAL;

  const onEnter = () => {
    setEvent(event);
    if (eventType === EVENT_TYPE.WORKSHOP) {
      history.push(`/workshop/${event.workshopId}`);
      return;
    }
    history.push(`/event/${event.eventId}`);
  };

  return (
    <Container width="550px" flexDirection="column" mb="20px">
      <Image
        src={imageSrc}
        alt="event image"
        onError={(e) => {
          e.target.src = getNoImageUrl(type, imgSize);
        }}
      />
      <CardBody
        active={activeEvent}
        alignItems="start"
        flexDirection="column"
        py={mobile ? '10px' : '25px'}
        px={mobile ? '15px' : '40px'}
        minHeight="317px"
      >
        <Flex alignItems="center" width={1}>
          <CardIconContainer>
            {eventType === EVENT_TYPE.WORKSHOP ? <WorkshopIcon /> : <GameIcon />}
          </CardIconContainer>
          <BossLabel type={type} ml="6px" />
          {showDate && startDate && (
            <Flex ml="auto">
              <CardDate date={startDate} />
            </Flex>
          )}
        </Flex>
        <CardTitle height="52px" active={activeEvent}>
          {name}
        </CardTitle>
        <CollapsableDescription>{description}</CollapsableDescription>
        <Flex mt="40px" ml="auto" minWidth="100px">
          <Button onClick={onEnter} disabled={!activeEvent}>
            ENTER
          </Button>
        </Flex>
      </CardBody>
    </Container>
  );
}
