import React, { useEffect } from 'react';
import { Auth, API } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { LoaderIcon } from 'boss-ui';
import SplunkRum from '@splunk/otel-web';
import config from '../../config';
import { onError } from '../../libs/error-lib';
import { useQuery } from '../../libs/hooks-lib';
import { setUserHeaders, formDataBody } from '../../libs/utils-lib';
import { useAppContext } from '../../libs/context-lib';
import { formatLoggedInUser } from '../../libs/cognito-lib';

const bodyObject = {
  grant_type: 'authorization_code',
  code: null,
  client_id: config.cognito.saml.APP_CLIENT_ID,
  redirect_uri: config.cognito.saml.REDIRECT_SIGN_IN,
};

function LoginSuccess() {
  const { userSession, appType } = useAppContext();
  const history = useHistory();
  const query = useQuery();

  const setDynamoUser = async (cognitoUser) => {
    const registeredUser = await API.get('users', '/user');
    if (!registeredUser.user) {
      userSession(cognitoUser);
      throw new Error('UserNotFoundInDynamo');
    }
    userSession({ ...cognitoUser, dynamoUser: registeredUser.user });
    setUserHeaders({ ...cognitoUser, dynamoUser: registeredUser.user });
    SplunkRum?.setGlobalAttributes({
      'user.id': registeredUser.user.userId,
      'user.email': registeredUser.user.email,
      'user.username': registeredUser.user.displayName,
    });
  };

  useEffect(() => {
    const validateUserSession = async () => {
      try {
        const authCode = query.get('code');
        const internalRedirect = query.get('state');
        if (!authCode) {
          throw new Error('Missing login session code');
        }
        bodyObject.code = authCode;
        const tokensRq = await fetch(`https://${config.cognito.saml.DOMAIN}/oauth2/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
          body: formDataBody(bodyObject),
        });
        const tokens = await tokensRq.json();
        const { id_token, refresh_token } = tokens;
        if (!id_token || !refresh_token) {
          throw new Error('Invalid Id or refresh token');
        }
        await Auth.federatedSignIn(
          `cognito-idp.${config.cognito.REGION}.amazonaws.com/${config.cognito.saml.USER_POOL_ID}`,
          {
            token: id_token,
            expires_at: 120 * 1000 + new Date().getTime(), // the expiration timestamp
          }
        );
        // this token will be used for refresh proccess
        localStorage.setItem('rt', refresh_token);
        // set user in the app state
        const loggedUser = formatLoggedInUser(await Auth.currentAuthenticatedUser());
        await setDynamoUser(loggedUser);
        history.push(internalRedirect);
      } catch (e) {
        if (e.message === 'UserNotFoundInDynamo') {
          history.push('/firstLogin');
        } else {
          onError(e);
          history.push('/login');
        }
      }
    };

    const setUserSession = async () => {
      try {
        // Check for user session before validating session by auth code
        const loggedUser = formatLoggedInUser(await Auth.currentAuthenticatedUser());
        await setDynamoUser(loggedUser);
        history.push('/');
      } catch (e) {
        if (e.message === 'UserNotFoundInDynamo') {
          history.push('/firstLogin');
          return;
        }
        validateUserSession();
      }
    };

    setUserSession();
  }, []);

  return (
    <Flex mt="15%" alignItems="center" justifyContent="center" flexDirection="column">
      <LoaderIcon appType={appType} />
    </Flex>
  );
}
export default LoginSuccess;
