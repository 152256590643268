import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '20px';

export const rotate360 = keyframes`
	0% {
		transform: rotate(0deg);
	}

  5% {
		transform: rotate(0deg);
	}

  50% {
		transform: rotate(180deg);
	}

  55% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
`;

const SVG = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: ${(props) => props.color || '#535353'};
  &:hover {
    fill:${(props) => props.hoverColor || '#66bb6a'};
  }
  ${(props) =>
    props.$hover &&
    css`
      fill: #66bb6a;
    `}
  ${(props) =>
    props.$rotate &&
    css`
      animation: ${rotate360} 1s ease infinite;
    `}
`;

export default function RefreshIcon({ rotate, hover, ...rest }) {
  return (
    <SVG viewBox="0 0 24 24" $rotate={rotate} $hover={hover} {...rest}>
      <rect width="24" height="24" opacity="0" />
      <path d="M20.3 13.43a1 1 0 0 0-1.25.65A7.14 7.14 0 0 1 12.18 19 7.1 7.1 0 0 1 5 12a7.1 7.1 0 0 1 7.18-7 7.26 7.26 0 0 1 4.65 1.67l-2.17-.36a1 1 0 0 0-1.15.83 1 1 0 0 0 .83 1.15l4.24.7h.17a1 1 0 0 0 .34-.06.33.33 0 0 0 .1-.06.78.78 0 0 0 .2-.11l.09-.11c0-.05.09-.09.13-.15s0-.1.05-.14a1.34 1.34 0 0 0 .07-.18l.75-4a1 1 0 0 0-2-.38l-.27 1.45A9.21 9.21 0 0 0 12.18 3 9.1 9.1 0 0 0 3 12a9.1 9.1 0 0 0 9.18 9A9.12 9.12 0 0 0 21 14.68a1 1 0 0 0-.7-1.25z" />
    </SVG>
  );
}
