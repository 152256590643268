import React, { useEffect, useState, useMemo } from 'react';
import Media from 'react-media';
import { Flex, Box } from 'reflexbox';
import get from 'lodash/get';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import Api from '../../api';
import { LoaderIcon, Menu, Breadcrumb } from 'boss-ui';
import { EventDetails } from '../../components';
import { useAppContext } from '../../libs/context-lib';
import { useQuery, useBreadcrumb } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { EVENT_ACCESS_TYPE, EVENT_DELIVERY, EVENT_TYPE } from '../../libs/constants';

const API = new Api();

export default function Details() {
  const [loadingEvent, setLoadingEvent] = useState(true);
  const { setEvent, event, appType } = useAppContext();
  const eventRouteMatch = useRouteMatch('/event/:eventId/detail');
  const history = useHistory();
  const query = useQuery();
  const inviteCodeFromQuery = query.get('invite_code');
  const isOnDemand = event && event.delivery === EVENT_DELIVERY.ON_DEMAND;
  const isRestricted = event && event.accessType === EVENT_ACCESS_TYPE.RESTRICTED;
  const [breadcrumbs, setBreadcrumbs] = useBreadcrumb();
  const location = useLocation();

  const MENU_ITEMS = [
    ...(isRestricted
      ? [
          {
            path: `/event/${eventRouteMatch.params.eventId}/detail`,
            label: 'HOME',
            exact: true,
            id: 'menuLinkHome',
          },
          {
            path: { pathname: '/', hash: '#play-now' },
            label: 'PLAY NOW',
            id: 'menuLinkHomePlayNow',
          },
          { path: { pathname: '/', hash: '#learn' }, label: 'LEARN', id: 'menuLinkLearn' },
          {
            path: { pathname: '/', hash: '#boss-events' },
            label: 'BOSS EVENTS',
            id: 'menuLinkEvents',
          },
        ]
      : [
          {
            path: '/',
            label: 'HOME',
            exact: true,
            id: 'menuLinkHome',
          },
          {
            path: isOnDemand
              ? `/event/${eventRouteMatch.params.eventId}/detail`
              : { pathname: '/', hash: '#play-now' },
            label: 'PLAY NOW',
            id: 'menuLinkHomePlayNow',
          },
          { path: { pathname: '/', hash: '#learn' }, label: 'LEARN', id: 'menuLinkLearn' },
          {
            path: isOnDemand
              ? { pathname: '/', hash: '#boss-events' }
              : `/event/${eventRouteMatch.params.eventId}/detail`,
            label: 'BOSS EVENTS',
            id: 'menuLinkEvents',
          },
        ]),
    {
      path: `/history`,
      label: 'HISTORY',
      id: 'menuLinkHistory',
    },
  ];

  const routes = [
    {
      path: `/event/:eventId/detail`,
      name: get(event, 'name', 'Current event'),
      staticFather: isRestricted
        ? {
            name: 'Home',
            action: () => history.push('/'),
          }
        : isOnDemand
        ? {
            name: 'Play Now',
            action: () => history.push({ pathname: '/', hash: '#play-now' }),
          }
        : {
            name: 'BOSS Events',
            action: () => history.push({ pathname: '/', hash: '#boss-events' }),
          },
    },
  ];

  useEffect(() => {
    const getEvent = async (id) => {
      setLoadingEvent(true);
      try {
        const { event, participant } = await API.get('events', `/events/${id}`);
        setEvent({ ...event, participant });
      } catch (e) {
        onError(e);
        history.push('/');
      } finally {
        setLoadingEvent(false);
      }
    };

    if (event && event.eventId === eventRouteMatch.params.eventId) {
      setLoadingEvent(false);
    } else {
      getEvent(eventRouteMatch.params.eventId);
    }
  }, [eventRouteMatch.params.eventId]);

  useMemo(() => {
    setBreadcrumbs(routes, eventRouteMatch);
  }, [location, event]);

  if (loadingEvent) {
    return (
      <Flex mt="10%" alignItems="center" justifyContent="center" flexDirection="column">
        <LoaderIcon appType={appType} />
      </Flex>
    );
  }

  return (
    <>
      <Menu menuItems={MENU_ITEMS} />
      <Media
        queries={{
          s: '(max-width: 759px)',
          m: '(max-width: 900px)',
        }}
      >
        {(matches) => (
          <Flex justifyContent="center" width={1}>
            <Box mx={matches.m ? '20px' : '10%'} mb="5%" mt="30px">
              <Flex my="30px">
                <Breadcrumb crumbs={breadcrumbs} />
              </Flex>
              <EventDetails
                id={event.eventId}
                imageURL={event.imageURL}
                type={event.type}
                eventType={EVENT_TYPE.GAME}
                name={event.name}
                description={event.description}
                confirmationNumberRequired={event.confirmationNumberRequired}
                startDate={event.startDate}
                endDate={event.endDate}
                delivery={event.delivery}
                activeStartDate={event.activeStartDate}
                activeEndDate={event.activeEndDate}
                registrationStatus={event.registrationStatus}
                registrationOpenDate={event.registrationOpenDate}
                registrationClosedDate={event.registrationClosedDate}
                inviteCode={inviteCodeFromQuery}
              />
            </Box>
          </Flex>
        )}
      </Media>
    </>
  );
}
