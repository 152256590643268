import React from 'react';

export default function QuestionMark(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 26 26">
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill="#a0a0a0"
        d="M12.493,20.218h3.228v3.228H12.493Zm3.228-2.655V18.6H12.493V16.184a1.614,1.614,0,0,1,1.614-1.614,2.421,2.421,0,1,0-2.374-2.9L8.567,11.04a5.65,5.65,0,1,1,7.154,6.523Z"
        transform="translate(-2.128 -3.223)"
      />
    </svg>
  );
}
