import React from 'react';

export default function CloseIcon(props) {
  return (
    <svg width={props.size || 30} height={props.size || 30} viewBox="1 7 30 20">
      <rect fill={props.inverted ? 'none' : '#535353'} width="32" height="32" />
      <g transform="translate(4 4)">
        <path fill={props.inverted ? 'none' : '#535353'} d="M0,0H24V24H0Z" />
        <path
          fill={props.inverted ? '#535353' : '#1d1d1d'}
          d="M19,10h1a1,1,0,0,1,1,1V21a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H5V9A7,7,0,1,1,19,9Zm-2,0V9A5,5,0,1,0,7,9v1Zm-6,4v4h2V14Z"
        />
      </g>
    </svg>
  );
}
