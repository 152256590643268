import React, { useState } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import get from 'lodash.get';
import CrossIcon from '../../icons/CrossIcon';
import StyledText from '../StyledText';
import RegularText from '../RegularText';
import LinkedText from '../LinkedText';

const Card = styled(Flex)`
  width: 100%;
  flex-direction: column;
  padding: 20px 0 20px;
  opacity: ${(props) => (props.saw ? 0.4 : 1)};
  filter: ${(props) => (props.loading ? 'blur(1px)' : 'none')};
`;

function NotificationCard({
  data = {},
  onRemoveNotification = () => {},
  lastItem = false,
  navigateTo = () => {},
}) {
  const { notificationType, notification, read } = data;
  const [isRemoving, setRemoving] = useState(false);

  let notificationBody;
  let title;
  switch (notificationType) {
    case 'TEAM_INVITE_RECEIVED':
      notificationBody = (
        <Flex style={{ whiteSpace: 'break-spaces' }} flexDirection="column">
          <RegularText style={{ whiteSpace: 'break-spaces' }}>
            {get(notification, 'team.name', 'A team')} wants you in their lines 💪
          </RegularText>
          <Flex ml="auto" mt="16px">
            <LinkedText onClick={() => navigateTo(`/event/${notification.eventId}/team/join`)}>
              <RegularText mediumWeight>See invitation</RegularText>
            </LinkedText>
          </Flex>
        </Flex>
      );
      title = 'You have been invited';
      break;
    case 'TEAM_INVITE_CANCELLED':
      title = `${get(notification, 'updatedByUser.displayName', 'A user')} left the team`;
      notificationBody = (
        <Flex>
          <RegularText style={{ whiteSpace: 'break-spaces' }}>Check your team </RegularText>
          <Flex>
            <LinkedText onClick={() => navigateTo(`/event/${notification.eventId}/team`)}>
              <RegularText mediumWeight>crew</RegularText>
            </LinkedText>
          </Flex>
        </Flex>
      );
      break;
    case 'TEAM_INVITE_REMOVED':
      title = 'You were removed from your team';
      notificationBody = (
        <Flex flexDirection="column">
          <RegularText>
            You are not longer part of {get(notification, 'team.name', 'the team')}
          </RegularText>
          <Flex ml="auto" mt="16px">
            <LinkedText onClick={() => navigateTo(`/event/${notification.eventId}/team/join`)}>
              <RegularText mediumWeight>Join another team</RegularText>
            </LinkedText>
          </Flex>
        </Flex>
      );
      break;
    case 'TEAM_INVITE_ACCEPTED':
      title = `${get(
        notification,
        'updatedByUser.displayName',
        'A user'
      )} accepted the team invitation`;
      notificationBody = (
        <Flex>
          <RegularText style={{ whiteSpace: 'break-spaces' }}>Check your team </RegularText>
          <Flex>
            <LinkedText onClick={() => navigateTo(`/event/${notification.eventId}/team`)}>
              <RegularText mediumWeight>crew</RegularText>
            </LinkedText>
          </Flex>
        </Flex>
      );
      break;
    case 'TEAM_INVITE_REJECTED':
      title = 'Your team invite was rejected';
      notificationBody = (
        <Flex ml="auto" mt="16px">
          <LinkedText onClick={() => navigateTo(`/event/${notification.eventId}/team/join`)}>
            <RegularText mediumWeight>Join another team</RegularText>
          </LinkedText>
        </Flex>
      );
      break;
    case 'TEAM_LEAVE':
      title = `${get(notification, 'updatedByUser.displayName', 'A user')} left the team`;
      notificationBody = (
        <Flex>
          <RegularText style={{ whiteSpace: 'break-spaces' }}>Check your team </RegularText>
          <Flex>
            <LinkedText onClick={() => navigateTo(`/event/${notification.eventId}/team`)}>
              <RegularText mediumWeight>crew</RegularText>
            </LinkedText>
          </Flex>
        </Flex>
      );
      break;
    case 'TEAM_INVITE_DISBAND':
      title = 'Your team was disbanded';
      notificationBody = (
        <Flex ml="auto" mt="16px">
          <LinkedText onClick={() => navigateTo(`/event/${notification.eventId}/team/join`)}>
            <RegularText mediumWeight>Join another team</RegularText>
          </LinkedText>
        </Flex>
      );
      break;
    default:
      title = 'Check this out';
  }
  return (
    <>
      <Card saw={read} loading={isRemoving ? 1 : 0}>
        <Flex mb="8px">
          <StyledText size="20px" light>
            {title}
          </StyledText>
          <Flex
            ml="auto"
            onClick={() => {
              setRemoving(true);
              onRemoveNotification();
            }}
            style={{ cursor: 'pointer' }}
          >
            <CrossIcon />
          </Flex>
        </Flex>
        {notificationBody}
      </Card>
      {!lastItem && <Flex style={{ borderBottom: '1px solid #535353' }} />}
    </>
  );
}

export default NotificationCard;
