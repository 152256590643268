import React from 'react';
import styled from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '24px';

const PencilSVG = styled(svgBase)`
  fill: ${(props) => props.fill || '#535353'};
  &:hover {
    fill: ${(props) => props.fillHover || '#81a2be'};
    cursor: pointer;
  }
`;
PencilSVG.displayName = 'PencilIcon-PencilSVG';

const PencilIcon = (props) => (
  <svg width={props.size || SIZE} height={props.size || SIZE} viewBox="0 0 24 24">
    <PencilSVG viewBox="0 0 20 20" {...props}>
      <path d="M7.243,18H3V13.757L14.435,2.322a1,1,0,0,1,1.414,0l2.829,2.829a1,1,0,0,1,0,1.414ZM3,20H21v2H3Z" />
    </PencilSVG>
  </svg>
);

export default PencilIcon;
