import React from 'react';
import styled, { css } from 'styled-components';
import svgBase from './StyledSVGBase';

const SIZE = '24px';

const Arrow = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: #535353;
  ${(props) =>
    props.hover &&
    css`
      fill: #81c784;
      cursor: pointer;
    `}
`;

export default function TopArrow(props) {
  return (
    <Arrow viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path d="M13,7.828V20H11V7.828L5.636,13.192,4.222,11.778,12,4l7.778,7.778-1.414,1.414Z" />
    </Arrow>
  );
}
