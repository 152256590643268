import React from 'react';

export default function OpenQuestion({ value = '', onChange = () => {} }) {
  return (
    <textarea
      placeholder="Enter specification"
      style={{
        backgroundColor: '#2c2c2c',
        border: '1px solid #535353',
        width: '100%',
        height: '100px',
        color: '#D0D0D0',
        resize: 'none',
        fontSize: '14px',
        fontFamily: 'Roboto Mono',
        padding: '5px',
        letterSpacing: '0px',
        marginBottom: '15px',
      }}
      className="OpenQuestion-text"
      onChange={(e) => {
        onChange(e.target.value);
      }}
      value={value}
    />
  );
}
