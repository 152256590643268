import React, { useState, useCallback, useEffect } from 'react';
import { Flex, Box } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import Media from 'react-media';
import debounce from 'lodash/debounce';
import {
  Button,
  Checkbox,
  Form,
  FormLabel,
  FormLeyend,
  LinkedText,
  Modal,
  QuestionCircleIcon,
  StyledText,
  Tooltip,
} from 'boss-ui';
import Api from '../../api';
import { FormInput, ScrolleableContainer, TermsConditions } from '../../components';
import { useFormFields } from '../../libs/hooks-lib';
import { useAppContext } from '../../libs/context-lib';
import { onError } from '../../libs/error-lib';
import { USER_ACKSTATEMENT, DISPLAY_NAME_REGEXP } from '../../libs/constants';
import { setUserHeaders } from '../../libs/utils-lib';

const API = new Api();

export default function FirstSignIn() {
  const [fields, handleFieldChange] = useFormFields({
    displayName: '',
    ack: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [ackVisible, setAckVisible] = useState(false);
  const [ackScrolled, setAckScrolled] = useState(false);
  const [availableName, setNameAvailable] = useState(true);
  const [searchLoading, setSearchLoading] = useState(true);
  const { user, userSession } = useAppContext();
  const history = useHistory();
  const onCheckBoxChange = (e) => {
    handleFieldChange({
      target: {
        value: e.target.checked,
        id: e.target.id,
      },
    });
  };

  const validateForm = () =>
    availableName && fields.displayName.match(DISPLAY_NAME_REGEXP) && fields.ack;

  const checkDisplayName = async (name) => {
    try {
      const data = await API.get('users', '/users/check-displayname', {
        queryStringParameters: {
          q: name,
        },
      });
      setNameAvailable(data.user.available);
    } catch (e) {
      onError(e);
      setNameAvailable(false);
    } finally {
      setSearchLoading(false);
    }
  };

  const delayedSearch = useCallback(
    debounce((q) => checkDisplayName(q), 300),
    []
  );

  const onNameChange = (e) => {
    handleFieldChange(e);
    if (e.target.value.length > 2) {
      setSearchLoading(true);
      delayedSearch(e.target.value);
    }
  };

  const handleUserAttributesSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const dynamoUser = await API.post('users', '/users', {
        body: {
          userName: user.username,
          displayName: fields.displayName,
          email: user.attributes.email,
          ackStatement: fields.ack ? USER_ACKSTATEMENT.ACCEPTED : USER_ACKSTATEMENT.REJECTED,
        },
      });
      userSession((u) => ({ ...u, dynamoUser: dynamoUser.user }));
      setUserHeaders({ dynamoUser: dynamoUser.user, ...user });
      history.push('/');
    } catch (err) {
      onError(err);
      setIsLoading(false);
    }
  };

  const handleAcceptACK = () => {
    setAckVisible(false);
    handleFieldChange({
      target: {
        value: true,
        id: 'ack',
      },
    });
  };

  useEffect(() => {
    setUserHeaders(user);
    if (user.dynamoUser) {
      history.push('/');
    }
  }, []);

  return (
    <>
      <Flex justifyContent="center">
        <Box>
          <Flex justifyContent="center" mt="100px" mb="16px">
            <StyledText size="24px" medium>
              How do you want to be known?
            </StyledText>
          </Flex>
          <Form>
            <Flex>
              <Box py="40px" px="60px" width="412px">
                {/* Box with relative position to get question mark stuck to the input */}
                <Box mb="20px" style={{ position: 'relative' }}>
                  <FormLabel>Display Name</FormLabel>
                  <FormInput
                    autoFocus
                    placeholder="Enter your nickname"
                    value={fields.displayName}
                    onChange={onNameChange}
                    id="displayName"
                    validate={fields.displayName}
                    validating={searchLoading}
                    available={availableName}
                    pattern={DISPLAY_NAME_REGEXP}
                    unavailableMessage="This name was already taken!"
                    invalidPatternMessage="From 3 to 30 length, spaces, a-z, 0-9 and _ allowed"
                    validMessage="Name is available"
                  />
                  <Flex
                    data-tip
                    data-for="displayName"
                    style={{
                      position: 'absolute',
                      right: '-30px',
                      top: '28px',
                    }}
                  >
                    <QuestionCircleIcon />
                  </Flex>
                  <Tooltip id="displayName">
                    You must choose a display name to continue. Note that your display name may be
                    visible to other participants so choose a name that protects your identity if
                    remaining anonymous is important to you. (3 characters at least, alphanumerics,
                    spaces and underscores allowed)
                  </Tooltip>
                </Box>
                <Flex>
                  <label>
                    <Checkbox checked={fields.ack} onChange={onCheckBoxChange} id="ack" />
                  </label>
                  <Box mb="20px" ml="5px">
                    <FormLeyend size="12px">
                      I acknowledge the{' '}
                      <LinkedText onClick={() => setAckVisible(true)}>
                        Terms and Conditions
                      </LinkedText>
                    </FormLeyend>
                  </Box>
                </Flex>
                <Button
                  isLoading={isLoading || searchLoading}
                  disabled={!validateForm()}
                  onClick={handleUserAttributesSubmit}
                >
                  OK
                </Button>
              </Box>
            </Flex>
          </Form>
        </Box>
      </Flex>
      {ackVisible && (
        <Flex>
          <Modal onBackgroundClick={() => setAckVisible(false)} margin="5% auto">
            <Form>
              <Box maxWidth="600px">
                <Flex justifyContent="center" width="600px" py="15px" backgroundColor="#2C2C2C">
                  <FormLeyend size="20px">Splunk Terms and Conditions</FormLeyend>
                </Flex>
                <ScrolleableContainer setScrolled={setAckScrolled}>
                  <Flex py="12px" px="8px" width={1}>
                    <TermsConditions />
                  </Flex>
                </ScrolleableContainer>
                <Flex px="38px" py="24px" backgroundColor="#2C2C2C">
                  <Media query="(max-width: 40em)">
                    {(matches) => (
                      <Flex ml="auto" width={matches ? 1 : 1 / 2}>
                        <Box ml="auto" width={1 / 2}>
                          <Button onClick={() => setAckVisible(false)} secondary>
                            DECLINE
                          </Button>
                        </Box>
                        <Box ml="20px" width={1 / 2}>
                          <Button onClick={handleAcceptACK} disabled={!ackScrolled}>
                            ACKNOWLEDGE
                          </Button>
                        </Box>
                      </Flex>
                    )}
                  </Media>
                </Flex>
              </Box>
            </Form>
          </Modal>
        </Flex>
      )}
    </>
  );
}
