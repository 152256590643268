import React, { useEffect, useState, useMemo } from 'react';
import Media from 'react-media';
import { Flex, Box } from 'reflexbox';
import get from 'lodash/get';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import Api from '../../api';
import { LoaderIcon, Menu, Breadcrumb } from 'boss-ui';
import { EventDetails } from '../../components';
import { useAppContext } from '../../libs/context-lib';
import { useQuery, useBreadcrumb } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { EVENT_TYPE } from '../../libs/constants';

const API = new Api();

export default function Details() {
  const [loadingEvent, setLoadingEvent] = useState(true);
  const { setEvent, event, appType } = useAppContext();
  const workshopRouteMatch = useRouteMatch('/workshops/:eventId/detail');
  const history = useHistory();
  const query = useQuery();
  const inviteCodeFromQuery = query.get('invite_code');
  const [breadcrumbs, setBreadcrumbs] = useBreadcrumb();
  const location = useLocation();

  const MENU_ITEMS = [
    { path: '/', label: 'HOME', exact: true, id: 'menuLinkHome' },
    {
      path: { pathname: '/', hash: '#play-now' },
      label: 'PLAY NOW',
      id: 'menuLinkHomePlayNow',
    },
    {
      path: `/workshops/${workshopRouteMatch.params.eventId}/detail`,
      label: 'LEARN',
      id: 'menuLinkLearn',
    },
    {
      path: { pathname: '/', hash: '#boss-events' },
      label: 'BOSS EVENTS',
      id: 'menuLinkEvents',
    },
    {
      path: `/history`,
      label: 'HISTORY',
      id: 'menuLinkHistory',
    },
  ];

  const routes = [
    {
      path: `/workshops/:eventId/detail`,
      name: get(event, 'name', 'Current workshop'),
      staticFather: {
        name: 'Learn',
        action: () => history.push({ pathname: '/', hash: '#learn' }),
      },
    },
  ];

  useEffect(() => {
    const getEvent = async (id) => {
      setLoadingEvent(true);
      try {
        const rq = await API.get('workshops', `/workshops/${id}`);
        setEvent(rq.workshop);
      } catch (e) {
        onError(e);
        history.push('/');
      } finally {
        setLoadingEvent(false);
      }
    };

    if (event) {
      setLoadingEvent(false);
    } else {
      getEvent(workshopRouteMatch.params.eventId);
    }
  }, [workshopRouteMatch]);

  useMemo(() => {
    setBreadcrumbs(routes, workshopRouteMatch);
  }, [location, event]);

  if (loadingEvent) {
    return (
      <Flex mt="10%" alignItems="center" justifyContent="center" flexDirection="column">
        <LoaderIcon appType={appType} />
      </Flex>
    );
  }

  return (
    <>
      <Menu menuItems={MENU_ITEMS} />
      <Media
        queries={{
          s: '(max-width: 759px)',
          m: '(max-width: 900px)',
        }}
      >
        {(matches) => (
          <Flex justifyContent="center" width={1}>
            <Box mx={matches.m ? '20px' : '10%'} mb="5%" mt="30px">
              <Flex my="30px">
                <Breadcrumb crumbs={breadcrumbs} />
              </Flex>
              <EventDetails
                id={event.workshopId}
                imageURL={event.imageURL}
                type={event.type}
                eventType={EVENT_TYPE.WORKSHOP}
                name={event.name}
                description={event.description}
                confirmationNumberRequired={event.confirmationNumberRequired}
                activeStartDate={event.activeStartDate}
                activeEndDate={event.activeEndDate}
                startDate={event.startDate}
                delivery={event.delivery}
                registrationStatus={event.registrationStatus}
                registrationOpenDate={event.registrationOpenDate}
                registrationClosedDate={event.registrationClosedDate}
                inviteCode={inviteCodeFromQuery}
              />
            </Box>
          </Flex>
        )}
      </Media>
    </>
  );
}
