import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { Button } from 'boss-ui';

const BodyContainer = styled(Flex)`
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
  padding-top: 30px;
  padding-bottom: 30px;
  font-weight: normal;
  border: 1px solid #81c784;
  width: 608px;
  min-height: 400px;
  align-items: center;
  max-width: 924px;
  flex-direction: column;
  height: 100%;
  ${(props) =>
    props.required &&
    css`
      border: 1px solid #e06c75;
    `}
`;
BodyContainer.displayName = 'Survey-BodyContainer';

const MainContainer = styled(Flex)`
  flex-direction: column;
  justifycontent: space-between;
  align-items: flex-start;
  padding-left: 60px;
  padding-right: 60px;
  width: 100%;
`;
MainContainer.displayName = 'Survey-MainContainer';

const SecondaryContainer = styled(Flex)`
  width: 100%;
  min-height: 480px;
  flex-direction: column;
  justify-content: space-between;
`;
SecondaryContainer.displayName = 'Survey-SecondaryContainer';

const TextIntroContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
`;
TextIntroContainer.displayName = 'Survey-TextIntroContainer';

const SubmitedSucess = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 370px;
  font-size: 40px;
  color: #D0D0D0;
  margin-bottom: 23px;
`;
SubmitedSucess.displayName = 'Survey-SubmitedSucess';

const ActionsContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: ${props => props.showOnDismiss ? 'space-between' : 'flex-end'};
  align-items: center;
  margin-top: 25px;
`;
ActionsContainer.displayName = 'Survey-ActionsContainer';

const PrevButton = styled(Button)`
  width: 100px;
  height: 40px;
  margin-right: 32px;
`;
PrevButton.displayName = 'Survey-PrevButton';

const SubmitButton = styled(Button)`
  width: 170px;
  height: 40px;
`;
SubmitButton.displayName = 'Survey-SubmitButton';

const NextButton = styled(Button)`
  width: 100px;
  height: 40px;
`;
NextButton.displayName = 'Survey-NextButton';

export {
  BodyContainer,
  MainContainer,
  SecondaryContainer,
  TextIntroContainer,
  SubmitedSucess,
  ActionsContainer,
  PrevButton,
  SubmitButton,
  NextButton,
};