import React, { useState, useRef } from 'react';
import { Flex, Box } from 'reflexbox';
import styled, { useTheme } from 'styled-components';
import RegularText from '../RegularText';
import UploadIcon from '../../icons/UploadIcon';
import FileIcon from '../../icons/FileIcon';

const Container = styled(Flex)`
  max-width: 488px;
  height: 137px;
  padding: 20px;
  background-color: ${(props) => props.theme.color.dragDrop.background};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed
    ${(props) =>
      props.draggedOn
        ? props.theme.color.dragDrop.border.draggedOn
        : props.theme.color.dragDrop.border.draggedOff};
  margin-top: 6px;
`;
Container.displayName = 'DragDrop-Container';

export default function DragDrop({ onFileChange }) {
  const theme = useTheme();
  const [draggedOn, setDraggedOn] = useState(false);
  const [fileName, setFileName] = useState(null);
  const hiddenFileInput = useRef(null);

  const dragOver = (e) => {
    e.preventDefault();
    setDraggedOn(true);
  };

  const dragEnter = (e) => {
    e.preventDefault();
    setDraggedOn(true);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setDraggedOn(false);
  };

  const fileDrop = (e) => {
    e.preventDefault();
    setDraggedOn(false);
    const files = e.dataTransfer.files;
    if (files.length && files[0].name) {
      setFileName(files[0].name);
      onFileChange(files[0]);
    }
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const onChangeFile = async (evt) => {
    if (evt.target.files && evt.target.files[0]) {
      setFileName(evt.target.files[0].name);
      onFileChange(evt.target.files[0]);
    }
  };

  return (
    <>
      <Container
        draggedOn={draggedOn}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
      >
        <Flex mb="4px">
          <UploadIcon />
        </Flex>
        <RegularText mediumWeight fontSize="14px">
          Drag &#38; Drop
        </RegularText>
        <RegularText mediumWeight fontSize="14px" my="6px">
          or
        </RegularText>
        <RegularText
          onClick={handleClick}
          mediumWeight
          fontSize="14px"
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
        >
          Browse file
        </RegularText>
        <input
          type="file"
          onChange={onChangeFile}
          onClick={(evt) => {
            // eslint-disable-next-line no-param-reassign
            evt.target.value = null;
          }}
          ref={hiddenFileInput}
          style={{ display: 'none' }}
          multiple={false}
        />
      </Container>
      {fileName && (
        <Flex mt="20px" width={1} alignItems="center">
          <Box>
            <FileIcon />
          </Box>
          <RegularText
            mediumWeight
            fontSize="14px"
            ml="10px"
            pt="4px"
            color={theme.color.dragDrop.color}
          >
            {fileName}
          </RegularText>
        </Flex>
      )}
    </>
  );
}
