import React from 'react';
import styled from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '40px';

const Base = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: #d0d0d0;
  &:hover {
    fill: #66bb6a;
  }
`;

export default function NotificationIcon({ showDot = false }) {
  return (
    <Base viewBox="0 0 40 40">
      <g transform="translate(8 8)">
        <path fill="none" d="M0,0H24V24H0Z" />
        <path d="M22,20H2V18H3V11.031a9,9,0,1,1,18,0V18h1ZM9.5,21h5a2.5,2.5,0,1,1-5,0Z" />
        {showDot && <circle cx="28" cy="5" r="4" fill="#66bb6a" />}
      </g>
    </Base>
  );
}
