import React, { useState, useEffect } from 'react';
import styled, { css, keyframes, useTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import { Flex } from 'reflexbox';
import Media from 'react-media';
import LinkedText from '../LinkedText';
import SubMenu from './SubMenu';
import ContextMenu from '../ContextMenu';
import ContextMenuItem from '../ContextMenuItem';
import TreeDotsIcon from '../../icons/TreeDotsIcon';

const DEFAULT_MENU_HEIGHT = 50;
const DEFAULT_SCROLL_OFFSET_LIMIT = 170;
const DEFAULT_MOBILE_MAX_WIDTH = '600px';

const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 0.5;
}
`;

const Link = styled(NavHashLink)`
  align-items: center;
  margin-right: 20px;
  font-family: ${(props) => props.theme.font.family};
  font-size: 14px;
  position: relative;
  background-color: ${(props) => props.theme.color.navigationMenu.background.inactive};
  color: ${(props) => props.theme.color.navigationMenu.color.inactive};
  text-decoration: none;
  padding-bottom: 15px;

  &.active {
    border-bottom: 1px solid;
    color: ${(props) => props.theme.color.navigationMenu.color.active};
    border-bottom-color: ${(props) => props.theme.color.navigationMenu.border.active};
  }

  &:hover,
  &:focus {
    border-bottom: 1px solid;
    color: ${(props) => props.theme.color.navigationMenu.color.active};
    border-bottom-color: ${(props) => props.theme.color.navigationMenu.border.active};
  }
`;
Link.displayName = 'Menu-Link';

const Container = styled(Flex)`
  overflow: hidden;
  ${(props) =>
    props.scrolled &&
    css`
      position: fixed;
      width: 100%;
      width: -moz-available; /* WebKit-based browsers will ignore this. */
      z-index: 1000;
      top: 0;
      animation: ${fadeIn} 0.5s ease;
      -moz-animation: ${fadeIn} 0.5s ease;
      -webkit-animation: ${fadeIn} 0.5s ease;
    `}
  height: ${(props) => props.menuHeight}px;
  background-color: ${(props) => props.theme.color.navigationMenu.background.inactive};
  box-shadow: 0px 3px 6px #00000029;
`;
Container.displayName = 'Menu-Container';

const isLinkActive = (match = false, menuItem = {}, location = {}) => {
  if (!match) {
    return false;
  }
  // location hash is empty && menuItem hash is undefined
  if (location.hash === '' && menuItem.path && !menuItem.path.hash) {
    return true;
  }
  // location hash is different than menuItem hash
  if (menuItem.path && location.hash !== menuItem.path.hash) {
    return false;
  }
  return true;
};

function Menu({
  menuItems = [],
  auxiliaryItems = [],
  menuHeight = DEFAULT_MENU_HEIGHT,
  scrollLimit = DEFAULT_SCROLL_OFFSET_LIMIT,
  mobileMaxWidth = DEFAULT_MOBILE_MAX_WIDTH,
}) {
  const theme = useTheme();
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const scrollOffset = scrolled ? window.scrollY + menuHeight : window.scrollY;
    if (scrollOffset > scrollLimit) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <Media
      queries={{
        s: `(max-width: ${mobileMaxWidth})`,
      }}
    >
      {(screenSize) => (
        <Container
          alignItems="flex-end"
          justifyContent="space-between"
          menuHeight={menuHeight}
          width={1}
          px="20px"
          scrolled={scrolled}
        >
          <Flex>
            {menuItems.map((i) =>
              i.subMenu ? (
                <SubMenu
                  items={i.subMenu}
                  label={i.label.toUpperCase()}
                  rootPath={i.rootPath}
                  key={`${i.label}${i.rootPath}`}
                  id={i.id}
                />
              ) : (
                <Link
                  to={i.path}
                  key={i.id}
                  elementId={i.elementId}
                  // custom implementation of scroll to take into account the header height
                  scroll={(el) => {
                    // dispatch a custom event in order to disable Home auto scroll
                    window.dispatchEvent(new Event('menu-fired-scroll'));
                    window.scroll({ top: el.offsetTop - 115 });
                  }}
                  exact={i.exact}
                  isActive={(match, location) => isLinkActive(match, i, location)}
                >
                  {i.label.toUpperCase()}
                </Link>
              )
            )}
          </Flex>
          {screenSize.s ? (
            <Flex mr="-12px">
              {!!auxiliaryItems.length && (
                <ContextMenu button={<TreeDotsIcon size="40px" />}>
                  {auxiliaryItems.map((auxItem) => (
                    <ContextMenuItem key={auxItem.id}>
                      <LinkedText
                        font={theme.font.family}
                        color={
                          auxItem.dangerTheme
                            ? theme.color.navigationMenu.color.inactive
                            : theme.color.navigationMenu.color.active
                        }
                        hoverColor={
                          auxItem.dangerTheme
                            ? theme.color.navigationMenu.hover.danger
                            : theme.color.navigationMenu.hover.inactive
                        }
                        fontSize="10px"
                        onClick={auxItem.onClick}
                        id={auxItem.id}
                      >
                        {auxItem.label}
                      </LinkedText>
                    </ContextMenuItem>
                  ))}
                </ContextMenu>
              )}
            </Flex>
          ) : (
            <Flex>
              {auxiliaryItems.map((auxItem) => (
                <Flex key={auxItem.id} pb="15px" ml="20px" style={{ fontSize: '14px' }}>
                  <LinkedText
                    font={theme.font.family}
                    color={
                      auxItem.dangerTheme
                        ? theme.color.navigationMenu.color.inactive
                        : theme.color.navigationMenu.color.active
                    }
                    hoverColor={
                      auxItem.dangerTheme
                        ? theme.color.navigationMenu.hover.danger
                        : theme.color.navigationMenu.hover.inactive
                    }
                    fontSize="14px"
                    onClick={auxItem.onClick}
                    id={auxItem.id}
                  >
                    {auxItem.label}
                  </LinkedText>
                </Flex>
              ))}
            </Flex>
          )}
        </Container>
      )}
    </Media>
  );
}

export default withRouter(Menu);
