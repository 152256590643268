import React, { useState } from 'react';
import { Flex } from 'reflexbox';
import { GridIcon, ListIcon } from 'boss-ui';
import { DISPLAY_QUESTIONS_MODE } from '../../libs/constants';

export default function ScenarioQuestionsDisplaySelector({ onSelected, selected }) {
  const [listHover, setListHover] = useState(false);
  const [gridHover, setGridHover] = useState(false);
  return (
    <Flex ml="auto" width="60px" justifyContent="space-between">
      <Flex
        onMouseEnter={() => setListHover(true)}
        onMouseLeave={() => setListHover(false)}
        onClick={() => onSelected(DISPLAY_QUESTIONS_MODE.LIST)}
        className="ScenarioQuestionsDisplaySelector-ListIcon"
      >
        <ListIcon hover={listHover} selected={selected === DISPLAY_QUESTIONS_MODE.LIST} />
      </Flex>
      <Flex
        onMouseOver={() => setGridHover(true)}
        onMouseLeave={() => setGridHover(false)}
        onClick={() => onSelected(DISPLAY_QUESTIONS_MODE.GRID)}
        className="ScenarioQuestionsDisplaySelector-GridIcon"
      >
        <GridIcon hover={gridHover} selected={selected === DISPLAY_QUESTIONS_MODE.GRID} />
      </Flex>
    </Flex>
  );
}
