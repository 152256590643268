import React from 'react';

export default function InviteOnlyIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} {...props} viewBox="0 0 24 24">
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill="#fff"
        d="M3.783,2.826,12,1l8.217,1.826A1,1,0,0,1,21,3.8v9.987a6,6,0,0,1-2.672,4.992L12,23,5.672,18.781A6,6,0,0,1,3,13.79V3.8a1,1,0,0,1,.783-.976ZM12,13.5l2.939,1.545-.561-3.272,2.377-2.318-3.286-.478L12,6,10.53,8.977l-3.285.478,2.377,2.318-.56,3.272Z"
      />
    </svg>
  );
}
