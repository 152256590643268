import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { RegularText } from 'boss-ui';
import { Button } from 'boss-ui';

const MainContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-left: 60px;
  padding-right: 60px;
  height: ${(props) => props.scrolled ? {} : '350px'}
`;
MainContainer.displayName = 'SurveyIntroduction-MainContainer';

const ContentContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
ContentContainer.displayName = 'SurveyIntroduction-ContentContainer';

const Name = styled(Flex)`
  font-size: 28px;
  color: #D0D0D0;
  margin-bottom: 23px;
`;
Name.displayName = 'SurveyIntroduction-Name';

const Description = styled(RegularText)`
  color: #D0D0D0;
  letter-spacing: 0px;
  line-height: 27px;
`;
Description.displayName = 'SurveyIntroduction-Description';

const RequiredContainer = styled(Flex)`
  flex-direction: row;
  width: 100%;
  margin-top: 15px;
`;
RequiredContainer.displayName = 'SurveyIntroduction-RequiredContainer';

const Star = styled(RegularText)`
  color: #e06c75;
  position: relative;
  top: -5px;
`;
Star.displayName = 'SurveyIntroduction-Star';

const Required = styled(RegularText)`
  color: #e06c75;
  margin-left: 3px;
`;
Required.displayName = 'SurveyIntroduction-Required';

const ActionsContainer = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: ${props => props.showOnDismiss ? 'space-between' : 'flex-end'};
  align-items: center;
`;
ActionsContainer.displayName = 'SurveyIntroduction-ActionsContainer';

const ButtonWrapper = styled(Button)`
  width: 170px;
  height: 40px;
`;
ButtonWrapper.displayName = 'SurveyIntroduction-ButtonWrapper';

export {
  MainContainer,
  ContentContainer,
  Name,
  Description,
  RequiredContainer,
  Star,
  Required,
  ActionsContainer,
  ButtonWrapper
};