import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import Media from 'react-media';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, UnregularButton } from 'boss-ui';
import CardBody from './CardBody';
import CardTitle from './CardTitle';
import { SKELTELON_STYLE } from '../../libs/constants';

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
  box-shadow: '0px 3px 6px #00000029';
`;
Container.displayName = 'CardSkeletonContainer';

const CollapsableDescription = () => {
  return (
    <>
      <UnregularButton disabled>
        <Skeleton width={100} />
      </UnregularButton>
    </>
  );
};

function CardSkeletonBlock() {
  return (
    <Container width="330px" flexDirection="column" margin="15px 30px 15px 0">
      <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
        <Skeleton height="186px" width="100%" />
      </SkeletonTheme>
      <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
        <CardBody
          alignItems="start"
          flexDirection="column"
          height="100%"
          py="45px"
          px="60px"
          width="330px"
          style={{
            borderColor: 'transparent',
          }}
        >
          <CardTitle>
            <Skeleton width="285px" />
          </CardTitle>
          <CollapsableDescription />
          <Flex mt="66px" ml="auto" minWidth="100px">
            <Button disabled>ENTER</Button>
          </Flex>
        </CardBody>
      </SkeletonTheme>
    </Container>
  );
}

function CardSkeletonLandscapeBlock() {
  return (
    <Container flexDirection="column" margin="15px 30px 15px 0">
      <Flex width={1} flexDirection="row">
        <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
          <Skeleton height="186px" width="330px" />
        </SkeletonTheme>
        <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
          <CardBody
            alignItems="start"
            flexDirection="column"
            height="100%"
            px="60px"
            style={{
              borderColor: 'transparent',
              width: '600px',
              paddingTop: '2px',
            }}
          >
            <CardTitle>
              <Skeleton width="300px" />
            </CardTitle>
          </CardBody>
        </SkeletonTheme>
      </Flex>
      <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
        <Skeleton height="25px" width="100%" style={{ marginTop: '20px', marginBottom: '20px' }} />
      </SkeletonTheme>
    </Container>
  );
}

export default function CardSkeleton({ landscape, style }) {
  return (
    <Media
      queries={{
        s: '(min-width: 760px)',
        m: '(min-width: 1350px)',
        l: '(min-width: 1800px)',
      }}
    >
      {(matches) => (
        <Flex style={style}>
          {landscape ? (
            <CardSkeletonLandscapeBlock />
          ) : (
            <Flex flexDirection="row" alignItems="flex-start" flexWrap="wrap">
              <CardSkeletonBlock />
              {matches.s && <CardSkeletonBlock />}
              {matches.m && <CardSkeletonBlock />}
              {matches.l && <CardSkeletonBlock />}
            </Flex>
          )}
        </Flex>
      )}
    </Media>
  );
}
