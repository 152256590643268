import React from 'react';
import { Box } from 'reflexbox';
import styled from 'styled-components';

const PROFILE_BOX_WIDTH_REGULAR = 420;
const PROFILE_BOX_WIDTH_MOBILE = 320;

const Background = styled.div`
  position: fixed;
  z-index: 900;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: transparent;
`;

const Arrow = styled.div`
  width: 0;
  height: 0;
  margin-left: ${(props) => (props.mobile ? '270px' : '370px')};
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  box-shadow: 0px 4px 10px #00000059;
  border-bottom: 13px solid ${(props) => props.theme.color.profile.border};
`;

export default function FloatingMenu({ onBackgroundClick, mobile, children, parentRef = null }) {
  let floatingLeftMargin = document.documentElement.clientWidth;

  if (parentRef && parentRef.current) {
    floatingLeftMargin = parentRef.current.getBoundingClientRect().x + 60;
  }

  return (
    <Background onClick={() => onBackgroundClick && onBackgroundClick()}>
      <Box
        ml={
          mobile
            ? floatingLeftMargin - PROFILE_BOX_WIDTH_MOBILE
            : floatingLeftMargin - PROFILE_BOX_WIDTH_REGULAR
        }
        mt="48px"
        style={{ position: 'absolute' }}
        justifyContent="right"
        onClick={(e) => e.stopPropagation()}
      >
        <Arrow mobile={mobile} />
        {children}
      </Box>
    </Background>
  );
}
