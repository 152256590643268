import React, { useState, useEffect } from 'react';
import { Flex } from 'reflexbox';
import { SpinnerIcon, Markdown } from 'boss-ui';
import { onError } from '../libs/error-lib';
import config from '../config';

export default function TermsAndConditions() {
  const [loadingTerms, setLoadingTerms] = useState(true);
  const [termsAndConditionsMD, setTermsAndConditionsMD] = useState(null);

  useEffect(() => {
    const fetchTerms = async () => {
      setLoadingTerms(true);
      try {
        const rq = await fetch(config.termsConditionsUrl, {
          headers: { 'Cache-Control': 'max-age=0, must-revalidate' },
        });
        if (rq.ok) {
          setTermsAndConditionsMD(await rq.text());
        }
      } catch (e) {
        onError(e);
        setTermsAndConditionsMD(
          'Something went wrong getting the terms and conditions, please try again'
        );
      } finally {
        setLoadingTerms(false);
      }
    };

    fetchTerms();
  }, []);

  if (loadingTerms) {
    return (
      <Flex width={1} justifyContent="center">
        <SpinnerIcon />
      </Flex>
    );
  }

  return <Markdown md={termsAndConditionsMD} />;
}
