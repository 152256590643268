import get from 'lodash/get';
import { toast } from 'react-toastify';

export function onError(error = {}) {
  let message = error.toString();
  if (error.message === ERROR_NAMES.ABORTED_REQUEST) {
    return;
  }
  if (error.message) {
    message = error.message;
  }
  if (error && error.response) {
    const errors = get(error, 'response.data.errors', []);
    if (errors.length > 0) {
      errors.forEach((e) => toast(e.message));
      return;
    }
    message = get(error, 'response.data.message');
  }
  toast(message);
}

export function getMessageError(error) {
  let message = error.toString();

  if (error.message) {
    message = error.message;
  }
  if (error && error.response) {
    const errors = get(error, 'response.data.errors', []);
    if (errors.length > 0) {
      message = '';
      errors.forEach((e) => {
        message += e.message;
      });
      return message;
    }
    message = get(error, 'response.data.message');
  }
  return message;
}

export const ERROR_NAMES = {
  ABORTED_REQUEST: 'Request aborted',
  SESSION_EXPIRED: 'Session expired',
};
