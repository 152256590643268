import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { WorkshopIcon, InviteOnlyIcon } from './icons';
import Api from '../api';
import {
  RegularText,
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
  Tooltip,
  GameIcon,
} from 'boss-ui';
import BossLabel from './BossLabel';
import WorkshopContent from './workshops/WorkshopContent';
import WorkshopVideoProgress from './workshops/WorkshopVideoProgress';
import WorkshopProgressLabel from './workshops/WorkshopProgressLabel';
import CardDate from './cards/CardDate';
import CardDescription from './cards/CardDescription';
import CardIconContainer from './cards/CardIconContainer';
import CardTitle from './cards/CardTitle';
import EventLabel from './EventLabel';
import { onError } from '../libs/error-lib';
import { formatMinutes } from '../libs/dates-lib';
import { EVENT_TYPE, EVENT_DELIVERY, SKELTELON_STYLE } from '../libs/constants';

const BodyContainer = styled(Flex)`
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
  padding: ${(props) => props.theme.margin.s};
  font-family: 'Roboto Mono';
  font-weight: normal;
  border: 1px solid #a088a4;
  width: 100%;
`;
BodyContainer.displayName = 'HistoryDetails-BodyContainer';

const transformEventStats = (stats, scenarios = [], scenariosScores = {}, isOnDemand = false) => {
  if (!stats || isEmpty(stats)) {
    return [];
  }
  const transformedStats = [
    {
      id: 0,
      scenario: 'Overall',
      totalScore: stats.total || 0,
      baseScore: stats.base || 0,
      bonusScore: stats.bonus || 0,
      penalty: -stats.penalty || 0,
      average: 0,
      median: 0,
    },
  ];
  // on-demand event has not scenarios
  if (stats.scenarios) {
    scenarios.forEach((s, id) => {
      const scenarioStat = stats.scenarios[s.scenarioId];
      if (!isOnDemand && scenariosScores && scenariosScores[s.scenarioId]) {
        const average =
          (scenariosScores[s.scenarioId].total || scenariosScores[s.scenarioId].total === 0) &&
          scenariosScores[s.scenarioId].count
            ? Number(
                scenariosScores[s.scenarioId].total / scenariosScores[s.scenarioId].count
              ).toFixed(0)
            : 0;
        const median =
          (scenariosScores[s.scenarioId].max || scenariosScores[s.scenarioId].max === 0) &&
          (scenariosScores[s.scenarioId].min || scenariosScores[s.scenarioId].min === 0)
            ? Number(
                (scenariosScores[s.scenarioId].max + scenariosScores[s.scenarioId].min) / 2
              ).toFixed(0)
            : 0;
        transformedStats[0].average += Number(average);
        transformedStats[0].median += Number(median);
        transformedStats.push({
          id: id + 1,
          scenario: s.title,
          totalScore: get(scenarioStat, 'total', 0),
          baseScore: get(scenarioStat, 'base', 0),
          bonusScore: get(scenarioStat, 'bonus', 0),
          penalty: -get(scenarioStat, 'penalty', 0),
          average: Number(average),
          median: Number(median),
        });
      } else if (isOnDemand) {
        transformedStats.push({
          id: id + 1,
          scenario: s.title,
          totalScore: get(scenarioStat, 'total', 0),
          baseScore: get(scenarioStat, 'base', 0),
          bonusScore: get(scenarioStat, 'bonus', 0),
          penalty: -get(scenarioStat, 'penalty', 0),
          average: 0,
          median: 0,
        });
      }
    });
  }
  if (transformedStats[0].average === 0) {
    transformedStats[0].average = '-';
  }
  if (transformedStats[0].median === 0) {
    transformedStats[0].median = '-';
  }
  return transformedStats;
};

const API = new Api();

export default function HistoryDetails({
  id,
  type,
  eventType,
  name,
  description,
  startDate,
  delivery,
  isRestrictedEvent = false,
  duration = 0,
  scenarios = [],
  scenariosScores = {},
  globalTeamsCount,
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [scenarioData, setScenarioData] = useState([]);
  const [stats, setStats] = useState({});
  const [workshopContentWithStats, setWorkshopContentWithStats] = useState([]);
  const [userTeamName, setUserTeamName] = useState('');
  const history = useHistory();
  const showDate = delivery === EVENT_DELIVERY.NORMAL;
  const isOnDemand = delivery === EVENT_DELIVERY.ON_DEMAND;

  useEffect(() => {
    setLoadingData(true);
    const getScenarioDetails = async () => {
      try {
        if (eventType === EVENT_TYPE.GAME) {
          const statsRq = await API.get('users', `/user/events/${id}/stats`);
          setScenarioData(
            transformEventStats(statsRq.stats, scenarios, scenariosScores, isOnDemand)
          );
          if (!isOnDemand) {
            setUserTeamName(get(statsRq, 'stats.team.name'));
          }
          setStats(statsRq.stats);
        } else {
          const rq = await API.get('users', `/user/workshops/${id}`);
          setStats(rq.stats);
          setWorkshopContentWithStats(get(rq, 'workshop.content.workshop', []));
        }
      } catch (e) {
        onError(e);
        history.push('/history');
      } finally {
        setLoadingData(false);
      }
    };
    getScenarioDetails();
  }, []);

  if (!id) {
    history.push('/');
    return null;
  }

  return (
    <BodyContainer alignItems="start" maxWidth="924px" flexDirection="column" height="100%">
      <Flex width={1} alignItems="center">
        <CardIconContainer>
          {eventType === EVENT_TYPE.WORKSHOP ? <WorkshopIcon /> : <GameIcon />}
        </CardIconContainer>
        {isRestrictedEvent && (
          <>
            <Flex data-tip data-for="inviteOnlyIcon" pl="8px" pr="4px">
              <InviteOnlyIcon />
            </Flex>
            <Tooltip id="inviteOnlyIcon">INVITE ONLY</Tooltip>
          </>
        )}
        <BossLabel type={type} ml="6px" />
        {eventType === EVENT_TYPE.WORKSHOP ? (
          <Flex ml="auto">
            <WorkshopProgressLabel stats={stats} style={{ position: 'static' }} forceProgress />
          </Flex>
        ) : (
          <>
            {showDate && startDate && (
              <Flex ml="auto">
                <CardDate date={startDate} />
              </Flex>
            )}
          </>
        )}
      </Flex>
      {isRestrictedEvent && (
        <Flex
          style={{
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginLeft: '22px',
            marginLop: '1px',
          }}
        >
          <EventLabel style={{ position: 'absolute', marginTop: '-67px' }}>
            Private Event
          </EventLabel>
        </Flex>
      )}
      <CardTitle style={{ color: '#A088A4' }}>{name}</CardTitle>
      <CardDescription style={{ marginBottom: '20px' }}>{description}</CardDescription>
      {loadingData ? (
        <Box style={{ width: '100%' }}>
          <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
            <Skeleton />
          </SkeletonTheme>
        </Box>
      ) : (
        <>
          {eventType === EVENT_TYPE.WORKSHOP ? (
            <Flex
              width={1}
              style={{ wordBreak: 'break-all' }}
              flexDirection="column"
              className="HistoryDetails-WorkshopContainer"
            >
              <WorkshopVideoProgress stats={stats} style={{ width: '250px' }} />
              <WorkshopContent content={workshopContentWithStats} />
            </Flex>
          ) : (
            <>
              {isOnDemand ? (
                <Flex color="#81A2BE" className="HistoryDetails-Duration">
                  Event Duration: {formatMinutes(duration).hour}h {formatMinutes(duration).minutes}m
                </Flex>
              ) : (
                <>
                  {stats && stats.rank && globalTeamsCount && (
                    <Flex color="#A5D6A7" pb="8px">
                      Place: {stats.rank}/{globalTeamsCount}
                    </Flex>
                  )}
                  {userTeamName && (
                    <Flex color="#D0D0D0" pb="8px">
                      Team: {userTeamName}
                    </Flex>
                  )}
                </>
              )}
              {scenarioData.length ? (
                <Table style={{ marginTop: '40px' }}>
                  <TableHead>
                    <TableRow style={{ height: '60px' }}>
                      <TableHeader
                        style={{ paddingLeft: '20px', borderRight: '1px solid #1D1D1D' }}
                      >
                        Scenario
                      </TableHeader>
                      <TableHeader
                        width="60px"
                        style={{ paddingLeft: '10px', borderRight: '1px solid #1D1D1D' }}
                      >
                        Total Score
                      </TableHeader>
                      <TableHeader
                        width="60px"
                        style={{ paddingLeft: '10px', borderRight: '1px solid #1D1D1D' }}
                      >
                        Base Score
                      </TableHeader>
                      <TableHeader
                        width="60px"
                        style={{ paddingLeft: '10px', borderRight: '1px solid #1D1D1D' }}
                      >
                        Bonus Score
                      </TableHeader>
                      <TableHeader
                        width="60px"
                        style={{ paddingLeft: '10px', borderRight: '1px solid #1D1D1D' }}
                      >
                        Penalty
                      </TableHeader>
                      {!isOnDemand && (
                        <>
                          <TableHeader
                            width="60px"
                            style={{ paddingLeft: '10px', borderRight: '1px solid #1D1D1D' }}
                          >
                            Average
                          </TableHeader>
                          <TableHeader
                            width="60px"
                            style={{ paddingLeft: '10px', borderRight: '1px solid #1D1D1D' }}
                          >
                            Median
                          </TableHeader>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {scenarioData.map((row, idx) => {
                      const key = get(row, 'id', idx);
                      return (
                        <TableRow
                          key={key}
                          style={
                            key === 0
                              ? { height: '50px', border: '1px solid #A5D6A7', color: '#A5D6A7' }
                              : { height: '50px' }
                          }
                        >
                          <TableData
                            style={{ paddingLeft: '20px', borderRight: '1px solid #2C2C2C' }}
                          >
                            {row.scenario}
                          </TableData>
                          <TableData
                            style={{ paddingLeft: '10px', borderRight: '1px solid #2C2C2C' }}
                          >
                            {row.totalScore}
                          </TableData>
                          <TableData
                            style={{ paddingLeft: '10px', borderRight: '1px solid #2C2C2C' }}
                          >
                            {row.baseScore}
                          </TableData>
                          <TableData
                            style={{ paddingLeft: '10px', borderRight: '1px solid #2C2C2C' }}
                          >
                            {row.bonusScore}
                          </TableData>
                          <TableData
                            style={
                              isOnDemand
                                ? { paddingLeft: '10px' }
                                : { paddingLeft: '10px', borderRight: '1px solid #2C2C2C' }
                            }
                          >
                            {row.penalty}
                          </TableData>
                          {!isOnDemand && (
                            <>
                              <TableData
                                style={{ paddingLeft: '10px', borderRight: '1px solid #2C2C2C' }}
                              >
                                {row.average}
                              </TableData>
                              <TableData style={{ paddingLeft: '10px' }}>{row.median}</TableData>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Flex
                  mt="20px"
                  width={1}
                  p="8px"
                  style={{
                    border: '1px solid #898989',
                  }}
                >
                  <RegularText>No statistical data for this event</RegularText>
                </Flex>
              )}
            </>
          )}
        </>
      )}
    </BodyContainer>
  );
}
