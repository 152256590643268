import React from 'react';

export default function UserSlashIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 50}
      height={props.size || 50}
      viewBox="0 0 120 120"
    >
      <g transform="translate(-150.515 145.018)">
        <circle
          fill="#e06c75"
          opacity="0.25"
          cx="60"
          cy="60"
          r="60"
          transform="translate(150.515 -145.018)"
        />
        <path fill="none" d="M174.515-121.018h72v72h-72Z" />
        <path
          fill="#1d1d1d"
          d="M225.515-82.018a12,12,0,0,1,12,12,12,12,0,0,1-12,12,12.13,12.13,0,0,1-12-12h-6A12,12,0,0,1,195.5-58.031a12,12,0,0,1-11.987-12.014,12,12,0,0,1,12.014-11.986,12,12,0,0,1,10.386,6.013h9.2A11.994,11.994,0,0,1,225.515-82.018Zm-45-3v-6h6v-9a12,12,0,0,1,12-12h24a12,12,0,0,1,12,12v9h6v6Z"
        />
        <path
          fill="#af585e"
          isolation="isolate"
          d="M251.749-62.55a18.766,18.766,0,0,0-18.766,18.766,18.766,18.766,0,0,0,18.766,18.766,18.766,18.766,0,0,0,18.766-18.766A18.787,18.787,0,0,0,251.749-62.55ZM237.675-43.784A14.05,14.05,0,0,1,251.7-57.859a14.051,14.051,0,0,1,8.189,2.616l-19.6,19.6A13.985,13.985,0,0,1,237.675-43.784Zm22.264,11.458a14.048,14.048,0,0,1-8.19,2.617,13.988,13.988,0,0,1-8.142-2.616l19.6-19.6A14.05,14.05,0,0,1,259.939-32.326Z"
        />
      </g>
    </svg>
  );
}
