import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import styled, { css } from 'styled-components';
import { ExpandIcon, CollapseIcon, CheckMark } from '../icons';

const WorkshopTopic = ({ title, subTopics, progress }) => {
  const [visible, setVisible] = useState(false);
  return (
    <Box width={1}>
      <TopicTitle onTitleClick={() => setVisible((v) => !v)} visible={visible} progress={progress}>
        {title}
      </TopicTitle>
      {visible &&
        subTopics.map((st) => {
          if (st.children.length) {
            return (
              <WorkshopTopic
                title={st.title}
                subTopics={st.children}
                key={st.title}
                progress={st.stats}
              />
            );
          }
          return (
            <TopicChildren key={st.title} isDone={st.isCompleted}>
              {st.isCompleted && (
                <Box minWidth="24px">
                  <CheckMark />
                </Box>
              )}
              <Flex ml="6px">{st.title}</Flex>
            </TopicChildren>
          );
        })}
    </Box>
  );
};

const TopicTitle = ({ visible, children, onTitleClick, progress = {} }) => {
  const showProgress = progress.total > 0;
  return (
    <Flex
      style={{ color: '#DE935F', cursor: 'pointer', fontSize: '14px' }}
      onClick={onTitleClick}
      width={1}
      p="22px"
      textAlign="left"
    >
      <div>{visible ? <CollapseIcon /> : <ExpandIcon />}</div>
      <Flex mx="6px" style={{ whiteSpace: 'break-spaces' }}>
        {children}
      </Flex>
      {!visible && showProgress && (
        <Box ml="auto" width="40px" display="table" className="WorkshopContent-TopicTitleProgress">
          {progress.progressPercentage}%
        </Box>
      )}
    </Flex>
  );
};

const TopicChildren = styled(Flex)`
  font-size: 14px;
  color: ${(props) => props.theme.color.card.classic.description};
  text-align: left;
  font-weight: 300;
  padding: 22px;
  &:hover {
    background-color: #2c2c2c;
  }
  ${(props) =>
    props.isDone &&
    css`
      background-color: #1d1d1d;
      color: ${props.theme.color.breadcrumb.active};
    `}
`;

export default function WorkshopContent({ content = [] }) {
  return (
    <Box width={1}>
      {content.map((c) => (
        <Flex
          my="6px"
          style={{ border: '1px solid #2C2C2C', fontFamily: 'Roboto Mono' }}
          width={1}
          key={c.title}
        >
          <WorkshopTopic title={c.title} subTopics={c.children} progress={c.stats} />
        </Flex>
      ))}
    </Box>
  );
}
