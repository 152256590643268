import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useQuery } from '../../hooks';

export default function AuthenticatedRoute({
  children,
  validateSession = () => true,
  fallbackRoute = '/login',
  skipRedirect = false,
  ...rest
}) {
  const { pathname, search } = useLocation();
  const query = useQuery();
  const internalRedirect = query.get('redirect');

  return (
    <>
      {validateSession() ? (
        <>
          <Route {...rest}>{children}</Route>
        </>
      ) : (
        <Route {...rest}>
          <Redirect
            to={
              internalRedirect
                ? `${fallbackRoute}?redirect=${internalRedirect}`
                : skipRedirect ? fallbackRoute
                  : `${fallbackRoute}?redirect=${pathname}${search}`
            }
          />
        </Route>
      )}
    </>
  );
}