import React from 'react';
import { Flex, Box } from 'reflexbox';
import truncate from 'lodash/truncate';
import { LinkedText, UnregularButton, TeamIcon, Item, ListItem } from 'boss-ui';
import { useTheme } from 'styled-components';
import { TEAM_VISIBILITY, TEAM_ACCESSIBILITY } from '../../libs/constants';
import TeamMembersState from './TeamMembersState';

const TeamAccessibility = ({ team, onJoinTeam, isAnInvite }) => {
  const getAccessibility = () => {
    if (team.full) {
      return 'Team is full';
    }
    if (isAnInvite) {
      return (
        <Flex>
          <UnregularButton active onClick={onJoinTeam}>
            Join Team
          </UnregularButton>
        </Flex>
      );
    }
    switch (team.accessibility) {
      case TEAM_ACCESSIBILITY.CLOSED: {
        return null;
      }
      case TEAM_ACCESSIBILITY.OPEN: {
        return (
          <Flex>
            <UnregularButton active onClick={onJoinTeam}>
              Join Team
            </UnregularButton>
          </Flex>
        );
      }
      default:
        return null;
    }
  };

  return <Flex justifyContent="flex-end">{getAccessibility()}</Flex>;
};

export default function TeamListItem({
  mobile = false,
  item = {},
  onTeamClicked = () => {},
  onJoinTeam = () => {},
  transparentBackground = false,
  isAnInvite = false,
}) {
  const themeColors = useTheme().color;

  if (mobile) {
    return (
      <Box width={1}>
        <Flex my="10px" width={1} alignItems="center">
          <ListItem>
            <Item noBorder width="20%">
              <TeamIcon
                backgroundColor={
                  transparentBackground ? themeColors.background : themeColors.form.background
                }
              />
            </Item>
            <Item noBorder width="80%">
              <LinkedText onClick={onTeamClicked}>
                {truncate(item.name, { length: 19, omission: '...' })}
              </LinkedText>
            </Item>
          </ListItem>
        </Flex>
        <Flex
          noBorder
          width={1}
          alignItems="center"
          style={{ borderBottom: '1px solid #2c2c2c' }}
          justifyContent="flex-end"
        >
          <ListItem>
            <Item noBorder public={item.visibility === TEAM_VISIBILITY.PUBLIC} width="35%" center>
              <TeamMembersState team={item} />
            </Item>
            <Item noBorder full={item.full} width="55%">
              <TeamAccessibility team={item} onJoinTeam={onJoinTeam} isAnInvite={isAnInvite} />
            </Item>
          </ListItem>
        </Flex>
      </Box>
    );
  }

  return (
    <ListItem>
      <Item noBorder width="10%">
        <TeamIcon
          backgroundColor={
            transparentBackground ? themeColors.background : themeColors.form.background
          }
        />
      </Item>
      <Item width="45%">
        <LinkedText onClick={onTeamClicked}>
          {truncate(item.name, { length: 19, omission: '...' })}
        </LinkedText>
      </Item>
      <Item public={item.visibility === TEAM_VISIBILITY.PUBLIC} width="20%" center>
        <TeamMembersState team={item} />
      </Item>
      <Item center full={item.full} width="25%">
        <TeamAccessibility team={item} onJoinTeam={onJoinTeam} isAnInvite={isAnInvite} />
      </Item>
    </ListItem>
  );
}
