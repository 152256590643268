import React from 'react';
import styled, { css } from 'styled-components';

const Button = styled.div`
  background-color: transparent;
  border: transparent;
  font-family: 'Roboto Mono';
  font-size: 14px;
  white-space: nowrap;
  padding: ${(props) => (props.noPadding ? 0 : '8px')};
  color: ${(props) => props.theme.color.table.header.color};
  cursor: pointer;

  &:hover {
    ${(props) =>
      props.active
        ? css`
            background: ${props.theme.color.button.unregular.active.hover.background};
            color: ${props.theme.color.button.unregular.active.hover.color};
            border-color: ${props.theme.color.button.unregular.active.hover.border};
          `
        : css`
            color: ${props.theme.color.button.unregular.inactive.hover.color};
            border-color: ${props.theme.color.button.unregular.inactive.hover.border};
          `}
    ${(props) =>
      props.disabled &&
      css`
        background: ${props.theme.color.button.regular.accept.disabled.background};
        border-color: ${props.theme.color.button.regular.accept.disabled.border};
        cursor: not-allowed;
      `}
  }
`;

const Text = styled.span`
  color: ${(props) =>
    props.active
      ? props.theme.color.button.unregular.active.default.color
      : props.theme.color.button.unregular.inactive.default.color};
  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        color: ${props.theme.color.button.unregular.active.hover.color};
      `}
  }
`;

function UnregularButton(props) {
  const { children, onClick, ...rest } = props;
  return (
    <Button onClick={rest.disabled ? () => {} : onClick} {...rest}>
      [<Text {...rest}>{props.children}</Text>]
    </Button>
  );
}

export default UnregularButton;
