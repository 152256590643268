import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';

const Tab = styled.div`
  align-items: center;
  margin-right: 20px;
  font-family: Roboto Mono;
  font-size: 14px;
  position: relative;
  background-color: #1d1d1d;
  color: #898989;
  text-decoration: none;
  padding-bottom: 15px;

  ${(props) =>
    props.active &&
    css`
      border-bottom: 1px solid;
      color: #66bb6a;
      border-bottom-color: #66bb6a;
    `}

  &:hover,
  &:focus {
    border-bottom: 1px solid;
    border-bottom-color: #66bb6a;
    cursor: pointer;
  }
`;

const TabContainer = styled(Flex)`
  height: 20px;
  background-color: #1d1d1d;
  overflow-x: auto;
`;

const Tabs = ({ current, labels, onTabChange }) => {
  return (
    <TabContainer>
      {labels.map((l, idx) => (
        <Tab key={l} active={idx === current} onClick={() => onTabChange(idx)}>
          {l.toUpperCase()}
        </Tab>
      ))}
    </TabContainer>
  );
};

export default Tabs;
