import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import {
  Button,
  Checkbox,
  Form,
  FormLeyend,
  GameIcon,
  Input,
  LinkedText,
  Markdown,
  Modal,
  QuestionCircleIcon,
  Tabs,
  Tooltip,
} from 'boss-ui';
import Api from '../../api';
import { WorkshopIcon, InviteOnlyIcon } from '../icons';
import BossLabel from '../BossLabel';
import CardBody from '../cards/CardBody';
import CardDate from '../cards/CardDate';
import CardDescription from '../cards/CardDescription';
import CardIconContainer from '../cards/CardIconContainer';
import CardTitle from '../cards/CardTitle';
import EventLabel from '../EventLabel';
import ScrolleableContainer from '../ScrolleableContainer';
import Text from '../Text';
import WorkshopContent from '../workshops/WorkshopContent';
import { onError } from '../../libs/error-lib';
import { getImageURLSized } from '../../libs/utils-lib';
import {
  EVENT_TYPE,
  CONFIRMATION_NUMBER_REGEXP,
  SKELTELON_STYLE,
  EVENT_DELIVERY,
  EVENT_ACCESS_TYPE,
  DEFAULT_BOX_BORDER_STYLE,
} from '../../libs/constants';
import { useAppContext } from '../../libs/context-lib';
import { isTodayBetween, isTodayAfterThan } from '../../libs/dates-lib';
import {
  getNoImageUrl,
  isOnDemandEventInsidePlayWindow,
  isNormalEventInsideActiveWindow,
} from '../../libs/event-lib';
import TermsAndConditions from '../TermsConditions';

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 432px;
`;

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
`;

const API = new Api();

const EnrollComponent = ({ onEnrollEvent, disableEnroll, requireConfirmationNumber, delivery }) => {
  const [confirmationNumber, setConfirmationNumber] = useState('');
  const [ackVisible, setAckVisible] = useState(false);
  const [shareInformationAccepted, setShareInformationAccepted] = useState(false);

  if (requireConfirmationNumber) {
    return (
      <>
        <Box my="20px">
          <Flex>
            <Box mr="6px" className="EventDetail-FlexCheckbox" minWidth="auto">
              <label className="EventDetail-LblCheckbox">
                <Checkbox
                  checked={shareInformationAccepted}
                  onChange={(e) => setShareInformationAccepted(e.target.checked)}
                  id="ack"
                  className="EventDetail-Checkbox"
                />
              </label>
            </Box>
            <Box>
              <FormLeyend size="10px">
                I would like Splunk to share my contact information with the sponsors and
                co-presenters identified on this page so they can contact me by email, post or phone
                about their news, information, events and offers, subject to privacy policies
                available on their websites.
              </FormLeyend>
              <FormLeyend size="10px">
                <LinkedText onClick={() => setAckVisible(true)}>
                  Check Terms and conditions.
                </LinkedText>
              </FormLeyend>
            </Box>
          </Flex>
          <Flex mt="6px" alignItems="center" justifyContent="space-between" width={1}>
            <Box style={{ position: 'relative' }} width="285px">
              <Flex width={1} mt="6px">
                <Input
                  value={confirmationNumber}
                  onChange={(e) => setConfirmationNumber(e.target.value)}
                  placeholder="Enter your Confirmation Number"
                  height="25px"
                />
                <Flex data-tip data-for="confirmationNumber" ml="4px">
                  <QuestionCircleIcon />
                </Flex>
              </Flex>
              <Tooltip id="confirmationNumber">
                You must enter a confimation number to continue. You should have got this number by
                email.
              </Tooltip>
            </Box>
            <Box mt="10px">
              <Button
                id="EventDetails-btnEnroll"
                onClick={() => onEnrollEvent(shareInformationAccepted, confirmationNumber)}
                disabled={!confirmationNumber.match(CONFIRMATION_NUMBER_REGEXP) || disableEnroll}
              >
                {delivery === EVENT_DELIVERY.ON_DEMAND ? 'PLAY NOW' : 'ENROLL NOW'}
              </Button>
            </Box>
          </Flex>
        </Box>
        {ackVisible && (
          <Flex>
            <Modal onBackgroundClick={() => setAckVisible(false)} margin="5% auto">
              <Form>
                <Box maxWidth="600px">
                  <Flex justifyContent="center" width="600px" py="15px" backgroundColor="#2C2C2C">
                    <FormLeyend size="20px">Splunk Terms and Conditions</FormLeyend>
                  </Flex>
                  <ScrolleableContainer>
                    <Flex py="12px" px="8px" width={1}>
                      <TermsAndConditions />
                    </Flex>
                  </ScrolleableContainer>
                </Box>
              </Form>
            </Modal>
          </Flex>
        )}
      </>
    );
  }

  return (
    <>
      <Box mt="10px" my="20px">
        <Flex>
          <Box mr="6px" className="EventDetail-FlexCheckbox" minWidth="auto">
            <label className="EventDetail-LblCheckbox">
              <Checkbox
                checked={shareInformationAccepted}
                onChange={(e) => setShareInformationAccepted(e.target.checked)}
                id="ack"
                className="EventDetail-Checkbox"
              />
            </label>
          </Box>
          <Box>
            <FormLeyend size="10px">
              I would like Splunk to share my contact information with the sponsors and
              co-presenters identified on this page so they can contact me by email, post or phone
              about their news, information, events and offers, subject to privacy policies
              available on their websites.
            </FormLeyend>
            <FormLeyend size="10px">
              <LinkedText onClick={() => setAckVisible(true)}>
                Check Terms and conditions.
              </LinkedText>
            </FormLeyend>
          </Box>
        </Flex>
        <Box mt="6px" ml="auto" width="150px">
          <Button
            id="EventDetails-btnEnroll"
            onClick={() => onEnrollEvent(shareInformationAccepted)}
            disabled={disableEnroll}
          >
            {delivery === EVENT_DELIVERY.ON_DEMAND ? 'PLAY NOW' : 'ENROLL NOW'}
          </Button>
        </Box>
      </Box>
      {ackVisible && (
        <Flex>
          <Modal onBackgroundClick={() => setAckVisible(false)} margin="5% auto">
            <Form>
              <Box maxWidth="600px">
                <Flex justifyContent="center" width="600px" py="15px" backgroundColor="#2C2C2C">
                  <FormLeyend size="20px">Splunk Terms and Conditions</FormLeyend>
                </Flex>
                <ScrolleableContainer>
                  <Flex py="12px" px="8px" width={1}>
                    <TermsAndConditions />
                  </Flex>
                </ScrolleableContainer>
              </Box>
            </Form>
          </Modal>
        </Flex>
      )}
    </>
  );
};

const EnterEventComponent = ({
  eventId,
  eventType,
  eventDelivery,
  participantJoinDate,
  eventDuration,
  onPlayAgainEvent,
  loading = false,
}) => {
  const history = useHistory();
  const validOnDemand = isOnDemandEventInsidePlayWindow(participantJoinDate, eventDuration);

  const onClick = () => {
    if (eventType === EVENT_TYPE.GAME) {
      if (eventDelivery === EVENT_DELIVERY.ON_DEMAND) {
        if (validOnDemand) {
          history.push(`/event/${eventId}`);
          return;
        }
        onPlayAgainEvent();
        return;
      }
      history.push(`/event/${eventId}`);
      return;
    }
    history.push(`/workshop/${eventId}`);
  };

  const label =
    eventDelivery === EVENT_DELIVERY.ON_DEMAND
      ? validOnDemand
        ? 'CONTINUE'
        : 'PLAY AGAIN'
      : 'ENTER';

  return (
    <Box ml="auto" mb="20px" width="110px">
      <Button onClick={onClick} id="EventDetails-btnEnter" disabled={loading}>
        {label}
      </Button>
    </Box>
  );
};

export default function EventDetails({
  id,
  imageURL,
  type,
  eventType,
  name,
  description,
  confirmationNumberRequired,
  startDate,
  delivery,
  registrationStatus,
  registrationOpenDate,
  registrationClosedDate,
  inviteCode,
  activeStartDate,
  activeEndDate,
}) {
  const [details, setDetails] = useState(null);
  const [userInEvent, setUserInEvent] = useState(null);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [enrollingEvent, setEnrollingEvent] = useState(false);
  const [enrollForbiden, setEnrollForbidden] = useState(false);
  const [closedRegistrationMessage, setClosedRegistrationMessage] = useState(null);
  const { user, setEvent, event } = useAppContext();
  const history = useHistory();
  const isEventRunning =
    eventType === EVENT_TYPE.WORKSHOP
      ? isTodayBetween(new Date(activeStartDate), new Date(activeEndDate))
      : delivery === EVENT_DELIVERY.NORMAL
      ? isNormalEventInsideActiveWindow(
          activeStartDate,
          activeEndDate,
          registrationOpenDate,
          registrationClosedDate,
          registrationStatus
        )
      : // on-demand event gets active style
        true;

  useEffect(() => {
    const getDetails = async () => {
      setLoadingDetails(true);
      try {
        if (eventType === EVENT_TYPE.GAME) {
          const rq = await API.get('events', `/events/${id}`);
          setEvent(rq.event);
          setUserInEvent(rq.participant);
        } else {
          const rq = await API.get('workshops', `/workshops/${id}`);
          setEvent(rq.workshop);
          setDetails(rq.workshop.content);
          setUserInEvent(rq.participant);
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          setEnrollForbidden(true);
        }
        onError(e);
      } finally {
        setLoadingDetails(false);
      }
    };
    const checkEventRegistration = () => {
      if (eventType === EVENT_TYPE.GAME && delivery !== EVENT_DELIVERY.ON_DEMAND) {
        if (!registrationStatus) {
          setClosedRegistrationMessage('Registration for this event is not open yet.');
        } else if (registrationStatus === 'CLOSED') {
          setClosedRegistrationMessage('Registration for this event has been closed.');
        } else if (!isTodayAfterThan(registrationOpenDate)) {
          setClosedRegistrationMessage('Registration for this event has not started yet.');
        } else if (isTodayAfterThan(registrationClosedDate) || !isEventRunning) {
          setClosedRegistrationMessage('Registration for this event has ended.');
        }
      }
    };
    if (event && event.participant) {
      setUserInEvent(event.participant);
      setLoadingDetails(false);
    } else {
      getDetails();
    }
    checkEventRegistration();
    return () => API.abortCurrentRequest();
  }, []);

  const onEnrollEvent = async (acceptShareInformation = false, confirmationNumber) => {
    setEnrollingEvent(true);
    try {
      if (eventType === EVENT_TYPE.GAME) {
        await API.post('events', `/events/${id}/join`, {
          body: {
            name: user.dynamoUser.displayName,
            email: user.attributes.email,
            confirmationNumber,
            inviteCodeId: inviteCode,
            acceptShareInformation,
          },
        });
        // an event fetch is needed in order to refresh participant information of the join
        if (delivery === EVENT_DELIVERY.ON_DEMAND) {
          const rq = await API.get('events', `/events/${id}`);
          setEvent({ ...rq.event, participant: rq.participant });
        }
        history.push(`/event/${id}`);
      } else {
        await API.post('workshops', `/workshops/${id}/join`, {
          body: {
            name: user.dynamoUser.displayName,
            email: user.attributes.email,
            inviteCodeId: inviteCode,
            acceptShareInformation,
          },
        });
        history.push(`/workshop/${id}`);
      }
    } catch (e) {
      setEnrollingEvent(false);
      onError(e);
    }
  };

  if (!id) {
    history.push('/');
    return null;
  }
  const showDate = delivery === EVENT_DELIVERY.NORMAL;
  const imgSize = 'large';
  const imageSrc = getImageURLSized(imageURL, imgSize);
  const isRestrictedEvent = event && event.accessType === EVENT_ACCESS_TYPE.RESTRICTED;
  const userEnrolled = userInEvent && userInEvent.enrolled;

  return (
    <Container maxWidth="766px" flexDirection="column">
      <Image
        src={imageSrc}
        alt="Event detail image"
        onError={(e) => {
          e.target.src = getNoImageUrl(type, imgSize);
        }}
      />
      <CardBody
        active={isEventRunning}
        alignItems="start"
        flexDirection="column"
        height="100%"
        restricted={isRestrictedEvent}
      >
        <Flex width={1} alignItems="center">
          <CardIconContainer>
            {eventType === EVENT_TYPE.WORKSHOP ? <WorkshopIcon /> : <GameIcon />}
          </CardIconContainer>
          {isRestrictedEvent && (
            <>
              <Flex data-tip data-for="inviteOnlyIcon" pl="8px" pr="4px">
                <InviteOnlyIcon />
              </Flex>
              <Tooltip id="inviteOnlyIcon">INVITE ONLY</Tooltip>
            </>
          )}
          <BossLabel type={type} ml="6px" />
          {showDate && startDate && (
            <Flex ml="auto">
              <CardDate date={startDate} />
            </Flex>
          )}
        </Flex>
        {isRestrictedEvent && (
          <Flex
            style={{
              width: '100%',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginLeft: '22px',
              marginLop: '1px',
            }}
          >
            <EventLabel style={{ position: 'absolute', marginTop: '-67px' }}>
              Private Event
            </EventLabel>
          </Flex>
        )}
        <CardTitle active={isEventRunning} restricted={isRestrictedEvent}>
          {name}
        </CardTitle>
        <CardDescription>
          <Markdown md={description} />
        </CardDescription>
        {loadingDetails ? (
          <Flex ml="auto" mb="20px">
            <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
              <Skeleton width="100px" height="30px" />
            </SkeletonTheme>
          </Flex>
        ) : (
          !enrollForbiden &&
          (userEnrolled ? (
            <EnterEventComponent
              eventDelivery={delivery}
              participantJoinDate={userInEvent.joinDate}
              eventDuration={event.duration}
              eventId={id}
              eventType={eventType}
              onPlayAgainEvent={onEnrollEvent}
              loading={enrollingEvent}
            />
          ) : closedRegistrationMessage ? (
            <Flex width={1} mt="16px" style={DEFAULT_BOX_BORDER_STYLE}>
              <Text
                style={{
                  fontWeight: '400',
                  fontSize: '18px',
                  paddingTop: '20px',
                  paddingBottom: '20px',
                  color: '#E5C07B',
                }}
                className="EventDetails-RegistrationMessage"
              >
                {closedRegistrationMessage}
              </Text>
            </Flex>
          ) : (
            <EnrollComponent
              onEnrollEvent={onEnrollEvent}
              disableEnroll={enrollingEvent || loadingDetails || !isEventRunning}
              requireConfirmationNumber={confirmationNumberRequired}
              termsUrl={event.terms}
              delivery={delivery}
            />
          ))
        )}
        {details && (
          <Box mt="66px" width={1}>
            <Tabs current={0} labels={['SUMMARY']} onTabChange={() => {}} />
            <WorkshopContent content={details.workshop} />
          </Box>
        )}
      </CardBody>
    </Container>
  );
}
