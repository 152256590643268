import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Button, UnregularButton } from 'boss-ui';
import CardBody from './CardBody';
import CardTitle from './CardTitle';
import { SKELTELON_STYLE } from '../../libs/constants';

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
  box-shadow: '0px 3px 6px #00000029';
  height: 627px;
`;
Container.displayName = 'BigCardSkeletonContainer';

const CollapsableDescription = () => {
  return (
    <>
      <UnregularButton disabled>
        <Skeleton width={100} />
      </UnregularButton>
    </>
  );
};

export default function BigCardSkeleton({ mobile }) {
  return (
    <Container width="100%" maxWidth="550px" flexDirection="column" mb="20px">
      <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
        <Skeleton height="320px" width="100%" />
      </SkeletonTheme>
      <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
        <CardBody
          alignItems="start"
          flexDirection="column"
          height="100%"
          py="45px"
          px="60px"
          style={{
            borderColor: 'transparent',
          }}
        >
          <CardTitle>
            <Skeleton width={mobile ? '200px' : '400px'} />
          </CardTitle>
          <CollapsableDescription />
          <Flex mt="66px" ml="auto" minWidth="100px">
            <Button disabled>ENTER</Button>
          </Flex>
        </CardBody>
      </SkeletonTheme>
    </Container>
  );
}
