import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { Flex, Box } from 'reflexbox';
import { Switch, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import {
  AuthenticatedRoute,
  ConfirmationModal,
  Menu,
  Input,
  RegularText,
  StyledText,
  UnregularButton,
} from 'boss-ui';
import {
  Card,
  CardSkeleton,
  ErrorBoundary,
  RestrictedEvents,
  ToggleButton,
} from '../../components';
import { EVENT_TYPE, EVENT_DELIVERY } from '../../libs/constants';
import { useAppContext } from '../../libs/context-lib';
import { isOnDemandEventInsidePlayWindow } from '../../libs/event-lib';
import { scrollToHash } from '../../libs/utils-lib';
import History from '../History';

const EMPTY_LIST_MESSAGE = 'Coming soon...';
const MENU_ITEMS = [
  { path: '/', elementId: '#top', label: 'HOME', exact: true, id: 'menuLinkHome' },
  {
    path: { pathname: '/', hash: '#play-now' },
    label: 'PLAY NOW',
    exact: true,
    id: 'menuLinkHomePlayNow',
  },
  { path: { pathname: '/', hash: '#learn' }, label: 'LEARN', id: 'menuLinkLearn', exact: true },
  {
    path: { pathname: '/', hash: '#boss-events' },
    label: 'BOSS EVENTS',
    id: 'menuLinkEvents',
    exact: true,
  },
  {
    path: `/history`,
    label: 'HISTORY',
    id: 'menuLinkHistory',
    exact: true,
  },
];

const API = new Api();

export default function Home() {
  const [loadingEvents, setLoadingEvents] = useState(true);
  const [loadingRestrictedEvents, setLoadingRestrictedEvents] = useState(true);
  const [loadingOnDemandEvents, setLoadingOnDemandEvents] = useState(true);
  const [onDemandEvents, setOnDemandEvents] = useState([]);
  const [loadingWorkshops, setLoadingWorkshops] = useState(true);
  const [filteredWorkshops, setFilteredWorkshops] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [filteredRestrictedEvents, setFilteredRestrictedEvents] = useState([]);
  const [ignoreType, setIgnoreType] = useState(false);
  const [hasUserScrolled, setHasUserScrolled] = useState(false);
  const [inviteCodeModalVisible, setInviteCodeModalVisible] = useState(false);
  const [inviteCodeValue, setInviteCodeValue] = useState('');
  const [loadingInviteCodeEvent, setLoadingInviteCodeEvent] = useState(false);
  const { appType, setEvent } = useAppContext();
  const history = useHistory();

  useEffect(() => {
    const getOnDemandEvents = async () => {
      setLoadingOnDemandEvents(true);
      try {
        const rq = await API.get('events', '/events', {
          queryStringParameters: { accessType: EVENT_DELIVERY.ON_DEMAND },
        });
        const eventsWithType = rq.events.map((e) => ({ ...e, eventType: EVENT_TYPE.GAME }));
        setOnDemandEvents(
          ignoreType ? eventsWithType : eventsWithType.filter((e) => e.type === appType)
        );
      } catch (e) {
        onError(e);
      } finally {
        setLoadingOnDemandEvents(false);
      }
    };
    getOnDemandEvents();
  }, [ignoreType]);

  useEffect(() => {
    const getRestrictedEvents = async () => {
      setLoadingRestrictedEvents(true);
      try {
        const rq = await API.get('events', '/events', {
          queryStringParameters: { accessType: 'RESTRICTED' },
        });
        const eventsWithType = rq.events.map((e) => ({ ...e, eventType: EVENT_TYPE.GAME }));
        setFilteredRestrictedEvents(
          ignoreType ? eventsWithType : eventsWithType.filter((e) => e.type === appType)
        );
      } catch (e) {
        onError(e);
      } finally {
        setLoadingRestrictedEvents(false);
      }
    };
    getRestrictedEvents();
  }, [ignoreType]);

  useEffect(() => {
    const getEvents = async () => {
      setLoadingEvents(true);
      try {
        const rq = await API.get('events', '/events');
        const eventsWithType = rq.events.map((e) => ({ ...e, eventType: EVENT_TYPE.GAME }));
        setFilteredEvents(
          ignoreType ? eventsWithType : eventsWithType.filter((e) => e.type === appType)
        );
      } catch (e) {
        onError(e);
      } finally {
        setLoadingEvents(false);
      }
    };
    getEvents();
  }, [ignoreType]);

  useEffect(() => {
    const getWorkshops = async () => {
      setLoadingWorkshops(true);
      try {
        const rq = await API.get('workshops', '/workshops');
        const workshopsWithType = rq.workshops.map((w) => ({
          ...w,
          eventType: EVENT_TYPE.WORKSHOP,
        }));
        setFilteredWorkshops(
          ignoreType ? workshopsWithType : workshopsWithType.filter((w) => w.type === appType)
        );
      } catch (e) {
        onError(e);
      } finally {
        setLoadingWorkshops(false);
      }
    };
    getWorkshops();
  }, [ignoreType]);

  const onCardClicked = (event) => {
    const enrolled = get(event, 'participant.enrolled', false);
    if (event.eventType === EVENT_TYPE.WORKSHOP) {
      setEvent({ ...event, eventId: event.workshopId });
      history.push(
        enrolled ? `/workshop/${event.workshopId}` : `/workshops/${event.workshopId}/detail`
      );
      return;
    }
    setEvent(event);
    if (event.delivery === EVENT_DELIVERY.ON_DEMAND) {
      // if event is still playable
      if (
        enrolled &&
        isOnDemandEventInsidePlayWindow(get(event, 'participant.joinDate'), event.duration)
      ) {
        history.push(`/event/${event.eventId}`);
        return;
      }
      // play again event
      history.push(`/event/${event.eventId}/detail`);
      return;
    }
    history.push(enrolled ? `/event/${event.eventId}` : `/event/${event.eventId}/detail`);
  };

  const onInviteCodeSubmit = async () => {
    try {
      setLoadingInviteCodeEvent(true);
      const { inviteCode } = await API.get('events', `/invite-codes/${inviteCodeValue}`);
      setLoadingInviteCodeEvent(false);
      history.push(`/event/${inviteCode.eventId}/detail?invite_code=${inviteCodeValue}`);
    } catch (e) {
      setLoadingInviteCodeEvent(false);
      onError(e);
    }
  };

  // hash auto scroll
  useEffect(() => {
    if (!(loadingRestrictedEvents || loadingEvents || loadingWorkshops || loadingOnDemandEvents)) {
      if (!hasUserScrolled) {
        scrollToHash(window.location);
      }
    }
  }, [
    loadingEvents,
    loadingOnDemandEvents,
    loadingWorkshops,
    loadingRestrictedEvents,
    hasUserScrolled,
  ]);

  // user scroll event listener control
  useEffect(() => {
    const onUserScrolled = () => setHasUserScrolled(true);
    window.addEventListener('scroll', onUserScrolled);
    window.addEventListener('menu-fired-scroll', onUserScrolled);
    return () => {
      window.removeEventListener('scroll', onUserScrolled);
      window.removeEventListener('menu-fired-scroll', onUserScrolled);
    };
  }, []);

  return (
    <>
      <div id="#top" style={{ position: 'absolute', top: 0 }} />
      <Menu menuItems={MENU_ITEMS} />
      <Media
        queries={{
          xs: '(max-width: 500px)',
          s: '(max-width: 759px)',
          m: '(max-width: 900px)',
          l: '(max-width: 1171px)',
        }}
      >
        {(matches) => (
          <>
            <Switch>
              <AuthenticatedRoute path="/" exact>
                <>
                  <Box mx="12%" mt={matches.m ? '30px' : '60px'} mb="5%" textAlign="left">
                    {/* display prop fixes margin issue with absolute elements in IOS */}
                    <Flex alignItems="center" style={{ display: 'inline-block' }}>
                      <Flex
                        justifyContent="center"
                        style={{
                          position: 'absolute',
                          top: matches.m ? '115px' : '145px',
                          left: matches.m ? '12%' : '44%',
                        }}
                      >
                        <UnregularButton onClick={() => setInviteCodeModalVisible(true)}>
                          I have a code
                        </UnregularButton>
                      </Flex>
                      <Flex
                        style={{
                          position: 'absolute',
                          top: matches.m ? '120px' : '150px',
                          right: matches.m ? '20px' : '12%',
                        }}
                      >
                        <RegularText alignItems="flex-end">
                          Show All Events
                          <ToggleButton
                            isOn={ignoreType}
                            onToggle={() => setIgnoreType((prev) => !prev)}
                          />
                        </RegularText>
                      </Flex>
                    </Flex>

                    {/* vertical space for smaller screens padding */}
                    <Flex mt={matches.m ? '10px' : '0px'} />

                    {(filteredRestrictedEvents.length > 0 || loadingRestrictedEvents) && (
                      <Flex
                        className="Home-ContainerSpecialEvents"
                        flexDirection="column"
                        mb="50px"
                      >
                        <Flex alignSelf="start" mb="30px" id="#private">
                          <StyledText light size="28px">
                            Private Events
                          </StyledText>
                        </Flex>
                        <ErrorBoundary>
                          <RestrictedEvents
                            restrictedEvents={filteredRestrictedEvents}
                            loadingRestrictedEvents={loadingRestrictedEvents}
                            onCardClick={onCardClicked}
                          />
                        </ErrorBoundary>
                      </Flex>
                    )}

                    <Flex mb="60px" id="play-now" flexDirection="column">
                      <Flex
                        mb="30px"
                        alignItems="center"
                        flexDirection={matches.s ? 'column-reverse' : 'row'}
                      >
                        <Flex alignSelf="start">
                          <StyledText light size="28px">
                            Play Now
                          </StyledText>
                        </Flex>
                      </Flex>
                      <ErrorBoundary>
                        {loadingOnDemandEvents ? (
                          <CardSkeleton />
                        ) : (
                          <Flex
                            flexDirection="row"
                            justifyContent={matches.s ? 'center' : 'flex-start'}
                            alignItems="flex-start"
                            flexWrap="wrap"
                            className="Home-ContainerPlayNowEvents"
                          >
                            {onDemandEvents.length === 0 ? (
                              <Flex mx="15px">
                                <RegularText alignItems="center" width="max-content">
                                  {EMPTY_LIST_MESSAGE}
                                </RegularText>
                              </Flex>
                            ) : (
                              <>
                                {onDemandEvents.map((e, idx) => {
                                  const key = get(e, 'scenarioId', idx);
                                  const title = get(e, 'name');
                                  const showDate = e && e.delivery === EVENT_DELIVERY.NORMAL;
                                  return (
                                    <Flex
                                      key={key}
                                      style={{
                                        minWidth: '330px',
                                        padding: '15px 30px 15px 0',
                                      }}
                                      className={`Home-CardFlex-${e.scenarioId}`}
                                    >
                                      <Card
                                        {...e}
                                        title={title}
                                        onCardClick={() => onCardClicked(e)}
                                        showDate={showDate}
                                        joinDate={get(e, 'participant.joinDate')}
                                        enrolled={get(e, 'participant.enrolled')}
                                      />
                                    </Flex>
                                  );
                                })}
                              </>
                            )}
                          </Flex>
                        )}
                      </ErrorBoundary>
                    </Flex>

                    <Flex mb="60px" id="learn" flexDirection="column">
                      <Flex
                        mb="30px"
                        alignItems="center"
                        flexDirection={matches.s ? 'column-reverse' : 'row'}
                      >
                        <Flex alignSelf="start">
                          <StyledText light size="28px">
                            Learn
                          </StyledText>
                        </Flex>
                      </Flex>
                      <ErrorBoundary>
                        {loadingWorkshops ? (
                          <CardSkeleton />
                        ) : (
                          <Flex
                            flexDirection="row"
                            justifyContent={matches.s ? 'center' : 'flex-start'}
                            alignItems="flex-start"
                            flexWrap="wrap"
                            className="Home-ContainerLearnEvents"
                          >
                            {filteredWorkshops.length === 0 ? (
                              <Flex mx="15px">
                                <RegularText alignItems="center" width="max-content">
                                  {EMPTY_LIST_MESSAGE}
                                </RegularText>
                              </Flex>
                            ) : (
                              <>
                                {filteredWorkshops.map((e, idx) => {
                                  const key = get(e, 'id', idx);
                                  const title = get(e, 'name');
                                  return (
                                    <Flex
                                      key={key}
                                      index={idx}
                                      style={{
                                        minWidth: '330px',
                                        padding: '15px 30px 15px 0',
                                      }}
                                      className={`Home-CardFlex-${e.id}`}
                                    >
                                      <Card
                                        {...e}
                                        stats={get(e, 'participant.stats')}
                                        title={title}
                                        onCardClick={() => onCardClicked(e)}
                                        enrolled={get(e, 'participant.enrolled')}
                                      />
                                    </Flex>
                                  );
                                })}
                              </>
                            )}
                          </Flex>
                        )}
                      </ErrorBoundary>
                    </Flex>

                    <Flex mb="60px" id="boss-events" flexDirection="column">
                      <Flex
                        mb="30px"
                        alignItems="center"
                        flexDirection={matches.s ? 'column-reverse' : 'row'}
                      >
                        <Flex alignSelf="start">
                          <StyledText light size="28px">
                            Boss Events
                          </StyledText>
                        </Flex>
                      </Flex>
                      <ErrorBoundary>
                        {loadingEvents ? (
                          <CardSkeleton />
                        ) : (
                          <Flex
                            flexDirection="row"
                            justifyContent={matches.s ? 'center' : 'flex-start'}
                            alignItems="flex-start"
                            flexWrap="wrap"
                            className="Home-ContainerBossEvents"
                          >
                            {filteredEvents.length === 0 ? (
                              <Flex mx="15px">
                                <RegularText alignItems="center" width="max-content">
                                  {EMPTY_LIST_MESSAGE}
                                </RegularText>
                              </Flex>
                            ) : (
                              <>
                                {filteredEvents.map((e, idx) => {
                                  const key = get(e, 'scenarioId', idx);
                                  const title = get(e, 'name');
                                  const showDate = e && e.delivery === EVENT_DELIVERY.NORMAL;
                                  return (
                                    <Flex
                                      key={key}
                                      style={{
                                        minWidth: '330px',
                                        padding: '15px 30px 15px 0',
                                      }}
                                      className={`Home-CardFlex-${e.scenarioId}`}
                                    >
                                      <Card
                                        {...e}
                                        title={title}
                                        onCardClick={() => onCardClicked(e)}
                                        showDate={showDate}
                                        enrolled={get(e, 'participant.enrolled')}
                                      />
                                    </Flex>
                                  );
                                })}
                              </>
                            )}
                          </Flex>
                        )}
                      </ErrorBoundary>
                    </Flex>
                  </Box>
                  {inviteCodeModalVisible && (
                    <ConfirmationModal
                      acceptLabel="SUBMIT"
                      onCancel={() => setInviteCodeModalVisible(false)}
                      onAccept={onInviteCodeSubmit}
                      loading={loadingInviteCodeEvent}
                    >
                      <Box width="300px">
                        <RegularText light mediumWeight>
                          Enter your code below to access the private event you were invited to.
                        </RegularText>
                        <Flex width={1} mt="16px" mx="16px">
                          <Input
                            height="25px"
                            style={{ width: '265px' }}
                            placeholder="Enter Code"
                            value={inviteCodeValue}
                            onChange={(e) => setInviteCodeValue(e.target.value)}
                          />
                        </Flex>
                      </Box>
                    </ConfirmationModal>
                  )}
                </>
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/history">
                <History />
              </AuthenticatedRoute>
            </Switch>
          </>
        )}
      </Media>
    </>
  );
}
