import React from 'react';
import styled, { css } from 'styled-components';

const HiddenButton = styled.input.attrs({ type: 'radio' })`
  // Hide radio visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const StyledRadioButton = styled.div`
  display: inline-block;
  border-radius: 100%;
  width: 10px;
  height: 10px;
  color: #4caf50;
  border: 2px solid #4caf50;
  ${(props) =>
    props.checked &&
    css`
      background-color: #4caf50;
    `}
  opacity: 1;
  transition: all 150ms;
`;

const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

const RadioButton = ({ checked, ...props }) => {
  return (
    <Container>
      <HiddenButton {...props} />
      <StyledRadioButton checked={checked} />
    </Container>
  );
};

export default RadioButton;
