import React, { useEffect, useState, useMemo } from 'react';
import Media from 'react-media';
import { Flex } from 'reflexbox';
import get from 'lodash/get';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import Api from '../../api';
import { LoaderIcon, Menu, Breadcrumb } from 'boss-ui';
import { HistoryDetails } from '../../components';
import { useAppContext } from '../../libs/context-lib';
import { useBreadcrumb } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import { EVENT_TYPE, EVENT_ACCESS_TYPE } from '../../libs/constants';

const API = new Api();

export default function Details() {
  const location = useLocation();
  const [loadingEvent, setLoadingEvent] = useState(true);
  const { setEvent, event, appType } = useAppContext();
  const eventRouteMatch = useRouteMatch('/history/:itemId/event');
  const workshopRouteMatch = useRouteMatch('/history/:itemId/workshop');
  let historyRouteMatch = eventRouteMatch;
  let eventType = EVENT_TYPE.GAME;
  let linkType = 'event';

  if (!historyRouteMatch) {
    eventType = EVENT_TYPE.WORKSHOP;
    linkType = 'workshop';
    historyRouteMatch = workshopRouteMatch;
  }
  const history = useHistory();
  const [breadcrumbs, setBreadcrumbs] = useBreadcrumb();
  const match = useRouteMatch(location.pathname);

  const routes = [
    {
      path: `/history`,
      name: 'HISTORY',
    },
    {
      path: `/history/${historyRouteMatch.params.itemId}/${linkType}`,
      name: get(event, 'name', ''),
    },
  ];

  const MENU_ITEMS = [
    { path: '/', label: 'HOME', exact: true, id: 'menuLinkHome' },
    {
      path: { pathname: '/', hash: '#play-now' },
      label: 'PLAY NOW',
      id: 'menuLinkHomePlayNow',
    },
    { path: { pathname: '/', hash: '#learn' }, label: 'LEARN', id: 'menuLinkLearn' },
    {
      path: { pathname: '/', hash: '#boss-events' },
      label: 'BOSS EVENTS',
      id: 'menuLinkEvents',
    },
    {
      path: `/history/${historyRouteMatch.params.itemId}/${linkType}`,
      label: 'HISTORY',
      id: 'menuLinkHistory',
    },
  ];

  useMemo(() => {
    setBreadcrumbs(routes, match);
  }, [location, event]);

  useEffect(() => {
    const getEvent = async (id) => {
      setLoadingEvent(true);
      try {
        if (eventType === EVENT_TYPE.GAME) {
          const rq = await API.get('events', `/events/${id}`);
          setEvent(rq.event);
        } else {
          const rq = await API.get('workshops', `/workshops/${id}`);
          setEvent(rq.workshop);
        }
      } catch (e) {
        onError(e);
        history.push('/history');
      } finally {
        setLoadingEvent(false);
      }
    };

    if (event) {
      setLoadingEvent(false);
    } else {
      getEvent(historyRouteMatch.params.itemId);
    }
  }, [historyRouteMatch]);

  if (loadingEvent) {
    return (
      <Flex mt="10%" alignItems="center" justifyContent="center" flexDirection="column">
        <LoaderIcon appType={appType}/>
      </Flex>
    );
  }

  return (
    <>
      <Menu menuItems={MENU_ITEMS} />
      <Media
        queries={{
          s: '(max-width: 759px)',
          m: '(max-width: 900px)',
        }}
      >
        {(matches) => (
          <Flex width={1} justifyContent="center">
            <Flex
              mx={matches.m ? '20px' : '10%'}
              mb="5%"
              mt="30px"
              justifyContent="center"
              width="924px"
              flexDirection="column"
            >
              <Flex my="30px" width={1 / 2}>
                <Breadcrumb crumbs={breadcrumbs} />
              </Flex>
              <HistoryDetails
                id={event.model === EVENT_TYPE.WORKSHOP ? event.workshopId : event.eventId}
                type={event.type}
                eventType={
                  event.model === EVENT_TYPE.WORKSHOP ? EVENT_TYPE.WORKSHOP : EVENT_TYPE.GAME
                }
                name={event.name}
                description={event.description}
                startDate={event.startDate}
                delivery={event.delivery}
                isRestrictedEvent={event.accessType === EVENT_ACCESS_TYPE.RESTRICTED}
                duration={event.duration}
                scenarios={event.scenarios}
                scenariosScores={event.scenariosScores}
                globalTeamsCount={event.globalTeamsCount}
              />
            </Flex>
          </Flex>
        )}
      </Media>
    </>
  );
}
