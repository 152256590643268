import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import StyledText from '../StyledText';
import UnregularButton from '../UnregularButton';
import Notification from './NotificationCard';

const Container = styled(Box)`
  width: 100%;
  max-width: 420px;
  max-height: 700px;
  overflow-y: auto;
  padding: 40px 20px 40px 20px;
  background-color: ${(props) => props.theme.color.notificationList.container.background};
  border: solid 1px ${(props) => props.theme.color.notificationList.container.border};
  text-align: left;
  box-shadow: 0px 4px 10px ${(props) => props.theme.color.notificationList.container.boxShadow};
  filter: ${(props) => (props.loading ? 'blur(1px)' : 'none')};
`;

export default function NotificationsList({
  onHideList = () => {},
  notifications = [],
  onRemoveNotification,
  onRemoveAllNotifications,
}) {
  const history = useHistory();

  const onNavigation = (path) => {
    history.push(path);
    onHideList();
  };

  if (notifications.length === 0) {
    return (
      <Container>
        <StyledText size="20px" light>
          All clear here...
        </StyledText>
      </Container>
    );
  }
  return (
    <Container>
      <Flex>
        <Flex ml="auto" mt="-30px" mr="-20px">
          <UnregularButton onClick={onRemoveAllNotifications}>clear all</UnregularButton>
        </Flex>
      </Flex>
      {notifications.map((n, idx) => (
        <Notification
          key={n.createdAt}
          data={n}
          onRemoveNotification={() => onRemoveNotification(n)}
          lastItem={idx === notifications.length - 1}
          navigateTo={onNavigation}
        />
      ))}
    </Container>
  );
}
