import React from 'react';
import { Box } from 'reflexbox';

const GREEN_COLOR = '#61ADA1';
const ORANGE_COLOR = '#DE935F';

function WorkshopVideoProgress({ stats = {}, style }) {
  if (stats && stats.completed && stats.total) {
    return (
      <Box
        style={{
          border: '1px solid #2C2C2C',
          color: '#D0D0D0',
          fontFamily: 'Roboto Mono',
          fontSize: '14px',
          textAlign: 'left',
          fontWeight: 400,
          ...style,
        }}
        py="12px"
        px="22px"
        className="WorkshopVideoProgress-Box"
      >
        <p className="WorkshopVideoProgress-VideosSeen">
          Videos seen: <span style={{ color: GREEN_COLOR }}>{stats.completed}</span>
        </p>
        <p className="WorkshopVideoProgress-RemainingVideos">
          Remaining videos:{' '}
          <span style={{ color: ORANGE_COLOR }}>{stats.total - stats.completed}</span>
        </p>
      </Box>
    );
  }
  return null;
}

export default WorkshopVideoProgress;
