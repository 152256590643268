import React from 'react';
import { Box, Flex } from 'reflexbox';
import get from 'lodash/get';
import WorkshopContent from './WorkshopContent';
import { LinkedText, TabScreen } from 'boss-ui';

const PURPLE_COLOR = '#A088A4';
const GREEN_COLOR = '#61ADA1';
const BLUE_COLOR = '#81A2BE';

const getTabsNames = (workshop) => {
  const arrNames = [{ label: 'SUMMARY' }, { label: 'RESOURCES' }];
  if (workshop.linksList && workshop.linksList.list) {
    arrNames.push({ label: 'MORE INFO' });
  }
  return arrNames;
};

const getTabsContent = (workshop, workshopLinks = { list: [] }) => ({
  0: (
    <Flex width={1} mt="20px" style={{ wordBreak: 'break-all' }}>
      <WorkshopContent content={workshop.content.workshop} />
    </Flex>
  ),
  1: (
    <Box
      width={1}
      mt="20px"
      style={{
        fontFamily: 'Roboto Mono',
        fontSize: '14px',
        textAlign: 'left',
        color: '#A0A0A0',
        fontWeight: 400,
        wordBreak: 'break-all',
      }}
    >
      <>
        Access the <span style={{ color: GREEN_COLOR }}>Splunk Server</span> to answer questions
        throughout this workshop, using the below shown{' '}
        <span style={{ color: GREEN_COLOR }}>server</span> and{' '}
        <span style={{ color: PURPLE_COLOR }}>credentials:</span>
      </>
      <Box style={{ border: '1px solid #2C2C2C', wordBreak: 'break-all' }} my="16px" p="16px">
        <p>
          SplunkServer:{' '}
          <span style={{ color: GREEN_COLOR }}>
            <LinkedText onClick={() => window.open(get(workshop, 'serverUrl', ''), '_blank')}>
              {get(workshop, 'serverUrl', '-')}
            </LinkedText>
          </span>
        </p>
        <p>
          User ID:{' '}
          <span style={{ color: PURPLE_COLOR }}>{get(workshop, 'serverUsername', '-')}</span>
        </p>
        <p>
          Password:{' '}
          <span style={{ color: PURPLE_COLOR }}>{get(workshop, 'serverPassword', '-')}</span>
        </p>
      </Box>
    </Box>
  ),
  2: (
    <Box
      width={1}
      mt="20px"
      style={{
        fontFamily: 'Roboto Mono',
        fontSize: '14px',
        textAlign: 'left',
        color: '#A0A0A0',
        fontWeight: 400,
        wordBreak: 'break-all',
      }}
    >
      <p>Links for further reading:</p>
      {workshopLinks.list.map((item) => {
        return (
          <p key={item.url}>
            <LinkedText onClick={() => window.open(item.url, '_blank')} color={BLUE_COLOR}>
              {item.title}
            </LinkedText>
          </p>
        );
      })}
    </Box>
  ),
});

export default function WorkshopTabs({ workshop = {} }) {
  return (
    <Box mt="66px" width={1}>
      <TabScreen
        tabs={getTabsNames(workshop)}
        tabsContent={getTabsContent(workshop, workshop.linksList)}
      />
    </Box>
  );
}
