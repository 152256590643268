import React from 'react';
import styled from 'styled-components';
import Base from '../StyledSVGBase';

const UnlockSVG = styled(Base)`
  width: ${(props) => props.size || '32px'};
  height: ${(props) => props.size || '32px'};
  pointer-events: all;
`;
UnlockSVG.displayName = 'UnlockIcon-UnlockSVG';

export default function UnlockIcon(props) {
  return (
    <UnlockSVG fill={props.fill ? props.fill : '#66bb6a'} height={props.size || 32} viewBox="0 0 448 512" {...props}>
      <path d="M400 256H152V152.9c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v16c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-16C376 68 307.5-.3 223.5 0 139.5.3 72 69.5 72 153.5V256H48c-26.5 0-48 21.5-48 48v160c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48zM264 408c0 22.1-17.9 40-40 40s-40-17.9-40-40v-48c0-22.1 17.9-40 40-40s40 17.9 40 40v48z" />
    </UnlockSVG>
  );
}
