import EventEmitter from 'events';
import forEach from 'lodash/forEach';
import { signRequest } from '../../libs/utils-lib';

export const WEB_SOCKET_STATUS = {
  OPEN: 'OPEN', // connection is open
  CLOSED: 'CLOSED', // connection was closed by client or server
  PENDING: 'PENDING', // connection is not open but the instance is ready
  RECONNECTING: 'RECONNECTING', // connection is about to be open
};
const API_GATEWAY_SIGNATURE_ACTION = 'execute-api';
const NORMAL_WEB_SOCKET_CLOSE_CODE = 1000;
const ABNORMAL_WEB_SOCKET_CLOSE_CODE = 1006;
const DEFAULT_RECONNECTION_TIME = 10000;
const SEND_REINTENT_MAX_ATTEMPTS = 10;
const SEND_REINTENT_INTERVAL_TIME = 500;
class WebSocketClient extends EventEmitter {
  constructor(props = {}) {
    const { customMessageEvents = [], autoReconnectInterval = DEFAULT_RECONNECTION_TIME } = props;
    if (!WebSocketClient.instance) {
      super();
      this.retryCount = 1;
      this.autoReconnectIntervalBase = autoReconnectInterval; // ms
      this.state = WEB_SOCKET_STATUS.PENDING;
      this.customMessageEvents = customMessageEvents;
      this.channelsSubscriptions = {};
      WebSocketClient.instance = this;
    } else {
      WebSocketClient.instance.customMessageEvents =
        WebSocketClient.instance.customMessageEvents.concat(customMessageEvents);
      WebSocketClient.instance.autoReconnectIntervalBase = autoReconnectInterval;
      WebSocketClient.instance.retryCount = 1;
    }
    return WebSocketClient.instance;
  }

  async open({ url, method = 'GET', channels = {}, authenticateRq = false }) {
    if (this.state === WEB_SOCKET_STATUS.OPEN || this.state === WEB_SOCKET_STATUS.RECONNECTING) {
      // eslint-disable-next-line no-console
      console.log('ws already open');
      throw new Error('ws already open');
    }
    this.url = url;

    let connectionRequest;
    if (authenticateRq) {
      connectionRequest = await signRequest({ url, method }, API_GATEWAY_SIGNATURE_ACTION);
    } else {
      connectionRequest = url;
    }
    this.instance = new WebSocket(connectionRequest);

    this.instance.onopen = () => {
      // eslint-disable-next-line no-console
      console.log('WS opened');
      this.state = WEB_SOCKET_STATUS.OPEN;
      this.retryCount = 1;
      forEach(channels, (subscriptionMessage, channelKey) => {
        this.subscribe({ channelKey, subscriptionMessage });
      });
    };

    this.instance.onmessage = (e, flags) => {
      if (typeof e.data === 'string') {
        const data = JSON.parse(e.data);
        if (data.notificationType && this.customMessageEvents.includes(data.notificationType)) {
          this.emit(data.notificationType, data);
        } else {
          this.emit('message', { data });
        }
      }
    };

    this.instance.onclose = (e) => {
      this.state = WEB_SOCKET_STATUS.CLOSED;
      // eslint-disable-next-line no-console
      console.log('WS closed', e);
      switch (e.code) {
        case NORMAL_WEB_SOCKET_CLOSE_CODE:
          break;
        case ABNORMAL_WEB_SOCKET_CLOSE_CODE:
          // Abnormal closure
          this.retryCount++;
          this.reconnect(e);
          break;
        default:
          this.reconnect(e);
          break;
      }
    };

    this.instance.onerror = (e) => {
      // eslint-disable-next-line no-console
      console.error('WS error', e);
    };
  }

  /**
   * Use this just for cases when the listener and the customMessageEvent has to be cleaned.
   * If you use for clean up you need to use addListener for add the listener and the customMessageEvent.
   * Do not use the constructor customMessageEvents param in this case
   * @param {*} event: notificationType string
   * @param {*} listener: event handler function
   */
  clearListener(event, listener) {
    this.removeListener(event, listener);
    this.customMessageEvents = this.customMessageEvents.filter(
      (messageEvent) => messageEvent !== event
    );
  }

  /**
   * Use this just for cases when the listener and the customMessageEvent has to be added.
   * If you use for adding on useEffect you need to use clearListener for removing the listener and the customMessageEvent.
   * Do not use the constructor customMessageEvents param in this case
   * @param {*} event: notificationType string
   * @param {*} listener: event handler function
   */
  addListener(event, listener) {
    this.on(event, listener);
    this.customMessageEvents = this.customMessageEvents.concat([event]);
  }

  waitForOpenConnection() {
    return new Promise((resolve, reject) => {
      let currentAttempt = 0;
      const interval = setInterval(() => {
        if (currentAttempt > SEND_REINTENT_MAX_ATTEMPTS - 1) {
          clearInterval(interval);
          reject(new Error('Maximum number of attempts exceeded'));
        } else if (this.state === WEB_SOCKET_STATUS.OPEN) {
          clearInterval(interval);
          resolve();
        }
        currentAttempt++;
      }, SEND_REINTENT_INTERVAL_TIME);
    });
  }

  async send(data, option) {
    if (this.state !== WEB_SOCKET_STATUS.OPEN) {
      try {
        await this.waitForOpenConnection();
      } catch (e) {
        throw new Error('Web socket is not opened, notifications can not be send');
      }
    }
    this.instance.send(JSON.stringify(data), option);
  }

  close() {
    if (this.state === WEB_SOCKET_STATUS.OPEN) {
      this.instance.close(NORMAL_WEB_SOCKET_CLOSE_CODE);
      this.state = WEB_SOCKET_STATUS.CLOSED;
    }
  }

  async subscribe({ channelKey, subscriptionMessage }) {
    if (!channelKey) {
      throw new Error('channelKey is mandatory');
    }
    if (this.channelsSubscriptions[channelKey]) {
      console.log(`Already subscribed to ${channelKey}`);
      return;
    }
    try {
      await this.send({ action: 'subscribeChannel', channel: channelKey, ...subscriptionMessage });
      this.channelsSubscriptions[channelKey] = subscriptionMessage;
    } catch (e) {
      console.error(
        `Error on channel subscription, channelKey: ${channelKey} subscriptionMessage: ${subscriptionMessage} error: ${JSON.stringify(
          e
        )}`
      );
    }
  }

  async unsubscribe({ channelKey, unsubscriptionMessage }) {
    if (!channelKey) {
      throw new Error('channelKey is mandatory');
    }
    if (!this.channelsSubscriptions[channelKey]) {
      console.log(`Not subscribed to ${channelKey}`);
      return;
    }
    try {
      await this.send({
        action: 'unsubscribeChannel',
        channel: channelKey,
        ...unsubscriptionMessage,
      });
      delete this.channelsSubscriptions[channelKey];
    } catch (e) {
      console.error(
        `Error on channel unsubscription, channelKey: ${channelKey} unsubscriptionMessage: ${unsubscriptionMessage} error: ${JSON.stringify(
          e
        )}`
      );
    }
  }

  reconnect(e) {
    this.state = WEB_SOCKET_STATUS.RECONNECTING;
    // eslint-disable-next-line no-console
    console.log(
      `WS retry in ${this.autoReconnectIntervalBase * this.retryCount}ms`,
      JSON.stringify(e)
    );
    const that = this;
    const channelsSubscriptions = that.channelsSubscriptions;
    that.channelsSubscriptions = {};
    setTimeout(function () {
      // eslint-disable-next-line no-console
      console.log('WS reconnecting...', channelsSubscriptions);
      that.state = WEB_SOCKET_STATUS.PENDING;
      that.open({
        url: that.url,
        channels: channelsSubscriptions,
        authenticateRq: process.env.REACT_APP_STAGE !== 'test',
      });
    }, this.autoReconnectIntervalBase * this.retryCount);
  }
}

export default WebSocketClient;
