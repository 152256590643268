import React from 'react';
import styled, { css } from 'styled-components';
import { CHECKBOX_STATUS } from '../../constants';

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  // Hide checkbox visually but remain accessible to screen readers.
  // Source: https://polished.js.org/docs/#hidevisually
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
HiddenCheckbox.displayName = 'Checkbox-HiddenCheckbox';

const Icon = styled.svg`
  stroke: white;
  fill: transparent;
  stroke-width: 3px;
  display: block;
`;
Icon.displayName = 'Checkbox-Icon';

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
  color: ${(props) => props.theme.color.checkbox.color};
  border: 2px solid ${(props) => props.theme.color.checkbox.border};
  ${(props) =>
    props.checked &&
    css`
      background-color: ${props.theme.color.checkbox.background};
    `}
  opacity: 1;
  transition: all 150ms;
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;
StyledCheckbox.displayName = 'Checkbox-StyledCheckbox';

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const Checkbox = ({ className, checked, status = CHECKBOX_STATUS.CHECKED, ...props }) => (
  <CheckboxContainer className={className}>
    <HiddenCheckbox checked={checked} {...props} />
    <StyledCheckbox checked={checked} className="Checkbox-StyledCheckbox">
      <Icon viewBox="0 0 24 24">
        {status === CHECKBOX_STATUS.CHECKED && <polyline points="20 6 9 17 4 12" />}
        {status === CHECKBOX_STATUS.MIXED && <polyline points="4 12 20 12" />}
      </Icon>
    </StyledCheckbox>
  </CheckboxContainer>
);

export default Checkbox;
