import { get } from 'lodash';

export const ALERTABLE_NOTIFICATIONS = [
  'TEAM_INVITE_RECEIVED',
  'TEAM_INVITE_REMOVED',
  'TEAM_INVITE_ACCEPTED',
  'TEAM_INVITE_REJECTED',
  'TEAM_INVITE_CANCELLED',
  'TEAM_INVITE_DISBAND',
];

export const ACTIONABLE_NOTIFICATIONS = [
  'TEAM_INVITE_REMOVED',
  'TEAM_INVITE_DISBAND',
];

const NOTIFICATION_MESSAGE_BY_NAME = {
  TEAM_INVITE_RECEIVED: () => 'You received a team invitation',
  TEAM_INVITE_REMOVED: () => 'You were removed from your team',
  TEAM_INVITE_ACCEPTED: (n) => `${get(n, 'notification.name', 'Someone')} joined the team`,
  TEAM_INVITE_REJECTED: () => 'Your team invite was rejected',
  TEAM_INVITE_CANCELLED: (n) => `${get(n, 'notification.name', 'Someone')} left the team`,
  TEAM_INVITE_DISBAND: () => 'Your team was disbanded',
  DEFAULT: () => 'New notification received',
};

export function formatNotificationMessage(notification) {
  const message = NOTIFICATION_MESSAGE_BY_NAME[notification.notificationType](notification);
  if (message) {
    return message;
  }
  return NOTIFICATION_MESSAGE_BY_NAME.DEFAULT;
}
