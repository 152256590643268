export const EVENT_QUESTION_STATUS = {
  CORRECT: 'CORRECT',
  INCORRECT: 'INCORRECT',
  PENDING: 'PENDING',
};
export const DEFAULT_FORM_INPUT_TYPE = 'input';
export const INPUT_FORM_TYPE_TEXTAREA = 'textarea';
export const DEFAULT_BONUS_MULTIPLIER = 2;
export const QUESTION_CARD_DEFAULT_WIDTH = '380px';

export const RESOURCE_STATE = {
  ASSIGNED: 'ASSIGNED',
  UNASSIGNED: 'UNASSIGNED',
  PROVISIONING: 'PROVISIONING',
};

export const RESOURCE_TYPE = {
  SERVER: 'server',
  URL: 'url',
  CREDENTIALS: 'credentials',
};

export const RESOURCES_COLORS = ['#61ADA1', '#81A2BE', '#E5C07B', '#A088A4'];

export const CHECKBOX_STATUS = {
  CHECKED: 'checked',
  MIXED: 'mixed'
}