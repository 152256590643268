import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  border: 1px solid ${(props) => (props.active ? '#81C784' : '#888')};
  z-index: 999;
  max-width: ${(props) => (props.mobile ? null : 'max-content')};
  margin: ${(props) => (props.margin ? props.margin : '15% auto')};
`;

const Background = styled.div`
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: ${(props) => (props.hideScroll ? 'hidden' : 'auto')};
  background-color: rgba(0, 0, 0, 0.4);
`;
Background.displayName = 'ModalBackground';

function Modal(props) {
  const { mobile, onBackgroundClick, margin, ...rest } = props;

  return (
    <Background onClick={() => onBackgroundClick && onBackgroundClick()} {...rest}>
      <Box margin={margin} mobile={mobile} onClick={(e) => e.stopPropagation()} {...rest} />
    </Background>
  );
}

export default Modal;
