import React, { useRef, useLayoutEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Flex } from 'reflexbox';

const Container = styled(Flex)`
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.color.question_card.background.inactive};
  padding: ${(props) => props.theme.margin.s};
  border: 2px solid;
  margin-bottom: ${(props) => props.theme.margin.s};
  border-color: ${(props) => props.theme.color.question_card.border.pending};
`;

const DEFUALT_SKELETON_WIDTH = '300px';
function QuestionInLineBlock() {
  const containerRef = useRef(null);
  const theme = useTheme();
  const [skeletonWidth, setSkeletonWidth] = useState(DEFUALT_SKELETON_WIDTH);

  useLayoutEffect(() => {
    if (containerRef && containerRef.current) {
      setSkeletonWidth(
        containerRef.current.getBoundingClientRect().width - theme.margin.s.replace(/px/, '') * 2
      );
    }
  }, [containerRef]);

  return (
    <Container ref={containerRef}>
      <SkeletonTheme
        color={theme.color.skeleton.color}
        highlightColor={theme.color.skeleton.highlightColor}
      >
        <Skeleton height="20px" width={skeletonWidth} />
      </SkeletonTheme>
    </Container>
  );
}

export default function QuestionInLineCardSkeleton() {
  return (
    <Flex flexDirection="column" width={1}>
      <QuestionInLineBlock />
      <QuestionInLineBlock />
      <QuestionInLineBlock />
    </Flex>
  );
}
