import styled from 'styled-components';
import { Flex } from 'reflexbox';

const ListHeader = styled(Flex)`
  box-shadow: 0px 3px 6px #00000029;
  background-color: #2c2c2c;
  height: 130px;
  padding: 40px 45px 40px 45px;
  align-items: center;
`;

export default ListHeader;
