import React, { useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { Flex } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import { LoaderIcon } from 'boss-ui';
import { useAppContext } from '../../libs/context-lib';
import { deleteUserHeaders, sendWarning } from '../../libs/utils-lib';
import WebSocketClient from '../../components/websocket';
import { onError, getMessageError } from '../../libs/error-lib';

export default function Logout() {
  const { userSession, appType } = useAppContext();
  const history = useHistory();
  const wsClient = new WebSocketClient();

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await Auth.signOut();
        userSession(null);
        deleteUserHeaders();
        wsClient.close();
      } catch (err) {
        onError(err);
        sendWarning(`logout Error ${getMessageError(err)}`);
      } finally {
        history.push('/login');
      }
    };
    handleLogout();
  }, []);

  return (
    <Flex mt="15%" alignItems="center" justifyContent="center" flexDirection="column">
      <LoaderIcon appType={appType} />
    </Flex>
  );
}
