import {
  MainContainer,
  ContentContainer,
  Name,
  Description,
  RequiredContainer,
  Star,
  Required,
  ActionsContainer,
  ButtonWrapper
} from './styles';
import { UnregularButton } from 'boss-ui';

export default function SurveyIntroduction({ scrolled, showOnDismiss, onDismiss, onNext, name = '', description = '' }) {
  return (
    <MainContainer scrolled={scrolled}>
      <ContentContainer>
        <Name>{name}</Name>
        <Description align="left">{description}</Description>
        <RequiredContainer>
          <Star align="center" mediumWeight>*</Star>
          <Required align="center" mediumWeight>Required</Required>
        </RequiredContainer>
      </ContentContainer>
      <ActionsContainer showOnDismiss={showOnDismiss}>
        {showOnDismiss && (
          <UnregularButton id="test-Survey-ButtonDismiss" onClick={onDismiss}>
            Dismiss Survey
          </UnregularButton>
        )}
        <ButtonWrapper onClick={onNext}>TAKE THE SURVEY</ButtonWrapper>
      </ActionsContainer>
    </MainContainer>
  )
}