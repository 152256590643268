import React, { useState, useEffect, useCallback } from 'react';
import { Flex } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { UnregularButton } from 'boss-ui';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import { isEmptyQuestion, checkIsPendingQuestions } from '../../libs/survey-lib';
import { SURVEY_QUESTIONS_TYPE, SURVEY_QUESTION_OPTION_OTHER } from '../../libs/constants';
import SurveyIntroduction from './SurveyIntroduction';
import QuestionContainer from './QuestionContainer';
import {
  BodyContainer,
  MainContainer,
  SecondaryContainer,
  TextIntroContainer,
  SubmitedSucess,
  ActionsContainer,
  PrevButton,
  NextButton,
  SubmitButton
} from './styles';

const prepareAnswers = (answers) => {
  const tmpAnswers = [];
  for (let x = 0; x < answers.length; x++) {
    tmpAnswers[x] = {
      id: answers[x].id,
      name: answers[x].name,
      text: answers[x].text,
      type: answers[x].type,
      required: answers[x].required,
      options: answers[x].options ? answers[x].options : null,
      answer: answers[x].answer ? answers[x].answer : '',
    };
    // case OPTIONS_AND_TEXT is more complex to set answer, can set from options array or textarea
    if (answers[x].type[0] === SURVEY_QUESTIONS_TYPE.OPTIONS_AND_TEXT) {
      tmpAnswers[x].answer =
        answers[x].answer.option === SURVEY_QUESTION_OPTION_OTHER
          ? answers[x].answer.text
          : answers[x].answer.option;
    }
  }
  return tmpAnswers;
};

const setOpacity = (answers, currentIndex = 0) => {
  if (answers && answers.length > 0) {
    const tmpAnswers = [...answers];
    for (let x = 0; x < tmpAnswers.length; x++) {
      tmpAnswers[x].opacity = 0;
    }
    if (currentIndex === 0 || currentIndex !== null) {
      tmpAnswers[currentIndex].opacity = 1;
    }
    return tmpAnswers;
  }
  return answers;
};

const API = new Api();

export default function Survey({
  id,
  campaignId,
  name,
  description = '',
  questions = [],
  showOnDismiss = true,
  showTextIntro = true,
  scrolled = false,
  onDismiss,
}) {
  const [loadingData, setLoadingData] = useState(false);
  const [answers, setAnswers] = useState(questions);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showIntro, setShowIntro] = useState(showTextIntro && !scrolled);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [isPendingQuestions, setIsPendingQuestions] = useState(false);
  const history = useHistory();

  const onSubmitSurvey = async () => {
    setLoadingData(true);
    try {
      let url = `/user/surveys/${id}/submissions`;
      if (campaignId) {
        url += `?cid=${campaignId}`;
      }
      await API.post('users', url, {
        body: { name, description, questions: prepareAnswers(answers) },
      });
      setSubmitSuccess(true);
      if (showOnDismiss) {
        setTimeout(onDismiss, 1500);
      }
    } catch (e) {
      onError(e);
    } finally {
      setLoadingData(false);
    }
  };

  const setAnswerQuestion = (idx, answer) => {
    const tmpAnswers = [...answers];
    tmpAnswers[idx].answer = answer;
    tmpAnswers[idx].showQuestionWarning = false;
    // when you answer a single question and you arent on scroll mode, the container border must be green (not required warning).
    if (!scrolled) {
      setIsPendingQuestions(false);
    }
    // check if there is not some pending question to answer on scroll mode
    if (!checkIsPendingQuestions(tmpAnswers) && scrolled) {
      setIsPendingQuestions(false);
    }
    setAnswers(tmpAnswers);
  }

  const setShowQuestionWarning = (idx) => {
    const tmpAnswers = [...answers];
    if (idx || idx === 0) {
      tmpAnswers[idx].showQuestionWarning = true;
    } else {
      for (let x = 0; x < tmpAnswers.length; x++) {
        tmpAnswers[x].showQuestionWarning = isEmptyQuestion(tmpAnswers[x]);
      }
    }
    setAnswers(tmpAnswers);
  }

  const handleClickNext = useCallback(() => {
    if (isEmptyQuestion(answers[currentIndex])) {
      setShowQuestionWarning(currentIndex);
      setIsPendingQuestions(true);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  }, [answers, currentIndex]);

  const handleClickSubmit = useCallback(() => {
    if (checkIsPendingQuestions(answers)) {
      setShowQuestionWarning();
      setIsPendingQuestions(true);
    } else {
      setIsPendingQuestions(false);
      onSubmitSurvey();
    }
  }, [answers]);

  useEffect(() => {
    // for opacity effect on pagination only
    if (!scrolled && answers.length > 0) {
      setAnswers(setOpacity([...answers], null));
      setTimeout(() => {
        setAnswers(setOpacity([...answers], currentIndex));
      }, 100);
    }
  }, [currentIndex]);

  if (!id) {
    history.push('/');
    return null;
  }

  return (
    <BodyContainer required={isPendingQuestions}>
      {submitSuccess ? (
        <SubmitedSucess>Thank you!</SubmitedSucess>
      ) : (
        <>
          {showIntro ? (
            <SurveyIntroduction
              scrolled={scrolled}
              showOnDismiss={showOnDismiss}
              onDismiss={onDismiss}
              onNext={() => setShowIntro(false)}
              name={name}
              description={description} />
          ) : (
            <MainContainer>
              <SecondaryContainer>
                {scrolled && showTextIntro && (
                  <TextIntroContainer>
                    <SurveyIntroduction name={name} description={description} />
                  </TextIntroContainer>
                )}
                {answers.map((question, idx) => {
                  const key = get(question, 'name', idx);

                  if (idx === currentIndex || scrolled) {
                    return <QuestionContainer
                      key={key}
                      scrolled={scrolled}
                      question={question}
                      idx={idx}
                      answers={answers}
                      onAnswerQuestion={(idx, change) => setAnswerQuestion(idx, change)} />
                  }
                })}
                <ActionsContainer showOnDismiss={showOnDismiss}>
                  {showOnDismiss && (
                    <UnregularButton id="test-Survey-ButtonDismiss" onClick={onDismiss}>
                      Dismiss Survey
                    </UnregularButton>
                  )}
                  <Flex>
                    {currentIndex > 0 && !scrolled && (
                      <PrevButton
                        onClick={() => setCurrentIndex(currentIndex - 1)}
                      >
                        {'< PREV'}
                      </PrevButton>
                    )}
                    {currentIndex + 1 === answers.length || scrolled ? (
                      <SubmitButton
                        disabled={loadingData}
                        onClick={handleClickSubmit}
                        id="test-Survey-ButtonSubmit"
                      >
                        SUBMIT SURVEY
                      </SubmitButton>
                    ) : (
                      <>
                        {!scrolled && (
                          <NextButton
                            onClick={handleClickNext}
                            id="test-Survey-ButtonNext"
                          >
                            {'NEXT >'}
                          </NextButton>
                        )}
                      </>
                    )}
                  </Flex>
                </ActionsContainer>
              </SecondaryContainer>
            </MainContainer>
          )}
        </>
      )}
    </BodyContainer>
  );
}
