import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { SandTimeIcon } from 'boss-ui';

const ProgressLabel = styled(Flex)`
  font-family: 'Roboto Mono';
  font-weight: normal;
  font-size: 16px;
  position: absolute;
  padding: 0px;
  width: 205px;
  background-color: #45a647;
  ${(props) =>
    props.completed &&
    css`
      padding: ${props.theme.margin.xs};
      width: 115px;
      background-color: #a088a4;
    `};
`;
ProgressLabel.displayName = 'WorkshopProgressLabel-ProgressLabel';

function WorkshopProgressLabel({ stats = {}, style = {}, forceProgress = false }) {
  if (
    stats &&
    (stats.isWorkshopCompleted || stats.progressPercentage || stats.progressPercentage === 0)
  ) {
    return (
      <>
        {stats.isWorkshopCompleted && !forceProgress ? (
          <ProgressLabel style={style} completed>
            Completed
          </ProgressLabel>
        ) : (
          <ProgressLabel style={style}>
            <Flex style={{ padding: '6px', backgroundColor: 'black' }}>
              <SandTimeIcon color="#d0d0d0" />
            </Flex>
            <Flex style={{ padding: '6px', marginLeft: '6px' }}>
              {stats.progressPercentage}% completed
            </Flex>
          </ProgressLabel>
        )}
      </>
    );
  }
  return null;
}

export default WorkshopProgressLabel;
