import React from 'react';
import { Flex } from 'reflexbox';
import {
  RegularFrown,
  RegularSmile,
  RegularSadTear,
  RegularMeh,
  RegularLaughBeam,
  SolidSmile,
  SolidSadTear,
  SolidMeh,
  SolidLaughBeam,
  SolidFrown,
} from '../../icons';
import { RegularText } from 'boss-ui';

const DEFAULT_RANGE_OPTIONS = [
  { value: 1, label: 'Sad', regularIcon: <RegularSadTear />, solidIcon: <SolidSadTear /> },
  { value: 2, label: 'Frown', regularIcon: <RegularFrown />, solidIcon: <SolidFrown /> },
  { value: 3, label: 'Meh', regularIcon: <RegularMeh />, solidIcon: <SolidMeh /> },
  { value: 4, label: 'Smile', regularIcon: <RegularSmile />, solidIcon: <SolidSmile /> },
  { value: 5, label: 'Laugh', regularIcon: <RegularLaughBeam />, solidIcon: <SolidLaughBeam /> },
];

const getFace = (filled = false, solidIcon, regularIcon) => {
  if (filled) {
    return solidIcon;
  }
  return regularIcon;
};

export default function SmileQuestion({ value, onChange = () => {} }) {
  return (
    <Flex width="320px" alignItems="center" justifyContent="space-between">
      <RegularText color="#A5D6A7" mediumWeight fontSize="14px">
        Sadness
      </RegularText>
      <Flex>
        {DEFAULT_RANGE_OPTIONS.map((option) => {
          return (
            <Flex
              style={{ cursor: 'pointer' }}
              key={option.value}
              onClick={() => onChange(option.value)}
              width="30px"
            >
              {getFace(value === option.value, option.solidIcon, option.regularIcon)}
            </Flex>
          );
        })}
      </Flex>
      <RegularText color="#A5D6A7" mediumWeight fontSize="14px">
        Total Joy!
      </RegularText>
    </Flex>
  );
}
