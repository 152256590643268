import React from 'react';
import styled, { css } from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import { ShakeContent } from 'boss-ui';
import { DEFAULT_BOX_BORDER_STYLE } from '../../libs/constants';

const Row = styled.tr`
  margin: 6px;
  height: 20px;
  ${(props) =>
    props.correct &&
    css`
      color: ${props.theme.color.table.row.correct};
    `}
  ${(props) =>
    props.incorrect &&
    css`
      color: ${props.theme.color.table.row.incorrect};
    `}
`;
const Cell = styled.td`
  ${(props) =>
    props.right &&
    css`
      text-align: right;
    `}
`;
const TableHead = styled.thead``;
const ScoreTable = styled.table`
  border: ${DEFAULT_BOX_BORDER_STYLE.border};
  font-family: 'Roboto Mono';
  font-size: 14px;
  color: ${(props) => props.theme.color.text.regular.color};
  border-collapse: collapse;
  white-space: nowrap;
  ${TableHead} {
    background-color: ${(props) => props.theme.color.table.header.background};
    color: ${(props) => props.theme.color.text.light.color};
  }
  ${Cell} {
    padding: ${(props) => props.theme.margin.xs};
  }
`;

const EMPTY_ANSWER_SCORE = { total: 0, base: 0, bonus: 0, penalty: 0 };

export default function PointsTable({ score, shakeTotal }) {
  const { total, base, bonus, penalty } = isEmpty(score) ? EMPTY_ANSWER_SCORE : score;
  return (
    <ScoreTable>
      <TableHead>
        <Row>
          <Cell>Total Points:</Cell>
          <Cell right>
            <ShakeContent shake={shakeTotal} justifyContent="flex-end">
              {total}
            </ShakeContent>
          </Cell>
        </Row>
      </TableHead>
      <tbody>
        <Row correct>
          <Cell>Base points:</Cell>
          <Cell right>{Math.round(base)}</Cell>
        </Row>
        <Row correct>
          <Cell>Bonus points:</Cell>
          <Cell right>{Math.round(bonus)}</Cell>
        </Row>
        <Row incorrect>
          <Cell>Penalty points:</Cell>
          <Cell right>{-Math.round(penalty)}</Cell>
        </Row>
      </tbody>
    </ScoreTable>
  );
}
