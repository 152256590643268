import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'reflexbox';
import { truncateString } from '../../utils';
import QuestionState from './QuestionState';
import RegularText from '../RegularText';
import {
  EVENT_QUESTION_STATUS,
  DEFAULT_BONUS_MULTIPLIER,
  QUESTION_CARD_DEFAULT_WIDTH,
} from '../../constants';
import SpinnerIcon from '../../icons/SpinnerIcon';
import TrashCanIcon from '../../icons/TrashCanIcon';

const QUESTION_MAX_LENGHT = 520;
const QUESTION_TITLE_MAX_LENGHT = 33;

const Container = styled(Flex)`
  width: ${QUESTION_CARD_DEFAULT_WIDTH};
  height: 620px;
  background-color: ${(props) => props.theme.color.question_card.background.inactive};
  padding: ${(props) => props.theme.margin.s};
  border: 2px solid;
  margin-bottom: ${(props) => props.theme.margin.s};
  border-color: ${(props) => props.theme.color.question_card.border.pending};
  ${(props) =>
    props.correct &&
    css`
      border-color: ${props.theme.color.question_card.border.correct};
    `}
  ${(props) =>
    props.incorrect &&
    css`
      border-color: ${props.theme.color.question_card.border.incorrect};
    `}
  
  &:hover {
    background-color: ${(props) => props.theme.color.question_card.background.active};
    cursor: pointer;
  }
  ${(props) =>
    props.highLigted &&
    css`
      background-color: ${props.theme.color.question_card.background.active};
      cursor: default;
    `}
`;

Container.displayName = 'QuestionCard-Container';

export default function QuestionCard({
  question,
  answer = {},
  onSelectCard,
  selectedCard,
  points,
  isEditionView = false,
  removingQuestion = false,
  onRemoveQuestion = () => {},
  error = null,
}) {
  const [isCardHover, setIsCardHover] = useState(false);
  const { base_point_value, question_id, background, title } = question;
  const isCorrect = answer.state === EVENT_QUESTION_STATUS.CORRECT;
  const isIncorrect = answer.state === EVENT_QUESTION_STATUS.INCORRECT || error !== null;

  const onRemoveQuestionClick = (e) => {
    e && e.stopPropagation();
    if (!removingQuestion) {
      onRemoveQuestion();
    }
  };

  return (
    <Flex justifyContent="center" width="fit-content">
      <Container
        highLigted={selectedCard}
        correct={isCorrect}
        incorrect={isIncorrect}
        onMouseEnter={() => setIsCardHover(true)}
        onMouseLeave={() => setIsCardHover(false)}
      >
        <Flex
          onClick={onSelectCard}
          flexDirection="column"
          width={1}
          className="QuestionCard-clickeableContainer"
        >
          <Flex alignItems="center">
            <RegularText fontSize="24px" mediumWeight>
              #{question_id}
            </RegularText>
            <Flex ml="auto">
              <QuestionState status={answer.state} iconSize={19} />
            </Flex>
          </Flex>
          <Flex my="20px">
            <RegularText
              fontSize="24px"
              mediumWeight
              style={{
                textOverflow: 'ellipsis',
                textDecoration: isCardHover && 'underline solid 0.5px',
              }}
            >
              {truncateString(title, QUESTION_TITLE_MAX_LENGHT)}
            </RegularText>
          </Flex>
          <Box mt="20px">
            <RegularText style={{ overflowWrap: 'anywhere' }}>
              {truncateString(background, QUESTION_MAX_LENGHT)}
            </RegularText>
          </Box>
          <Flex mt="auto" alignItems="center">
            {isCorrect ? (
              <RegularText fontSize="20px" mediumWeight style={{ whiteSpace: 'break-spaces' }}>
                Points Earned:{' '}
                <RegularText fontSize="20px" mediumWeight color="#81C784">
                  {points.total}{' '}
                </RegularText>
                / {Math.round(base_point_value * DEFAULT_BONUS_MULTIPLIER)}
              </RegularText>
            ) : (
              <RegularText fontSize="20px" mediumWeight>
                Base Points: {base_point_value}
              </RegularText>
            )}
            {isEditionView && (
              <Flex
                className="QuestionCard-removeButton"
                ml="auto"
                height="30px"
                onClick={onRemoveQuestionClick}
              >
                {removingQuestion ? (
                  <SpinnerIcon size="30px" color="#ba0816" />
                ) : (
                  isCardHover && <TrashCanIcon />
                )}
              </Flex>
            )}
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
}
