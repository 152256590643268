import React, { useState } from 'react';
import { Box, Flex } from 'reflexbox';
import { RegularStar, SolidStar } from '../../icons';
import { RegularText } from 'boss-ui';

const DEFAULT_RANGE_OPTIONS = { 1: 'Bad', 2: 'Regular', 3: 'Good', 4: 'Very good', 5: 'Excelent' };

const Star = ({ filled = false }) => {
  if (filled) {
    return <SolidStar />;
  }
  return <RegularStar />;
};

const calculateInitialState = (options, value = 0) => {
  const initialState = {};
  Object.keys(options).forEach((k) => {
    initialState[k] = { value: k, label: options[k], filled: k <= value };
  });
  return initialState;
};

export default function StarQuestion({
  value,
  onChange = () => {},
  options = DEFAULT_RANGE_OPTIONS,
}) {
  const [stars, setStars] = useState(calculateInitialState(options, value));

  const onStarClicked = (clickedValue) => {
    setStars((prevStars) => {
      const newStars = {};
      Object.keys(prevStars).forEach((starKey) => {
        if (prevStars[starKey].value <= clickedValue) {
          newStars[starKey] = { ...prevStars[starKey], filled: true };
        } else {
          newStars[starKey] = { ...prevStars[starKey], filled: false };
        }
      });
      return newStars;
    });
    onChange(clickedValue);
  };

  const onStarHoverEnter = (hoverEnterValue) => {
    setStars((prevStars) => {
      const newStars = {};
      Object.keys(prevStars).forEach((starKey) => {
        if (prevStars[starKey].value <= hoverEnterValue) {
          newStars[starKey] = { ...prevStars[starKey], filled: true };
        } else {
          newStars[starKey] = { ...prevStars[starKey], filled: false };
        }
      });
      return newStars;
    });
  };

  const onStarHoverLeave = () => {
    setStars((prevStars) => {
      const newStars = {};
      Object.keys(prevStars).forEach((starKey) => {
        newStars[starKey] = {
          ...prevStars[starKey],
          filled: prevStars[starKey].value <= value,
        };
      });
      return newStars;
    });
  };

  return (
    <Box>
      <Flex width="300px">
        {Object.keys(stars).map((optionKey) => {
          return (
            <Flex
              style={{ cursor: 'pointer' }}
              key={stars[optionKey].value}
              onClick={() => onStarClicked(stars[optionKey].value)}
              onMouseEnter={() => onStarHoverEnter(stars[optionKey].value)}
              onMouseLeave={() => onStarHoverLeave(stars[optionKey].value)}
            >
              <Star filled={stars[optionKey].filled} />
            </Flex>
          );
        })}
      </Flex>
      <RegularText
        color="#A5D6A7"
        mediumWeight
        fontSize="14px"
        style={{ height: '25px', paddingTop: '5px' }}
      >
        {value && stars[value].label}
      </RegularText>
    </Box>
  );
}
