import styled, { css } from 'styled-components';
import { heightEffect } from '../../libs/animations-lib';

const CardDescription = styled.div`
  font-size: 16px;
  color: ${(props) => props.theme.color.card.classic.description};
  text-align: left;
  overflow: hidden;
  font-weight: 300;
  font-size: 14px;
  font-family: 'Roboto Mono';
  word-break: break-word;
  ${(props) =>
    props.climpAfterLine &&
    css`
      -webkit-line-clamp: ${props.climpAfterLine};
      -webkit-box-orient: vertical;
      display: -webkit-box;
    `}
  ${(props) =>
    props.animate &&
    css`
      animation: ${heightEffect} 2s ease-out;
      -moz-animation: ${heightEffect} 2s ease-out;
      -webkit-animation: ${heightEffect} 2s ease-out;
    `};
  ${(props) =>
    props.minHeight &&
    css`
      min-height: ${props.minHeight};
    `};
  ${(props) =>
    props.restricted &&
    css`
      color: ${props.theme.color.card.restricted.description};
    `};
`;

CardDescription.displayName = 'CardDescription';
export default CardDescription;
