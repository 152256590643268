import React, { useState, useLayoutEffect, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'reflexbox';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import first from 'lodash/first';
import Media from 'react-media';
import {
  Button,
  RegularText,
  UnregularButton,
  PrevIcon,
  NextIcon,
  EVENT_QUESTION_STATUS,
  ShakeContent,
} from 'boss-ui';
import Api from '../../api';
import QuestionState from './QuestionState';
import HintsTable from '../hints/HintsTable';
import QuestionAnswer from './QuestionAnswer';
import QuestionAnswerItem from './QuestionAnswerItem';
import QuestionHistoryModal from './QuestionHistoryModal';
import QuestionPointsTable from './QuestionPointsTable';
import {
  DEFAULT_BOX_BORDER_STYLE,
  DEFAULT_BONUS_MULTIPLIER,
  MENU_BAR_HEIGHT,
} from '../../libs/constants';
import { useScenarioContext, useAppContext } from '../../libs/context-lib';
import { updateQuestionBy, updateQuestionsWithAnswer } from '../../libs/question-lib';
import { onError } from '../../libs/error-lib';

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.question_card.background.inactive};
  padding: ${(props) => props.theme.margin.s};
  border: 2px solid;
  margin: ${(props) => props.theme.margin.s} 0 ${(props) => props.theme.margin.s} 0;
  border-color: ${(props) => props.theme.color.question_card.border.pending};
  box-shadow: 0px 12px 16px #0000004d;
  width: 100%;
  max-width: 1000px;
  ${(props) =>
    props.correct &&
    css`
      border-color: ${props.theme.color.question_card.border.correct};
    `}
  ${(props) =>
    props.incorrect &&
    css`
      border-color: ${props.theme.color.question_card.border.incorrect};
    `}
`;
Container.displayName = 'QuestionBigCard-Container';

const getGridColumnsOnExpanded = (media) => {
  if (media.s) {
    return { gridColumnStart: 1, gridColumnEnd: 2 };
  }
  if (media.xl) {
    return { gridColumnStart: 1, gridColumnEnd: 4 };
  }
  return { gridColumnStart: 1, gridColumnEnd: 3 };
};

const API = new Api();

export default function QuestionBigCard({
  question,
  media = {},
  onCloseCard,
  answer = {},
  points,
  gridNavigation = {},
}) {
  const { phrasing, base_point_value, question_id, external, required_resources, background } =
    question;
  const { isPrev, isNext, goNext, goPrev } = gridNavigation;
  const { currentScenario, setScenarioQuestions, scenarioQuestions } = useScenarioContext();
  const { event, user } = useAppContext();
  const history = useHistory();
  const [submittingAnswer, setSubmittingAnswer] = useState(false);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [questionPoints, setQuestionPoints] = useState(points);
  const [questionHistory, setQuestionHistory] = useState([]);
  const [lastQuestionAnswer, setLastAnswer] = useState(answer);
  const [closing, setClosing] = useState(false);
  const [shaking, setShaking] = useState(false);
  const questionRef = useRef(null);
  const isQuestionCorrect = lastQuestionAnswer.state === EVENT_QUESTION_STATUS.CORRECT;
  const isQuestionIncorrect = lastQuestionAnswer.state === EVENT_QUESTION_STATUS.INCORRECT;
  const includeBackground = background !== phrasing.question_phrasing;
  /**
   * Provides the external url of the required resource to answer the question
   */
  const externalURL = useMemo(() => {
    if (!required_resources || !required_resources.length || !user.userInTeam.resources) {
      return null;
    }
    const resourceId = first(required_resources).resource_id;
    if (resourceId && user.userInTeam.resources && user.userInTeam.resources.length) {
      const userResource = user.userInTeam.resources.find((r) => r.name === resourceId);
      return userResource ? userResource.instance_url : null;
    }
    return null;
  }, [required_resources, user.userInTeam]);

  useLayoutEffect(() => {
    const questionBoxPosition = questionRef.current.getBoundingClientRect();
    window.scrollTo(0, questionBoxPosition.top + window.pageYOffset - MENU_BAR_HEIGHT);
  }, []);

  useEffect(() => {
    const getQuestion = async () => {
      try {
        const historyRequest = await API.get(
          'users',
          `/user/events/${currentScenario.eventId}/scenarios/${currentScenario.scenarioId}/questions/${question_id}`
        );
        setQuestionHistory(get(historyRequest, 'question.activity', []));
        setQuestionPoints(historyRequest.question.total);
        setLastAnswer(historyRequest.question.answer);
        setScenarioQuestions((scenarioQuestions) =>
          updateQuestionBy(
            scenarioQuestions,
            {
              question: historyRequest.question.question,
              total: historyRequest.question.total,
              answer: historyRequest.question.answer,
            },
            question_id
          )
        );
      } catch (e) {
        onError(e);
      }
    };

    getQuestion();
    return () => API.abortCurrentRequest();
  }, []);

  // update answer and points state variables if there is a prop change
  useEffect(() => {
    setLastAnswer(answer);
    setQuestionPoints(points);
  }, [answer, points]);

  // update questionHistory state if there is a new answer
  useEffect(() => {
    // prevent modifications in history for lastQuestionAnswer default value
    if (isEmpty(lastQuestionAnswer)) {
      return;
    }
    if (!questionHistory[0]) {
      setQuestionHistory([lastQuestionAnswer]);
      return;
    }
    // update history if history is empty (first condition) or history is not empty and last item is different than lastQuestionAnswer
    if (questionHistory[0] && questionHistory[0].createdAt !== lastQuestionAnswer.createdAt) {
      setQuestionHistory((answers) => [lastQuestionAnswer].concat(answers));
    }
  }, [lastQuestionAnswer]);

  const onClose = () => {
    if (!submittingAnswer) {
      setClosing(true);
      window.scrollTo(0, window.pageYOffset - 720);
      onCloseCard();
    }
  };

  const shakeComponents = () => {
    setShaking(true);
    setTimeout(() => setShaking(false), 1000);
  };

  const submitAnswer = async (answerInput) => {
    setSubmittingAnswer(true);
    try {
      const answerRequest = await API.post(
        'users',
        `/user/events/${currentScenario.eventId}/scenarios/${currentScenario.scenarioId}/questions/${question_id}/answer`,
        {
          body: {
            answer: answerInput,
            phrasing_id: phrasing.phrasing_id,
          },
        }
      );
      setQuestionPoints(answerRequest.total);
      setLastAnswer(answerRequest.answer);
      setScenarioQuestions(
        updateQuestionBy(scenarioQuestions, answerRequest, question_id, updateQuestionsWithAnswer)
      );
    } catch (e) {
      onError(e);
      if (e.response && e.response.status === 403) {
        history.push('/');
      }
    } finally {
      setSubmittingAnswer(false);
      shakeComponents();
    }
  };

  const buyHint = async (hintId) => {
    try {
      const rq = await API.post(
        'events',
        `/user/events/${currentScenario.eventId}/scenarios/${currentScenario.scenarioId}/questions/${question_id}/hints`,
        {
          body: {
            hint_id: hintId,
            phrasing_id: phrasing.phrasing_id,
          },
        }
      );
      const questionRequest = await API.get(
        'users',
        `/user/events/${currentScenario.eventId}/scenarios/${currentScenario.scenarioId}/questions/${question_id}`
      );
      // update question in question list
      setScenarioQuestions(
        updateQuestionBy(scenarioQuestions, questionRequest.question, question_id)
      );
      // update question points
      setQuestionPoints(rq.total);
      // update question history with the hint
      setQuestionHistory((answers) => [{ ...rq.hint }, ...answers]);
    } catch (e) {
      onError(e);
    }
  };

  const refreshHints = async () => {
    try {
      const questionRequest = await API.get(
        'users',
        `/user/events/${currentScenario.eventId}/scenarios/${currentScenario.scenarioId}/questions/${question_id}`
      );
      // update question in question list
      setScenarioQuestions(
        updateQuestionBy(scenarioQuestions, questionRequest.question, question_id)
      );
    } catch (e) {
      onError(e);
    }
  };

  return (
    <Flex
      justifyContent="center"
      width={1}
      style={getGridColumnsOnExpanded(media)}
      ref={questionRef}
    >
      <Container
        minHeight="620px"
        closing={closing}
        correct={isQuestionCorrect}
        incorrect={isQuestionIncorrect}
      >
        <Flex flexDirection="column" width="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <RegularText fontSize="24px" mediumWeight>
              #{question_id}
            </RegularText>
            <ShakeContent shake={shaking}>
              <QuestionState status={lastQuestionAnswer.state} />
            </ShakeContent>
            <Flex>
              <UnregularButton onClick={onClose}>Close</UnregularButton>
            </Flex>
          </Flex>
          {includeBackground && (
            <Flex mt="10px">
              <RegularText color="#979797" fontSize="14px">
                {background}
              </RegularText>
            </Flex>
          )}
          <Box mt="10px">
            <RegularText fontSize="18px" mediumWeight>
              Question:
            </RegularText>
            <RegularText fontSize="18px" mediumWeight>
              {phrasing.question_phrasing}
            </RegularText>
            {phrasing.answer_guidance && (
              <Flex mt="6px">
                <RegularText color="#81A2BE" style={{ wordBreak: 'break-word' }}>
                  Answer guidance: {phrasing.answer_guidance}
                </RegularText>
              </Flex>
            )}
          </Box>
          <QuestionAnswer
            localStorageId={`${currentScenario.eventId}${currentScenario.scenarioId}${question_id}`}
            isExternal={external}
            externalURL={externalURL}
            lastQuestionAnswer={lastQuestionAnswer}
            onSubmitAnswer={submitAnswer}
            isQuestionCorrect={isQuestionCorrect}
            isSubmittingAnswer={submittingAnswer}
          />
          <Flex mt="40px" flexDirection="column">
            <HintsTable
              hints={phrasing.hints}
              hintsStrategy={event.hintScoring}
              totalHints={phrasing.hints_count}
              onBuyHint={buyHint}
              onRefreshHintsList={refreshHints}
              active={!isQuestionCorrect}
            />
          </Flex>
          <Media query="(max-width: 750px)">
            {(matches) => (
              <Flex mt="40px" mb="20px">
                <Box minWidth="200px">
                  <Flex mb="4px">
                    <RegularText mediumWeight fontSize="14px">
                      Points earned
                    </RegularText>
                  </Flex>
                  <QuestionPointsTable score={questionPoints} shakeTotal={shaking} />
                </Box>
                {matches ? (
                  <Flex ml="auto">
                    <UnregularButton
                      noPadding
                      onClick={() => setShowHistoryModal(true)}
                      disabled={isEmpty(lastQuestionAnswer)}
                      className="QuestionBigCard-openActivity"
                    >
                      View Details
                    </UnregularButton>
                  </Flex>
                ) : (
                  <Box width={1} ml="12px">
                    <Flex mb="4px">
                      <RegularText mediumWeight fontSize="14px">
                        Submission history
                      </RegularText>
                      <Flex ml="auto">
                        <UnregularButton
                          noPadding
                          onClick={() => setShowHistoryModal(true)}
                          disabled={isEmpty(lastQuestionAnswer)}
                          className="QuestionBigCard-openActivity"
                        >
                          View Details
                        </UnregularButton>
                      </Flex>
                    </Flex>
                    <Box p="6px" style={DEFAULT_BOX_BORDER_STYLE} minHeight="126px">
                      {isEmpty(lastQuestionAnswer) ? (
                        <RegularText fontSize="12px">No answer so far</RegularText>
                      ) : (
                        <QuestionAnswerItem {...lastQuestionAnswer} />
                      )}
                    </Box>
                  </Box>
                )}
              </Flex>
            )}
          </Media>
          <Flex mt="auto" justifyContent="space-between" alignItems="center">
            <Box>
              <Button
                hide={!isPrev}
                disabled={submittingAnswer}
                onClick={goPrev}
                className="QuestionBigCard-PrevQuestionButton"
                secondary
              >
                <PrevIcon />
              </Button>
            </Box>
            {isQuestionCorrect ? (
              <RegularText fontSize="20px" mediumWeight style={{ whiteSpace: 'break-spaces' }}>
                Points Earned:{' '}
                <RegularText fontSize="20px" mediumWeight color="#81C784">
                  {questionPoints.total}{' '}
                </RegularText>
                / {Math.round(base_point_value * DEFAULT_BONUS_MULTIPLIER)}
              </RegularText>
            ) : (
              <RegularText fontSize="20px" mediumWeight>
                Base Points: {base_point_value}
              </RegularText>
            )}
            <Box>
              <Button
                hide={!isNext}
                disabled={submittingAnswer}
                onClick={goNext}
                className="QuestionBigCard-NextQuestionButton"
                secondary
              >
                <NextIcon />
              </Button>
            </Box>
          </Flex>
        </Flex>
      </Container>
      {showHistoryModal && (
        <QuestionHistoryModal
          onClose={() => setShowHistoryModal(false)}
          answers={questionHistory}
          questionId={question_id}
        />
      )}
    </Flex>
  );
}
