import React from 'react';
import { useMarked } from '../../hooks';
import RegularText  from '../RegularText';

const Markdown = ({ md = '', ...args }) => {
  const eventRulesHtml = useMarked(md);
  return (
    <RegularText {...args}>
      <div
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: eventRulesHtml,
        }}
      />
    </RegularText>
  );
};

export default Markdown;
