import React from 'react';
import styled from 'styled-components';
import svgBase from './StyledSVGBase';

const SIZE = '40px';

const Arrow = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: #1d1d1d;
  &:hover {
    fill: #2c2c2c;
    cursor: pointer;
  }
`;

export default function LeftArrowShow() {
  return (
    <Arrow viewBox="0 0 40 40">
      <rect width="40" height="40" />
      <g transform="translate(8 8)">
        <path fill="none" d="M0,0H24V24H0Z" />
        <path fill="#535353" d="M12,14H4V10h8V4l8,8-8,8Z" transform="translate(3)" />
        <rect fill="#535353" width="4" height="24" />
      </g>
    </Arrow>
  );
}
