import React from 'react';
import { useTheme } from 'styled-components';
import { CurveArrow } from './icons';

export default function FloaitngMessage({
  top = 0,
  left = 0,
  active = false,
  direction = 'top',
  children,
}) {
  const colors = useTheme().color.text.floatingMessage;
  const color = active ? colors.active : colors.inactive;

  return (
    <div
      style={{
        color,
        position: 'absolute',
        top,
        left,
        ...(direction === 'left' && { display: 'flex' }),
      }}
    >
      <CurveArrow direction={direction} color={color} />
      {children}
    </div>
  );
}
