import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import { API, Auth } from 'aws-amplify';
import Media from 'react-media';
import {
  Button,
  Form,
  FormLabel,
  FormLeyend,
  LinkedText,
  StyledText,
  RegularText,
  BooIcon,
  BotsIcon,
} from 'boss-ui';
import SplunkRum from '@splunk/otel-web';
import config from '../../config';
import { useAppContext } from '../../libs/context-lib';
import { onError } from '../../libs/error-lib';
import { useFormFields, useQuery } from '../../libs/hooks-lib';
import { FormInput } from '../../components';
import VerifyEmailForm from '../../components/VerifyEmailForm';
import { setUserHeaders } from '../../libs/utils-lib';
import { BOTS_TYPE } from '../../libs/constants';
import { getEventShortName } from '../../libs/event-lib';

const iconSize = 145;

export default function Login() {
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);
  const [showVerifyEmail, setShowVerifyEmail] = useState(false);
  const { userSession, appType } = useAppContext();
  const history = useHistory();
  const labelName = getEventShortName(appType);

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  const setDynamoUser = async (cognitoUser) => {
    const registeredUser = await API.get('users', '/user');
    if (!registeredUser.user) {
      userSession(cognitoUser);
      throw new Error('UserNotFoundInDynamo');
    }
    userSession({ ...cognitoUser, dynamoUser: registeredUser.user });
    setUserHeaders({ ...cognitoUser, dynamoUser: registeredUser.user });
    SplunkRum?.setGlobalAttributes({
      'user.id': registeredUser.user.userId,
      'user.email': registeredUser.user.email,
      'user.username': registeredUser.user.displayName,
    });
  };

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setLoading(true);
      const cognitoUser = await Auth.signIn(fields.email.toLowerCase(), fields.password);
      await setDynamoUser(cognitoUser);
    } catch (e) {
      if (e.code === 'UserNotConfirmedException') {
        setShowVerifyEmail(true);
      } else if (e.message === 'UserNotFoundInDynamo') {
        history.push('/firstLogin');
      } else {
        setLoading(false);
        onError(e);
      }
    }
  }

  function renderConfirmationForm() {
    return <VerifyEmailForm email={fields.email} password={fields.password} />;
  }

  function FederatedLogin() {
    const query = useQuery();
    const internalRedirect = query.get('redirect');
    return (
      <Media
        queries={{
          s: '(max-width: 615px)',
        }}
      >
        {(screenSize) => (
          <Flex justifyContent="center">
            <Flex width="100%" flexDirection="column" alignItems="center">
              <Flex justifyContent="center" mt="100px" mb="20px">
                <StyledText size="40px" light>
                  Welcome to {labelName}
                </StyledText>
              </Flex>
              <Flex
                flexDirection="column"
                alignItems="center"
                width={screenSize.s ? '90%' : '608px'}
                height={!screenSize.s && '225px'}
                style={{ border: '1px solid #373737' }}
                px={screenSize.s ? '10px' : '80px'}
                py="40px"
              >
                <Flex pb="40px">
                  <RegularText style={{ color: '#d0d0d0', letterSpacing: '0px' }}>
                    You’ll be redirected to Splunk’s general login page. Use your Splunk Username
                    and Password to access {labelName}.
                  </RegularText>
                </Flex>
                {!screenSize.s && (
                  <Flex pb="40px" style={{ position: 'absolute', transform: 'translateX(-303px)' }}>
                    {appType === BOTS_TYPE.SOC ? (
                      <BotsIcon size={iconSize} />
                    ) : (
                      <BooIcon size={iconSize} />
                    )}
                  </Flex>
                )}
                <Flex width={screenSize.s ? '80%' : '292px'}>
                  <Button
                    onClick={() =>
                      window.location.assign(
                        `${config.cognito.saml.IDP_LOGIN_URL}?identity_provider=${
                          config.cognito.saml.IDP_NAME
                        }&response_type=${config.cognito.saml.IDP_RESPONSE_TYPE}&client_id=${
                          config.cognito.saml.APP_CLIENT_ID
                        }&redirect_uri=${config.cognito.saml.REDIRECT_SIGN_IN}&scope=${
                          config.cognito.saml.IDP_SCOPE
                        }&state=${internalRedirect || '/'}`
                      )
                    }
                  >
                    GO TO SIGN IN
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        )}
      </Media>
    );
  }

  function renderForm() {
    return (
      <Flex justifyContent="center">
        <Box>
          <Flex justifyContent="center" mt="100px" mb="16px">
            <StyledText size="40px" light>
              Sign In
            </StyledText>
          </Flex>
          <Form>
            <Flex>
              <Box py="40px" px="60px" width="412px">
                <Box mb="20px">
                  <FormLabel>Email</FormLabel>
                  <FormInput
                    autoFocus
                    placeholder="Enter your email"
                    type="email"
                    value={fields.email}
                    onChange={handleFieldChange}
                    id="email"
                  />
                </Box>
                <Box mb="20px">
                  <FormLabel>Password</FormLabel>
                  <FormInput
                    placeholder="Enter your password"
                    id="password"
                    value={fields.password}
                    onChange={handleFieldChange}
                    type="password"
                  />
                </Box>
                <Box mb="20px">
                  <Button
                    id="login-button"
                    isLoading={loading}
                    disabled={!validateForm()}
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Login
                  </Button>
                </Box>
                <Flex justifyContent="center">
                  <FormLeyend>
                    <LinkedText onClick={() => history.push('/recovery')}>
                      Forgot your password?
                    </LinkedText>
                  </FormLeyend>
                </Flex>
              </Box>
            </Flex>
          </Form>
          <Flex mt="20px" justifyContent="center">
            <FormLeyend size="14px">
              Don’t have an account?{' '}
              <LinkedText onClick={() => history.push('/signup')}>SIGN UP</LinkedText>
            </FormLeyend>
          </Flex>
        </Box>
      </Flex>
    );
  }

  return config.cognito.SAML_LOGIN
    ? FederatedLogin()
    : showVerifyEmail
    ? renderConfirmationForm()
    : renderForm();
}
