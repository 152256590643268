import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';

const BodyContainer = styled(Flex)`
  padding: ${(props) => props.theme.margin.s};
  font-family: 'Roboto Mono';
  font-weight: normal;
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.color.card.classic.border.active
        : props.theme.color.card.classic.border.inactive};
  ${(props) =>
    props.restricted &&
    css`
      border: 1px solid ${props.theme.color.card.restricted.border};
    `};
  ${(props) =>
    props.inprogress &&
    css`
      border: 1px solid ${props.theme.color.card.classic.border.inprogress};
    `};
  ${(props) =>
    props.completed &&
    css`
      border: 1px solid ${props.theme.color.card.classic.border.completed};
    `};
  ${(props) =>
    props.history &&
    css`
      border: 1px solid ${props.theme.color.card.classic.border.history};
    `};
  border-top-color: transparent;
`;

BodyContainer.displayName = 'CardBody';
export default BodyContainer;
