import React from 'react';
import { SCENARIO_HINTS_STRATEGIES } from '../../libs/constants';
import TimeBasedHint from './TimeBasedHint';
import BuyBasedHint from './BuyBasedHint';

export default function HintsTable(props) {
  return props.hintsStrategy === SCENARIO_HINTS_STRATEGIES.TIME ? (
    <TimeBasedHint {...props} />
  ) : (
    <BuyBasedHint {...props} />
  );
}
