import styled from 'styled-components';

const CardIcon = styled.div`
  background-color: ${(props) => props.theme.color.background};
  align-items: center;
  width: 26px;
  height: 26px;
`;

CardIcon.displayName = 'CardIconContainer';
export default CardIcon;
