import omit from 'lodash/omit';
import find from 'lodash/find';
import { TEAM_NAME_REGEXP, USER_INVITE_STATE } from './constants';

const VISIBILITY_RAW = {
  PUBLIC: true,
  PRIVATE: false,
};
export const VISIBILITY_VALUE = {
  true: 'PUBLIC',
  false: 'PRIVATE',
};
const ACCESSIBILITY_RAW = { OPEN: true, CLOSED: false };
export const ACCESSIBILITY_VALUE = { true: 'OPEN', false: 'CLOSED' };

export const defineTeamMembers = (team, maxNumberOfParticipants) => ({
  ...team,
  full: maxNumberOfParticipants === team.numberOfParticipants,
});

const validateChanges = (team) => {
  if (!team.name.match(TEAM_NAME_REGEXP)) {
    throw new Error('Check on your team name!');
  }
};

export const getTeamChanges = (baseTeam, newTeam) => {
  const changes = {};
  const changedParticipants = [];
  validateChanges(newTeam);
  newTeam.participants.forEach((editedParticipant) => {
    if (editedParticipant.dirty) {
      const originalParticipant = find(
        baseTeam.participants,
        (p) => p.userId === editedParticipant.userId
      );
      if (originalParticipant && originalParticipant.state === editedParticipant.state) {
        changedParticipants.push(omit(editedParticipant, 'state'));
        return;
      }
      changedParticipants.push(editedParticipant);
    }
  });
  if (changedParticipants.length > 0) {
    changes.participants = changedParticipants;
  }
  if (newTeam.name !== baseTeam.name) {
    changes.name = newTeam.name;
  }
  if (newTeam.visibility !== VISIBILITY_RAW[baseTeam.visibility]) {
    changes.visibility = VISIBILITY_VALUE[newTeam.visibility];
  }
  if (newTeam.accessibility !== ACCESSIBILITY_RAW[baseTeam.accessibility]) {
    changes.accessibility = ACCESSIBILITY_VALUE[newTeam.accessibility];
  }
  return changes;
};

export const getUserRoleInTeam = (participants = [], userId) => {
  const userInTeam = participants.find((p) => p.userId === userId && p.state === USER_INVITE_STATE.ACCEPTED);
  if (userInTeam) {
    return userInTeam.role;
  }
  throw new Error('User is not in the team');
};

export const getContainerStyleMedia = (matches) => {
  const style = { mx: '10%', width: '' };
  if (matches.s) {
    style.mx = '0px';
    style.width = '100%';
  }
  return style;
};

export const getStyleMedia = (matches) => {
  const style = {
    boxWidth: '766px',
    boxPx: '60px',
    formWidth: '',
    flexWidth: '',
    flexPx: '',
    teamNameMobile: false,
    joinTeamFlexDirection: 'column',
    joinTeamWidth: '750px',
    joinTeamHeight: '530px',
    joinTeamInputWidth: '66%',
    joinTeamInputPX: '45px',
    joinTeamInputPY: '40px',
    joinTeamInputHeight: '130px',
    teamInvitationsWidth: '300px',
    switchButtonsFlex: 'row',
    switchButtonsWidth: '67%',
    btnSetJoinWidth: '25%',
    btnSetLeaveWidth: '50%',
    btnNewTeam: '50%',
    pointAdjustFlexDir: 'row',
    pointAdjustAlign: 'center',
    pointAdjustPy: '',
    pointAdjustAssignBtnWitdh: '25%',
    pointAdjustAssignReasonWitdh: '400px',
  };

  if (matches && matches.l) {
    style.joinTeamWidth = '100%';
    style.joinTeamHeight = '100%';
    style.joinTeamInputWidth = '100%';
    style.joinTeamInputPX = '20px';
    style.joinTeamInputPY = '10px';
    style.joinTeamInputHeight = '90px';
    style.teamInvitationsWidth = '100%';
  }
  if (matches && matches.m) {
    style.flexPx = '10px';
    style.flexWidth = '100%';
    style.formWidth = '100%';
    style.boxWidth = '100%';
    style.boxPx = '20px';
    style.btnSetJoinWidth = '100%';
    style.btnSetLeaveWidth = '100%';
    style.joinTeamWidth = '100%';
    style.joinTeamHeight = '100%';
    style.joinTeamInputWidth = '100%';
    style.joinTeamInputPX = '20px';
    style.joinTeamInputPY = '10px';
    style.joinTeamInputHeight = '90px';
    style.teamInvitationsWidth = '100%';
  }

  if (matches && matches.s) {
    style.teamNameMobile = true;
    style.boxWidth = '100%';
    style.switchButtonsFlex = 'column';
    style.switchButtonsWidth = '170px';
    style.btnNewTeam = '100%';
    style.pointAdjustFlexDir = 'column';
    style.pointAdjustAlign = 'flex-start';
    style.pointAdjustPy = '5px';
    style.pointAdjustAssignBtnWitdh = '100%';
    style.pointAdjustAssignReasonWitdh = '100%';
    style.joinTeamWidth = '100%';
    style.joinTeamHeight = '100%';
    style.joinTeamInputWidth = '100%';
    style.joinTeamInputPX = '20px';
    style.joinTeamInputPY = '10px';
    style.joinTeamInputHeight = '90px';
    style.teamInvitationsWidth = '100%';
  }
  if (matches && matches.xs) {
    style.boxWidth = '100%';
  }
  return style;
};
