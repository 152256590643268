import React from 'react';
import styled from 'styled-components';
import Base from '../StyledSVGBase';

const BlockSVG = styled(Base)`
  fill: #898989;
  pointer-events: all;
  &:hover {
    fill: #e06c75;
  }
`;
BlockSVG.displayName = 'BlockIcon-BlockSVG';

export default function BlockIcon(props) {
  return (
    <BlockSVG width={props.size || 32} height={props.size || 32} viewBox="0 0 32 32" {...props}>
      <path style={{isolation: 'isolate'}} d="M242.983-62.55a10,10,0,0,0-10,10,10,10,0,0,0,10,10,10,10,0,0,0,10-10A10.011,10.011,0,0,0,242.983-62.55Zm-7.5,10a7.487,7.487,0,0,1,7.475-7.5,7.487,7.487,0,0,1,4.364,1.394L236.877-48.211A7.452,7.452,0,0,1,235.483-52.55Zm11.864,6.106a7.486,7.486,0,0,1-4.364,1.395,7.454,7.454,0,0,1-4.339-1.394l10.445-10.445A7.487,7.487,0,0,1,247.347-46.444Z" transform="translate(-226.983 68.551)"/>
    </BlockSVG>
  );
}
