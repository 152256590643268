import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';

const Button = styled.span`
  content: '';
  position: absolute;
  left: 2px;
  top: 2px;
  width: 18px;
  height: 18px;
  border-radius: 12px;
  transition: 0.2s;
  background: #d0d0d0;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 38px;
  height: 22px;
  background: #535353;
  border-radius: 50px;
  position: relative;
  &:active ${Button} {
    width: 10px;
  }
`;

const Input = styled.input`
  height: 0;
  width: 0;
  visibility: hidden;
  &:checked + ${Label} ${Button} {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
  &:checked + ${Label} {
    background-color: #66bb6a;
  }
`;

export default function ToggleButton({ isOn, onToggle }) {
  return (
    <Flex mx="5px" align="center" className="ToggleButton-FlexToggle">
      <Input
        checked={isOn}
        onChange={onToggle}
        id="react-switch-new"
        className="ToggleButton-Input"
        type="checkbox"
      />
      <Label htmlFor="react-switch-new" id="labelToggleButton">
        <Button />
      </Label>
    </Flex>
  );
}
