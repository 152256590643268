import React, { useEffect, useState, Suspense } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { isEmpty } from 'lodash';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import { useAppContext } from '../../libs/context-lib';
import {
  LoaderIcon,
  Modal,
  CrossIcon,
  EventCredits,
} from 'boss-ui';
import { EventMenu } from '../../components';
import { sendWarning } from '../../libs/utils-lib';
import { EVENT_DELIVERY, USER_ROLES } from '../../libs/constants';
import EventFloatingBox from '../../components/event/EventFloatingBox';
import { getEventShortName } from '../../libs/event-lib';

const EventPlayer = React.lazy(() =>
  import('../EventPlayer').catch(() => {
    sendWarning('force reload by chunk error on EventPlayer', true);
  })
);

const API = new Api();

export default function Event() {
  const { user, userSession, event, setNavTitle, setEvent, appType } = useAppContext();
  const [creditsModalVisible, setCreditsModalVisible] = useState(false);
  const [loadingUserInEvent, setLoadingUser] = useState(true);
  const [loadingEvent, setLoadingEvent] = useState(false);
  const history = useHistory();
  const routeMatch = useRouteMatch('/event/:eventId');
  const isAdmin = user.role === USER_ROLES.ADMIN;

  useEffect(() => {
    const getUserInEvent = async (eventId) => {
      setLoadingUser(true);
      try {
        const rq = await API.get('users', `/user/events/${eventId}`);
        userSession((userInSession) => ({
          ...userInSession,
          userInTeam: rq.team,
          role: rq.participant.role,
        }));
        // if user does not have a team in a normal event redirect to join team page
        if (rq.event && rq.event.delivery === EVENT_DELIVERY.NORMAL && isEmpty(rq.team)) {
          history.push(`/event/${eventId}/team/join`);
        }
      } catch (e) {
        onError(e);
        history.push('/');
      } finally {
        setLoadingUser(false);
      }
    };
    const getEventByPathId = async () => {
      setLoadingEvent(true);
      try {
        const { event, participant } = await API.get(
          'events',
          `/events/${routeMatch.params.eventId}`
        );
        setEvent({ ...event, participant });
        setNavTitle(event.name);
        getUserInEvent(event.eventId);
      } catch (e) {
        onError(e);
        history.push('/');
      } finally {
        setLoadingEvent(false);
      }
    };

    if (event && event.scenarios) {
      getUserInEvent(event.eventId);
      setNavTitle(event.name);
    } else {
      getEventByPathId();
    }

    return () => {
      setNavTitle(null);
      userSession((u) => ({ ...u, userInTeam: undefined }));
      API.abortCurrentRequest();
    };
  }, []);

  if (loadingUserInEvent || loadingEvent) {
    return (
      <Flex mt="10%" alignItems="center" justifyContent="center" flexDirection="column">
        <LoaderIcon appType={appType} />
      </Flex>
    );
  }

  const leaveEvent = async () => {
    history.push('/');
  };

  return (
    <Suspense
      fallback={
        <Flex mt="10%" alignItems="center" justifyContent="center" flexDirection="column">
          <LoaderIcon appType={appType} />
        </Flex>
      }
    >
      <EventMenu
        onLeaveEvent={leaveEvent}
        onShowCredits={() => setCreditsModalVisible(true)}
      />
      {creditsModalVisible && (
        <Modal
          onBackgroundClick={() => setCreditsModalVisible(false)}
          margin="5% auto 5% auto"
          hideScroll
        >
          <Flex width={1} style={{ background: 'black' }}>
            <Flex
              p="4px"
              ml="auto"
              onClick={() => setCreditsModalVisible(false)}
              style={{ cursor: 'pointer' }}
            >
              <CrossIcon />
            </Flex>
          </Flex>
          <EventCredits contentUrl={event.eventCreditsMd} title={getEventShortName(event.type)} />
        </Modal>
      )}
      <EventFloatingBox isAdmin={isAdmin} />
      <EventPlayer />
    </Suspense>
  );
}
