import { get } from 'lodash';

export function getScenariosNavigation(scenarios = []) {
  const pages = {};
  if (scenarios === null) {
    return pages;
  }
  scenarios.forEach((scenario, idx) => {
    pages[scenario.scenarioId] = {
      id: scenario.scenarioId,
      nextScenarioId: get(scenarios, `${[idx + 1]}.scenarioId`, null),
      prevScenarioId: get(scenarios, `${[idx - 1]}.scenarioId`, null),
    };
  });
  return pages;
}

export function isScenarioPartOfEvent(scenarioId, scenarios = []) {
  if (!scenarioId || scenarioId === null || scenarios === null) {
    return false;
  }

  return scenarios.findIndex((s) => s.scenarioId === scenarioId) >= 0;
}

function getResourceAndDependency(resources, resource = {}) {
  let matchedResources = [];
  const matchedResource = resources.find((r) => r.resourceSpecSrn === resource.srn);
  if (matchedResource) {
    matchedResources.push(matchedResource);
    if (resource.requiredSpec) {
      matchedResources = matchedResources.concat(
        getResourceAndDependency(resources, resource.requiredSpec)
      );
    }
  }
  return matchedResources;
}

export function getUserScenarioResources(scenarioResources, userResources = []) {
  let filteredResources = [];
  scenarioResources.forEach((resource) => {
    filteredResources = filteredResources.concat(getResourceAndDependency(userResources, resource));
  });
  return filteredResources;
}

export default { getScenariosNavigation, isScenarioPartOfEvent, getUserScenarioResources };
