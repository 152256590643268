import React from 'react';
import { Box, Flex } from 'reflexbox';
import styled from 'styled-components';
import LogOutIcon from '../../icons/LogOutIcon';
import AccountSettingsIcon from '../../icons/AccountSettingsIcon';
import StyledText from '../StyledText';
import FormLabel from '../FormLabel';
import LinkedText from '../LinkedText';
import { truncateString } from '../../utils';

const Card = styled(Box)`
  position: absolute;
  padding: 40px;
  width: 100%;
  background-color: ${(props) => props.theme.color.profile.background};
  border: solid 1px ${(props) => props.theme.color.profile.border};
  text-align: left;
  box-shadow: 0px 4px 10px #00000059;
`;

export default function ProfileSection({
  displayName = '',
  email = '',
  hideSettings = true,
  onLogOut,
  onAccountGo,
}) {
  const INSIDE_MARGIN = '16px';
  return (
    <Card justifyContent="left">
      <StyledText light size="28px" textAlign="left">
        {truncateString(displayName, 19)}
      </StyledText>
      <Box mt={INSIDE_MARGIN}>
        <FormLabel style={{ wordBreak: 'break-all' }}>{email}</FormLabel>
      </Box>
      {hideSettings ? null : (
        <Flex
          mt="46px"
          ml="10px"
          onClick={onAccountGo}
          justifyContent="flex-start"
          alignItems="center"
          style={{ cursor: 'pointer' }}
        >
          <AccountSettingsIcon size="18px" />
          <LinkedText
            font="Roboto Mono"
            color="#A0A0A0"
            style={{ marginLeft: '20px', fontSize: '14px' }}
          >
            Account Settings
          </LinkedText>
        </Flex>
      )}
      <Flex
        mt="46px"
        ml="10px"
        onClick={onLogOut}
        justifyContent="flex-start"
        alignItems="center"
        style={{ cursor: 'pointer' }}
      >
        <LogOutIcon size="20px" />
        <LinkedText
          font="Roboto Mono"
          color="#A0A0A0"
          style={{ marginLeft: '20px', fontSize: '14px' }}
        >
          Sign Out
        </LinkedText>
      </Flex>
    </Card>
  );
}
