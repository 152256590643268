import React from 'react';
import styled, { css } from 'styled-components';

function BaseButton({
  children,
  className,
  disabled = false,
  id,
  isLoading,
  onClick = () => {},
  style,
  type,
}) {
  return (
    <button
      className={className}
      disabled={disabled || isLoading}
      id={id}
      onClick={onClick}
      style={style}
      type={type || 'button'}
    >
      {children}
    </button>
  );
}

const Button = styled(BaseButton)`
  opacity: 1;
  font-family: 'Roboto Mono';
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.56px;
  background: ${(props) => props.theme.color.button.regular.accept.disabled.background};
  border: 1px solid ${(props) => props.theme.color.button.regular.accept.disabled.border};
  opacity: 1;
  color: ${(props) => props.theme.color.button.regular.accept.disabled.color};
  height: ${(props) => props.height ? props.height : '32px'};
  width: -webkit-fill-available;
  width: -moz-available;
  cursor: not-allowed;
  ${(props) =>
    !(props.disabled || props.isLoading) &&
    !props.secondary &&
    css`
      cursor: pointer;
      color: ${props.theme.color.button.regular.accept.default.color};
      background: ${props.theme.color.button.regular.accept.default.background};
      box-shadow: 0px 3px 6px #00000029;
      border: 1px solid ${props.theme.color.button.regular.accept.default.border};
      &:hover,
      &:focus {
        background: ${props.theme.color.button.regular.accept.hover.background};
        box-shadow: 0px 4px 10px #00000040;
      }
    `}
  ${(props) =>
    !props.disabled &&
    props.secondary &&
    css`
      background: ${props.theme.color.button.regular.reject.default.background};
      cursor: pointer;
      border: 1px solid ${props.theme.color.button.regular.reject.default.border};
      color: ${props.theme.color.button.regular.reject.default.color};
      &:hover,
      &:focus {
        background: ${props.theme.color.button.regular.reject.hover.background};
        box-shadow: 0px 4px 10px #00000040;
      }
    `}
  ${(props) =>
    props.hide &&
    css`
      display: none;
    `}
    ${(props) =>
    !props.disabled &&
    props.danger &&
    css`
      background: ${props.theme.color.button.regular.danger.default.background};
      cursor: pointer;
      border: 1px solid ${props.theme.color.button.regular.danger.default.border};
      color: ${props.theme.color.button.regular.danger.default.color};
      &:hover,
      &:focus {
        background: ${props.theme.color.button.regular.danger.hover.background};
        box-shadow: 0px 4px 10px #00000040;
      }
    `}
`;

Button.displayName = 'Button';

export default Button;
