import React from 'react';
import { Flex, Box } from 'reflexbox';
import format from 'date-fns/format';
import {
  RegularText,
  Modal,
  UnregularButton,
  Table,
  TableBody,
  TableRow,
  TableData,
} from 'boss-ui';
import QuestionAnswerItem from './QuestionAnswerItem';

const Hint = ({ createdAt, username, score }) => {
  return (
    <Box p="4px" style={{ whiteSpace: 'break-spaces' }}>
      <RegularText mediumWeight fontSize="13px" mb="6px">
        HINT PURCHASE{' '}
      </RegularText>
      <Flex mb="4px" alignItems="center">
        <RegularText fontSize="13px">Time: </RegularText>
        <RegularText fontSize="12px">{format(new Date(createdAt), 'PPpp')}</RegularText>
      </Flex>
      <Flex mb="4px" alignItems="center">
        <RegularText fontSize="13px">User: </RegularText>
        <RegularText fontSize="12px">{username}</RegularText>
      </Flex>
      <Flex mb="4px" alignItems="center">
        <RegularText fontSize="13px">Hint cost: </RegularText>
        <RegularText fontSize="12px">{score.total}</RegularText>
      </Flex>
    </Box>
  );
};

export default function QuestionHistoryModal({ answers = [], onClose, questionId }) {
  return (
    <Modal margin="10% auto auto auto" onBackgroundClick={onClose}>
      <Flex
        px="40px"
        py="60px"
        width="640px"
        backgroundColor="#1D1D1D"
        flexDirection="column"
        maxHeight="360px"
      >
        <Flex height="0px">
          <Flex ml="auto" style={{ position: 'relative', top: '-40px', left: '30px' }}>
            <UnregularButton onClick={onClose}>Close Details</UnregularButton>
          </Flex>
        </Flex>
        <Flex mb="8px">
          <RegularText mediumWeight fontSize="14px">
            #{questionId}
          </RegularText>
        </Flex>
        <RegularText fontSize="14px">Question Activity</RegularText>
        <Flex style={{ overflowY: 'auto' }} mt="6px">
          <Table>
            <TableBody>
              {answers.map((answer) => {
                return (
                  <TableRow key={answer.createdAt}>
                    <TableData>
                      {answer.type === 'HINT' ? (
                        <Hint {...answer} />
                      ) : (
                        <>
                          <RegularText mediumWeight fontSize="13px" mb="2px" ml="4px">
                            ANSWER SUBMIT
                          </RegularText>
                          <QuestionAnswerItem {...answer} />
                        </>
                      )}
                    </TableData>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Flex>
      </Flex>
    </Modal>
  );
}
