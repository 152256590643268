import styled, { css } from 'styled-components';

const NavGroup = styled.div`
  display: flex;
  float: left;
  align-items: center;
  height: 100%;
  ${(props) =>
    props.right &&
    css`
      float: right;
    `};
`;

export default NavGroup;
