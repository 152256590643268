import React from 'react';
import styled, { css } from 'styled-components';
import Base from '../StyledSVGBase';

const Check = styled(Base)`
  fill: #66bb6a;
  ${(props) =>
    props.hover &&
    !props.disabled &&
    css`
      fill: #2ab800;
    `}
`;
Check.displayName = 'CheckIcon-Check';

const SquareIcon = styled(Base)`
  ${(props) =>
    props.hover &&
    css`
      cursor: ${props.disabled ? 'not-allowed' : 'pointer'};
    `}
`;
SquareIcon.displayName = 'CheckIcon-SquareIcon';

export default function CheckIcon(props) {
  return (
    <SquareIcon width={props.size || 34} height={props.size || 32} viewBox="0 0 34 32" {...props}>
      <rect fill="none" width="34" height="32" />
      <path
        fill="#6c6c6c"
        d="M-9.566-13.087h-1.705V1.2h1.705V2.839h-3.753V-14.739h3.753Zm19.2-1.652H13.4V2.839H9.637V1.2H11.35V-13.087H9.637Z"
        transform="translate(17 22)"
      />
      <Check {...props}>
        <path
          d="M10,15.172l8.533-8.616L19.948,7.97,10,18,4.5,12.746l1.414-1.414Z"
          transform="translate(4.364 4.021)"
        />
      </Check>
    </SquareIcon>
  );
}
