import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'reflexbox';

const Menu = styled.div`
  font-family: 'Roboto Mono';
  background: #373737 0% 0% no-repeat padding-box;
  opacity: 1;
  width: max-content;
  text-align: left;
  padding: 6px 2px 6px 2px;
  border: transparent;
  font-size: 14px;
  z-index: 10;
`;
Menu.displayName = 'Menu';

const ButtonMenu = styled.button`
  background-color: transparent;
  border: transparent;
  height: max-content;
  width: max-content;
  cursor: pointer;
`;
ButtonMenu.displayName = 'ButtonMenu';

const Background = styled(Box)`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &:hover {
    background: ${(props) => props.theme.color.button.unregular.active.hover.background};
  }
`;
Background.displayName = 'Background';

function ContextMenu(props) {
  const { button, children, id } = props;
  const [menuVisible, setMenuVisibility] = useState(false);
  const [menuTop, setMenuTop] = useState('0px');
  const onContextClick = (e) => {
    try {
      const rect = e.currentTarget.getBoundingClientRect();
      setMenuTop(`${rect.top + rect.height}px`);
    } catch (err) {
      setMenuTop(e.pageY);
    }
    e.preventDefault();
    setMenuVisibility(true);
  };

  return (
    <Background onMouseLeave={() => setMenuVisibility(false)}>
      <ButtonMenu onClick={onContextClick} id={id}>
        {button}
      </ButtonMenu>
      <Flex
        style={{
          position: 'fixed',
          top: menuTop,
          zIndex: 15,
        }}
        onClick={() => setMenuVisibility(false)}
      >
        {menuVisible && <Menu>{children}</Menu>}
      </Flex>
    </Background>
  );
}

export default ContextMenu;
