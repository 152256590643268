import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'reflexbox';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { StyledText } from 'boss-ui';
import { ExpandIcon, CollapseIcon, CheckMark, TimerIcon } from '../icons';

const isVisibleSlug = (activeClassSlug, slug) => {
  if (activeClassSlug.indexOf(slug) > -1) {
    return true;
  }
  return false;
};

export default function WorkshopContentMenu({ content, title, activeClassSlug, classesProgress }) {
  const { done, total, completed } = classesProgress;

  return (
    <>
      <Box pt="24px" pb="12px" px="20px" style={{ borderBottom: 'solid 1px #2C2C2C' }}>
        <StyledText light size="20px">
          {title}
        </StyledText>
        <Flex
          style={{
            borderTop: 'solid 1px #2C2C2C',
            color: '#81a2be',
            fontFamily: 'Roboto Mono',
          }}
          mt="12px"
          py="6px"
          alignItems="center"
        >
          <TimerIcon />
          <Flex mx="3px" />
          {Math.ceil((completed / total) * 100)}%
        </Flex>
      </Box>
      {content.map((c) => (
        <Flex my="2px" style={{ fontFamily: 'Roboto Mono' }} width={1} key={c.slug}>
          <WorkshopTopic
            title={c.title}
            slug={c.slug}
            subTopics={c.children}
            activeClassSlug={activeClassSlug}
            classesDone={done}
          />
        </Flex>
      ))}
    </>
  );
}

const WorkshopTopic = ({
  title = '',
  slug = '',
  subTopics,
  activeClassSlug = '',
  classesDone = [],
  innerLevel = 1,
}) => {
  const history = useHistory();
  const [visible, setVisible] = useState(isVisibleSlug(activeClassSlug, slug));
  const menuPadding = 18 * innerLevel;

  useEffect(() => {
    setVisible(isVisibleSlug(activeClassSlug, slug));
  }, [activeClassSlug]);

  return (
    <Box width={1}>
      <TopicTitle
        visible={visible}
        onTitleClick={() => setVisible((visible) => !visible)}
        menuPadding={menuPadding}
      >
        {title}
      </TopicTitle>
      <Box>
        {visible &&
          subTopics.map((st) => {
            if (st.children.length) {
              return (
                <WorkshopTopic
                  title={st.title}
                  subTopics={st.children}
                  activeClassSlug={activeClassSlug}
                  key={st.slug}
                  slug={st.slug}
                  classesDone={classesDone}
                  innerLevel={innerLevel + 1}
                />
              );
            }
            const classDone = classesDone[st.slug];
            return (
              <TopicChildren
                key={st.slug}
                isActive={st.slug === activeClassSlug}
                onClick={() => history.push(st.slug)}
                isDone={classDone}
                menuPadding={menuPadding}
              >
                {classDone && (
                  <Box minWidth="24px">
                    <CheckMark />
                  </Box>
                )}
                <Flex ml="6px">{st.title}</Flex>
              </TopicChildren>
            );
          })}
      </Box>
    </Box>
  );
};

const TopicTitle = ({ visible, children, onTitleClick, menuPadding }) => {
  return (
    <Flex
      style={{ color: '#DE935F', cursor: 'pointer', fontSize: '12px' }}
      onClick={onTitleClick}
      width={1}
      py="17px"
      pr="20px"
      pl={`${menuPadding}px`}
    >
      <Box minWidth="15px">{visible ? <CollapseIcon /> : <ExpandIcon />}</Box>
      <Flex ml="4px">{children}</Flex>
    </Flex>
  );
};

const TopicChildren = styled.div`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.card.classic.description};
  text-align: left;
  font-weight: 300;
  padding: 17px 17px 17px 0px;
  padding-left: ${(props) => `${props.menuPadding + 15}px`};
  background-color: #252525;
  cursor: pointer;
  &:hover {
    background-color: #2c2c2c;
  }
  ${(props) =>
    props.isDone &&
    css`
      background-color: #1d1d1d;
      color: ${props.theme.color.breadcrumb.active};
    `}
  ${(props) =>
    props.isActive &&
    css`
      background-color: #2c2c2c;
      color: ${props.theme.color.breadcrumb.active};
    `}
`;
