import React from 'react';
import { Flex } from 'reflexbox';
import intervalToDuration from 'date-fns/intervalToDuration';
import styled, { css } from 'styled-components';
import { SandTimeIcon } from 'boss-ui';
import WorkshopProgressLabel from '../workshops/WorkshopProgressLabel';
import { EVENT_TYPE, EVENT_DELIVERY } from '../../libs/constants';
import { isOnDemandEventInsidePlayWindow } from '../../libs/event-lib';
import EventLabel from '../EventLabel';

const EnrolledLabel = styled(Flex)`
  font-family: 'Roboto Mono';
  font-weight: normal;
  font-size: 16px;
  position: absolute;
  margin-top: -18px;
  background-color: ${(props) => props.theme.color.card.classic.border.inactive};
  ${(props) =>
    props.active &&
    css`
      background-color: ${props.theme.color.card.classic.border.active};
    `};
  ${(props) =>
    props.inprogress &&
    css`
      background-color: ${props.theme.color.card.classic.border.inprogress};
    `};
  ${(props) =>
    props.completed &&
    css`
      background-color: ${props.theme.color.card.classic.border.completed};
    `};
`;
EnrolledLabel.displayName = 'Card-EnrolledLabel';

const padZeroStart = (number, nroDigits = 2) => `0${number}`.slice(-nroDigits);

const TimeLeftLabel = ({ timeLeft = {} }) => {
  return (
    <EnrolledLabel inprogress>
      <Flex style={{ backgroundColor: 'black' }} p="6px">
        <SandTimeIcon color="#d0d0d0" />
      </Flex>
      <Flex ml="6px" p="6px">
        {padZeroStart(timeLeft.hours)}h {padZeroStart(timeLeft.minutes)}m left
      </Flex>
    </EnrolledLabel>
  );
};

/**
 * @param  {String} eventType WORKSHOP / GAME for icons
 * @param  {boolean} history
 * @param  {object} stats workshop progress stats
 * @param  {boolean} isEventRunning
 * @param  {String} delivery NORMAL / ON_DEMAND
 * @param  {boolean} showProgressLabel
 * @param  {Boolean} enrolled
 * @param  {String} joinDate date of joining to the event
 * @param  {String} duration event duration in minutes
 * @param  {Boolean} showEnrolledLabel
 * @param  {Boolean} restricted
 */
export default function CardLabel({
  eventType,
  history,
  stats,
  isEventRunning,
  delivery,
  showProgressLabel,
  enrolled,
  joinDate,
  duration,
  showEnrolledLabel,
  restricted = false,
}) {
  if (!history) {
    if (eventType === EVENT_TYPE.WORKSHOP) {
      return stats.progressPercentage ? (
        <WorkshopProgressLabel stats={stats} style={{ marginTop: '-18px' }} />
      ) : (
        enrolled && (
          <EnrolledLabel active={isEventRunning} p="6px">
            Enrolled
          </EnrolledLabel>
        )
      );
    }
    if (delivery === EVENT_DELIVERY.ON_DEMAND) {
      if (showProgressLabel) {
        const inProgressStartDate = new Date(joinDate);
        const inProgressEndDate = new Date(inProgressStartDate.getTime() + duration * 60000);
        return (
          <TimeLeftLabel
            timeLeft={intervalToDuration({ start: new Date(), end: inProgressEndDate })}
          />
        );
      }
      if (enrolled && !isOnDemandEventInsidePlayWindow(joinDate, duration)) {
        return (
          <EnrolledLabel completed p="6px">
            Play Again
          </EnrolledLabel>
        );
      }
      return (
        <EnrolledLabel active p="6px">
          Play Now
        </EnrolledLabel>
      );
    }
    if (restricted) {
      return (
        <EventLabel enrolled={enrolled} active={isEventRunning}>
          {enrolled ? 'Enrolled' : 'Private Event'}
        </EventLabel>
      );
    }
    return showEnrolledLabel ? (
      <EnrolledLabel active={isEventRunning} p="6px">
        Enrolled
      </EnrolledLabel>
    ) : null;
  }
  return null;
}
