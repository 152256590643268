import React from 'react';

const SIZE = '24px';

export const Timer = (props) => (
  <svg viewBox="0 0 24 24" width={props.size || SIZE} height={props.size || SIZE}>
    <path
      fill="#81a2be"
      d="M14.063,0H.938A.937.937,0,0,0,0,.938v.625A.937.937,0,0,0,.938,2.5c0,3.553,1.993,6.552,4.72,7.5-2.728.948-4.72,3.947-4.72,7.5A.937.937,0,0,0,0,18.438v.625A.937.937,0,0,0,.938,20H14.063A.937.937,0,0,0,15,19.063v-.625a.937.937,0,0,0-.937-.937c0-3.553-1.993-6.552-4.72-7.5,2.728-.948,4.72-3.947,4.72-7.5A.937.937,0,0,0,15,1.563V.938A.937.937,0,0,0,14.063,0ZM11.13,15H3.87c.666-1.828,2.035-3.125,3.63-3.125S10.463,13.172,11.13,15Zm0-10H3.87a7.329,7.329,0,0,1-.433-2.5h8.125A7.329,7.329,0,0,1,11.131,5Z"
      transform="translate(5 2)"
    />
    <rect fill="none" width="24" height="24" />
  </svg>
);

export default Timer;
