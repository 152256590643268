import React, { useState } from 'react';
import { Box } from 'reflexbox';
import styled, { css } from 'styled-components';
import { RightArrowHide, RightArrowShow, LeftArrowHide, LeftArrowShow } from './icons';

const COLLAPSED_WIDTH = '20px';
const MenuContainer = styled.div`
  display: flex;
  z-index: 10;
  ${(props) =>
    props.mobile && props.visible
      ? css`
          width: 100%;
          position: absolute;
          z-index: 11;
        `
      : css`
          max-width: 500px;
        `}
`;

const WorkshopMenuContent = styled(Box)`
  color: transparent;
  background-color: ${(props) => props.theme.color.form.background};
  text-align: left;
`;

export default function CollapsableMenu({
  side = 'left',
  expandedWidth = '300px',
  children,
  collapsed = false,
  mobile,
}) {
  const [menuVisible, setMenuVisible] = useState(!collapsed);
  const getMenuWidth = (expectedWidth, mobile, menuVisible) => {
    if (mobile) {
      return menuVisible ? '100%' : '0';
    }
    return menuVisible ? expectedWidth : COLLAPSED_WIDTH;
  };
  const menuWidth = getMenuWidth(expandedWidth, mobile, menuVisible);

  return (
    <MenuContainer mobile={mobile} visible={menuVisible}>
      {side === 'right' && (
        <Box onClick={() => setMenuVisible((toggleMenu) => !toggleMenu)}>
          {menuVisible ? <RightArrowHide /> : <RightArrowShow />}
        </Box>
      )}
      <WorkshopMenuContent width={menuWidth}>{menuVisible && children}</WorkshopMenuContent>
      {side === 'left' && (
        <Box onClick={() => setMenuVisible((toggleMenu) => !toggleMenu)} height="40px">
          {menuVisible ? <LeftArrowHide /> : <LeftArrowShow />}
        </Box>
      )}
    </MenuContainer>
  );
}
