import React from 'react';
import styled, { css, useTheme } from 'styled-components';
import Media from 'react-media';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { Flex } from 'reflexbox';
import { QUESTION_CARD_DEFAULT_WIDTH } from '../../constants';

const Container = styled(Flex)`
  width: ${QUESTION_CARD_DEFAULT_WIDTH};
  height: 620px;
  background-color: ${(props) => props.theme.color.question_card.background.inactive};
  padding: ${(props) => props.theme.margin.s};
  border: 2px solid;
  margin: ${(props) => props.theme.margin.s} 0 ${(props) => props.theme.margin.s} 0;
  border-color: ${(props) => props.theme.color.question_card.border.pending};
  ${(props) =>
    props.correct &&
    css`
      border-color: ${props.theme.color.question_card.border.correct};
    `}
  ${(props) =>
    props.incorrect &&
    css`
      border-color: ${props.theme.color.question_card.border.incorrect};
    `}
  
  &:hover {
    background-color: ${(props) => props.theme.color.question_card.background.active};
    cursor: pointer;
  }
  ${(props) =>
    props.highLigted &&
    css`
      background-color: ${props.theme.color.question_card.background.active};
      cursor: default;
    `}
`;

const getGridConfiguration = (matches) => {
  if (matches.m) {
    return '1fr 1fr 1fr';
  }
  if (matches.s) {
    return '1fr 1fr';
  }
  return `${QUESTION_CARD_DEFAULT_WIDTH}`;
};

const DESCRIPTION_SKELETON_LINES = 10;
function QuestionCardBlock() {
  const theme = useTheme();
  return (
    <Container>
      <Flex flexDirection="column">
        <SkeletonTheme
          color={theme.color.skeleton.color}
          highlightColor={theme.color.skeleton.highlightColor}
        >
          <Skeleton height="30px" width="330px" />
        </SkeletonTheme>
        <Flex my="30px" />
        <SkeletonTheme
          color={theme.color.skeleton.color}
          highlightColor={theme.color.skeleton.highlightColor}
        >
          <Skeleton height="20px" width="330px" count={DESCRIPTION_SKELETON_LINES} />
        </SkeletonTheme>
        <Flex my="110px" />
        <SkeletonTheme
          color={theme.color.skeleton.color}
          highlightColor={theme.color.skeleton.highlightColor}
        >
          <Skeleton height="24px" width="330px" />
        </SkeletonTheme>
      </Flex>
    </Container>
  );
}

export default function QuestionCardSkeleton() {
  return (
    <Media
      queries={{
        s: '(min-width: 1000px)',
        m: '(min-width: 1880px)',
      }}
    >
      {(matches) => (
        <Flex
          width={1}
          style={{
            display: 'grid',
            gridTemplateColumns: getGridConfiguration(matches),
            gridGap: '22px',
            gridAutoFlow: 'dense',
          }}
        >
          <QuestionCardBlock />
          {matches.s && <QuestionCardBlock />}
          {matches.m && <QuestionCardBlock />}
        </Flex>
      )}
    </Media>
  );
}
