import React from 'react';
import styled, { css } from 'styled-components';
import Base from '../StyledSVGBase';

const List = styled(Base)`
  fill: #535353;
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
      fill: #d0d0d0;
    `};
  ${(props) =>
    props.selected &&
    css`
      fill: #d0d0d0;
    `};
`;
List.displayName = 'ListIcon-List';

export default function ListIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <List {...props}>
        <rect fill="none" width="24" height="24" />
        <path d="M2,14.134H22V9.865H2ZM21,3H3A1,1,0,0,0,2,4V7.468H22V4A1,1,0,0,0,21,3ZM2,20a1,1,0,0,0,1,1H21a1,1,0,0,0,1-1V16.533H2Z" />
      </List>
    </svg>
  );
}
