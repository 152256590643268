import React, { useState, useLayoutEffect } from 'react';
import trim from 'lodash/trim';
import { Flex, Box } from 'reflexbox';
import { Button, RegularText, LinkedText, FormLabel, Input, EVENT_QUESTION_STATUS } from 'boss-ui';
import { CheckMark } from '../icons';
import { useLocalStorage } from '../../libs/hooks-lib';

const AnswerCorrect = ({ answer }) => (
  <Flex alignItems="center">
    <RegularText color="#81C784" mediumWeight style={{ wordBreak: 'break-all' }}>
      {answer}
    </RegularText>
    <Flex minWidth="24px">
      <CheckMark />
    </Flex>
  </Flex>
);

export default function QuestionAnswer({
  localStorageId,
  isExternal,
  externalURL,
  isQuestionCorrect,
  lastQuestionAnswer,
  onSubmitAnswer,
  isSubmittingAnswer,
}) {
  const [answerInput, setAnswerInput] = useLocalStorage(localStorageId, '');
  const [incorrectAnswer, setIncorrectAnswer] = useState(false);

  useLayoutEffect(() => {
    if (
      lastQuestionAnswer.state === EVENT_QUESTION_STATUS.INCORRECT &&
      !lastQuestionAnswer.isWebSocketAnswer
    ) {
      setIncorrectAnswer(true);
    }
  }, [lastQuestionAnswer]);

  const handleEnterSubmit = (e) => {
    if (e.key === 'Enter' && trim(answerInput) !== '') {
      onSubmitAnswer(answerInput);
    }
  };

  if (isExternal) {
    return isQuestionCorrect ? (
      <AnswerCorrect answer={lastQuestionAnswer.text} />
    ) : (
      <Flex mt="12px" style={{ whiteSpace: 'break-spaces' }}>
        <RegularText>Answer this question in </RegularText>
        <RegularText>
          {externalURL ? (
            <LinkedText
              className="QuestionAnswerExternalURL"
              onClick={() => window.open(externalURL, '_blank')}
            >
              this external server
            </LinkedText>
          ) : (
            'the external server'
          )}
        </RegularText>
      </Flex>
    );
  }

  return (
    <Box mt="20px">
      <Flex mb="4px">
        <FormLabel>Answer</FormLabel>
      </Flex>
      <Flex alignItems="baseline" width={1}>
        {isQuestionCorrect ? (
          <AnswerCorrect answer={lastQuestionAnswer.text} />
        ) : (
          <>
            <Input
              className="QuestionAnswerInputAnswer"
              onChange={(e) => {
                setIncorrectAnswer(false);
                setAnswerInput(e.target.value);
              }}
              value={answerInput}
              placeholder="Enter your answer"
              height="28px"
              onKeyDown={handleEnterSubmit}
              id="QuestionAnswer-Input"
              dangerBorder={incorrectAnswer}
            />
            <Box ml="6px" width="200px">
              <Button
                className="QuestionAnswerSubmitAnswer"
                onClick={() => onSubmitAnswer(answerInput)}
                isLoading={isSubmittingAnswer}
                disabled={!answerInput.length || trim(answerInput) === ''}
                id="QuestionAnswer-Button"
              >
                SUBMIT
              </Button>
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
}
