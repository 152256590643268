import React, { useState, useCallback, useEffect } from 'react';
import { Flex, Box } from 'reflexbox';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import { Button, Form, FormLabel, StyledText, Tooltip, QuestionCircleIcon, RegularText, ConfirmationModal } from 'boss-ui';
import Api from '../../api';
import { FormInput } from '../../components';
import { useFormFields } from '../../libs/hooks-lib';
import { useAppContext } from '../../libs/context-lib';
import { onError } from '../../libs/error-lib';
import { DISPLAY_NAME_REGEXP } from '../../libs/constants';
import { setUserHeaders } from '../../libs/utils-lib';

const API = new Api();

export default function Account() {
  const [fields, handleFieldChange] = useFormFields({
    displayName: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [availableName, setNameAvailable] = useState(true);
  const { user, userSession } = useAppContext();
  const currentName = get(user, 'dynamoUser.displayName', '');
  const [searchLoading, setSearchLoading] = useState(true);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);

  const history = useHistory();
  const validateForm = () =>
    availableName &&
    fields.displayName.match(DISPLAY_NAME_REGEXP) &&
    currentName !== fields.displayName;

  const checkDisplayName = async (name) => {
    try {
      const data = await API.get('users', '/users/check-displayname', {
        queryStringParameters: {
          q: name,
        },
      });
      setNameAvailable(data.user.available);
    } catch (e) {
      onError(e);
      setNameAvailable(false);
    } finally {
      setSearchLoading(false);
    }
  };

  const delayedSearch = useCallback(
    debounce((q) => checkDisplayName(q), 300),
    []
  );

  const onNameChange = (e) => {
    handleFieldChange(e);
    if (e.target.value === currentName) {
      setSearchLoading(true);
      return;
    }
    if (e.target.value.length > 2) {
      setSearchLoading(true);
      delayedSearch(e.target.value);
    }
  };

  const handleUserAttributesSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const editedUser = await API.patch('users', '/user', {
        body: {
          displayName: fields.displayName,
        },
      });
      userSession((u) => ({
        ...u,
        dynamoUser: {
          ...u.dynamoUser,
          displayName: editedUser.user.displayName,
          slug: editedUser.user.slug,
        },
      }));
      setUserHeaders({ dynamoUser: editedUser.user, ...user });
      history.push('/');
    } catch (err) {
      onError(err);
      setIsLoading(false);
    }
  };

  const deleteAccount = async () => {
    setConfirmationModalVisible(false);
    try {
      await API.del('users', `/user`);
      history.push('/logout');
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    if (!user.dynamoUser) {
      history.push('/');
    }
    handleFieldChange({
      target: {
        value: currentName,
        id: 'displayName',
      },
    });
  }, []);

  return (
    <>
      <Flex justifyContent="center">
        <Box>
          <Flex justifyContent="center" mt="100px" mb="16px">
            <StyledText size="24px" medium>
              Account Settings
            </StyledText>
          </Flex>
          <Form>
            <Flex>
              <Box py="40px" px="60px" width="412px">
                {/* Box with relative position to get question mark stuck to the input */}
                <Box mb="20px" style={{ position: 'relative' }}>
                  <FormLabel>Display Name</FormLabel>
                  <FormInput
                    autoFocus
                    placeholder="User display name"
                    value={fields.displayName}
                    onChange={onNameChange}
                    id="displayName"
                    validate={fields.displayName}
                    validating={searchLoading}
                    available={availableName}
                    pattern={DISPLAY_NAME_REGEXP}
                    unavailableMessage="This name was already taken!"
                    invalidPatternMessage="From 3 to 30 length, spaces, a-z, 0-9 and _ allowed"
                    validMessage="Name is available"
                  />
                  <Flex
                    data-tip
                    data-for="displayName"
                    style={{
                      position: 'absolute',
                      right: '-30px',
                      top: '28px',
                    }}
                  >
                    <QuestionCircleIcon />
                  </Flex>
                  <Tooltip id="displayName">
                    You must choose a display name to continue. Note that your display name may be
                    visible to other participants so choose a name that protects your identity if
                    remaining anonymous is important to you. (3 characters at least, alphanumerics,
                    spaces and underscores allowed)
                  </Tooltip>
                </Box>
                <Flex ml="auto" width={1}>
                  <Box width={1}>
                    <Button
                      onClick={handleUserAttributesSubmit}
                      isLoading={isLoading || searchLoading}
                      disabled={!validateForm()}
                    >
                      SAVE
                    </Button>
                  </Box>
                </Flex>
                <Flex ml="auto" mt="10px" width={1}>
                  <Box width={1}>
                    <Button onClick={() => setConfirmationModalVisible(true)} danger>
                      DELETE ACCOUNT
                    </Button>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Form>
        </Box>
      </Flex>
      {confirmationModalVisible && (
        <ConfirmationModal
          dangerDialog
          onCancel={() => setConfirmationModalVisible(false)}
          onAccept={deleteAccount}
          acceptLabel="DELETE"
        >
          <RegularText>
            Are you sure you want to delete your account?
            <br />
            All your data will be deleted!.
          </RegularText>
        </ConfirmationModal>
      )}
    </>
  );
}
