import React from 'react';
import styled, { css } from 'styled-components';

const ListBody = styled.tbody``;

const List = styled.table`
  padding: ${(props) => (props.mobile ? '20px 0px 0px 20px' : '35px 45px 0px 45px')};
  width: 100%;
`;
List.displayName = 'ListTableList';

const ListContainer = styled.div`
  ${(props) =>
    props.refreshing &&
    css`
      opacity: 0.8;
      cursor: not-allowed;
    `}
  background-color: ${(props) => (props.transparentBackground ? 'transparent' : '#1d1d1d')};
  width: 100%;
  height: 100%;
`;
ListContainer.displayName = 'ListTableListContainer';

export default function ListTable({
  refreshing = false,
  mobile,
  children,
  transparentBackground = false,
}) {
  return (
    <ListContainer
      refreshing={refreshing}
      mobile={mobile}
      transparentBackground={transparentBackground}
    >
      <List mobile={mobile}>
        <ListBody mobile={mobile}>{children}</ListBody>
      </List>
    </ListContainer>
  );
}
