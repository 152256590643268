import styled from 'styled-components';

const CardImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 186px;
`;
CardImage.displayName = 'CardImageImg';

export default CardImage;
