import React from 'react';
import styled, { css } from 'styled-components';

const Crumb = styled.a`
  color: ${(props) => props.theme.color.breadcrumb.inactive};
  cursor: pointer;
  font-size: 14px;
  font-family: 'Roboto Mono';
  ${(props) =>
    props.active &&
    css`
      cursor: default;
      color: ${(props) => props.theme.color.breadcrumb.active};
    `};
`;

const CrumbSpace = styled.div`
  font-size: 14px;
  font-family: 'Roboto Mono';
  margin: 0 6px 0 6px;
  color: ${(props) => props.theme.color.breadcrumb.inactive};
`;

const CrumbLine = styled.div`
  display: flex;
  overflow-x: auto;
`;

export default function Breadcrumb({ crumbs = [] }) {
  const last = crumbs.length - 1;
  return (
    <CrumbLine>
      {crumbs.map((p, idx) => (
        <div key={p.path ? p.path : p.name} style={{ display: 'flex' }}>
          {p.staticFather && (
            <>
              <Crumb onClick={p.staticFather.action}>{p.staticFather.name}</Crumb>
              <CrumbSpace>{'>'}</CrumbSpace>
            </>
          )}
          <Crumb onClick={p.action} active={last === idx}>
            {p.name}
          </Crumb>
          {last > idx && <CrumbSpace>{'>'}</CrumbSpace>}
        </div>
      ))}
    </CrumbLine>
  );
}
