import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Flex } from 'reflexbox';
import { UnauthenticatedRoute, AuthenticatedRoute, LoaderIcon } from 'boss-ui';
import FirstLogin from './containers/FirstSignIn';
import Login from './containers/Login';
import Signup from './containers/Signup';
import LoginSuccess from './containers/LoginSuccess';
import Account from './containers/Account';
import NotFound from './containers/NotFound';
import PasswordRecovery from './containers/PasswordRecovery';
import Home from './containers/Home';
import Event from './containers/Event';
import Logout from './containers/Logout';
import EventDetails from './containers/EventDetails';
import HistoryDetails from './containers/HistoryDetails';
import WorkshopDetails from './containers/WorkshopDetails';
import Survey from './containers/Survey';
import DiscordJoinRedirection from './containers/DiscordJoinRedirection';
import { sendWarning } from './libs/utils-lib';
import { useAppContext } from './libs/context-lib';
import {
  validateCognitoUserExists,
  validateUserSession,
  validateDynamoUserExists,
} from './libs/user-lib';
import config from './config';

const Workshop = React.lazy(() =>
  import('./containers/Workshop').catch(() => {
    sendWarning('force reload by chunk error on Workshop', true);
  })
);

export default function Routes() {
  const { user, appType } = useAppContext();
  const isUserSessionValid = () => validateUserSession(user);
  const isCognitoUserValid = () => validateCognitoUserExists(user);
  const isBossUserValid = () => validateDynamoUserExists(user);

  return (
    <Switch>
      <AuthenticatedRoute path="/event/:eventId?/detail" exact validateSession={isUserSessionValid}>
        <EventDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/event/:eventId?" validateSession={isUserSessionValid}>
        <Event />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path="/workshops/:eventId?/detail"
        validateSession={isUserSessionValid}
        exact
      >
        <WorkshopDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path="/history/:eventId?/workshop"
        validateSession={isUserSessionValid}
        exact
      >
        <HistoryDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        path="/history/:eventId?/event"
        validateSession={isUserSessionValid}
        exact
      >
        <HistoryDetails />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/survey/:surveyId?" exact>
        <Survey />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/workshop/:workshopId?" validateSession={isUserSessionValid}>
        <Suspense
          fallback={
            <Flex mt="10%" alignItems="center" justifyContent="center" flexDirection="column">
              <LoaderIcon appType={appType} />
            </Flex>
          }
        >
          <Workshop />
        </Suspense>
      </AuthenticatedRoute>
      <AuthenticatedRoute validateSession={isCognitoUserValid} path="/firstLogin">
        <FirstLogin />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/discordJoin" validateSession={isUserSessionValid}>
        <DiscordJoinRedirection />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/account" validateSession={isUserSessionValid}>
        <Account />
      </AuthenticatedRoute>
      <Route path="/successLogin">
        <LoginSuccess />
      </Route>
      <UnauthenticatedRoute path="/login" exact validateSession={isCognitoUserValid}>
        <Login />
      </UnauthenticatedRoute>
      {!config.cognito.SAML_LOGIN && (
        <UnauthenticatedRoute path="/signup" exact validateSession={isCognitoUserValid}>
          <Signup />
        </UnauthenticatedRoute>
      )}
      <UnauthenticatedRoute path="/recovery" exact validateSession={isCognitoUserValid}>
        <PasswordRecovery />
      </UnauthenticatedRoute>
      <AuthenticatedRoute path="/logout" exact validateSession={isCognitoUserValid}>
        <Logout />
      </AuthenticatedRoute>
      <AuthenticatedRoute path="/" validateSession={isCognitoUserValid}>
        <AuthenticatedRoute path="/" validateSession={isBossUserValid} fallbackRoute="/firstLogin">
          <Home />
        </AuthenticatedRoute>
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
