import React from 'react';
import styled from 'styled-components';
import Base from '../StyledSVGBase';

const CheckIcon = styled(Base)`
  fill: #a0a0a0;
  &:hover {
    fill: #e06c75;
    cursor: pointer;
  }
`;
CheckIcon.displayName = 'ClearIcon-CheckIcon';

export default function CheckMark(props) {
  return (
    <CheckIcon {...props} width={props.size || 24} height={props.size || 24} viewBox="0 0 28 28">
      <rect fill="none" width="24" height="24" />
      <path
        d="M6.535,3H21a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H6.535a1,1,0,0,1-.832-.445l-5.333-8a1,1,0,0,1,0-1.11l5.333-8A1,1,0,0,1,6.535,3ZM13,10.586,10.172,7.757,8.757,9.172,11.586,12,8.757,14.828l1.415,1.415L13,13.414l2.828,2.829,1.415-1.415L14.414,12l2.829-2.828L15.828,7.757Z"
        transform="translate(4 4)"
      />
    </CheckIcon>
  );
}
