import React from 'react';
import { useTheme } from 'styled-components';
import { Flex } from 'reflexbox';
import { CheckMark } from '../icons';
import { RegularText, CrossIcon, QuestionIcon as QuestionMark, EVENT_QUESTION_STATUS } from 'boss-ui';

function QuestionState({ status, iconSize = 24 }) {
  const theme = useTheme();
  switch (status) {
    case EVENT_QUESTION_STATUS.CORRECT:
      return (
        <Flex alignItems="center">
          <CheckMark size={iconSize} />
          <RegularText fontSize="14px" color={theme.color.question_card.color.correct}>
            Correct
          </RegularText>
        </Flex>
      );
    case EVENT_QUESTION_STATUS.INCORRECT:
      return (
        <Flex alignItems="center">
          <CrossIcon size={iconSize} color={theme.color.table.row.incorrect} />
          <RegularText fontSize="14px" color={theme.color.question_card.color.incorrect}>
            Incorrect
          </RegularText>
        </Flex>
      );
    default:
      return (
        <Flex alignItems="center">
          <QuestionMark size={iconSize} />
          <RegularText fontSize="14px" color={theme.color.question_card.color.pending}>
            Not Answered
          </RegularText>
        </Flex>
      );
  }
}

export default QuestionState;