import React from 'react';

const WIDTH = 30;
const HEIGHT = 40;
function BooIcon(props) {
  return (
    <svg
      width={props.size || WIDTH}
      height={props.size || HEIGHT}
      viewBox="0 0 337.0914307 384.1937256"
    >
      <g id="BOO">
        <linearGradient
          id="SVGID_1_"
          gradientUnits="userSpaceOnUse"
          x1="62.776226"
          y1="287.4862366"
          x2="282.8515015"
          y2="97.5329742"
        >
          <stop offset="0%" style={{ stopColor: '#ED0080' }} />
          <stop offset="6%" style={{ stopColor: '#ED0D72' }} />
          <stop offset="34%" style={{ stopColor: '#EF4538' }} />
          <stop offset="47%" style={{ stopColor: '#F05A22' }} />
          <stop offset="99%" style={{ stopColor: '#F99D1C' }} />
        </linearGradient>
        <circle
          style={{ fill: 'url(#SVGID_1_)' }}
          cx="169.7421875"
          cy="195.1609955"
          r="166.3019257"
        />
        <path
          fill="#FFFFFF"
          d="M169.7421875,39.2599564c-86.1018677,0-155.9011841,69.7992935-155.9011841,155.901123
		s69.799324,155.9011993,155.9011841,155.9011993s155.901123-69.7993774,155.901123-155.9011993
		S255.8439789,39.2599564,169.7421875,39.2599564L169.7421875,39.2599564z"
        />
        <path
          d="M169.7421875,44.2700043c40.3044281,0,78.1965179,15.6954041,106.696106,44.1949921
		c28.4996033,28.499527,44.1950073,66.3916473,44.1950073,106.6960907s-15.6954041,78.196579-44.1950073,106.6961823
		c-28.499588,28.4995422-66.3916779,44.1949463-106.696106,44.1949463c-40.3045044,0-78.1965942-15.6954041-106.6961823-44.1949463
		c-28.4995842-28.4996033-44.194931-66.3916626-44.194931-106.6961823s15.6953449-78.1965637,44.194931-106.6960907
		C91.5455933,59.9654083,129.4376831,44.2700043,169.7421875,44.2700043"
        />
        <g>
          <polygon
            fill="#363C44"
            points="-6.0573277,283.6479492 342.6569214,209.4478302 342.6569214,114.4594345 -6.0573277,188.6595459 		
			"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M82.4019775,222.8423462c-1.5776443-1.7671509-3.4988708-3.0991669-5.7376022-3.982254
			c3.1208344-2.2997589,5.3433151-5.1035919,6.6260986-8.3661804c1.6253967-4.1387939,2.0403595-8.3445282,1.2340622-12.503006
			c-1.0627594-5.4727631-4.1151657-9.6696472-9.0725555-12.4724884c-4.8771515-2.7585297-11.4968529-3.3502045-19.6745071-1.7641907
			l-28.237587,5.4786682l-0.0103378,80.4484406l32.5949326-6.3253174c6.8889618-1.335968,12.5207214-3.5924072,16.7397461-6.7053528
			c4.2731781-3.1523285,7.2645416-6.9485168,8.8894424-11.2802734c1.6199722-4.3179626,1.9832535-8.8653107,1.0804749-13.5170288
			C86.170105,228.4312897,84.6791,225.4000397,82.4019775,222.8423462z M45.9670486,249.5682526l-0.0669479-16.9765167
			l11.8448715-2.2987823c3.1301804-0.6074219,5.4929543-0.4459686,7.0169449,0.4794464
			c1.4983902,0.9126282,2.411499,2.3066559,2.7910233,4.2618561c0.5355606,2.760498,0.1629333,5.2414093-1.1385651,7.5825195
			c-1.235527,2.2190399-4.1013756,3.7794495-8.5172997,4.6369476L45.9670486,249.5682526z M63.6907921,200.3753357
			c1.3206902,0.7944794,2.1196022,1.9896545,2.4420204,3.6524506c0.4479446,2.3086243,0.0708847,4.4302063-1.1543121,6.4858093
			c-1.1725235,1.9689789-3.8335953,3.368927-7.9098701,4.1604462L45.9069939,216.83992l0.0300255-14.6413116l11.9605484-2.3204346
			C60.3868484,199.3928223,62.3351517,199.562149,63.6907921,200.3753357z"
          />
        </g>
        <path
          fill="#FFFFFF"
          d="M233.493988,186.0044403c-4.026062-3.0154877-9.8852386-4.7865906-17.4116516-5.2630768l-6.4848328-0.425293
		c-3.0199127-0.1772156-5.2935944-0.656662-6.7609711-1.4275208c-1.2453766-0.6507416-1.9975281-1.724823-2.2992706-3.2822723
		c-0.4804382-2.4730377,0.1161652-4.5650787,1.8242493-6.3952484c1.8345947-1.9689636,4.2298584-3.2182922,7.3221436-3.8188171
		c2.8727264-0.5572205,5.394989-0.2992859,7.7105103,0.7875824c2.1678467,1.0179596,3.5879669,3.0686493,4.3420868,6.2702026
		l0.333252,1.4147186l18.268158-3.5461273l-0.3047028-1.4954376c-1.6824799-8.260849-5.5662842-14.0712891-11.5440979-17.2708893
		c-5.8773956-3.144455-13.0331573-3.9251404-21.2693787-2.3292847c-5.4294586,1.0533905-10.3228607,3.0243378-14.544342,5.8586884
		c-4.2707062,2.8727264-7.4978638,6.4730072-9.5923615,10.7003937c-2.1284637,4.2953339-2.6955261,9.1143951-1.6849518,14.3233337
		c1.123291,5.7887878,3.8429413,10.1727142,8.0821533,13.0306854c4.1186066,2.7811737,9.8222351,4.4311829,16.9592896,4.9037323
		l6.5640717,0.4036407c4.1712799,0.305191,6.095459,1.0986938,6.9746094,1.7100525
		c1.1877899,0.8259888,1.9325562,2.0910492,2.2766266,3.8670654c0.5207977,2.6797791-0.1776886,4.9697113-2.1348572,7.0016937
		c-2.0698853,2.150116-4.9253998,3.5904236-8.4862976,4.2815399c-3.2000732,0.6221924-6.1407318,0.3278351-8.7452087-0.8722687
		c-2.4882812-1.1449585-4.0979309-3.4309387-4.9199677-6.986908l-0.3288269-1.4216003l-18.2730865,3.5451355l0.3032227,1.4954376
		c1.7514038,8.6270752,5.8370361,14.6846313,12.1426849,18.0043335c4.1072845,2.1629181,8.7855682,3.247818,13.9762878,3.247818
		c2.6398926,0,5.413208-0.2805786,8.3095703-0.8427124c5.7927246-1.1242828,10.9676971-3.1867981,15.3831177-6.1304169
		c4.4676056-2.9800415,7.8581848-6.7023926,10.0772247-11.0636749c2.2569427-4.4341431,2.8742218-9.3999023,1.8345947-14.7584686
		C240.2475891,193.6194611,237.5899506,189.0711212,233.493988,186.0044403z"
        />
        <path
          fill="#FFFFFF"
          d="M310.4928284,184.498642c-1.1454468-5.9020081-3.8035583-10.4493713-7.8990479-13.5150604
		c-4.026062-3.0154877-9.8852539-4.7865906-17.4116516-5.2630768l-6.4848328-0.425293
		c-3.0194092-0.1772156-5.2930908-0.656662-6.7604675-1.4275208c-1.2458801-0.6517181-1.9980469-1.724823-2.2997742-3.2822723
		c-0.47995-2.4730377,0.1161804-4.5650787,1.823761-6.396225c1.835083-1.9679871,4.2303467-3.2173157,7.3226318-3.8178406
		c2.8722229-0.5582123,5.394989-0.3002777,7.710022,0.7866058c2.168335,1.0189362,3.5884399,3.0696259,4.3425598,6.2702026
		l0.333252,1.4147034l18.2681885-3.5441589l-0.304718-1.4964142c-1.6820068-8.2598572-5.5662842-14.0712891-11.5440979-17.2708893
		c-5.8778992-3.144455-13.0336609-3.9261322-21.2693787-2.3292999c-5.4289551,1.0534058-10.3218689,3.024353-14.544342,5.8587036
		c-4.2707214,2.8727264-7.4978638,6.4720154-9.5923615,10.6994171c-2.1284637,4.2963104-2.6950378,9.1153717-1.6849518,14.3233185
		c1.123291,5.7878113,3.842453,10.1727142,8.0821533,13.0316772c4.1185913,2.7811737,9.8222351,4.4311829,16.9597778,4.9037323
		l6.5640869,0.4026489c4.1712646,0.3071594,6.095459,1.0996857,6.9736023,1.7100677
		c1.1882935,0.8269653,1.9330444,2.0920258,2.2771301,3.868042c0.5207825,2.6797791-0.1777039,4.9697113-2.1348572,7.0016937
		c-2.0698853,2.150116-4.9253845,3.5904236-8.4862976,4.2815399c-3.2000732,0.6221924-6.1412354,0.3268433-8.7452087-0.8722687
		c-2.4882812-1.1449585-4.0979309-3.4309387-4.9199829-6.9878845l-0.3288269-1.4216156l-18.2730713,3.5461273l0.3032227,1.4954376
		c1.7514038,8.6270752,5.8370209,14.6846313,12.1426697,18.0033417c4.1068115,2.1639099,8.7860718,3.2488098,13.9772644,3.2488098
		c2.6399231,0,5.4122314-0.2805786,8.3085938-0.8427124c5.7902832-1.1242828,10.9657288-3.1867981,15.3831482-6.1304169
		c4.4675903-2.9800415,7.8581848-6.7023926,10.0772095-11.0636749
		C310.9151611,194.8229675,311.5324402,189.8581848,310.4928284,184.498642z"
        />
        <path
          id="Logo_17_"
          fill="#FFFFFF"
          d="M114.0024033,107.6023178c0,0.9661255-0.2053833,1.8580017-0.6135559,2.6774368
		c-0.4089203,0.8199081-0.9794159,1.514389-1.7098618,2.0842056c-0.7304993,0.5707245-1.5991058,1.016983-2.6069107,1.3387833
		c-1.0080261,0.3213501-2.1111908,0.4810791-3.3089523,0.4810791c-1.4328156,0-2.7174377-0.195694-3.8575058-0.5882263
		c-1.1391144-0.3933029-2.2772903-1.0418625-3.4166412-1.9453354l1.8840179-3.0633545
		c0.9053268,0.7583694,1.7226181,1.3137283,2.4533386,1.6641846c0.7292786,0.3511581,1.4751129,0.5248566,2.2356415,0.5248566
		c0.9344177,0,1.6859589-0.2394562,2.2560043-0.7224197c0.5697708-0.4827194,0.8551559-1.1332016,0.8551559-1.9514694
		c0-0.3509064-0.0518112-0.6814728-0.1535873-0.987442c-0.1017609-0.3066711-0.2915192-0.6218567-0.5693207-0.943634
		c-0.2766037-0.3209076-0.655426-0.671814-1.1374588-1.0515976c-0.4817581-0.3799896-1.1017227-0.8487473-1.8596649-1.4034424
		c-0.583519-0.4095993-1.1523361-0.8333817-1.7065277-1.2716141c-0.55513-0.4389114-1.0581818-0.9072418-1.5106201-1.4045944
		c-0.4519424-0.496933-0.8165817-1.0515518-1.094635-1.665596c-0.2770767-0.6157074-0.4147873-1.3168411-0.4147873-2.105545
		c0-0.9060287,0.1890488-1.7325745,0.5679016-2.4788818c0.3781281-0.745369,0.8951569-1.3806992,1.5520096-1.9067459
		c0.6554718-0.5267181,1.4413071-0.9358292,2.3600998-1.2278519c0.9179001-0.2931519,1.9157639-0.4396362,2.9933472-0.4396362
		c1.1381531,0,2.2391739,0.1526489,3.3065948,0.4574127c1.0650711,0.3052139,2.0513458,0.7472763,2.9573822,1.3276901
		l-1.7089386,2.7512817c-1.1681976-0.8175888-2.3951492-1.2266998-3.6811905-1.2266998
		c-0.7874985,0-1.4386597,0.2053909-1.9493027,0.6145325c-0.5113602,0.4095993-0.7664566,0.9204788-0.7664566,1.5356903
		c0,0.5851746,0.225296,1.1126175,0.6772385,1.5802002c0.4493332,0.4680481,1.2282944,1.1265793,2.3357162,1.9739227
		c1.1145096,0.8199081,2.0383301,1.5361633,2.7711639,2.1507111c0.73349,0.6145325,1.3120728,1.1930542,1.7372589,1.7332535
		c0.4259262,0.541893,0.7191238,1.091095,0.880249,1.6464615
		C113.9200668,106.3160172,114.0024033,106.9298401,114.0024033,107.6023178 M139.5061798,102.2174301
		c0-1.6355591-0.2346954-3.1253815-0.702301-4.4696579c-0.4687653-1.3449783-1.1352997-2.4982986-1.9973602-3.4639511
		c-0.8641663-0.9645004-1.8884888-1.7172089-3.0732574-2.2574005c-1.1855011-0.5402451-2.4938049-0.8109436-3.9284973-0.8109436
		c-1.5799255,0-2.9708557,0.2993469-4.17099,0.8979797c-1.1994553,0.5994034-2.3111572,1.5418854-3.3361969,2.826973
		l-0.0305252-3.1986694h-5.6319504v32.865921h5.6319504V110.716774c0.5567703,0.6431656,1.091774,1.1852417,1.6047745,1.6241989
		c0.5127716,0.4401245,1.0333862,0.7985992,1.5607834,1.0764313c0.5281754,0.2768631,1.084465,0.4744339,1.6710968,0.5924835
		c0.586319,0.1166992,1.217186,0.1739349,1.8915634,0.1739349c1.4950256,0,2.8878021-0.2993469,4.1773987-0.8968277
		c1.2910309-0.5993652,2.4039001-1.4393845,3.3428345-2.5214767c0.938446-1.0802002,1.6705475-2.3444901,2.1984863-3.7912064
		C139.2423553,105.5282898,139.5061798,103.9421539,139.5061798,102.2174301 M133.381134,102.4393845
		c0,0.8492279-0.089447,1.7630615-0.2652588,2.7431793c-0.1767426,0.9798813-0.4782257,1.8875656-0.9046326,2.7209473
		c-0.4268494,0.8331909-0.9855499,1.5203705-1.6766968,2.0617218c-0.6923676,0.5411682-1.5662689,0.8107147-2.6248932,0.8107147
		c-1.7654877,0-3.1556931-0.7004166-4.1707993-2.1062241c-1.0141907-1.4034271-1.5217285-3.3340759-1.5217285-5.7914352
		c0-2.5145874,0.515152-4.5112762,1.5449371-5.9889908c1.0293121-1.4777298,2.4263535-2.2159882,4.1913528-2.2159882
		c1.6767426,0,3.0001678,0.6956635,3.9706116,2.084404C132.8955536,98.1476746,133.381134,100.041626,133.381134,102.4393845
		 M142.0127716,81.4811554v32.161972h5.779953v-32.161972H142.0127716L142.0127716,81.4811554z M166.699295,113.652153h5.7799377
		V91.709343h-5.7799377v11.8085403c0,1.0539322-0.0431213,1.8653564-0.1322937,2.4353561
		c-0.0881958,0.5712128-0.2350464,1.0906219-0.440506,1.5588913c-0.8209381,1.8147202-2.3183746,2.7212296-4.4913635,2.7212296
		c-1.7024536,0-2.8926544-0.6137924-3.5672302-1.8447495c-0.2645874-0.4663925-0.4482574-0.9942856-0.5509949-1.5787659
		c-0.1027374-0.5858917-0.1541138-1.4332352-0.1541138-2.54702V91.709343h-5.7794495v12.466835
		c0,0.8492813,0.0072632,1.5667496,0.0222778,2.1514206c0.0145416,0.5851746,0.0513763,1.1045685,0.1095276,1.5586548
		c0.0586395,0.4543228,0.1245422,0.8561096,0.1982117,1.2070312c0.0726929,0.3511353,0.1822205,0.6883316,0.3290405,1.0096817
		c0.5262909,1.3459549,1.3821259,2.3634338,2.5674744,3.0517807c1.1863251,0.6864548,2.6416016,1.0290756,4.3677979,1.0290756
		c1.551712,0,2.9125061-0.2694931,4.083313-0.8109055c1.1708221-0.541893,2.3120728-1.4270782,3.4242554-2.6561432
		L166.699295,113.652153L166.699295,113.652153z M191.6281281,113.6424026h5.7809143v-12.439888
		c0-0.8468552-0.008728-1.5693207-0.0232697-2.1684418c-0.0145264-0.5986328-0.0499115-1.1168747-0.1095123-1.5551224
		c-0.0586395-0.4377289-0.1308441-0.8236771-0.2195282-1.1601486c-0.0867462-0.3360214-0.1904449-0.6639557-0.3072357-0.9865189
		c-0.5267792-1.3137207-1.3830872-2.3274231-2.5679626-3.043251c-1.1863251-0.7155304-2.6420898-1.073555-4.3697205-1.073555
		c-1.5507507,0-2.9125061,0.2706985-4.08284,0.8128357c-1.1717987,0.5406799-2.3120728,1.4275665-3.4252167,2.6554184
		l-0.0106659-2.9346466h-5.8298645v21.8933182h5.8298645v-11.8210983c0-1.0213089,0.0363312-1.8168411,0.1090393-2.3861618
		c0.0736542-0.56884,0.212738-1.1009979,0.4196777-1.597908c0.3804016-0.8748093,0.9609833-1.5392685,1.7382812-1.9914474
		c0.7782898-0.452446,1.7111511-0.6786575,2.7976532-0.6786575c1.7029266,0,2.8921509,0.6133194,3.567215,1.8386154
		c0.2621765,0.4670715,0.4472961,0.993782,0.548584,1.5766373c0.1036987,0.583252,0.1545868,1.4301224,0.1545868,2.5389557
		V113.6424026L191.6281281,113.6424026z M215.9511871,114.0863571l5.3903351-1.8845062l-8.8092499-10.9536362l7.4499207-7.9745636
		l-4.3823395-1.8837662l-7.7542419,8.9816132h-0.6130371V81.4873047h-5.8293762v32.1612625h5.8293762v-11.6554718
		L215.9511871,114.0863571L215.9511871,114.0863571z M226.3451233,113.0491943l17.526886-8.7090225v-3.5882111l-17.526886-8.7956085
		v3.9381638l13.5826263,6.6082458l-13.5826263,6.6955795V113.0491943L226.3451233,113.0491943z M227.674408,88.1760025v-0.7432022
		c0.0605621-0.0090027,0.1400299-0.01754,0.2539215-0.01754c0.3935089,0,0.5335541,0.1919022,0.5335541,0.3845291
		c0,0.2714005-0.2451935,0.3762131-0.5335541,0.3762131H227.674408L227.674408,88.1760025z M227.674408,89.3915482V88.446701
		h0.2447052c0.2791595,0,0.419693,0.105545,0.4720306,0.3852539c0.0440826,0.2884598,0.0964355,0.489563,0.1487579,0.5595932
		h0.3668671c-0.0339355-0.0700302-0.0867615-0.2011185-0.1386108-0.542572
		c-0.0440826-0.3229904-0.175415-0.4980774-0.3682861-0.533371v-0.0170059
		c0.236496-0.0700684,0.4288788-0.2451553,0.4288788-0.5333557c0-0.2103729-0.070282-0.3585129-0.2011261-0.4548111
		c-0.1318054-0.0965271-0.3498688-0.1665649-0.682312-0.1665649c-0.262207,0-0.4371338,0.0262604-0.6207886,0.0525284v2.1951523
		H227.674408L227.674408,89.3915482z M228.0412445,86.5842438h0.0101929c0.8737488,0,1.5725403,0.7432556,1.5725403,1.6703568
		c0,0.9266129-0.6987915,1.6703033-1.5725403,1.6613388c-0.8757019,0-1.584198-0.734726-1.584198-1.6698685
		C226.4672394,87.3274994,227.1757355,86.5842438,228.0412445,86.5842438L228.0412445,86.5842438z M228.0514374,86.2782745
		h-0.0101929c-1.0753326,0-1.9490814,0.8748016-1.9490814,1.9677963c0,1.1102448,0.8737488,1.9757996,1.9490814,1.9757996
		c1.0850372,0,1.942337-0.8655548,1.942337-1.9757996C229.9835815,87.1530762,229.1262817,86.2782745,228.0514374,86.2782745
		L228.0514374,86.2782745z"
        />
        <g>
          <path
            fill="#FFFFFF"
            d="M112.1307602,304.6702576c-2.2314453,0.4707031-4.0341797,0.2333984-5.4091797-0.7128906
			c-1.3759766-0.9462891-2.28125-2.4570312-2.71875-4.5317383c-0.2568359-1.2133789-0.2998047-2.409668-0.1289062-3.5908203
			c0.1689453-1.1801758,0.5351562-2.2744141,1.1005859-3.2832031c0.5634766-1.0078125,1.3173828-1.8666992,2.2617188-2.5771484
			c0.9423828-0.7099609,2.0605469-1.2016602,3.3525391-1.4741211c2.2304688-0.4707031,4.03125-0.2382812,5.4052734,0.6982422
			c1.3740234,0.9375,2.28125,2.4526367,2.7226562,4.5458984c0.2558594,1.2138672,0.2978516,2.4116211,0.1289062,3.5908203
			c-0.1699219,1.1806641-0.5371094,2.2753906-1.1005859,3.2832031c-0.5644531,1.0087891-1.3183594,1.8686523-2.2617188,2.5776367
			C114.5399399,303.9065857,113.4217758,304.3977966,112.1307602,304.6702576z M111.698143,301.0208435
			c0.8007812-0.1694336,1.4511719-0.5507812,1.9492188-1.1474609c0.4980469-0.5947266,0.8271484-1.3339844,0.9882812-2.2163086
			c0.1611328-0.8828125,0.1357422-1.8320312-0.0791016-2.8500977c-0.21875-1.0375977-0.6279297-1.7890625-1.2275391-2.2548828
			c-0.5996094-0.4663086-1.3681641-0.6005859-2.3076172-0.4023438c-0.8027344,0.1689453-1.4521484,0.5512695-1.9501953,1.1474609
			c-0.4980469,0.5947266-0.8271484,1.3349609-0.9882812,2.2167969s-0.1347656,1.8325195,0.0800781,2.8491211
			c0.2148438,1.0185547,0.6220703,1.7651367,1.2246094,2.2407227
			C109.9881821,301.0799255,110.7586899,301.2185974,111.698143,301.0208435z"
          />
          <path
            fill="#FFFFFF"
            d="M122.9315414,307.9095154l-0.2929688-18.2421875l-2.3183594,0.4892578l-0.0712891-3.3881836
			l2.3476562-0.4956055l-0.0107422-0.487793c-0.03125-2.1811523,0.4052734-3.7963867,1.3076172-4.8452148
			s2.234375-1.7587891,3.9960938-2.1308594c0.4492264-0.0947266,0.8955154-0.1630859,1.3359451-0.2055664
			c0.4414062-0.0419922,0.8242188-0.0668945,1.1494141-0.0737305l0.0585938,3.1767578l-1.1152344,0.2353516
			c-1.0185547,0.2148438-1.708992,0.5488281-2.0732498,1.0043945s-0.5429688,1.1826172-0.5361328,2.1831055l-0.0029297,0.2763672
			l3.2002029-0.675293l0.0996094,3.3823242l-3.2285233,0.6811523l0.2646484,18.2485352L122.9315414,307.9095154z"
          />
          <path
            fill="#FFFFFF"
            d="M147.9657288,297.2303162c-2.4853516,0.5244141-4.609375,0.2211914-6.3701172-0.9091797
			c-1.7617188-1.1308594-2.9316406-3.0649414-3.5097656-5.8061523c-0.3427734-1.6230469-0.4375-3.237793-0.2871094-4.8447266
			c0.1523438-1.605957,0.5654297-3.1083984,1.2412109-4.5073242c0.6748047-1.4003906,1.6162109-2.5898438,2.8193359-3.5693359
			c1.203125-0.9799805,2.6855469-1.6557617,4.4462891-2.0273438c2.5048828-0.5288086,4.6337891-0.2260742,6.3847656,0.90625
			c1.7519531,1.1323242,2.9169922,3.0693359,3.4951172,5.8085938c0.3427734,1.6245117,0.4384766,3.2387695,0.2871094,4.8447266
			c-0.1523438,1.605957-0.5654297,3.1088867-1.2412109,4.5092773c-0.6757812,1.3984375-1.6152344,2.5893555-2.8193359,3.5688477
			C151.2088928,296.1824646,149.7264709,296.8587341,147.9657288,297.2303162z M147.5711975,293.3269958
			c1.2519531-0.2641602,2.25-0.8876953,2.9921875-1.8725586c0.7431641-0.984375,1.2207031-2.1845703,1.4326172-3.5986328
			c0.2128906-1.4135742,0.1601562-2.8740234-0.1582031-4.3818359c-0.3173828-1.5068359-0.9160156-2.6220703-1.7949219-3.3457031
			c-0.8779297-0.7241211-1.9921875-0.9438477-3.3427734-0.6591797c-1.2519531,0.2646484-2.25,0.8881836-2.9921875,1.8730469
			c-0.7431641,0.984375-1.2216797,2.1791992-1.4355469,3.5839844c-0.2148438,1.4047852-0.1611328,2.8701172,0.1611328,4.3959961
			c0.3173828,1.5068359,0.9150391,2.6225586,1.7949219,3.3461914
			C145.1053772,293.3934021,146.2206116,293.6121521,147.5711975,293.3269958z"
          />
          <path
            fill="#FFFFFF"
            d="M159.8280334,300.1233826l-0.3349609-21.637207l3.6396484-0.7680664l0.3320312,1.8608398
			c0.3486328-0.7680664,0.8789062-1.4267578,1.5908203-1.9750977c0.7128906-0.5488281,1.5087891-0.9165039,2.3896484-1.1025391
			c1.6621094-0.3505859,3.0703125-0.0703125,4.2246094,0.8413086c1.1523438,0.9116211,1.9394531,2.3647461,2.3613281,4.3608398
			c0.4130859,1.9570312,0.4160156,3.7192383,0.0117188,5.2866211c-0.40625,1.5678711-1.1103516,2.8554688-2.1132812,3.8642578
			c-1.0029297,1.0092773-2.2089844,1.6621094-3.6181641,1.9594727c-0.9980469,0.2109375-1.8789062,0.2021484-2.6425781-0.0244141
			c-0.7626953-0.2265625-1.3857422-0.6171875-1.8710938-1.1689453l0.1396484,7.6357422L159.8280334,300.1233826z
			 M163.7079163,284.2200623l0.0351562,2.1987305c0.0068359,0.3261719,0.0439453,0.6455078,0.1103516,0.9580078
			c0.1855469,0.8808594,0.5654297,1.4707031,1.1396484,1.7685547c0.5722656,0.2983398,1.203125,0.3740234,1.8886719,0.2294922
			c0.859375-0.1816406,1.5488281-0.5761719,2.0634766-1.1860352s0.8564453-1.3662109,1.0224609-2.2709961
			c0.1669922-0.9033203,0.1416016-1.8745117-0.0771484-2.9106445c-0.2226562-1.0576172-0.6289062-1.7939453-1.2177734-2.2119141
			c-0.5888672-0.4169922-1.2841797-0.5410156-2.0869141-0.3720703c-0.8212891,0.1738281-1.5126953,0.5800781-2.0732422,1.2197266
			C163.9530334,282.2815857,163.6835022,283.1409607,163.7079163,284.2200623z"
          />
          <path
            fill="#FFFFFF"
            d="M175.9325256,286.2078552l3.875-0.8173828c0.1445312,0.5009766,0.4638672,0.8520508,0.9570312,1.0541992
			c0.4921875,0.2041016,1.1005859,0.2280273,1.8251953,0.0751953c0.8017578-0.1694336,1.4052734-0.4604492,1.8085938-0.8720703
			c0.4033203-0.4121094,0.5498047-0.8818359,0.4384766-1.4106445c-0.0869141-0.4106445-0.2724609-0.6791992-0.5537109-0.8032227
			c-0.2822266-0.1245117-0.8085938-0.1767578-1.5810547-0.1567383l-0.9111328,0.0385742
			c-1.6630859,0.0644531-2.9345703-0.171875-3.8154297-0.7128906c-0.8798828-0.5385742-1.4501953-1.425293-1.7109375-2.6586914
			c-0.2148438-1.0166016-0.1279297-1.965332,0.2597656-2.8447266c0.3857422-0.878418,1.0234375-1.6313477,1.9140625-2.2592773
			c0.8896484-0.6269531,1.9902344-1.0786133,3.3017578-1.3554688c1.8193359-0.3837891,3.3330078-0.2592773,4.5400391,0.375
			c1.2070312,0.6347656,1.9785156,1.7036133,2.3164062,3.206543l-3.7871094,0.7993164
			c-0.1464844-0.5004883-0.4472656-0.8613281-0.9013672-1.0825195c-0.4560547-0.2202148-1.0068359-0.2626953-1.6533203-0.1264648
			c-0.7226562,0.1523438-1.2695312,0.4267578-1.6347656,0.8203125c-0.3671875,0.3945312-0.4970703,0.8466797-0.3896484,1.3544922
			c0.078125,0.3720703,0.2783203,0.6162109,0.6005859,0.7319336c0.3193359,0.1162109,0.8857422,0.1616211,1.6982422,0.1328125
			l0.9111328-0.0395508c1.6201172-0.0756836,2.8681641,0.1508789,3.7460938,0.6811523
			c0.8789062,0.5297852,1.4453125,1.4018555,1.7021484,2.6152344c0.21875,1.0366211,0.1201172,2.019043-0.2988281,2.9443359
			c-0.4179688,0.9262695-1.0986328,1.71875-2.0419922,2.378418c-0.9443359,0.659668-2.1123047,1.1352539-3.5019531,1.4287109
			c-1.8974609,0.4003906-3.46875,0.2973633-4.7089844-0.3085938
			C177.094635,288.7884216,176.2919006,287.7264099,175.9325256,286.2078552z"
          />
          <path
            fill="#FFFFFF"
            d="M200.2176819,283.1678162l-0.0888672-5.0712891l-4.6386719,0.9790039l-0.0664062-3.512207
			l4.6376953-0.9790039l-0.0888672-5.0703125l3.6396484-0.7680664l0.0888672,5.0703125l4.6376953-0.9790039l0.0673828,3.5126953
			l-4.6386719,0.9785156l0.0898438,5.0712891L200.2176819,283.1678162z"
          />
          <path
            fill="#FFFFFF"
            d="M225.5760803,280.8514099c-2.4853516,0.5249023-4.6103516,0.2216797-6.3701172-0.9086914
			c-1.7617188-1.1308594-2.9326172-3.0654297-3.5107422-5.8061523c-0.3427734-1.6230469-0.4375-3.2382812-0.2871094-4.8452148
			c0.1533203-1.605957,0.5664062-3.1083984,1.2421875-4.5073242c0.6748047-1.3999023,1.6152344-2.5893555,2.8193359-3.5693359
			c1.203125-0.9794922,2.6845703-1.6557617,4.4462891-2.0273438c2.5039062-0.5283203,4.6337891-0.2260742,6.3857422,0.90625
			c1.75,1.1323242,2.9160156,3.0698242,3.4941406,5.809082c0.3427734,1.6240234,0.4375,3.2387695,0.2871094,4.8447266
			c-0.1533203,1.605957-0.5664062,3.1083984-1.2421875,4.5087891c-0.6757812,1.3989258-1.6142578,2.5898438-2.8183594,3.5688477
			C228.8192444,279.8040466,227.3368225,280.4798279,225.5760803,280.8514099z M225.1805725,276.9485779
			c1.2519531-0.2641602,2.2509766-0.8881836,2.9921875-1.8730469c0.7441406-0.984375,1.2216797-2.184082,1.4335938-3.5981445
			c0.2128906-1.4140625,0.1601562-2.8745117-0.1582031-4.3823242c-0.3183594-1.5068359-0.9169922-2.621582-1.7949219-3.3457031
			c-0.8789062-0.7241211-1.9921875-0.9438477-3.3427734-0.6586914c-1.2529297,0.2641602-2.25,0.8876953-2.9921875,1.8725586
			c-0.7431641,0.984375-1.2226562,2.1791992-1.4365234,3.5844727c-0.2138672,1.4042969-0.1611328,2.8701172,0.1611328,4.3959961
			c0.3183594,1.5068359,0.9160156,2.6225586,1.7958984,3.3457031
			C222.7157288,277.0149841,223.8309631,277.2332458,225.1805725,276.9485779z"
          />
          <path
            fill="#FFFFFF"
            d="M240.6053772,277.250824l-0.2001953-14.4609375l-4.0800781,0.8608398l-0.0976562-3.6582031
			l2.4365234-0.5141602c0.6650391-0.1401367,1.125-0.3344727,1.3789062-0.5820312
			c0.2548828-0.2475586,0.4248047-0.6977539,0.5126953-1.3500977l0.1513672-1.1669922l3.6992188-0.7807617l0.3085938,20.7851562
			L240.6053772,277.250824z"
          />
          <path
            fill="#FFFFFF"
            d="M252.3465881,274.7727966l-0.2011719-14.4609375l-4.0800781,0.8613281l-0.0966797-3.6582031
			l2.4365234-0.5146484c0.6650391-0.1401367,1.125-0.3339844,1.3789062-0.5820312
			c0.2548828-0.2475586,0.4248047-0.6977539,0.5126953-1.3500977l0.1523438-1.1669922l3.6982422-0.7807617l0.3085938,20.7851562
			L252.3465881,274.7727966z"
          />
          <path
            fill="#FFFFFF"
            d="M263.4481506,278.5618591c-0.4296875,0.0908203-0.8515625,0.1538086-1.2617188,0.1894531
			c-0.4121094,0.0366211-0.7607422,0.0537109-1.0478516,0.0527344l-0.0283203-3.3354492l0.8203125-0.1733398
			c0.7050781-0.1484375,1.2421875-0.3139648,1.6132812-0.4941406c0.3710938-0.1811523,0.6660156-0.4521484,0.8857422-0.8154297
			s0.4296875-0.8935547,0.6308594-1.5893555l0.1591797-0.5546875l-0.9394531,0.1982422l-5.1621094-14.578125l4.3447266-0.9169922
			l2.8603516,10.0668945c0.0878906,0.328125,0.2128906,0.4755859,0.3691406,0.4423828
			c0.1367188-0.0288086,0.2480469-0.2255859,0.3349609-0.5913086l2.4521484-11.1879883l4.5800781-0.9663086l-5.0908203,18.3061523
			c-0.3476562,1.2573242-0.7480469,2.2885742-1.2021484,3.0888672c-0.4541016,0.8017578-1.0234375,1.421875-1.7060547,1.8632812
			C265.3749084,278.0066833,264.5057678,278.3392029,263.4481506,278.5618591z"
          />
        </g>
        <path
          fill="#FFFFFF"
          d="M155.9682922,168.7668762c-6.6743317-4.4725189-14.9174652-5.7966614-24.4980011-3.9349976
		c-6.6910782,1.2985382-12.4385223,3.8109589-17.0833435,7.4663696c-4.6310272,3.6445618-8.3218765,8.1643524-10.9701538,13.4323578
		c-2.6310425,5.2305756-4.3046722,10.9425812-4.9736328,16.9784851c-0.666008,6.0053711-0.4036407,12.1446381,0.7797165,18.2465057
		c2.0280457,10.4483795,6.4602127,18.0121918,13.1739273,22.4788208c4.7506485,3.160202,10.2632904,4.7472076,16.4571991,4.7472076
		c2.5473633,0,5.2099152-0.2687683,7.9822388-0.8063049c6.6910706-1.2985382,12.4385071-3.8109436,17.0833282-7.4663544
		c4.6261139-3.6386719,8.3169556-8.1584625,10.9706421-13.4323578c2.631546-5.2355194,4.3046875-10.9475098,4.9731445-16.9784851
		c0.6665039-6.0053864,0.4046326-12.1436615-0.7792206-18.2455292
		C167.0565796,180.8052063,162.6436157,173.2394104,155.9682922,168.7668762z"
        />
        <path
          fill="#F99D1C"
          d="M175.2409973,185.0733337c-1.4769287,0-8.072876,1.6964111-8.9215088,1.9952393
		c0.2823486-1.5506592,0.4938965-3.0592041,0.6252441-4.5023193c1.1948242-13.1304932-7.4289551-21.2738037-7.4289551-21.2738037
		s4.1044922-1.9810791,2.7744141-5.8234863c-1.0744629-3.1037598-14.4443359-11.4599609-29.7888184,0.0828857
		c-6.6945877,5.0361328-10.2294998,10.9489746-12.0678787,16.2026367c-1.1491699,2.3345947-2.3098145,4.8626709-3.4667969,7.5244141
		c-3.0808105-1.5146484-16.5266113-4.9711914-19.1413574-3.4141846c-3.5302734,2.1022949-5.3587646,6.1466064-3.6567383,9.4085693
		c1.5985107,3.0638428,4.8703613,3.2241211,9.0065918,2.5458984c1.659668,2.5576172,4.2420654,4.9812012,7.2827148,7.7412109
		c-4.1992188,11.265625-7.8918457,22.5231934-10.1704102,29.8361816c1.8952637,6.6408691,4.9350586,11.8825684,9.078125,15.6561279
		c1.354248-0.9876709,2.6309814-2.0610352,3.694458-2.9971924c1.7489014-1.5397949,4.8201904-3.4902344,8.1300049-4.9259033
		c5.1750488-0.5292969,1.7415771,17.0531006,18.8403397,10.4342041c15.8342285-6.1293945,17.5422363,5.4348145,25.6580811,6.7875977
		c8.1158447,1.3525391,0.6824951-23.8896484,0.6824951-23.8896484l-3.2958984-23.4599609
		c2.9753418-1.0893555,6.246582-2.7944336,9.3728027-5.465332c0.8630371,0.5209961,1.8044434,0.861084,2.7930908,0.861084
		c4.1087646,0,7.4395752-2.982666,7.4395752-6.6621094C182.6805725,188.0561218,179.349762,185.0733337,175.2409973,185.0733337z"
        />
        <path
          fill="#363C44"
          d="M143.7261353,172.6383057c-0.1422577,1.3496399-5.9734039,3.4104156-8.461441,3.5505676
		c-3.6855621,0.2076263-3.2058105,0.2076263-2.0391846-4.8630676c0.9090881-3.9513855,3.5093994-6.7713318,6.2706146-6.4928284
		S144.1511841,168.6060486,143.7261353,172.6383057z"
        />
        <path
          fill="#363C44"
          d="M158.1520233,171.2974701c2.0688934,6.6545105-1.8656616,5.7335358-6.4378662,3.7437744
		c-2.5452881-1.1076813-4.0703278,0.1783142-4.0703278-3.8753204s2.7062531-7.2082062,5.4821167-7.2082062
		C155.9017944,163.9577179,156.9485321,167.4266052,158.1520233,171.2974701z"
        />

        <ellipse
          transform="matrix(0.3693524 -0.9292894 0.9292894 0.3693524 -82.7924576 250.9849243)"
          fill="#363C44"
          cx="143.5229187"
          cy="186.4917908"
          rx="6.7473221"
          ry="3.6142423"
        />
      </g>
    </svg>
  );
}

export default BooIcon;
