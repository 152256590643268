import React, { useState, useEffect, useMemo } from 'react';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';
import Media from 'react-media';
import capitalize from 'lodash/capitalize';
import {
  Tooltip,
  RegularText,
  StyledText,
  InfoIcon,
  QuestionCircleIcon,
  ResourcesList,
  Markdown
} from 'boss-ui';
import { useAppContext, useScenarioContext } from '../../libs/context-lib';
import { removePlural } from '../../libs/utils-lib';
import { joinSpecsAndAllocationsData } from '../../libs/resource-lib';
import { getSrnGroupId } from '../../libs/event-lib';
import { getUserScenarioResources } from '../../libs/scenario-lib';
import { getAnswersStatus } from '../../libs/question-lib';
import CollapsableSection from '../CollapsableSection';
import ScenarioQuestions from '../questions/ScenarioQuestions';
import ScenarioStatusTable from './ScenarioStatusTable';
import MalloryMessage from '../MalloryMessage';
import Api from '../../api';
import { onError } from '../../libs/error-lib';
import ResourcesTable from '../ResourcesTable';
import config from '../../config';

const Container = styled(Box)`
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
  padding: ${(props) =>
    props.mobile
      ? props.theme.margin.xs
      : `${props.theme.margin.m} ${props.theme.margin.l} ${props.theme.margin.m} ${props.theme.margin.l}`};
  width: 100%;
  border: 1px solid ${(props) => props.theme.color.form.border.main};
  align-items: flex-start;
  flex-direction: column;
`;
Container.displayName = 'Scenario-Container';

const API = new Api();

export default function Scenario() {
  const { user, event } = useAppContext();
  const { currentScenario, scenarioResources, scenarioQuestions, loadingScenario } =
    useScenarioContext();
  const [answersStatus, setAnswersStatus] = useState();
  const [userResources, setUserResources] = useState([]);
  const [loadingResources, setLoadingResources] = useState(false);
  const [showResources, setShowResources] = useState(false);

  useEffect(() => setAnswersStatus(getAnswersStatus(scenarioQuestions)), [scenarioQuestions]);

  useEffect(() => {
    const getSpecs = async (eventId) => {
      const groupId = getSrnGroupId(eventId);
      setLoadingResources(true);
      try {
        const [specs, allocations] = await Promise.all([
          API.get('events', `/groups/${groupId}/specs`),
          API.get('events', `/consumer/groups/${groupId}/allocations`),
        ]);
        if (specs && allocations) {
          setUserResources(joinSpecsAndAllocationsData(specs.specs, allocations.allocations));
        }
      } catch (e) {
        onError(e);
      } finally {
        setLoadingResources(false);
      }
      return [];
    };
    if (event.eventId) {
      getSpecs(event.eventId);
    }
    return () => API.abortCurrentRequest();
  }, [event]);

  const scenarioUserResources = useMemo(() => {
    let filteredResources = [];
    if (!scenarioResources || !scenarioResources.length) {
      return [];
    }
    if (config.enableLegacyResources) {
      if (user.userInTeam && !user.userInTeam.resources) {
        return [];
      }
      scenarioResources.forEach((resource) => {
        const userResource = user.userInTeam.resources.find((r) => r.name === resource.name);
        if (userResource) {
          filteredResources.push(userResource);
        }
      });
    } else {
      filteredResources = getUserScenarioResources(scenarioResources, userResources);
    }
    return filteredResources;
  }, [user.userInTeam, scenarioResources, userResources, currentScenario.scenarioId]);

  useEffect(() => {
    if (event.isEventEnded) {
      setShowResources(false);
    } else {
      setShowResources(true);
    }
  }, [event.isEventEnded]);

  if (!currentScenario) {
    return (
      <MalloryMessage message="This episode is not longer active, go back and keep playing!" />
    );
  }

  return (
    <Media query="(max-width: 1000px)">
      {(matches) => (
        <Container>
          <Box textAlign="left">
            <RegularText mediumWeight medium fontSize="14px">
              {capitalize(removePlural(currentScenario.scenarioLabel))} {currentScenario.idx}
            </RegularText>
            <Flex mb="20px" mt="10px">
              <StyledText light size="28px">
                {capitalize(currentScenario.title)}
              </StyledText>
            </Flex>
            <CollapsableSection>
              <Markdown light md={currentScenario.description} />
              <Flex flexDirection={matches ? 'column' : 'row'} my="20px">
                <Box width={matches ? 1 : 1 / 4}>
                  <Flex mb="4px">
                    <RegularText fontSize="14px" medium>
                      Status
                    </RegularText>
                  </Flex>
                  <ScenarioStatusTable loading={loadingScenario} score={answersStatus} />
                </Box>
                {showResources && (
                  <Box
                    width={matches ? 1 : 3 / 4}
                    ml={matches ? 0 : '20px'}
                    mt={matches ? '6px' : 0}
                  >
                    <Flex mb="10px">
                      <RegularText fontSize="14px" mediumWeight>
                        Resources
                      </RegularText>
                      <Flex ml="6px" data-tip data-for="resources">
                        <QuestionCircleIcon size="20px" />
                      </Flex>
                      <Tooltip id="resources" place="right">
                        Use these resources to complete the scenario. In light green the
                        username/password to login to the server.
                      </Tooltip>
                    </Flex>
                    <Tooltip id="resources" place="right">
                      Use these resources to complete the scenario. In light green the
                      username/password to login to the server.
                    </Tooltip>
                    {config.enableLegacyResources ? (
                      <ResourcesTable
                        ignoreTooltip
                        loading={loadingScenario}
                        resources={scenarioUserResources}
                        errorMsg={currentScenario.scenarioError}
                      />
                    ) : (
                      <ResourcesList
                        loading={loadingResources || loadingScenario}
                        resources={scenarioUserResources}
                      />
                    )}
                  </Box>
                )}
              </Flex>
            </CollapsableSection>
            <Flex mt="20px">
              <RegularText mediumWeight fontSize="24px">
                Questions
              </RegularText>
              <Flex ml="auto" data-tip data-for="questions">
                <InfoIcon />
                <Tooltip id="questions" place="bottom">
                  <RegularText color="#81a2be" mediumWeight fontSize="12px">
                    Tips and Tricks
                  </RegularText>
                  <li>
                    Answers are case insensitive! e.g. &quot;Elite Hacker&quot; == &quot;eLite
                    haCKer&quot;
                  </li>
                  <li>Leading and trailing whitespace in your answer is stripped away.</li>
                  <li>
                    We strive to make the answers be short, concise, and easy to enter. If you find
                    youself entering very long answers with complex spacing and punctuation, you are
                    probably not on the right track.
                  </li>
                  <li>
                    Some questions contain free hints about the formatting of the answer. Use them!
                  </li>
                </Tooltip>
              </Flex>
            </Flex>
            <ScenarioQuestions />
          </Box>
        </Container>
      )}
    </Media>
  );
}
