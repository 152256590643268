import styled, { css } from 'styled-components';

const TableRow = styled.tr`
  height: ${(props) => props.height ? props.height : 'auto'};
  background-color: ${(props) => props.theme.color.table.row.background.inactive};
  color: ${(props) => props.theme.color.table.row.color};
  ${(props) =>
    props.selected &&
    css`
      background-color: ${props.theme.color.table.row.background.active};
    `};

  ${(props) =>
    props.captain &&
    css`
      color: ${props.theme.color.table.row.captain};
    `};

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `};

  ${(props) =>
    props.border &&
    css`
      border: ${props.border};
    `}

  ${(props) =>
    props.borderIncorrect &&
    css`
      border: 1.1px solid ${props.theme.color.table.row.incorrect};
    `};

  ${(props) =>
    props.borderTransparent &&
    css`
      border: 1px solid transparent;
    `};
  
  ${(props) =>
    props.mouseOver &&
    css` 
      border: 1px solid ${props.theme.color.table.row.hover};
      border-top: 1.1px solid ${props.theme.color.table.row.hover};
    `};
      
  &:hover {
    ${(props) =>
      props.onClick &&
      css`
        background-color: ${props.theme.color.table.row.background.active};
      `};
  }
`;
TableRow.displayName = 'TableRow';

export default TableRow;
