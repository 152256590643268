import React from 'react';
import styled, { css } from 'styled-components';
import Base from '../StyledSVGBase';

const CrossIcon = styled(Base)`
  fill: #e06c75;
  ${(props) =>
    props.hover &&
    css`
      fill: #ff5733;
    `}
`;

const SquareIcon = styled(Base)`
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
    `}
`;
export default function CancelIcon(props) {
  return (
    <SquareIcon width={props.size || 34} height={props.size || 32} viewBox="0 0 34 32" {...props}>
      <rect fill="none" width="34" height="32" />
      <path
        fill="#6c6c6c"
        d="M-9.566-13.087h-1.705V1.2h1.705V2.839h-3.753V-14.739h3.753Zm19.2-1.652H13.4V2.839H9.637V1.2H11.35V-13.087H9.637Z"
        transform="translate(17 22)"
      />
      <CrossIcon {...props}>
        <path
          d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z"
          transform="translate(5 4.364)"
        />
      </CrossIcon>
    </SquareIcon>
  );
}
