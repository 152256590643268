import React from 'react';
import styled from 'styled-components';
import Base from '../StyledSVGBase';

const CheckIcon = styled(Base)`
  fill: #81c784;
  &:hover {
    fill: #2ab800;
  }
`;
CheckIcon.displayName = 'CheckMark-CheckIcon';

export default function CheckMark(props) {
  return (
    <CheckIcon {...props} width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path fill="none" d="M0,0H24V24H0Z" />
      <path d="M10,15.172l9.192-9.193,1.415,1.414L10,18,3.636,11.636,5.05,10.222Z" />
    </CheckIcon>
  );
}
