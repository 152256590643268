import React, { useMemo, useEffect, useState } from 'react';
import styled from 'styled-components';
import intervalToDuration from 'date-fns/intervalToDuration';
import { Flex } from 'reflexbox';
import { differenceInDays } from 'date-fns';
import { isTodayBetween } from '../libs/dates-lib';
import { fadeIn } from '../libs/animations-lib';
import { RegularText, ShakeContent, SandTimeIcon } from 'boss-ui';

const FIVE_MINUTES = 500;

const CountDownBox = styled(Flex)`
  height: ${(props) => (props.height ? props.height : '84px')};
  width: 210px;
  justify-content: flex-start;
  padding: 8px;
  z-index: 100;
  cursor: default;
  display: flex;
  flex-direction: column;
  animation: ${fadeIn} 0.3s;
  border: 1px solid #1d1d1d;
  box-shadow: -3px 3px 3px #00000029;
  background-color: ${(props) => props.theme.color.nav.background};
`;
CountDownBox.displayName = 'CountDownBox';

const padZeroStart = (number, nroDigits = 2) => `0${number}`.slice(-nroDigits);
const getCountDownTime = (event) => {
  return (
    <>
      {padZeroStart(event.hours)}:{padZeroStart(event.minutes)}:{padZeroStart(event.seconds)}
    </>
  );
};

let startCountDownInterval;
let endCountDownInterval;
export default function CountDown({
  endDate,
  startDate,
  allowStartingPreview = false,
  isAdminView = false,
  mobile = false,
}) {
  const [eventEndCountDown, setEndCountDown] = useState();
  const [eventStartCountDown, setStartCountDown] = useState();
  const isEventHappening = useMemo(
    () => isTodayBetween(startDate, endDate),
    [startDate, endDate, eventEndCountDown]
  );

  const isEndingEvent = useMemo(() => {
    if (eventEndCountDown) {
      return (
        Number(
          `${padZeroStart(eventEndCountDown.months)}${padZeroStart(
            eventEndCountDown.days
          )}${padZeroStart(eventEndCountDown.hours)}${padZeroStart(
            eventEndCountDown.minutes
          )}${padZeroStart(eventEndCountDown.seconds)}`
        ) < FIVE_MINUTES
      );
    }
    return false;
  }, [eventEndCountDown]);

  const startEventCountDown = () =>
    setInterval(() => {
      setEndCountDown(
        intervalToDuration({
          start: new Date(),
          end: new Date(endDate),
        })
      );
    }, 1000);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!endDate) {
      return;
    }
    // event is happening: set end count down interval
    if (isEventHappening) {
      clearInterval(endCountDownInterval);
      endCountDownInterval = startEventCountDown();
      return;
    }
    // event is not happening: disable end count down interval and set start count down interval
    clearInterval(endCountDownInterval);
    setEndCountDown(null);
    clearInterval(startCountDownInterval);
    startCountDownInterval = setInterval(() => {
      setStartCountDown(
        intervalToDuration({
          start: new Date(),
          end: new Date(startDate),
        })
      );
    }, 1000);
  }, [startDate, endDate, isEventHappening]);

  // unmount function
  useEffect(
    () => () => {
      clearInterval(endCountDownInterval);
      clearInterval(startCountDownInterval);
    },
    []
  );

  // check if event starts
  useEffect(() => {
    // clear start count down interval and set end count down interval
    if (new Date() > new Date(startDate) && endDate) {
      clearInterval(startCountDownInterval);
      setStartCountDown(null);
      clearInterval(endCountDownInterval);
      endCountDownInterval = startEventCountDown();
    }
  }, [eventStartCountDown]);

  if (eventEndCountDown) {
    const endCountDownInDays = differenceInDays(new Date(endDate), new Date());
    if (isEventHappening) {
      return mobile ? (
        <div className="CountDown-mobileContainer" style={{ display: 'flex' }}>
          <SandTimeIcon />
          <RegularText light style={{ marginLeft: '8px' }}>
            {getCountDownTime(eventEndCountDown)}
          </RegularText>
        </div>
      ) : (
        <CountDownBox>
          <Flex mb="4px" className="CountDown-finishingLabel">
            <RegularText fontSize="14px">Finishing in:</RegularText>
          </Flex>
          {isEndingEvent ? (
            <ShakeContent shake>
              <RegularText fontSize="28px" mediumWeight color="#E06C75">
                {getCountDownTime(eventEndCountDown)}
              </RegularText>
            </ShakeContent>
          ) : endCountDownInDays > 0 ? (
            <RegularText
              fontSize="28px"
              mediumWeight
              {...(isAdminView ? { color: '#66bb6a' } : { light: true })}
            >{`${endCountDownInDays} ${endCountDownInDays > 1 ? 'days' : 'day'}`}</RegularText>
          ) : (
            <RegularText
              fontSize="28px"
              mediumWeight
              {...(isAdminView ? { color: '#66bb6a' } : { light: true })}
            >
              {getCountDownTime(eventEndCountDown)}
            </RegularText>
          )}
        </CountDownBox>
      );
    }
    return (
      <CountDownBox height="40px">
        <Flex mb="4px" className="CountDown-endedLabel">
          <RegularText fontSize="14px">Event has ended</RegularText>
        </Flex>
      </CountDownBox>
    );
  }

  if (eventStartCountDown && (allowStartingPreview || isAdminView)) {
    const startCountDownInDays = differenceInDays(new Date(startDate), new Date());
    return (
      <CountDownBox>
        <Flex mb="4px" className="CountDown-startingLabel">
          <RegularText fontSize="14px">Starting in:</RegularText>
        </Flex>
        <Flex>
          {startCountDownInDays > 0 ? (
            <RegularText fontSize="28px" mediumWeight light>{`${startCountDownInDays} ${
              startCountDownInDays > 1 ? 'days' : 'day'
            }`}</RegularText>
          ) : (
            <RegularText fontSize="28px" mediumWeight light>
              {getCountDownTime(eventStartCountDown)}
            </RegularText>
          )}
        </Flex>
      </CountDownBox>
    );
  }

  return null;
}
