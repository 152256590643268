import React, { useState, useEffect, useCallback } from 'react';
import { Flex, Box } from 'reflexbox';
import intersectionBy from 'lodash/intersectionBy';
import isEmpty from 'lodash/isEmpty';
import QuestionsGrid from './QuestionsGrid';
import QuestionsList from './QuestionsList';
import {
  RegularText,
  Select,
  QuestionCardSkeleton,
  QuestionInlineSkeleton,
  EVENT_QUESTION_STATUS,
} from 'boss-ui';
import { DISPLAY_QUESTIONS_MODE } from '../../libs/constants';
import WebSocketClient from '../websocket';
import { updateQuestionBy, updateQuestionByWS } from '../../libs/question-lib';
import { useScenarioContext } from '../../libs/context-lib';
import { useLocalStorage } from '../../libs/hooks-lib';
import Api from '../../api';
import ScenarioQuestionsDisplaySelector from './ScenarioQuestionsDisplaySelector';

const wsClient = new WebSocketClient({ customMessageEvents: ['TEAM_ANSWER_UPDATE'] });

const SELECT_FILTER_OPTIONS = [
  { value: 'ALL', label: 'Show All' },
  { value: 'INCORRECT', label: 'Attempted' },
  { value: 'CORRECT', label: 'Answered Correctly' },
  { value: 'PENDING', label: 'Not Answered' },
];

const filterQuestionsByState = (state, questions) => {
  if (state === 'ALL') {
    return questions;
  }
  return questions.filter((q) => {
    if (state === EVENT_QUESTION_STATUS.PENDING && isEmpty(q.answer)) {
      return true;
    }
    if (q.answer && state === q.answer.state) {
      return true;
    }
    return false;
  });
};

const API = new Api();

export default function ScenarioQuestions() {
  const { setScenarioQuestions, scenarioQuestions, loadingScenario, currentScenario } =
    useScenarioContext();
  const [focusedQuestionId, setFocusedQuestionId] = useState(null);
  const [filterQuestionValue, setFilterQuestionsValue] = useState(SELECT_FILTER_OPTIONS[0]);
  const [filterdScenarioQuestions, setFilteredScenarioQuestions] = useState(scenarioQuestions);
  const [displayMode, setDisplayMode] = useLocalStorage(
    `${currentScenario.scenarioId}-display`,
    DISPLAY_QUESTIONS_MODE.GRID
  );

  const onQuestionCardClick = (question) => {
    const questionId = question.question_id;
    if (focusedQuestionId === questionId) {
      setFocusedQuestionId(null);
    } else {
      setFocusedQuestionId(questionId);
    }
  };

  const onFilterChange = (selected) => {
    setFilterQuestionsValue(selected);
    setFilteredScenarioQuestions(filterQuestionsByState(selected.value, scenarioQuestions));
  };

  const getQuestionNavigation = useCallback(
    (questionIndex) => ({
      goNext: () =>
        setFocusedQuestionId(filterdScenarioQuestions[questionIndex + 1].question.question_id),
      goPrev: () =>
        setFocusedQuestionId(filterdScenarioQuestions[questionIndex - 1].question.question_id),
      isNext: questionIndex + 1 < filterdScenarioQuestions.length,
      isPrev: questionIndex > 0,
    }),
    [filterdScenarioQuestions]
  );

  /*
  When the source list changes or the selected card does, 
  update the grid with the filtered values only if there is no selected card,
  */
  useEffect(() => {
    if (!focusedQuestionId) {
      setFilteredScenarioQuestions(
        filterQuestionsByState(filterQuestionValue.value, scenarioQuestions)
      );
    }
  }, [focusedQuestionId, scenarioQuestions]);

  /*
  Updates the grid with the updated values only if there is a selected card
  */
  useEffect(() => {
    if (focusedQuestionId) {
      setFilteredScenarioQuestions((filterdScenarioQuestions) =>
        intersectionBy(scenarioQuestions, filterdScenarioQuestions, 'question.question_id')
      );
    }
  }, [scenarioQuestions]);

  const answerUpdateHandler = useCallback(
    async (update) => {
      const { questionId, scenarioId, eventId } = update;
      if (currentScenario?.scenarioId === scenarioId) {
        const updatedQuestion = await API.get(
          'users',
          `/user/events/${eventId}/scenarios/${scenarioId}/questions/${questionId}`
        );
        setScenarioQuestions(
          updateQuestionBy(
            scenarioQuestions,
            updatedQuestion.question,
            questionId,
            updateQuestionByWS
          )
        );
      }
    },
    [scenarioQuestions, currentScenario?.scenarioId, setScenarioQuestions]
  );

  useEffect(() => {
    wsClient.on('TEAM_ANSWER_UPDATE', answerUpdateHandler);
    return () => wsClient.removeListener('TEAM_ANSWER_UPDATE', answerUpdateHandler);
  }, [answerUpdateHandler]);

  // Clean focused question if scenario is changed
  useEffect(() => {
    setFocusedQuestionId(null);
  }, [currentScenario.scenarioId]);

  return (
    <Flex flexDirection="column" width={1}>
      <Flex alignItems="center">
        <Box my="12px" width="300px">
          <Flex>
            <RegularText fontSize="14px">Filter by:</RegularText>
          </Flex>
          <Select
            value={filterQuestionValue}
            onChange={onFilterChange}
            options={SELECT_FILTER_OPTIONS}
          />
        </Box>
        <ScenarioQuestionsDisplaySelector
          onSelected={(v) => setDisplayMode(v)}
          selected={displayMode}
        />
      </Flex>
      <Flex justifyContent="center">
        {loadingScenario ? (
          displayMode === DISPLAY_QUESTIONS_MODE.GRID ? (
            <QuestionCardSkeleton />
          ) : (
            <QuestionInlineSkeleton />
          )
        ) : displayMode === DISPLAY_QUESTIONS_MODE.GRID ? (
          <QuestionsGrid
            questions={filterdScenarioQuestions}
            onQuestionCardClick={onQuestionCardClick}
            onQuestionCardClosed={() => setFocusedQuestionId(null)}
            focusedQuestionId={focusedQuestionId}
            questionNavigation={getQuestionNavigation}
          />
        ) : (
          <QuestionsList
            questions={filterdScenarioQuestions}
            onQuestionCardClick={onQuestionCardClick}
            onQuestionCardClosed={() => setFocusedQuestionId(null)}
            focusedQuestionId={focusedQuestionId}
            questionNavigation={getQuestionNavigation}
          />
        )}
      </Flex>
    </Flex>
  );
}
