import styled, { css } from 'styled-components';
import TableHead from '../TableHead';
import TableBody from '../TableBody';
import TableRow from '../TableRow';
import TableData from '../TableData';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background: ${(props) => props.theme.color.background};
  text-align: left;
  font-family: ${(props) => props.theme.font.family};
  font-size: 14px;
  border: 1px solid ${(props) => props.theme.color.table.border};
  ${(props) =>
    props.fixed &&
    css`
      table-layout: fixed;
    `}

  ${TableData} {
    padding: ${(props) => !props.noDataPadding && '4px'};
    ${(props) =>
      props.dataPadding &&
      css`
        padding: ${props.dataPadding};
      `}
  }

  ${TableHead} {
    padding: 4px;
    ${TableRow} {
      background-color: ${(props) =>
        props.backgroundColor
          ? props.backgroundColor
          : props.theme.color.table.header.background.inactive};
      color: ${(props) => props.theme.color.table.header.color};
    }
  }
  ${TableBody} {
    ${TableRow}:nth-child(odd) {
      background-color: ${(props) =>
        props.backgroundColor ? props.backgroundColor : props.theme.color.table.row.oddBackground};
    }

    ${TableRow} {
      ${(props) =>
        props.backgroundColor &&
        css`
          background-color: ${props.backgroundColor};
        `}
    }
  }
`;

export default Table;
