import React from 'react';
import { Flex } from 'reflexbox';
import { BOTS_TYPE } from '../libs/constants';

const BOO_STYLE = {
  color: '#81A2BE',
};

const BOTS_STYLE = {
  color: '#A5D6A7',
};

export default function BossLabel({ type, ...rest }) {
  if (type === BOTS_TYPE.NOC) {
    return (
      <Flex {...rest} style={BOO_STYLE}>
        BOO
      </Flex>
    );
  }
  return (
    <Flex {...rest} style={BOTS_STYLE}>
      BOTS
    </Flex>
  );
}
