import React from 'react';

export default function TeamIcon(props) {
  return (
    <svg width={props.size || 52} height={props.size || 52} viewBox="0 0 52 52">
      <rect fill="none" width="52" height="52" transform="translate(0 0)" />
      <path
        fill={props.color ? props.color : '#2c2c2c'}
        d="M4.914,5.316,25,1,45.086,5.316A2.383,2.383,0,0,1,47,7.623V31.229a14.063,14.063,0,0,1-6.531,11.8L25,53,9.532,43.028A14.063,14.063,0,0,1,3,31.231V7.623A2.383,2.383,0,0,1,4.914,5.316Z"
        transform="translate(1 -1)"
      />
      <path
        fill={props.backgroundColor ? props.backgroundColor : '#1d1d1d'}
        d="M14,12.8a6,6,0,0,1,6,6V26H8V18.8a6,6,0,0,1,6-6ZM5.946,16.407a8.38,8.38,0,0,0-.336,1.98L5.6,18.8V26H2V20.6a4.2,4.2,0,0,1,3.744-4.176l.2-.017Zm16.109,0A4.2,4.2,0,0,1,26,20.6V26H22.4V18.8A8.378,8.378,0,0,0,22.054,16.407ZM6.2,9.2a3,3,0,1,1-3,3,3,3,0,0,1,3-3Zm15.6,0a3,3,0,1,1-3,3,3,3,0,0,1,3-3ZM14,2A4.8,4.8,0,1,1,9.2,6.8,4.8,4.8,0,0,1,14,2Z"
        transform="translate(12 9)"
      />
    </svg>
  );
}
