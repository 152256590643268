import { keyframes } from 'styled-components';

export const fadeIn = keyframes`
0% {
  opacity: 0;
}
100% {
  opacity: 0.5;
}
`;

export const animBOTSLogo = keyframes`
0% {
  box-shadow: 0 0 red;
}
100% {
  box-shadow: 0 0 100px 80px transparent;
  border-width: 2px;
}
`;

export const animBOOLogo = keyframes`
0% {
  box-shadow: 0 0 #e9a000;
}
100% {
  box-shadow: 0 0 90px 90px transparent;
  border-width: 2px;
}
`;

export const heightEffect = keyframes`
 from {height: 0; color: #1D1D1D;}
  to {height: 100%;}
`;

export const rotate360 = keyframes`
	0% {
		transform: rotate(0deg);
	}

  5% {
		transform: rotate(0deg);
	}

  50% {
		transform: rotate(180deg);
	}

  55% {
		transform: rotate(180deg);
	}

	100% {
		transform: rotate(360deg);
	}
`;
