import styled, { css } from 'styled-components';

const CardTitle = styled.div`
  color: ${(props) =>
    props.active
      ? props.theme.color.card.classic.title.active
      : props.theme.color.card.classic.title.inactive};
  font-size: 20px;
  text-align: left;
  height: ${(props) => props.height};
  margin-top: ${(props) => props.theme.margin.s};
  margin-bottom: ${(props) => props.theme.margin.s};
  ${(props) =>
    props.restricted &&
    css`
      color: ${props.theme.color.card.restricted.title};
    `};
  ${(props) =>
    props.inprogress &&
    css`
      color: ${props.theme.color.card.classic.title.inprogress};
    `};
  ${(props) =>
    props.completed &&
    css`
      color: ${props.theme.color.card.classic.title.completed};
    `};
  ${(props) =>
    props.history &&
    css`
      color: ${props.theme.color.card.classic.title.history};
    `};
`;

CardTitle.displayName = 'CardTitle';
export default CardTitle;
