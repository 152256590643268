import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import svgBase from '../StyledSVGBase';

const SIZE = '40px';

const ProfileSVG = styled(svgBase)`
  fill: #d0d0d0;
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
      fill: #66bb6a;
    `};
`;

export default function ProfileIcon(props) {
  const [hover, setHover] = useState(false);
  const { active } = props;
  return (
    <Flex onMouseLeave={() => setHover(false)} onMouseEnter={() => setHover(true)} {...props}>
      <svg width={props.size || SIZE} height={props.size || SIZE} viewBox="0 0 40 40">
        <g>
          <path fill="none" d="M0,0H40V40H0Z" />
          <ProfileSVG viewBox="0 0 40 40" hover={hover || active}>
            <path
              d="M16,2A14,14,0,1,1,2,16,14.005,14.005,0,0,1,16,2ZM7.632,20.782A10.469,10.469,0,0,0,16.224,25.8a10.463,10.463,0,0,0,8.59-5.018,12.6,12.6,0,0,0-17.182,0ZM16,14.6a4.2,4.2,0,1,0-4.2-4.2A4.2,4.2,0,0,0,16,14.6Z"
              transform="translate(4.333 4.333)"
            />
          </ProfileSVG>
        </g>
      </svg>
    </Flex>
  );
}
