import styled, { css } from 'styled-components';

const LinkedText = styled.span`
  cursor: not-allowed;

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;

      &:hover {
        color: ${props.hoverColor || '#d0d0d0'};
        text-decoration: underline;
      }
    `}
  ${(props) =>
    props.font &&
    css`
      font-family: ${props.font};
    `}
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;
LinkedText.displayName = 'LinkedText';

export default LinkedText;
