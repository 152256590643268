import React from 'react';
import styled, { css } from 'styled-components';
import svgBase from '../StyledSVGBase';

const UploadSVG = styled(svgBase)`
  width: ${(props) => props.size || '20px'};
  height: ${(props) => props.size || '20px'};
  fill: #898989;
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
    `};
`;
UploadSVG.displayName = 'UploadIcon-UploadSVG';

export default function UploadIcon(props) {
  return (
    <UploadSVG viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path d="M12,12.586l4.243,4.242-1.415,1.415L13,16.415V22H11V16.413l-1.828,1.83L7.757,16.828ZM12,2a7,7,0,0,1,6.954,6.194A5.5,5.5,0,0,1,18,18.978V16.964a3.5,3.5,0,1,0-1.112-6.911,5,5,0,1,0-9.777,0,3.5,3.5,0,0,0-1.292,6.88l.18.03v2.014A5.5,5.5,0,0,1,5.046,8.194,7,7,0,0,1,12,2Z" />
    </UploadSVG>
  );
}
