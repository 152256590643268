import React from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import PrevIcon from '../../icons/PrevIcon';
import NextIcon from '../../icons/NextIcon';

const Button = styled.button`
  border: 1px solid ${(props) => props.theme.color.button.small.border};
  box-shadow: 0px 3px 6px #00000029;
  background: ${(props) => props.theme.color.button.small.background};
  &:hover,
  &:focus {
    background: ${(props) => props.theme.color.button.regular.accept.hover.background};
    box-shadow: 0px 4px 10px #00000040;
  }
  margin-left: 8px;
  margin-right: 8px;
  ${(props) =>
    props.disabled &&
    css`
      background: ${props.theme.color.button.regular.accept.disabled.background};
      border: 1px solid ${props.theme.color.button.regular.accept.disabled.border};
      color: ${props.theme.color.button.regular.accept.disabled.color};
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

export default function Paginator({
  onNext,
  onPrev,
  isNext = false,
  isPrev = false,
  paginating = false,
  backgroundColor = '#1d1d1d',
}) {
  const goPrev = (e) => {
    e.preventDefault();
    if (isPrev && !paginating) {
      onPrev();
    }
  };
  const goNext = (e) => {
    e.preventDefault();
    if (isNext && !paginating) {
      onNext();
    }
  };
  return (
    <Flex alignItems="center" py="16px" justifyContent="center" backgroundColor={backgroundColor}>
      <Button disabled={!isPrev || paginating} onClick={goPrev}>
        <PrevIcon />
      </Button>
      <Button disabled={!isNext || paginating} onClick={goNext}>
        <NextIcon />
      </Button>
    </Flex>
  );
}
