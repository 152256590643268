import React, { useEffect, useState } from 'react';
import Media from 'react-media';
import { Flex, Box } from 'reflexbox';
import { useRouteMatch, useHistory } from 'react-router-dom';
import Api from '../../api';
import { LoaderIcon } from 'boss-ui';
import { Survey } from '../../components';
import { onError } from '../../libs/error-lib';
import { useQuery } from '../../libs/hooks-lib';
import { useAppContext } from '../../libs/context-lib';

const API = new Api();

export default function SurveyContainer() {
  const { appType } = useAppContext();
  const [loadingSurvey, setLoadingSurvey] = useState(true);
  const [showSurvey, setShowSurvey] = useState(false);
  const [survey, setSurvey] = useState(null);
  const surveyRouteMatch = useRouteMatch('/survey/:surveyId');
  const query = useQuery();
  const campaignId = query.get('cid');
  const viewMode = query.get('view');
  const history = useHistory();

  useEffect(() => {
    const getSurvey = async (id) => {
      setLoadingSurvey(true);
      try {
        const rq = await API.get('events', `/surveys/${id}`);
        setSurvey(rq.survey);
        setShowSurvey(true);
      } catch (e) {
        onError(e);
        history.push('/');
      } finally {
        setLoadingSurvey(false);
      }
    };

    getSurvey(surveyRouteMatch.params.surveyId);
  }, [surveyRouteMatch.params.surveyId]);

  if (loadingSurvey) {
    return (
      <Flex mt="10%" alignItems="center" justifyContent="center" flexDirection="column">
        <LoaderIcon appType={appType} />
      </Flex>
    );
  }

  return (
    <>
      <Media
        queries={{
          s: '(max-width: 759px)',
          m: '(max-width: 900px)',
        }}
      >
        {(matches) => (
          <Flex justifyContent="center" width={1}>
            <Box mx={matches.m ? '20px' : '10%'} mb="5%" mt="30px">
              {showSurvey && (
                <Flex mt="30px">
                  <Survey
                    id={survey.surveyId}
                    campaignId={campaignId}
                    name={survey.name}
                    description={survey.description}
                    questions={survey.questions}
                    onDismiss={() => setShowSurvey(false)}
                    showOnDismiss={false}
                    showTextIntro
                    scrolled={viewMode === 'scroll'}
                  />
                </Flex>
              )}
            </Box>
          </Flex>
        )}
      </Media>
    </>
  );
}
