import { FooterContainer, Link, UnorderedList, CopyrightText } from './styles';
import { getCurrentYear } from '../../libs/dates-lib';

const FOOTER_LINKS = [
  { title: 'Legal', url: 'https://www.splunk.com/en_us/legal.html' },
  { title: 'Privacy', url: 'https://www.splunk.com/view/SP-CAAAAAG' },
  {
    title: 'Splunk Licensing Terms',
    url: 'https://www.splunk.com/en_us/legal/splunk-terms-overview.html'
  },
  { title: 'Website Terms of Use', url: 'https://www.splunk.com/view/SP-CAAAAAH' }
];
const COPYRIGHT_UNICODE = '\u00A9';

function generateCopyrightText() {
  return `${COPYRIGHT_UNICODE} 2005-${getCurrentYear()} Splunk Inc. All rights reserved.`;
}

export default function Footer() {
  const links = FOOTER_LINKS.map(link =>
    <li>
      <Link target="_blank" href={link.url}>{link.title}</Link>
    </li>
  );
  return (<FooterContainer>
      <nav>
        <UnorderedList>{links}</UnorderedList>
      </nav>
      <CopyrightText>{generateCopyrightText()}</CopyrightText>
    </FooterContainer>
  );
}