import styled, { css } from 'styled-components';

export default styled.td`
  font-family: 'Roboto Mono';
  font-size: 14px;
  color: #d0d0d0;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  ${(props) =>
    props.available &&
    css`
      color: #a5d6a7;
    `}
  ${(props) =>
    props.invited &&
    css`
      color: #e5c07b;
    `}
  ${(props) =>
    props.unavailable &&
    css`
      color: #a088a4;
    `}
  ${(props) =>
    props.public &&
    css`
      color: #81a2be;
    `}
  ${(props) =>
    props.full &&
    css`
      color: #a088a4;
    `}
  width: ${(props) => props.width || 'auto'};
  ${(props) =>
    !props.noBorder &&
    css`
      border-bottom: 1px solid #2c2c2c;
    `}
`;
