import React, { useEffect } from 'react';
import { Box, Flex } from 'reflexbox';
import differenceInMilliseconds from 'date-fns/differenceInMilliseconds';
import { DEFAULT_BOX_BORDER_STYLE } from '../../libs/constants';
import { Tooltip, RegularText, InfoIcon } from 'boss-ui';
import { getHourMinuteDifference } from '../../libs/dates-lib';

const formatReleaseDate = (releaseTime) => {
  if (releaseTime.minutes > 1) {
    if (releaseTime.hour === 0) {
      return `${releaseTime.minutes} minutes`;
    }
    if (releaseTime.hour > 1) {
      return `${releaseTime.hour} hours and ${releaseTime.minutes} minutes`;
    }
    return `${releaseTime.hour} hour and ${releaseTime.minutes} minutes`;
  }
  if (releaseTime.hour === 0) {
    return `${releaseTime.minutes} minute`;
  }
  if (releaseTime.hour > 1) {
    return `${releaseTime.hour} hours and ${releaseTime.minutes} minute`;
  }
  return `${releaseTime.hour} hour and ${releaseTime.minutes} minute`;
};

export default function TimeBasedHint({ hints = [], totalHints, onRefreshHintsList }) {
  let releaseTimeOut;
  useEffect(() => {
    // avoid process if there is no hints
    if (hints.length) {
      // get the activation date of the closest one
      const firstHintToRelease = hints.reduce((selectedCandidate, candidate) => {
        const closest = new Date(selectedCandidate.activation);
        const closestCandidate = new Date(candidate.activation);
        const now = new Date();
        if (closest < now) {
          return candidate;
        }
        if (closest > closestCandidate && closestCandidate > now) {
          return candidate;
        }
        return selectedCandidate;
      });
      const distanceToReleace = differenceInMilliseconds(
        new Date(firstHintToRelease.activation),
        new Date()
      );
      if (distanceToReleace) {
        releaseTimeOut = setTimeout(() => onRefreshHintsList(), distanceToReleace);
      }
    }
    return () => clearTimeout(releaseTimeOut);
  }, []);

  return (
    <>
      <Flex mb="4px">
        <RegularText mediumWeight fontSize="14px">
          {`Hints [${totalHints}]`}
        </RegularText>
        <Flex data-tip data-for="hints" ml="6px">
          <InfoIcon />
          <Tooltip id="hints">The hints will be released as time goes by..</Tooltip>
        </Flex>
      </Flex>
      {hints.map((hint) => (
        <Box p="10px" style={DEFAULT_BOX_BORDER_STYLE} key={hint.hint_id}>
          {hint.hint ? (
            <RegularText fontSize="14px" color="#E5C07B">
              {hint.hint}
            </RegularText>
          ) : (
            <RegularText fontSize="14px">
              Hint will be released in{' '}
              {formatReleaseDate(getHourMinuteDifference(new Date(hint.activation), new Date()))}
            </RegularText>
          )}
        </Box>
      ))}
    </>
  );
}
