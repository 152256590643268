import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'reflexbox';
import Media from 'react-media';

const Container = styled(Flex)`
  border: 1px solid ${(props) => props.theme.color.messageCard.border};
  width: 100%;
  align-items: center;
`;

const Image = styled.img`
  height: 200px;
`;

const Message = styled(Flex)`
  font-size: 20px;
  font-family: 'Roboto Mono';
  font-weight: normal;
  color: ${(props) => props.theme.color.text.medium.color};
  text-align: left;
  overflow: hidden;
  font-weight: 300;
`;

export default function MalloryMessage({ message }) {
  return (
    <Media query="(max-width:640px)">
      {(matches) => (
        <Container flexDirection={matches ? 'column' : 'row'}>
          <Box ml={matches ? 0 : '50px'}>
            <Image
              src={`${process.env.PUBLIC_URL}/mallory_workshops_desktop@2x.png`}
              alt="mallory"
            />
          </Box>
          <Message width={matches ? 1 : 3 / 4} p={matches ? '20px' : 0}>
            {message}
          </Message>
        </Container>
      )}
    </Media>
  );
}
