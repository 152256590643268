import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';

const Label = styled.div`
  color: ${(props) =>
    props.light ? props.theme.color.text.light.color : props.theme.color.text.medium.color};
  text-align: left;
  font-family: 'Roboto Mono';
  font-size: 14px;
  letter-spacing: 0.56px;
  opacity: 1;
`;

const Required = styled.div`
  color: red;
`;

function FormLabel(props) {
  const { required, children, light, style } = props;
  return (
    <Flex style={style}>
      <Label light={light}>{children}</Label>
      {required && <Required title="This field is required">*</Required>}
    </Flex>
  );
}

export default FormLabel;
