import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Button, Form, FormLabel, FormLeyend, LinkedText } from 'boss-ui';
import { FormInput } from '../../components';
import { useFormFields } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';

export default function PasswordRecovery() {
  const [fields, setFields] = useFormFields({
    email: '',
    verificationCode: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });
  const [isLoading, setLoading] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const history = useHistory();

  async function handleSendRecoveryCode(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await Auth.forgotPassword(fields.email);
      setCodeSent(true);
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }

  async function handleResetPassword(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await Auth.forgotPasswordSubmit(fields.email, fields.verificationCode, fields.newPassword);
      history.push('/');
    } catch (e) {
      onError(e);
      setLoading(false);
    }
  }

  const validateNewPassword = () =>
    fields.verificationCode &&
    fields.newPassword.length > 7 &&
    fields.newPassword === fields.newPasswordConfirmation;

  function MailForm() {
    return (
      <Flex justifyContent="center" align="center" mt="100px" mb="16px">
        <Box>
          <Form onSubmit={handleSendRecoveryCode}>
            <Flex>
              <Box px="60px" py="40px" width="412px">
                <Box mb="20px">
                  <FormLabel>Email</FormLabel>
                  <FormInput
                    autoFocus
                    placeholder="Enter user email"
                    value={fields.email}
                    id="email"
                    onChange={setFields}
                    type="email"
                  />
                </Box>
                <Button type="submit" isLoading={isLoading} disabled={fields.email.length < 5}>
                  SEND RECOVERY CODE
                </Button>
              </Box>
            </Flex>
          </Form>
          <Flex justifyContent="center" mt="20px">
            <FormLeyend size="14px">
              Go back to <LinkedText onClick={() => history.push('/login')}>SIGN IN</LinkedText>
            </FormLeyend>
          </Flex>
        </Box>
      </Flex>
    );
  }

  function NewPasswordForm() {
    return (
      <Flex justifyContent="center" align="center" mt="100px" mb="16px">
        <Box>
          <Form onSubmit={handleResetPassword}>
            <Flex>
              <Box px="60px" py="40px" width="412px">
                <Box mb="20px">
                  <FormLabel>Code</FormLabel>
                  <FormInput
                    autoFocus
                    id="verificationCode"
                    placeholder="Enter verification code"
                    value={fields.verificationCode}
                    onChange={setFields}
                  />
                </Box>
                <Box mb="20px">
                  <FormLabel>New Password</FormLabel>
                  <FormInput
                    id="newPassword"
                    placeholder="Enter new password"
                    value={fields.newPassword}
                    onChange={setFields}
                    type="password"
                  />
                  <FormLeyend>
                    *At least 8 characters length 1 symbol, 1 number, 1 uppercase, 1 lowercase
                  </FormLeyend>
                </Box>
                <Box mb="20px">
                  <FormLabel>Confirm Password</FormLabel>
                  <FormInput
                    id="newPasswordConfirmation"
                    placeholder="Confirm password"
                    value={fields.newPasswordConfirmation}
                    onChange={setFields}
                    type="password"
                  />
                </Box>
                <Button type="submit" isLoading={isLoading} disabled={!validateNewPassword()}>
                  RESET PASSWORD
                </Button>
              </Box>
            </Flex>
          </Form>
        </Box>
      </Flex>
    );
  }

  return <>{codeSent ? NewPasswordForm() : MailForm()}</>;
}
