import React from 'react';
import styled, { css } from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '30px';

const EyeSVG = styled(svgBase)`
  fill: ${(props) =>
    props.inverted ? (props.invertedColor ? props.invertedColor : '#535353') : '#1d1d1d'};
  ${(props) =>
    props.inverted &&
    props.hover &&
    css`
      fill: #81a2be;
    `};
`;
EyeSVG.displayName = 'PublicIcon-EyeSVG';

export default function PublicIcon(props) {
  return (
    <svg width={props.size || SIZE} height={props.size || SIZE} viewBox="10 10 30 20">
      <g transform="translate(9 6)">
        <g transform="matrix(1, 0, 0, 1, -9, -6)">
          <rect
            fill={props.inverted ? 'none' : '#535353'}
            width="32"
            height="32"
            transform="translate(9 6)"
          />
        </g>
        <g transform="translate(4 4)">
          <EyeSVG viewBox="0 1 30 20" {...props}>
            <path d="M1.181,12a11,11,0,0,1,21.638,0A11,11,0,0,1,1.181,12ZM12,17a5,5,0,1,0-5-5A5,5,0,0,0,12,17Zm0-2a3,3,0,1,1,3-3A3,3,0,0,1,12,15Z" />
          </EyeSVG>
        </g>
      </g>
    </svg>
  );
}
