import styled from 'styled-components';
import { Flex } from 'reflexbox';

const ContainerEvtPlayerMobile = styled(Flex)`
  background-color: ${(props) => props.theme.color.navigationMenu.background.inactive};
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #121212;
`;
ContainerEvtPlayerMobile.displayName = 'ContainerEvtPlayerMobile';

export default ContainerEvtPlayerMobile;
