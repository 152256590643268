import styled, { css } from 'styled-components';

const TableData = styled.td.attrs((props) => ({
  colSpan: props.colspan || 1,
}))`
  vertical-align: middle;
  width: ${(props) => props.width || 'auto'};
  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `};

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `};

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `};

  ${(props) =>
    props.right &&
    css`
      text-align: right;
    `};

  ${(props) =>
    props.nowrap &&
    css`
      white-space: nowrap;
    `};

  ${(props) =>
    props.onClick &&
    css`
      cursor: pointer;
    `}
`;

export default TableData;
