import styled, { css } from 'styled-components';

const Textarea = styled.textarea`
  background: ${(props) => props.theme.color.input.default.background} 0% 0% no-repeat padding-box;
  border: 1px solid ${(props) => props.theme.color.input.default.border};
  opacity: 1;
  resize: none;
  overflow: hidden;
  color: ${(props) => props.theme.color.input.default.color};
  letter-spacing: 0px;
  font-family: 'Roboto Mono';
  font-size: 14px;
  display: block;
  width: -webkit-fill-available;
  width: -moz-available;
  height: ${(props) => (props.height ? props.height : '')};
  ${(props) =>
    props.search &&
    css`
      padding-left: 30px;
      background-color: #373737;
    `}
  &::placeholder {
    color: ${(props) => props.theme.color.input.default.placeholderColor};
  }
  &:hover {
    background: ${(props) => props.theme.color.input.hover.background} 0% 0% no-repeat padding-box;
    border: 1px solid ${(props) => props.theme.color.input.hover.border};
    opacity: 1;
  }
  &:focus {
    background: ${(props) => props.theme.color.input.focused.background} 0% 0% no-repeat padding-box;
    ${(props) =>
      props.search &&
      css`
        background-color: #454545;
      `}
    border: 1px solid ${(props) =>
      props.dangerBorder
        ? props.theme.color.input.error.border
        : props.theme.color.input.focused.border};
    opacity: 1;
  }
  ${(props) =>
    props.validate &&
    !props.validating &&
    css`
      box-shadow: none;
      border: 1px solid
        ${props.valid
          ? props.theme.color.input.success.border
          : props.theme.color.input.error.border};
    `}
  ${(props) =>
    props.dangerBorder &&
    css`
      box-shadow: none;
      border: 1px solid ${props.theme.color.input.error.border};
    `}
`;
Textarea.displayName = 'Textarea';

export default Textarea;
