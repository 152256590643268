import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Flex, Box } from 'reflexbox';
import { Button, StyledText, FormLabel, FormLeyend, Form, LinkedText } from 'boss-ui';
import { FormInput } from '../../components';
import { useFormFields } from '../../libs/hooks-lib';
import { onError } from '../../libs/error-lib';
import VerifyEmailForm from '../../components/VerifyEmailForm';

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
    confirmPassword: '',
  });
  const history = useHistory();
  const [newUser, setNewUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 7 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      const newUser = await Auth.signUp({
        username: fields.email.toLowerCase(),
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return <VerifyEmailForm email={fields.email} password={fields.password} />;
  }

  function renderForm() {
    return (
      <Flex justifyContent="center">
        <Box>
          <Flex justifyContent="center" mt="100px" mb="16px">
            <StyledText size="40px" light>
              Sign Up
            </StyledText>
          </Flex>
          <Form onSubmit={handleSubmit}>
            <Flex>
              <Box py="40px" px="60px" width="412px">
                <Box mb="20px">
                  <FormLabel>Email</FormLabel>
                  <FormInput
                    autoFocus
                    placeholder="Enter your email"
                    type="email"
                    value={fields.email}
                    onChange={handleFieldChange}
                    id="email"
                  />
                </Box>
                <Box mb="20px">
                  <FormLabel>Password</FormLabel>
                  <FormInput
                    id="password"
                    placeholder="Enter a password"
                    type="password"
                    value={fields.password}
                    onChange={handleFieldChange}
                  />
                  <FormLeyend>
                    *At least 8 characters length 1 symbol, 1 number, 1 uppercase, 1 lowercase
                  </FormLeyend>
                </Box>
                <Box mb="20px">
                  <FormLabel>Confirm Password</FormLabel>
                  <FormInput
                    id="confirmPassword"
                    placeholder="Re enter your password"
                    type="password"
                    value={fields.confirmPassword}
                    onChange={handleFieldChange}
                  />
                </Box>
                <Button isLoading={isLoading} disabled={!validateForm()} type="submit">
                  SIGN UP
                </Button>
              </Box>
            </Flex>
          </Form>
          <Flex justifyContent="center" mt="20px">
            <FormLeyend size="14px">
              Already have an account?{' '}
              <LinkedText onClick={() => history.push('/login')}>SIGN IN</LinkedText>
            </FormLeyend>
          </Flex>
        </Box>
      </Flex>
    );
  }

  return <>{newUser === null ? renderForm() : renderConfirmationForm()}</>;
}
