import styled from 'styled-components';

const NavTitle = styled.div`
  cursor: default;
  color: ${(props) => props.theme.color.navTitle.color};
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export default NavTitle;
