import React from 'react';

export default function FloatingPanel(props) {
  const { children } = props;
  return (
    <div
      style={{
        position: 'absolute',
        right: 0,
        minWidth: '200px',
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {children}
    </div>
  );
}
