import { API } from 'aws-amplify';
import { ERROR_NAMES } from './libs/error-lib';
import { sendWarning } from './libs/utils-lib';

export const BANNED_USER_CODE = 403;
class Api {
  constructor() {
    this.apiRequestPendingPromises = [];
  }

  async apiRequest(method, params) {
    let response;
    try {
      const rq = API[method](...params);
      const promiseIdx = this.apiRequestPendingPromises.push(rq);
      response = await rq;
      this.apiRequestPendingPromises.splice(promiseIdx, 1);
    } catch (e) {
      if (API.isCancel(e)) {
        throw new Error(ERROR_NAMES.ABORTED_REQUEST);
      }
      if (
        e.response &&
        e.response.status === 403 &&
        !(e.response.data && e.response.data.statusCode === BANNED_USER_CODE)
      ) {
        sendWarning('User session expired');
        window.history.pushState(null, '/login');
        throw new Error(ERROR_NAMES.SESSION_EXPIRED);
      }
      throw e;
    }
    return response;
  }

  get(...params) {
    return this.apiRequest('get', params);
  }

  del(...params) {
    return this.apiRequest('del', params);
  }

  put(...params) {
    return this.apiRequest('put', params);
  }

  post(...params) {
    return this.apiRequest('post', params);
  }

  patch(...params) {
    return this.apiRequest('patch', params);
  }

  abortCurrentRequest() {
    if (this.apiRequestPendingPromises.length) {
      this.apiRequestPendingPromises.forEach((requestPromise) => API.cancel(requestPromise));
      this.apiRequestPendingPromises = [];
    }
  }
}

export default Api;
