import { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import marked from 'marked';
import sanitizeHTML from 'sanitize-html';

const MD_DEFAULT_OPTIONS = {
  skipSanitize: false,
  markedOptions: null,
  sanitizeOptions: {
    allowedTags: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'blockquote',
      'p',
      'a',
      'ul',
      'ol',
      'nl',
      'li',
      'b',
      'i',
      'strong',
      'em',
      'strike',
      'code',
      'hr',
      'br',
      'div',
      'table',
      'thead',
      'caption',
      'tbody',
      'tr',
      'th',
      'td',
      'pre',
      'iframe',
    ],
    disallowedTagsMode: 'discard',
    allowedAttributes: {
      a: ['href', 'name', 'target'],
      img: ['src'],
    },
    selfClosing: ['img', 'br', 'hr', 'area', 'base', 'basefont', 'input', 'link', 'meta'],
    allowedSchemes: ['http', 'https', 'ftp', 'mailto'],
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: ['href', 'src', 'cite'],
    allowProtocolRelative: true,
  },
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function useMarked(markdown, options = MD_DEFAULT_OPTIONS) {
  const [html, setHtml] = useState(markdown);

  useEffect(() => {
    if (options.markedOptions) {
      marked.setOptions(options.markedOptions);
    }
    const tokens = marked.lexer(markdown);
    const html = marked.parser(tokens);
    setHtml(options.skipSanitize ? html : sanitizeHTML(html, options.sanitizeOptions));
  }, [markdown, options]);

  return html;
}

export function useCopyToClipboard(params = { resetTimeOut: null }) {
  const [isCopied, setCopied] = useState(false);
  const [textCopied, setText] = useState(null);

  const handleCopy = useCallback((text) => {
    const textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    setCopied(true);
    setText(text);
  }, []);

  useEffect(() => {
    let timeout;
    if (isCopied && params && params.resetTimeOut) {
      timeout = setTimeout(() => setCopied(false), params.resetTimeOut);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isCopied, params]);

  return [handleCopy, isCopied, textCopied];
}

export default { useQuery, useMarked, useCopyToClipboard };
