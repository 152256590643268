import React from 'react';
import ReactSelect from 'react-select';
import styled, { useTheme } from 'styled-components';

const StyledSelect = styled(ReactSelect).attrs({
  components: { IndicatorSeparator: null },
})`
  font-family: ${(props) => props.theme.font.family};
  font-size: 14px;
  margin-top: ${props => props.marginTop || '6px'};
  margin-right: ${props => props.marginRight};
  width: -webkit-fill-available;
`;
StyledSelect.displayName = 'Select-StyledSelect';

function Select(props) {
  const theme = useTheme();

  // react-select allows passing style function for nested components inside the select component
  const getStyles = {
    control: (provided) => ({
      ...provided,
      background: theme.color.select.control.background.inactive,
      border: `${theme.color.select.control.border} 1px solid`,
      borderRadius: '0px',
      color: theme.color.select.control.color,
      boxShadow: '0',
      '&:hover': {
        background: theme.color.select.control.background.active,
      },
      height: props.height || provided.height,
      minHeight: props.minHeight || provided.minHeight
    }),
    option: (provided) => ({
      ...provided,
      background: theme.color.select.option.background.inactive,
      '&:hover': {
        background: theme.color.select.option.background.active,
      },
    }),
    menu: (provided) => ({
      ...provided,
      background: theme.color.select.menu.background,
      border: `${theme.color.select.menu.border} 1px solid`,
      color: theme.color.select.menu.color,
      borderRadius: '0px',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: theme.color.select.singleValue.color,
      height: props.height || provided.height
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: props.height || provided.height
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: props.height || provided.height
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      height: props.height || provided.height
    }),
  };
  return <StyledSelect {...props} styles={getStyles} />;
}

export default Select;
