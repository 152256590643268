import React, { useState, useEffect } from 'react';
import { Flex } from 'reflexbox';
import map from 'lodash.map';
import Input from '../Input';
import CancelIcon from '../../icons/CancelIcon';
import CheckIcon from '../../icons/CheckIcon';

const areValidChanges = (valuesOnEdition, matchRegExp) => {
  if (!matchRegExp) {
    return true;
  }
  const validations = map(
    valuesOnEdition,
    (value) => value !== undefined && value !== null && value.toString().match(matchRegExp)
  );
  return !validations.some((v) => v === false || v === null);
};

export default function EditableInput({
  valueObject = {},
  onValueChanged = () => {},
  edit = false,
  setEditionActive = () => {},
  matchRegExp,
}) {
  const [valuesOnEdition, _setEditionValues] = useState({});
  const valuesOnEditionRef = React.useRef(valuesOnEdition);
  const [valueOnView, setViewValue] = useState('');
  const [saveHover, mouseHoverSave] = useState(false);
  const [cancelHover, mouseHoverCancel] = useState(false);

  const onCancelChange = () => {
    setEditionActive(false);
  };

  // work around to get state values on event handler
  const setEditionValues = (data) => {
    _setEditionValues(data);
    valuesOnEditionRef.current = data;
  };

  const getDisplayableValue = (objectValue) => {
    // reverse in order to have username field first
    const keys = Object.keys(objectValue).sort().reverse();
    let displayableValue = objectValue[keys[0]];
    for (let idx = 1; idx < keys.length; idx++) {
      displayableValue = displayableValue.concat(` / ${objectValue[keys[idx]]}`);
    }
    return displayableValue;
  };

  const onSaveChange = (change = valuesOnEdition) => {
    if (areValidChanges(change, matchRegExp)) {
      onValueChanged(change);
    }
  };

  useEffect(() => {
    const handleEnter = (event) => {
      if (event.key === 'Enter') {
        // work around to get state values on event handler
        onSaveChange(valuesOnEditionRef.current);
        event.preventDefault();
      }
    };
    if (edit) {
      window.addEventListener('keydown', handleEnter);
      setEditionValues(valueObject);
    }
    return () => window.removeEventListener('keydown', handleEnter);
  }, [edit]);

  useEffect(() => {
    setViewValue(getDisplayableValue(valueObject));
  }, [valueObject]);

  if (edit) {
    return (
      <Flex>
        <Flex width="-webkit-fill-available">
          {map(valuesOnEdition, (valueOnEdition, valueKey) => {
            return (
              <Input
                key={valueKey}
                id={valueKey}
                autoFocus
                value={valueOnEdition}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setEditionValues(
                    (function () {
                      return {
                        ...valuesOnEdition,
                        [valueKey]: newValue,
                      };
                    })()
                  );
                }}
              />
            );
          })}
        </Flex>
        <Flex width="70px">
          <Flex
            onMouseLeave={() => mouseHoverSave(false)}
            onMouseEnter={() => mouseHoverSave(true)}
            onClick={() => onSaveChange()}
          >
            <CheckIcon
              hover={saveHover}
              disabled={!areValidChanges(valuesOnEdition, matchRegExp)}
            />
          </Flex>
          <Flex
            ml="10px"
            onMouseLeave={() => mouseHoverCancel(false)}
            onMouseEnter={() => mouseHoverCancel(true)}
            onClick={onCancelChange}
          >
            <CancelIcon hover={cancelHover} />
          </Flex>
        </Flex>
      </Flex>
    );
  }

  return (
    <Flex py="6px" pl="10px">
      {valueOnView}
    </Flex>
  );
}
