import React, { useState, useEffect } from 'react';
import { Flex, Box } from 'reflexbox';
import find from 'lodash/find';
import { 
LinkedText, 
UnregularButton, 
Modal, 
CrossIcon, 
FormLabel, 
ContextMenuItem, 
ContextMenu, 
Paginator, 
SpyIcon, 
ListTable,
ListItem,
Item
} from 'boss-ui';
import debounce from 'lodash/debounce';
import Api from '../api';
import FormInput from './forms/FormInput';
import MemberState from './MemberState';
import { TEAM_MEMBER_ROLE, USER_TEAM_STATE } from '../libs/constants';
import { useAppContext } from '../libs/context-lib';
import { useDynamoPaginator } from '../libs/hooks-lib';
import { onError } from '../libs/error-lib';

const DEFAULT_PAGE_SIZE = 5;
const ZERO = 0;
const PAGE_STEP = 1;

const SeatAvailables = ({ seats, mobile = false }) => {
  if (!mobile) {
    return (
      <>
        <FormLabel>Seats left</FormLabel>
        <div
          style={{
            fontFamily: 'Roboto Mono',
            color: '#D0D0D0',
            fontSize: 24,
            borderBottom: '1px solid #1D1D1D',
            marginTop: '5px',
          }}
        >
          {seats}
        </div>
      </>
    );
  }
  return <span style={{ color: '#D0D0D0' }}>Seats left {seats}</span>;
};

const API = new Api();

export default function SearchUsers(props) {
  const { onClose, event, participants, addParticipants, screenWidthSchema } = props;
  const { user } = useAppContext();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [debounceSearching, setDebounceSearching] = useState(false);
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const [searchList, setSearchList] = useState([]);

  const inviteUser = (user, role) => {
    if (event.maxNumberOfParticipants - participants.length > 0) {
      user.recentlyAdded = true;
      addParticipants(user, role);
      const invited = find(searchList, (u) => u.userId === user.userId);
      invited.inviteState = USER_TEAM_STATE.INVITED;
    }
  };

  const getUserState = (user) => {
    if (user.hasTeam) {
      return 'member of another team';
    }
    const invitationSent = find(participants, (u) => u.userId === user.userId);
    return invitationSent ? USER_TEAM_STATE.INVITED : USER_TEAM_STATE.AVAILABLE;
  };

  const prepareUserList = (users) => {
    const filterList = [];
    users.forEach((u) => {
      if (u.userId !== user.dynamoUser.userId) {
        filterList.push({
          ...u,
          inviteState: getUserState(u),
        });
      }
    });
    return filterList;
  };

  const searchUser = async (q, page = ZERO) => {
    setLoading(true);
    let lastEvaluatedKey;
    if (page > ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const result = await API.get('events', `/events/${event.eventId}/member-sugestions`, {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          q,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
        },
      });
      setSearchList(prepareUserList(result.participants));
      paginate(result.pagination, page);
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
      setDebounceSearching(false);
    }
  };

  const delayedQuery = (q) => {
    setDebounceSearching(true);
    debounce(() => searchUser(q), 500)();
  };

  useEffect(() => {
    searchUser('');
  }, []);

  const cleanBox = () => {
    setSearch('');
    searchUser('');
  };

  const onNextPage = () => {
    if (!debounceSearching) {
      searchUser(search, currentPage + PAGE_STEP);
    }
  };

  const onPrevPage = () => {
    if (!debounceSearching) {
      searchUser(search, currentPage - PAGE_STEP);
    }
  };

  return (
    <Modal
      active
      onBackgroundClick={onClose}
      style={
        screenWidthSchema && screenWidthSchema.l
          ? {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginTop: '100px',
              minWidth: '96%',
              top: '-100px',
            }
          : { margin: '100px auto auto auto', top: '-100px' }
      }
    >
      <Flex
        width={screenWidthSchema && screenWidthSchema.l ? '100%' : '920px'}
        backgroundColor="#2C2C2C"
        flexDirection="column"
      >
        <Flex
          mr="10px"
          mt="6px"
          ml="auto"
          css={{
            cursor: 'pointer',
          }}
          onClick={onClose}
        >
          <CrossIcon />
        </Flex>
        <Flex height="113px" alignItems="center" style={{ boxShadow: '0px 3px 6px #00000029' }}>
          <Box
            mx={screenWidthSchema && screenWidthSchema.s ? '0px' : '58px'}
            px={screenWidthSchema && screenWidthSchema.s ? '20px' : '0px'}
            width={screenWidthSchema && screenWidthSchema.s ? '100%' : '66.6%'}
          >
            <FormLabel>Find a member for your team</FormLabel>
            <FormInput
              search
              remove={search.length > ZERO && cleanBox}
              onChange={(e) => {
                setSearch(e.target.value);
                delayedQuery(e.target.value);
              }}
              value={search}
              placeholder="Search by email address or by profile Display Name"
            />
            {screenWidthSchema && screenWidthSchema.s && (
              <Box>
                <SeatAvailables
                  seats={event.maxNumberOfParticipants - participants.length}
                  mobile
                />
              </Box>
            )}
          </Box>
          {!(screenWidthSchema && screenWidthSchema.s) && (
            <Box>
              <SeatAvailables seats={event.maxNumberOfParticipants - participants.length} />
            </Box>
          )}
        </Flex>
        <Flex height="400px" flexDirection="column">
          <ListTable refreshing={loading} mobile={screenWidthSchema && screenWidthSchema.s}>
            {searchList.map((item) => (
              <ListItem key={item.userId}>
                {screenWidthSchema && screenWidthSchema.s ? (
                  <Item>
                    <Flex flexDirection="column" justifyContent="center" alignItems="flex-start">
                      <Flex>{item.name}</Flex>
                      <Flex>
                        <MemberState
                          available={item.inviteState === USER_TEAM_STATE.AVAILABLE}
                          invited={item.inviteState === USER_TEAM_STATE.INVITED}
                          unavailable={
                            item.inviteState !== USER_TEAM_STATE.AVAILABLE &&
                            item.inviteState !== USER_TEAM_STATE.INVITED
                          }
                        >
                          {item.inviteState}
                        </MemberState>
                      </Flex>
                    </Flex>
                  </Item>
                ) : (
                  <>
                    <Item noBorder width="10%">
                      <SpyIcon />
                    </Item>
                    <Item>{item.name}</Item>
                    <Item
                      available={item.inviteState === USER_TEAM_STATE.AVAILABLE}
                      invited={item.inviteState === USER_TEAM_STATE.INVITED}
                      unavailable={
                        item.inviteState !== USER_TEAM_STATE.AVAILABLE &&
                        item.inviteState !== USER_TEAM_STATE.INVITED
                      }
                    >
                      {item.inviteState}
                    </Item>
                  </>
                )}
                <Item width={screenWidthSchema && screenWidthSchema.s ? '' : '15%'}>
                  {item.inviteState === USER_TEAM_STATE.AVAILABLE && (
                    <ContextMenu
                      button={
                        <UnregularButton active>
                          +{screenWidthSchema && screenWidthSchema.s ? '' : ' invite'}
                        </UnregularButton>
                      }
                    >
                      <ContextMenuItem>
                        <LinkedText onClick={() => inviteUser(item, TEAM_MEMBER_ROLE.CAPTAIN)}>
                          As captain
                        </LinkedText>
                      </ContextMenuItem>
                      <ContextMenuItem>
                        <LinkedText onClick={() => inviteUser(item, TEAM_MEMBER_ROLE.REGULAR)}>
                          As regular member
                        </LinkedText>
                      </ContextMenuItem>
                    </ContextMenu>
                  )}
                </Item>
              </ListItem>
            ))}
            {search.length > 3 && searchList.length === ZERO && !loading && (
              <ListItem>
                <Item>No users found</Item>
              </ListItem>
            )}
          </ListTable>
          <Paginator
            isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
            onNext={onNextPage}
            isPrev={currentPage > 0}
            onPrev={onPrevPage}
            paginating={loading || debounceSearching}
          />
        </Flex>
      </Flex>
    </Modal>
  );
}
