import styled from 'styled-components';

const FooterContainer = styled.div`
    text-align: center;
    margin-top: ${props => props.theme.margin.l};
    margin-bottom: ${props => props.theme.margin.s};
    font-family: ${props => props.theme.font.family};
    font-size: 14px;
    color: ${props => props.theme.color.text.regular.color};
    line-height: normal;
`;
FooterContainer.displayName = 'Footer-FooterContainer';

const Link = styled.a`
    color: ${props => props.theme.color.text.regular.color};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;
Link.displayName = 'Footer-Link';

const UnorderedList = styled.ul`
    list-style-type: none;
    padding: 0;
    margin: 0;

    @media (width >= 600px) {
        & > li {
            display: inline-block;
            border-right: 1px solid ${props => props.theme.color.text.regular.color};
            margin-right: 0.5em;
            padding-right: 0.5em;
        }

        & > li:last-child {
            border-right: none;
            margin-right: 0;
            padding-right: 0;
        }
    }
`;
UnorderedList.displayName = 'Footer-UnorderedList';

const CopyrightText = styled.span`
    color: ${props => props.theme.color.text.medium.color};
`;
CopyrightText.displayName = 'Footer-CopyrightText';

export { Link, FooterContainer, UnorderedList, CopyrightText };