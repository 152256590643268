import React from 'react';
import { useTheme } from 'styled-components';

export default function OpenIcon(props) {
  const highlightColor = useTheme().color.team.access.open;

  return (
    <svg width={props.size || 30} height={props.size || 30} viewBox="10 12 30 20">
      <g transform="translate(9 6)">
        <g transform="matrix(1, 0, 0, 1, -9, -6)">
          <rect
            fill={props.inverted ? 'none' : '#535353'}
            width="32"
            height="32"
            transform="translate(9 6)"
          />
        </g>
        <g transform="translate(4 4)">
          <path fill={props.inverted ? 'none' : '#535353'} d="M0,0H24V24H0Z" />
          <path
            fill={props.highlight ? highlightColor : props.inverted ? '#535353' : '#1d1d1d'}
            d="M7,10H20a1,1,0,0,1,1,1V21a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V11a1,1,0,0,1,1-1H5V9A7,7,0,0,1,18.262,5.869l-1.789.894A5,5,0,0,0,7,9Zm3,5v2h4V15Z"
          />
        </g>
      </g>
    </svg>
  );
}
