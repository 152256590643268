import React from 'react';
import styled, { css } from 'styled-components';
import svgBase from '../StyledSVGBase';

const DotsSVG = styled(svgBase)`
  width: ${(props) => props.size || '34px'};
  height: ${(props) => props.size || '22px'};
  fill: #898989;
  &:hover {
    fill: #66bb6a;
  }
  ${(props) =>
    props.disabled &&
    css`
      &:hover {
        fill: #898989;
      }
      cursor: not-allowed;
    `};
`;
DotsSVG.displayName = 'DotsSVG';

export const TreeDots = (props) => (
  <DotsSVG viewBox="0 0 44 32" {...props}>
    <g transform="translate(10 4)">
      <path fill="none" d="M0,0H24V24H0Z" />
      <path d="M4.5,10.5A1.5,1.5,0,1,0,6,12,1.5,1.5,0,0,0,4.5,10.5Zm15,0A1.5,1.5,0,1,0,21,12,1.5,1.5,0,0,0,19.5,10.5Zm-7.5,0A1.5,1.5,0,1,0,13.5,12,1.5,1.5,0,0,0,12,10.5Z" />
    </g>
  </DotsSVG>
);

export default TreeDots;
