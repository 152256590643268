import React, { useEffect, useState } from 'react';
import { Box, Flex } from 'reflexbox';
import Media from 'react-media';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import Api from '../../api';
import { StyledText, Paginator } from 'boss-ui';
import { Card, CardSkeleton, ErrorBoundary, MalloryMessage } from '../../components';
import { useAppContext } from '../../libs/context-lib';
import { onError } from '../../libs/error-lib';
import { EVENT_TYPE, EVENT_ACCESS_TYPE } from '../../libs/constants';
import { useDynamoPaginator } from '../../libs/hooks-lib';

const EMPTY_LIST_MESSAGE = `Looks like you haven't completed any event or workshop.`;

const DEFAULT_PAGE_SIZE = 10;
const ZERO = 0;
const PAGE_STEP = 1;

const API = new Api();

export default function History() {
  const [loading, setLoadingHistory] = useState(true);
  const [historyEvents, setHistoryEvents] = useState([]);
  const { setEvent } = useAppContext();
  const [{ pages, currentPage }, paginate] = useDynamoPaginator();
  const history = useHistory();

  const getUserEvents = async (page = ZERO) => {
    setLoadingHistory(true);
    let lastEvaluatedKey;
    if (page > ZERO) {
      lastEvaluatedKey =
        page > currentPage
          ? pages[currentPage].nextPagePointer
          : pages[currentPage].lastPagePointer;
    }
    try {
      const rq = await API.get('users', '/user/events', {
        queryStringParameters: {
          pageSize: DEFAULT_PAGE_SIZE,
          ...(lastEvaluatedKey && {
            ...lastEvaluatedKey,
          }),
        },
      });
      setHistoryEvents(
        rq.events.map((w) => ({
          ...w,
          eventType: w.model === EVENT_TYPE.WORKSHOP ? EVENT_TYPE.WORKSHOP : EVENT_TYPE.GAME,
        }))
      );
      paginate(rq.pagination, page);
      setLoadingHistory(false);
    } catch (e) {
      onError(e);
    }
  };

  useEffect(() => {
    getUserEvents();
    return () => API.abortCurrentRequest();
  }, []);

  const onCardClicked = (event) => {
    setEvent(event);
    history.push(
      `/history/${
        event.eventType === EVENT_TYPE.WORKSHOP
          ? `${event.workshopId}/workshop`
          : `${event.eventId}/event`
      }`
    );
  };

  return (
    <Media
      queries={{
        s: '(max-width: 759px)',
        m: '(max-width: 900px)',
      }}
    >
      {(matches) => (
        <Box mx={matches.m ? '20px' : '10%'} mt="60px" mb="5%" textAlign="left">
          <Flex
            mb="30px"
            alignItems="center"
            mx="15px"
            flexDirection={matches.s ? 'column-reverse' : 'row'}
          >
            <Flex alignSelf="start">
              <StyledText light size="28px">
                History
              </StyledText>
            </Flex>
          </Flex>
          <ErrorBoundary>
            {loading ? (
              <CardSkeleton style={{ marginLeft: '15px' }} />
            ) : (
              <Flex
                flexDirection="row"
                justifyContent={matches.s ? 'center' : 'flex-start'}
                alignItems="flex-start"
                flexWrap="wrap"
              >
                {historyEvents.length === 0 ? (
                  <Flex mx="15px" width={1}>
                    <MalloryMessage message={EMPTY_LIST_MESSAGE} />
                  </Flex>
                ) : (
                  <>
                    {historyEvents.map((e, idx) => {
                      const key = get(e, 'id', idx);
                      const title = get(e, 'name');
                      return (
                        <Flex
                          key={key}
                          index={idx}
                          style={{
                            minWidth: '330px',
                            padding: '15px',
                          }}
                        >
                          <Card
                            {...e}
                            stats={e.participant && e.participant.stats ? e.participant.stats : {}}
                            history
                            showDate
                            restricted={
                              e.accessType && e.accessType === EVENT_ACCESS_TYPE.RESTRICTED
                            }
                            title={title}
                            onCardClick={() => onCardClicked(e)}
                          />
                        </Flex>
                      );
                    })}
                    <Flex width={1} pr="20px" justifyContent="center" mt="20px">
                      <Paginator
                        isNext={pages[currentPage] && pages[currentPage].nextPagePointer}
                        onNext={() => getUserEvents(currentPage + PAGE_STEP)}
                        isPrev={currentPage > 0}
                        onPrev={() => getUserEvents(currentPage - PAGE_STEP)}
                        paginating={loading}
                        backgroundColor="transparent"
                      />
                    </Flex>
                  </>
                )}
              </Flex>
            )}
          </ErrorBoundary>
        </Box>
      )}
    </Media>
  );
}
