import styled from 'styled-components';

const FormLeyend = styled.div`
  text-align: left;
  font-family: 'Roboto Mono';
  font-size: ${(props) => (props.size ? props.size : '12px')};
  letter-spacing: 0.24px;
  color: ${(props) => props.theme.color.form.legend.color};
  opacity: 1;
`;

export default FormLeyend;
