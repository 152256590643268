import { Flex } from 'reflexbox';
import styled from 'styled-components';

const HovereableFlex = styled(Flex)`
  &:hover {
    cursor: ${(props) => (props.disable ? 'not-allowed' : 'pointer')};
  }
`;

export default HovereableFlex;
