import React, { useState } from 'react';
import { Flex } from 'reflexbox';
import styled, { css } from 'styled-components';
import { useCopyToClipboard } from '../../hooks';
import RegularText from '../RegularText';
import Tooltip from '../Tooltip';
import CopyClipboardIcon from '../../icons/CopyClipboardIcon';

const CopiedLabel = styled(Flex)`
  border: 1px solid;
  position: absolute;
  transition: opacity 1s;
  margin-top: -30px;
  margin-left: ${(props) => (props.mobile ? '-60px' : '-23px')};
  height: 30px;
  padding-left: 7px;
  padding-right: 7px;
  align-items: center;
  justify-content: center;
  background-color: #1d1d1d;
  color: #81c784;
  ${(props) =>
    props.hidden &&
    css`
      opacity: 0;
      z-index: -1000;
    `}
`;

CopiedLabel.displayName = 'CopyToClipboard-copiedLabel';

export default function CopyToClipboard({
  value = '',
  idx = 0,
  mobile = false,
  tooltip = null,
  copiedText = 'Copied!',
}) {
  const [handleCopy, isCopied] = useCopyToClipboard({ resetTimeOut: 2000 });
  const [indexCopied, setIndexCopied] = useState(-1);

  return (
    <Flex mx="6px" alignItems="center">
      <Flex data-tip data-for="copyLinkClipIcon">
        <Flex
          onClick={() => {
            setIndexCopied(idx);
            handleCopy(value);
          }}
          width="24px"
          style={{ cursor: 'pointer' }}
          className="CopyToClipboard-copyContainer"
        >
          <CopyClipboardIcon />
        </Flex>
      </Flex>
      {tooltip && !isCopied && <Tooltip id="copyLinkClipIcon">{tooltip}</Tooltip>}
      <CopiedLabel hidden={!isCopied || idx !== indexCopied} mobile={mobile}>
        <RegularText mediumWeight>{copiedText}</RegularText>
      </CopiedLabel>
    </Flex>
  );
}
