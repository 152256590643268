import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'reflexbox';
import { Input, TextArea, CrossIcon as RemoveIcon, SearchIcon } from 'boss-ui';
import { DEFAULT_FORM_INPUT_TYPE, INPUT_FORM_TYPE_TEXTAREA } from '../../libs/constants';

const InputIcon = styled.div`
  position: absolute;
  margin-top: 2.5px;
  margin-left: 5px;
  ${(props) =>
    props.remove &&
    css`
      margin-top: 0px;
      margin-right: 5px;
      margin-left: auto;
      position: relative;
      top: -34px;
      cursor: pointer;
    `};
`;

const InputValidation = styled.div`
  color: ${(props) =>
    props.valid ? props.theme.color.input.success.border : props.theme.color.input.error.border};
  font-size: 16px;
  opacity: 92%;
`;

function FormInput(props) {
  const {
    search,
    remove,
    available,
    validMessage,
    unavailableMessage,
    validate,
    validating,
    pattern,
    invalidPatternMessage,
    onChange,
    value,
    styleBox,
    inputType = DEFAULT_FORM_INPUT_TYPE,
  } = props;
  const [patternFulfilled, setPatternFulfilled] = useState(true);

  const inputChange = (e) => {
    onChange(e);
  };

  const getMessage = () => {
    if (!patternFulfilled) {
      return invalidPatternMessage;
    }
    if (validating) {
      return null;
    }
    if (!available) {
      return unavailableMessage;
    }
    return validMessage;
  };

  useEffect(() => {
    if (pattern && value !== null && value !== undefined) {
      if (value.toString().match(pattern)) {
        setPatternFulfilled(true);
      } else {
        setPatternFulfilled(false);
      }
    }
  }, [value]);

  return (
    <Box style={styleBox} width={1}>
      <Flex my="6px" alignItems="center" width={1}>
        {search && (
          <InputIcon>
            <SearchIcon />
          </InputIcon>
        )}
        {inputType === DEFAULT_FORM_INPUT_TYPE && (
          <Input
            {...props}
            valid={available && patternFulfilled}
            onChange={inputChange}
            height="32px"
          />
        )}
        {inputType === INPUT_FORM_TYPE_TEXTAREA && (
          <TextArea
            {...props}
            valid={available && patternFulfilled}
            onChange={inputChange}
            height="32px"
          />
        )}
      </Flex>
      {validate && (
        <Flex minHeight="24px">
          <InputValidation valid={available && patternFulfilled}>{getMessage()}</InputValidation>
        </Flex>
      )}
      {remove && (
        <Flex height="0px">
          <InputIcon remove onClick={remove}>
            <RemoveIcon size={16} />
          </InputIcon>
        </Flex>
      )}
    </Box>
  );
}

export default FormInput;
