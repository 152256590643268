import React, { useMemo, useState } from 'react';
import { Flex, Box } from 'reflexbox';
import styled from 'styled-components';
import { Button, Modal, RegularText, StyledText, BotsIcon, BooIcon, Paginator, PublicIcon, PrivateIcon, OpenIcon, CloseIcon } from 'boss-ui';
import { BOTS_TYPE, DEFAULT_BOX_BORDER_STYLE } from '../../libs/constants';
import { getEventShortName } from '../../libs/event-lib';

const Image = styled.img`
  object-fit: cover;
  width: 100%;
`;

export default function TeamWizardModal({
  onClose = () => {},
  eventType = BOTS_TYPE.SOC,
  mobile = false,
}) {
  const [currentPage, setCurrentPage] = useState(0);
  const eventShortName = getEventShortName(eventType);
  const eventIcon = useMemo(
    () => (eventType === BOTS_TYPE.SOC ? <BotsIcon size={300} /> : <BooIcon size={200} />),
    [eventType]
  );

  const pages = [
    <>
      <Flex my="16px">
        <StyledText light>Welcome to {eventShortName}!</StyledText>
      </Flex>
      <Flex>{eventIcon}</Flex>
      <Flex>
        <RegularText>
          For playing {eventShortName} you need to be part of a team. You can create your own team
          and send invites to your mates or join to a formed team.
        </RegularText>
      </Flex>
    </>,
    <>
      <Flex my="16px">
        <StyledText light>Creating a new team</StyledText>
      </Flex>
      <Flex style={DEFAULT_BOX_BORDER_STYLE}>
        <Image src={`${process.env.PUBLIC_URL}/wizard_create_team_screen.png`} alt="create_team" />
      </Flex>
      <Flex my="10px">
        <RegularText>
          Go to the Team tab and choose to CREATE A NEW TEAM, think about the coolest name possible
          and then send invites to your friends. You can always change your team name or send other
          invites after creation
        </RegularText>
      </Flex>
    </>,
    <>
      <Flex my="16px">
        <StyledText light>Joining a created team</StyledText>
      </Flex>
      <Flex style={DEFAULT_BOX_BORDER_STYLE}>
        <Image src={`${process.env.PUBLIC_URL}/wizard_join_team_screen.png`} alt="join_team" />
      </Flex>
      <Flex my="10px">
        <RegularText>
          Go to the Team tab and choose to JOIN A TEAM, search for a team by name. You can see the
          team formation as long as the team is public and / or join to the team if is open. You can
          also accept or reject invitations from here
        </RegularText>
      </Flex>
      <Flex width={1} mb="20px">
        <Flex justifyContent="center" alignItems="center" width={1 / 4}>
          <OpenIcon inverted />
          <RegularText mediumWeight fontSize="11px">
            Open team
          </RegularText>
        </Flex>
        <Flex justifyContent="center" alignItems="center" width={1 / 4}>
          <CloseIcon inverted />
          <RegularText mediumWeight fontSize="11px">
            Closed team
          </RegularText>
        </Flex>
        <Flex justifyContent="center" alignItems="center" width={1 / 4}>
          <PublicIcon inverted />
          <RegularText mediumWeight fontSize="11px">
            Public team
          </RegularText>
        </Flex>
        <Flex justifyContent="center" alignItems="center" width={1 / 4}>
          <PrivateIcon inverted />
          <RegularText mediumWeight fontSize="11px">
            Private team
          </RegularText>
        </Flex>
      </Flex>
    </>,
  ];

  return (
    <Modal
      active
      onBackgroundClick={onClose}
      margin={mobile ? '10% 2% auto 2%' : '10% 25% 10% 25%'}
      hideScroll
      mobile={mobile}
    >
      <Flex
        p={mobile ? '10px' : '40px'}
        flexDirection="column"
        alignItems="center"
        backgroundColor="#1d1d1d"
      >
        {pages[currentPage]}
        <Flex justifyContent="space-between" width={1} alignItems="center">
          <Box>
            <Button id="dismissBtn" onClick={onClose} secondary>
              Dismiss
            </Button>
          </Box>
          <Paginator
            isNext={pages.length > currentPage + 1}
            onNext={() => setCurrentPage((current) => current + 1)}
            isPrev={currentPage > 0}
            onPrev={() => setCurrentPage((current) => current - 1)}
          />
        </Flex>
      </Flex>
    </Modal>
  );
}
