import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Flex } from 'reflexbox';
import Button from '../Button';
import Modal from '../Modal';
import FormLeyend from '../FormLeyend';
import LinkedText from '../LinkedText';
import Tooltip from '../Tooltip';
import Select from '../Select';
import DragDrop from '../DragDrop';
import InfoIcon from '../../icons/InfoIcon';

const Background = styled(Flex)`
  background-color: ${(props) => props.theme.color.form.background};
  padding-top: ${(props) => props.theme.margin.m};
  padding-bottom: ${(props) => props.theme.margin.s};
  padding-left: ${(props) => props.theme.margin.s};
  padding-right: ${(props) => props.theme.margin.s};
  border: 1px solid ${(props) => props.theme.color.form.border.modal};
  flex-direction: column;
`;
Background.displayName = 'CsvImportModal-Background';

export default function CsvImportModal({
  filterLeyend = '',
  filterOptions = [],
  filterTooltipText = '',
  onImport,
  onCancel,
  enableCSVTemplate = false,
  onDownloadTemplate,
}) {
  const theme = useTheme();
  const [file, setFile] = useState(null);

  const enableFilterSection =
    filterLeyend !== null &&
    filterLeyend.length > 0 &&
    filterOptions.length > 0 &&
    filterTooltipText !== null &&
    filterTooltipText.length > 0;

  const [filterValue, setFilterValue] = useState(enableFilterSection && filterOptions[0]);

  return (
    <Modal style={{ overflow: 'hidden' }}>
      <Background width="500px">
        <FormLeyend>{filterLeyend}</FormLeyend>
        {enableFilterSection && (
          <Flex width={1} alignItems="center">
            <Flex width={1}>
              <Select options={filterOptions} value={filterValue} onChange={setFilterValue} />
            </Flex>
            <Flex ml="8px" data-tip data-for="filterSelect">
              <InfoIcon />
            </Flex>
            <Tooltip id="filterSelect">{filterTooltipText}</Tooltip>
          </Flex>
        )}
        <Flex mt="20px" mb="10px" flexDirection="column">
          <DragDrop onFileChange={setFile} />
        </Flex>
        {enableCSVTemplate && (
          <Flex>
            <LinkedText
              color={theme.color.form.legend.color}
              fontSize="14px"
              onClick={onDownloadTemplate}
            >
              Use a template as base for your csv file
            </LinkedText>
          </Flex>
        )}
        <Flex justifyContent="flex-end" mt="40px">
          <Flex width="150px">
            <Button
              onClick={() => {
                setFile(null);
                onCancel();
              }}
              secondary
            >
              CANCEL
            </Button>
          </Flex>
          <Flex width="150px" ml="20px">
            <Button disabled={!file} onClick={() => onImport(file, filterValue.value)}>
              IMPORT FILE
            </Button>
          </Flex>
        </Flex>
      </Background>
    </Modal>
  );
}
