import React from 'react';

export default function SearchIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill="#6c6c6c"
        d="M18.031,16.617,22.314,20.9,20.9,22.314l-4.282-4.283a9,9,0,1,1,1.414-1.414Zm-2.006-.742a7,7,0,1,0-.15.15l.15-.15Z"
      />
    </svg>
  );
}
