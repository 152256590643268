import React from 'react';
import styled from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '24px';

const PrevSVG = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: #898989;
  &:hover {
    fill: #66bb6a;
  }
`;

export default function PrevIcon() {
  return (
    <PrevSVG viewBox="0 0 24 24">
      <g>
        <path fill="none" d="M0,0H24V24H0Z" />
        <path d="M10.828,12l4.95,4.95-1.414,1.414L8,12l6.364-6.364L15.778,7.05Z" />
      </g>
    </PrevSVG>
  );
}
