import React, { useState, useMemo, useEffect } from 'react';
import { Box, Flex } from 'reflexbox';
import { withTheme } from 'styled-components';
import get from 'lodash/get';
import { LinkedText, Tabs } from 'boss-ui';
import Text from '../Text';

const TABS_LABELS = ['MORE INFO', 'RESOURCES', 'TRANSCRIPTION'];

const getTabsContent = (workshop, transcript = '', classLinks = [], colors) => ({
  0: (
    <Box
      width={1}
      mt="20px"
      style={{
        fontFamily: 'Roboto Mono',
        fontSize: '14px',
        textAlign: 'left',
        color: colors.regular,
        fontWeight: 400,
        wordBreak: 'break-all',
      }}
    >
      <>
        Access the <span style={{ color: colors.server }}>Splunk Server</span> to answer questions
        throughout this workshop, using the below shown{' '}
        <span style={{ color: colors.server }}>server</span> and{' '}
        <span style={{ color: colors.credentials }}>credentials:</span>
      </>
      <Box
        style={{ border: '1px solid #2C2C2C', wordBreak: 'break-all' }}
        my="16px"
        width="400px"
        p="16px"
      >
        <p>
          SplunkServer:{' '}
          <span style={{ color: colors.server }}>
            <LinkedText onClick={() => window.open(get(workshop, 'serverUrl', ''), '_blank')}>
              {get(workshop, 'serverUrl', '-')}
            </LinkedText>
          </span>
        </p>
        <p>
          User ID:{' '}
          <span style={{ color: colors.credentials }}>{get(workshop, 'serverUsername', '-')}</span>
        </p>
        <p>
          Password:{' '}
          <span style={{ color: colors.credentials }}>{get(workshop, 'serverPassword', '-')}</span>
        </p>
      </Box>
    </Box>
  ),
  1: (
    <Box
      width={1}
      mt="20px"
      style={{
        fontFamily: 'Roboto Mono',
        fontSize: '14px',
        textAlign: 'left',
        color: colors.regular,
        fontWeight: 400,
        wordBreak: 'break-all',
      }}
    >
      {!!classLinks.length && <p>Links for further reading:</p>}
      {classLinks.map((link) => (
        <p key={link}>
          <LinkedText onClick={() => window.open(link, '_blank')} color={colors.links}>
            {link}
          </LinkedText>
        </p>
      ))}
    </Box>
  ),
  2: (
    <Text p="0" style={{ whiteSpace: 'pre-wrap' }}>
      {transcript}
    </Text>
  ),
});

function WorkshopAdditionalInfoMenu(props) {
  const { workshop, classTranscript, classLinks, theme } = props;
  const [currentTabIdx, setCurrentTabIdx] = useState(0);
  const [transcript, setTranscript] = useState('');

  const tabsContent = useMemo(
    () => getTabsContent(workshop, transcript, classLinks, theme.color.workshopAdditionalInfo),
    [workshop, transcript, classLinks, theme]
  );

  useEffect(() => {
    const getClassTranscript = async () => {
      try {
        if (!classTranscript || classTranscript.url === '') {
          setTranscript('');
          return;
        }
        const rq = await fetch(`${classTranscript.url}`, {
          headers: { 'Cache-Control': 'max-age=0, must-revalidate' },
        });
        if (rq.ok) {
          setTranscript(await rq.text());
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    };

    getClassTranscript();
  }, [classTranscript]);

  return (
    <Box px="24px" minHeight="500px">
      <Tabs current={currentTabIdx} onTabChange={setCurrentTabIdx} labels={TABS_LABELS} />
      <Flex mt="26px">{tabsContent[currentTabIdx]}</Flex>
    </Box>
  );
}

export default withTheme(WorkshopAdditionalInfoMenu);
