import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { TopArrow } from './icons';
import { RegularText } from 'boss-ui';
import { fadeIn } from '../libs/animations-lib';

const ArrowBox = styled(Flex)`
  position: fixed;
  align-items: center;
  height: 52px;
  width: 172px;
  bottom: 100px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: ${fadeIn} 0.3s;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  border: 1px solid #1d1d1d;
  box-shadow: -3px 3px 3px #00000029;
  background-color: ${(props) => props.theme.color.background};
`;

const ScrollTopButton = () => {
  const [showScroll, setShowScroll] = useState(false);
  const [insideBox, setInsideBox] = useState(false);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
        return;
      }
      if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);
    return () => window.removeEventListener('scroll', checkScrollTop);
  });

  return (
    <ArrowBox
      onClick={scrollTop}
      show={showScroll}
      onMouseEnter={() => setInsideBox(true)}
      onMouseLeave={() => setInsideBox(false)}
    >
      <TopArrow hover={insideBox} />
      <Flex ml="6px">
        <RegularText mediumWeight fontSize="14px" color={insideBox ? '#81C784' : '#535353'}>
          Back to top
        </RegularText>
      </Flex>
    </ArrowBox>
  );
};

export default ScrollTopButton;
