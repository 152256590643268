import { RESOURCE_TYPE } from './constants';

export const getResourceCreationBody = (form, resourceType) => {
  if (resourceType === RESOURCE_TYPE.CREDENTIALS) {
    return { username: form.username, password: form.password };
  }
  return { url: form.url };
};

export const getResourceUpdateBody = (form, resourceType) => {
  if (resourceType === RESOURCE_TYPE.CREDENTIALS) {
    return { username: form.username, password: form.password };
  }
  return { url: form.url };
};

export const joinSpecsAndAllocationsData = (specs = [], allocs = []) => {
  const tmpArr = [];
  const dependencyObj = {};
  for (let s = 0; s < specs.length; s++) {
    if (specs[s].requiredSpec && !dependencyObj[specs[s].requiredSpec.srn]) {
      // is a dependency resource
      dependencyObj[specs[s].requiredSpec.srn] = true;
      tmpArr.push({
        resourceSpecSrn: specs[s].requiredSpec.srn,
        name: specs[s].requiredSpec.title,
        type: specs[s].requiredSpec.type,
        url: '',
        user: '',
        password: '',
        isDependency: true,
      });
    }
    // if the spec is not a dependency resource, then we push the object
    if (!dependencyObj[specs[s].srn]) {
      const tmpObj = {
        resourceSpecSrn: specs[s].srn,
        name: specs[s].title,
        type: specs[s].type,
      };
      if (specs[s].requiredSpec) {
        tmpObj.resourceDependencyId = specs[s].requiredSpec.srn;
      }
      tmpArr.push(tmpObj);
    }
  }
  // check the allocations to assign the username, password or url field
  for (let a = 0; a < allocs.length; a++) {
    for (let i = 0; i < tmpArr.length; i++) {
      if (
        allocs[a].specSrn === tmpArr[i].resourceSpecSrn &&
        allocs[a].slot &&
        allocs[a].slot.resource
      ) {
        tmpArr[i].url = allocs[a].slot.resource.url;
        tmpArr[i].user = allocs[a].slot.resource.username;
        tmpArr[i].password = allocs[a].slot.resource.password;
        tmpArr[i].state = allocs[a].state;
        break;
      } else if (allocs[a].specSrn === tmpArr[i].resourceSpecSrn && allocs[a].state) {
        // we dont have the slot but only the state
        tmpArr[i].state = allocs[a].state;
      }
    }
  }
  return tmpArr;
};