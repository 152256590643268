import { TEAM_MEMBER_ROLE } from './constants';

export const rules = {
  player: {
    dynamic: {
      'team-page:edit': (participant) => participant.role === TEAM_MEMBER_ROLE.CAPTAIN,
    },
    static: ['team-page:create'],
  },
  admin: {
    static: ['admin-page:visit', 'team-page:edit', 'team-page:create', 'team-page:navigate-user'],
  },
  superadmin: {
    static: [],
  },
};

export const checkPermission = (rules, role = 'player', action, data) => {
  const permissions = rules[role.toLowerCase()];
  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = permissions.static;

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  const dynamicPermissions = permissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};
