import React from 'react';

export default function WorkshopIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path fill="none" d="M0,0H24V24H0Z" />
      <path fill="#d0d0d0" d="M5,13.18v4L12,21l7-3.82v-4L12,17ZM12,3,1,9l11,6,9-4.91V17h2V9Z" />
    </svg>
  );
}
