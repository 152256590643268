import React from 'react';
import styled, { css } from 'styled-components';
import Base from '../StyledSVGBase';

const Grid = styled(Base)`
  fill: #535353;
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
      fill: #d0d0d0;
    `};
  ${(props) =>
    props.selected &&
    css`
      fill: #d0d0d0;
    `};
`;
Grid.displayName = 'GridIcon-Grid';

export default function GridIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <Grid {...props}>
        <path d="M9.333,11h5.334V3H9.333Zm0,10h5.334V13H9.333ZM21,3H16.667v8H22V4A1,1,0,0,0,21,3ZM2,4v7H7.333V3H3A1,1,0,0,0,2,4ZM16.667,21H21a1,1,0,0,0,1-1V13H16.667ZM2,20a1,1,0,0,0,1,1H7.333V13H2Z" />
        <rect fill="none" width="24" height="24" />
      </Grid>
    </svg>
  );
}
