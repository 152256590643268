import React from 'react';
import { Flex, Box } from 'reflexbox';
import TableData from '../TableData';

function TableHeader({ children, onClick, noWrap, xpadding, ypadding, justify, ...rest }) {
  return (
    <TableData {...rest} onClick={onClick}>
      <Flex alignItems="center" justifyContent={justify} px={xpadding} py={ypadding}>
        <Box css={noWrap ? { whiteSpace: 'nowrap' } : {}}>{children}</Box>
      </Flex>
    </TableData>
  );
}

export default TableHeader;
