import React from 'react';
import styled, { css } from 'styled-components';
import Base from '../StyledSVGBase';

const DoorIcon = styled(Base)`
  fill: #898989;
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
    `};
`;

export default function LogOutIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path fill="none" d="M0,0H24V24H0Z" />
      <DoorIcon {...props}>
        <path d="M4,18H6v2H18V4H6V6H4V3A1,1,0,0,1,5,2H19a1,1,0,0,1,1,1V21a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1Zm2-7h7v2H6v3L1,12,6,8Z" />
      </DoorIcon>
    </svg>
  );
}
