import React from 'react';
import { Flex } from 'reflexbox';
import { Tooltip, PublicIcon, PrivateIcon } from 'boss-ui';
import { TEAM_VISIBILITY } from '../../libs/constants';

const PUBLIC_VISIBILITY_MESSAGE = `This team is public you can see its members clicking in the team's name`;
const PRIVATE_VISIBILITY_MESSAGE = `This team is private you can't see its members`;

export default function TeamMembersState(props) {
  const { team } = props;
  const teamMembersState =
    team.visibility === TEAM_VISIBILITY.PUBLIC
      ? {
          icon: <PublicIcon inverted invertedColor="#A5D6A7" />,
          color: '#81a2be',
          tooltipMessage: PUBLIC_VISIBILITY_MESSAGE,
        }
      : {
          icon: <PrivateIcon inverted />,
          tooltipMessage: PRIVATE_VISIBILITY_MESSAGE,
          color: '#A0A0A0',
        };

  return (
    <Flex alignItems="center" justifyContent="flex-end">
      <Flex
        mr="10px"
        style={{
          color: teamMembersState.color,
          fontFamily: 'Roboto Mono',
          fontSize: '14px',
          whiteSpace: 'nowrap',
        }}
      >
        {team.numberOfParticipants ? `${team.numberOfParticipants} Members` : null}
      </Flex>
      <Flex data-tip data-for={`visibility-${team.teamId}`}>
        {teamMembersState.icon}
      </Flex>
      <Tooltip id={`visibility-${team.teamId}`} place="right">
        {teamMembersState.tooltipMessage}
      </Tooltip>
    </Flex>
  );
}
