import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { useLocation } from 'react-router-dom';

const TabButton = styled(Flex)`
  align-items: center;
  font-family: Roboto Mono;
  font-size: 14px;
  position: relative;
  background-color: ${(props) => props.theme.color.navigationMenu.background.inactive};
  color: ${(props) => props.theme.color.navigationMenu.color.inactive};
  text-decoration: none;
  padding: 15px;
  border-bottom: 1px solid transparent;
  box-shadow: inset 3px 2px 20px 1px #00000029;
  opacity: 0.8;

  ${(props) =>
    props.active &&
    css`
      color: ${props.theme.color.navigationMenu.color.active};
      border-bottom-color: ${props.theme.color.navigationMenu.border.active};
      border-bottom: 1px solid;
      box-shadow: -1px -6px 3px #00000029;
      opacity: 1;
    `}

  &:hover,
  &:focus {
    border-bottom: 1px solid;
    color: ${(props) => props.theme.color.navigationMenu.color.active};
    border-bottom-color: ${(props) => props.theme.color.navigationMenu.border.active};
    cursor: pointer;
  }

  ${(props) =>
    props.fontColor &&
    css`
      color: ${props.fontColor};
    `}
`;

const NOT_FOUND_IDX = -1;
export default function TabScreen({ tabs = [], tabsContent = [] }) {
  const [activeScreenIdx, setActiveScreen] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const hashMatchIdx = tabs.findIndex((t) => t.hash === location.hash);
    if (hashMatchIdx !== NOT_FOUND_IDX) {
      setActiveScreen(hashMatchIdx);
    }
  }, [location.hash]);

  return (
    <Flex flexDirection="column">
      <Flex>
        {tabs.map((t, idx) => (
          <TabButton
            key={t.label}
            active={idx === activeScreenIdx}
            fontColor={t.fontColor}
            onClick={() => setActiveScreen(idx)}
          >
            {t.label}
          </TabButton>
        ))}
      </Flex>
      {tabsContent[activeScreenIdx]}
    </Flex>
  );
}
