import { SURVEY_QUESTIONS_TYPE, SURVEY_QUESTION_OPTION_OTHER } from './constants';

export const isEmptyQuestion = (question) => {
  if (
    question &&
    question.required &&
    ((question.type[0] !== SURVEY_QUESTIONS_TYPE.OPTIONS_AND_TEXT && !question.answer) ||
      (question.type[0] === SURVEY_QUESTIONS_TYPE.OPTIONS_AND_TEXT &&
        (!question.answer ||
          (question.answer.option === SURVEY_QUESTION_OPTION_OTHER && !question.answer.text))))
  ) {
    return true;
  }
  return false;
};

export const checkIsPendingQuestions = (answers) => {
  for (let x = 0; x < answers.length; x++) {
    if (isEmptyQuestion(answers[x])) {
      return true;
    }
  }
  return false;
};