import React from 'react';

export default function CrossIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="2 2 20 20">
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={props.color ? props.color : '#898989'}
        d="M12,10.586l4.95-4.95L18.364,7.05,13.414,12l4.95,4.95L16.95,18.364,12,13.414l-4.95,4.95L5.636,16.95,10.586,12,5.636,7.05,7.05,5.636Z"
      />
    </svg>
  );
}
