import React from 'react';
import Media from 'react-media';
import get from 'lodash/get';
import { Flex } from 'reflexbox';
import LandscapeCard from './cards/LandscapeCard';
import CardSkeleton from './cards/CardSkeleton';
import Card from './cards/Card';
import { EVENT_DELIVERY } from '../libs/constants';

export default function RestrictedEvents(props) {
  const { onCardClick, restrictedEvents, loadingRestrictedEvents } = props;

  return (
    <>
      <Media
        queries={{
          schemaSmall: '(max-width: 759px)',
          schema1Boxes: '(min-width: 1px) and (max-width: 948px)',
          schema2Boxes: '(min-width: 948px) and (max-width: 1420px)',
          schema3Boxes: '(min-width: 1421px) and (max-width: 1894px)',
          schema4Boxes: '(min-width: 1895px) and (max-width: 2368px)',
          schema5Boxes: '(min-width: 2369px)',
        }}
      >
        {(matches) => (
          <>
            {loadingRestrictedEvents ? (
              <CardSkeleton landscape={!matches.schema1Boxes} />
            ) : (
              <>
                {matches.schema1Boxes ? (
                  <>
                    <Flex
                      flexDirection="row"
                      justifyContent={matches.schemaSmall ? 'center' : 'flex-start'}
                      alignItems="flex-start"
                      flexWrap="wrap"
                      className="RestrictedEvents-Flex"
                    >
                      {restrictedEvents.map((e, idx) => {
                        const key = get(e, 'id', idx);
                        const title = get(e, 'name');
                        const showDate = e && e.delivery === EVENT_DELIVERY.NORMAL;
                        return (
                          <Flex
                            key={key}
                            style={{
                              minWidth: '330px',
                              padding: '15px',
                            }}
                          >
                            <Card
                              {...e}
                              title={title}
                              onCardClick={() => onCardClick(e)}
                              showDate={showDate}
                              restricted
                              matches={matches}
                              enrolled={get(e, 'participant.enrolled')}
                            />
                          </Flex>
                        );
                      })}
                    </Flex>
                  </>
                ) : (
                  <>
                    {restrictedEvents.map((re, idx) => {
                      const key = get(re, 'id', idx);
                      const title = get(re, 'name');
                      const showDate = re && re.delivery === EVENT_DELIVERY.NORMAL;
                      return (
                        <LandscapeCard
                          key={key}
                          {...re}
                          title={title}
                          onCardClick={() => onCardClick(re)}
                          showDate={showDate}
                          style={{ justifySelf: 'center' }}
                          matches={matches}
                          enrolled={get(re, 'participant.enrolled')}
                        />
                      );
                    })}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Media>
    </>
  );
}
