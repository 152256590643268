import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Flex } from 'reflexbox';
import { NavLink, useRouteMatch } from 'react-router-dom';
import ExpandIcon from '../../icons/ExpandIcon';
import CollapseIcon from '../../icons/CollapseIcon';

const Container = styled(Flex)`
  background-color: ${(props) => props.theme.color.navigationMenu.background.inactive};
  box-shadow: 0px 3px 6px #00000029;
  flex-direction: column;
  width: max-content;
  min-width: 150px;
  padding-bottom: ${(props) => props.theme.margin.xs};
  padding-top: ${(props) => props.theme.margin.xs};
  text-align: left;
  border-top: 1px solid ${(props) => props.theme.color.navigationMenu.border.active};
`;
Container.displayName = 'SubMenu-Container';

const Link = styled(NavLink)`
  font-family: ${(props) => props.theme.font.family};
  font-size: 14px;
  position: relative;
  background-color: ${(props) => props.theme.color.navigationMenu.background.inactive};
  color: ${(props) => props.theme.color.navigationMenu.color.inactive};
  text-decoration: none;
  padding: ${(props) => props.theme.margin.s};

  &.active {
    color: ${(props) => props.theme.color.navigationMenu.color.active};
  }

  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.color.navigationMenu.background.active};
    color: ${(props) => props.theme.color.navigationMenu.color.active};
  }
`;
Link.displayName = 'SubMenu-Link';

const Label = styled(Flex)`
  align-items: center;
  margin-right: 20px;
  font-family: ${(props) => props.theme.font.family};
  font-size: 14px;
  position: relative;
  background-color: ${(props) => props.theme.color.navigationMenu.background.inactive};
  color: ${(props) => props.theme.color.navigationMenu.color.inactive};
  text-decoration: none;
  padding-bottom: 15px;
  border-bottom: 1px solid transparent;

  &:focus {
    border-bottom: 1px solid;
    border-bottom-color: ${(props) => props.theme.color.navigationMenu.border.active};
    color: ${(props) => props.theme.color.navigationMenu.color.active};
    cursor: pointer;
  }

  ${(props) =>
    props.active &&
    css`
      border-bottom: 1px solid;
      border-bottom-color: ${props.theme.color.navigationMenu.border.active};
    `}

  ${(props) =>
    props.isHover &&
    css`
      border-bottom: 1px solid;
      border-bottom-color: ${props.theme.color.navigationMenu.border.active};
      color: ${props.theme.color.navigationMenu.color.active};
      cursor: pointer;
    `}
`;
Label.displayName = 'SubMenu-Label';

const LabelIcon = ({ isOpen, isHover }) => {
  const theme = useTheme();
  const iconColor = isHover
    ? theme.color.navigationMenu.color.active
    : theme.color.navigationMenu.color.inactive;

  return (
    <Flex ml="4px" alignItems="center">
      {isOpen ? <CollapseIcon color={iconColor} /> : <ExpandIcon color={iconColor} />}
    </Flex>
  );
};

export default function SubMenu({ items = [], label, rootPath = '', id = '' }) {
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [menuTop, setMenuTop] = useState('0px');
  const [hover, setHover] = useState(false);
  const matchRoute = useRouteMatch(rootPath);

  const onContextClick = (e) => {
    try {
      const rect = e.currentTarget.getBoundingClientRect();
      setMenuTop(`${rect.top + rect.height - 1}px`);
    } catch (err) {
      setMenuTop(e.pageY);
    }
    e.preventDefault();
    setOptionsVisible(true);
  };

  const closeMenu = () => {
    setOptionsVisible(false);
    setHover(false);
  };

  return (
    <Flex
      style={{ position: 'relative' }}
      onMouseLeave={closeMenu}
      onMouseEnter={() => setHover(true)}
      flexDirection="column"
    >
      <Label onClick={onContextClick} isHover={hover} active={matchRoute} id={id}>
        {label}
        <LabelIcon isOpen={optionsVisible} isHover={hover} />
      </Label>
      <Flex
        style={{
          position: 'fixed',
          top: menuTop,
          zIndex: 15,
        }}
        onClick={closeMenu}
        className={label}
      >
        {optionsVisible && (
          <Container>
            {items.map((sm) => (
              <Link to={sm.path} exact={sm.exact} key={sm.path} id={sm.id}>
                {sm.label}
              </Link>
            ))}
          </Container>
        )}
      </Flex>
    </Flex>
  );
}
