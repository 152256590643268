import React, { useState } from 'react';
import { Flex, Box } from 'reflexbox';
import truncate from 'lodash/truncate';
import { StyledText, FormLabel, Tooltip, CancelIcon, PencilIcon, InfoIcon, CheckIcon, TeamIcon } from 'boss-ui';
import FormInput from '../forms/FormInput';
import { TEAM_NAME_REGEXP } from '../../libs/constants';

export default function TeamNameBadge(props) {
  const [editionActive, activateEdition] = useState(false);
  const [saveHover, mouseHoverSave] = useState(false);
  const [cancelHover, mouseHoverCancel] = useState(false);
  const {
    name,
    editionAllowed,
    onNameChange,
    available,
    validating,
    onNameReset,
    onNameChanged,
    mobile,
  } = props;

  const validName = available && name.match(TEAM_NAME_REGEXP);

  return (
    <Flex alignItems={mobile ? 'flex-start' : 'center'} flexDirection={mobile ? 'column' : 'row'}>
      {!mobile && <TeamIcon size="100px" backgroundColor="#535353" />}
      {editionActive ? (
        <Box style={{ position: 'relative' }} width={mobile ? '90%' : '60%'}>
          <FormLabel>Team Name</FormLabel>
          <FormInput
            id="name"
            autoFocus
            placeholder="Enter a name for your team"
            value={name}
            onChange={onNameChange}
            validate={name}
            validating={validating}
            available={available}
            pattern={TEAM_NAME_REGEXP}
            unavailableMessage="This name was already taken!"
            invalidPatternMessage="From 3 to 30 length, spaces, a-z, 0-9 and _ allowed"
            validMessage="Team name is available"
          />
          <Flex
            data-tip
            data-for="name_info"
            style={{
              position: 'absolute',
              right: '-30px',
              top: '33px',
            }}
          >
            <InfoIcon />
          </Flex>
          <Tooltip id="name_info" place="right">
            The team name you choose will be visible to all other BOTS participants. You may wish to
            avoid identifying yourself or your organization by name! (3 characters at least,
            alphanumerics, spaces and underscores allowed)
          </Tooltip>
        </Box>
      ) : (
        <Flex ml="16px">
          <StyledText size="40px" light>
            {truncate(name, { length: 22, omission: '...' })}
          </StyledText>
        </Flex>
      )}
      {editionAllowed && (
        <Flex
          style={
            mobile
              ? {
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  paddingRight: '10%',
                  marginTop: '-30px',
                  zIndex: '1',
                }
              : { marginLeft: 'auto' }
          }
        >
          {editionActive ? (
            <Flex>
              <Flex
                onMouseLeave={() => mouseHoverSave(false)}
                onMouseEnter={() => mouseHoverSave(true)}
                onClick={() => {
                  if (validName && !validating) {
                    onNameChanged();
                    activateEdition(false);
                  }
                }}
              >
                <CheckIcon hover={saveHover} disabled={!validName} />
              </Flex>
              <Flex
                ml="10px"
                onMouseLeave={() => mouseHoverCancel(false)}
                onMouseEnter={() => mouseHoverCancel(true)}
                onClick={() => {
                  onNameReset();
                  activateEdition(false);
                }}
              >
                <CancelIcon hover={cancelHover} />
              </Flex>
            </Flex>
          ) : (
            <Flex
              onClick={() => activateEdition(true)}
              ml={mobile ? '10px' : ''}
              mt={mobile ? '-5px' : ''}
            >
              <PencilIcon />
            </Flex>
          )}
        </Flex>
      )}
    </Flex>
  );
}
