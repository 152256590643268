import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import config from './config';
import './index.css';
import App from './App';
import { refreshSAMLTokens } from './libs/cognito-lib';
import setupRum from './rum';

const version = require('../package.json').version;

const NO_HEADER_VALUE = 'not-set';
setupRum(config.rum);

const customHeaders = () => {
  const user = window.user || {
    email: NO_HEADER_VALUE,
    displayName: NO_HEADER_VALUE,
    userName: NO_HEADER_VALUE,
  };

  return {
    'client-version': version,
    'client-metadata': encodeURIComponent(
      JSON.stringify({
        email: user.email,
        displayName: user.displayName,
        userName: user.userName,
      })
    ),
  };
};

Amplify.configure({
  Auth: {
    mandatorySignIn: config.cognito.MANDATORY_SIGN_IN,
    region: config.cognito.REGION,
    ...(config.cognito.SAML_LOGIN
      ? {
          oauth: {
            domain: config.cognito.saml.DOMAIN,
            scope: config.cognito.saml.SCOPE,
            redirectSignIn: config.cognito.saml.REDIRECT_SIGN_IN,
            redirectSignOut: config.cognito.saml.REDIRECT_SIGN_OUT,
            responseType: config.cognito.saml.RESPONSE_TYPE,
          },
          userPoolId: config.cognito.saml.USER_POOL_ID,
          identityPoolId: config.cognito.saml.IDENTITY_POOL_ID,
          userPoolWebClientId: config.cognito.saml.APP_CLIENT_ID,
          refreshHandlers: {
            [`cognito-idp.${config.cognito.REGION}.amazonaws.com/${config.cognito.saml.USER_POOL_ID}`]:
              refreshSAMLTokens,
          },
        }
      : {
          userPoolId: config.cognito.USER_POOL_ID,
          identityPoolId: config.cognito.IDENTITY_POOL_ID,
          userPoolWebClientId: config.cognito.APP_CLIENT_ID,
        }),
  },
  API: {
    endpoints: [
      {
        name: 'events',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: customHeaders,
      },
      {
        name: 'teams',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: customHeaders,
      },
      {
        name: 'users',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: customHeaders,
      },
      {
        name: 'resources',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: customHeaders,
      },
      {
        name: 'workshops',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        custom_header: customHeaders,
      },
    ],
  },
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
);
