import React from 'react';

export default function CollapsableIcon(props) {
  return (
    <svg width={props.size || 12} height={props.size || 20} viewBox="0 0 12 20">
      <g transform="translate(0)">
        <rect fill="none" width="12" height="20" />
        <path
          fill={props.color || '#de935f'}
          d="M10.666,11.636,15.333,16.3,14,17.636l-6-6,6-6,1.333,1.333Z"
          transform="translate(17.636 -2) rotate(90)"
        />
      </g>
    </svg>
  );
}
