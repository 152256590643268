import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';

const RegularText = styled(Flex)`
  color: ${(props) => (props.color ? props.color : props.theme.color.text.regular.color)};
  ${(props) =>
    props.light &&
    css`
      color: ${props.theme.color.text.light.color};
    `}
  ${(props) =>
    props.medium &&
    css`
      color: ${props.theme.color.text.medium.color};
    `}
  ${(props) =>
    props.regular &&
    css`
      color: ${props.theme.color.text.regular.color};
    `}
  ${(props) =>
    props.align &&
    css`
      text-align: ${props.align};
    `}
  ${(props) =>
    props.breakAll &&
    css`
      word-break: break-all;
    `}
  font-family: ${(props) => props.theme.font.family};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => (props.mediumWeight ? 500 : props.weight)};
`;
RegularText.displayName = 'RegularText';

RegularText.defaultProps = {
  fontSize: '16px',
  weight: 400,
};
export default RegularText;
