import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';
import { RegularText } from 'boss-ui';
import { WarningIcon } from '../../icons';

const MainContainer = styled(Flex)`
  flexd-irection: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 400ms;
  opacity: ${(props) => props.question.opacity};
`;
MainContainer.displayName = 'QuestionContainer-MainContainer';

const SecondaryContainer = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
SecondaryContainer.displayName = 'QuestionContainer-SecondaryContainer';

const RequiredContainer = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  color: #e06c75;
`;
RequiredContainer.displayName = 'QuestionContainer-RequiredContainer';

const LineSep = styled(Flex)`
  border: 1px solid #2c2c2c;
  width: 100%;
  height: 1px;
  margin-top: 15px;
  margin-bottom: 15px;
`;
LineSep.displayName = 'QuestionContainer-LineSep';

const QuestionCount = styled(RegularText)`
  color: ${(props) => props.question.showQuestionWarning ? '#e06c75' : '#81C784'};
  letter-spacing: 0px;
`;
QuestionCount.displayName = 'QuestionContainer-QuestionCount';

const Star = styled(RegularText)`
  color: #e06c75;
  position: relative;
  top: -5px;
  left: 3px;
`;
Star.displayName = 'QuestionContainer-Star';

const QuestionTitle = styled(RegularText)`
  color: #D0D0D0;
  letter-spacing: 0px;
  margin-top: 8px;
  margin-bottom: 32px;
`;
QuestionTitle.displayName = 'QuestionContainer-QuestionTitle';

const NumericScaleQuestionContainer = styled(Flex)`
  margin-bottom: 12px;
  width: 100%;
`;
NumericScaleQuestionContainer.displayName = 'QuestionContainer-NumericScaleQuestionContainer';

const StarQuestionContainer = styled(Flex)`
  margin-bottom: 12px;
  width: 100%;
`;
StarQuestionContainer.displayName = 'QuestionContainer-StarQuestionContainer';

const SmileQuestionContainer = styled(Flex)`
  margin-bottom: 12px;
  width: 100%;
`;
SmileQuestionContainer.displayName = 'QuestionContainer-SmileQuestionContainer';

const NominalScaleQuestionContainer = styled(Flex)`
  margin-bottom: 12px;
  width: 100%;
  margin-left: -12px;
`;
NominalScaleQuestionContainer.displayName = 'QuestionContainer-NominalScaleQuestionContainer';

const MixScaleQuestionContainer = styled(Flex)`
  margin-bottom: 12px;
  width: 100%;
  margin-left: -12px;
`;
MixScaleQuestionContainer.displayName = 'QuestionContainer-MixScaleQuestionContainer';

const OpenQuestionContainer = styled(Flex)`
  width: 100%;
`;
OpenQuestionContainer.displayName = 'QuestionContainer-OpenQuestionContainer';

const WarningIconWrapper = styled(WarningIcon)`
  margin-right: 5px;
`;
WarningIconWrapper.displayName = 'QuestionContainer-WarningIconWrapper';

const Required = styled(RegularText)`
  color: #e06c75;
  font-size: 14px;
`;
Required.displayName = 'QuestionContainer-Required';

export {
  MainContainer,
  SecondaryContainer,
  RequiredContainer,
  LineSep,
  QuestionCount,
  Star,
  QuestionTitle,
  NumericScaleQuestionContainer,
  StarQuestionContainer,
  SmileQuestionContainer,
  NominalScaleQuestionContainer,
  MixScaleQuestionContainer,
  OpenQuestionContainer,
  WarningIconWrapper,
  Required
};