import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Flex, Box } from 'reflexbox';
import RegularText from '../RegularText';
import { EVENT_QUESTION_STATUS } from './constants';
import SpinnerIcon from '../../icons/SpinnerIcon';
import TrashCanIcon from '../../icons/TrashCanIcon';

const Container = styled(Flex)`
  width: 100%;
  height: 60px;
  background-color: ${(props) => props.theme.color.question_card.background.inactive};
  padding: ${(props) => props.theme.margin.s};
  border: 2px solid;
  margin-bottom: ${(props) => props.theme.margin.s};
  border-color: ${(props) => props.theme.color.question_card.border.pending};
  ${(props) =>
    props.correct &&
    css`
      border-color: ${props.theme.color.question_card.border.correct};
    `}
  ${(props) =>
    props.incorrect &&
    css`
      border-color: ${props.theme.color.question_card.border.incorrect};
    `}
  
  &:hover {
    background-color: ${(props) => props.theme.color.question_card.background.active};
    cursor: pointer;
  }
  ${(props) =>
    props.highLigted &&
    css`
      background-color: ${props.theme.color.question_card.background.active};
      cursor: default;
    `}
`;
Container.displayName = 'QuestionInLine-Container';

export default function QuestionInLine({
  question,
  answer = {},
  onSelectCard,
  selectedCard,
  points,
  isEditionView = false,
  removingQuestion = false,
  onRemoveQuestion = () => {},
  error = null,
}) {
  const [isCardHover, setIsCardHover] = useState(false);
  const { base_point_value, question_id, title } = question;
  const isCorrect = answer.state === EVENT_QUESTION_STATUS.CORRECT;

  const onRemoveQuestionClick = (e) => {
    e && e.stopPropagation();
    if (!removingQuestion) {
      onRemoveQuestion();
    }
  };

  return (
    <Container
      highLigted={selectedCard}
      correct={isCorrect}
      incorrect={answer.state === EVENT_QUESTION_STATUS.INCORRECT || error}
      onClick={onSelectCard}
      onMouseEnter={() => setIsCardHover(true)}
      onMouseLeave={() => setIsCardHover(false)}
    >
      <Flex alignItems="center" width={1}>
        <Box style={{ whiteSpace: 'nowrap' }}>
          <RegularText fontSize="24px" mediumWeight style={{ display: 'contents' }}>
            # {question_id}
          </RegularText>
        </Box>
        <Box
          ml="30px"
          mr="12px"
          style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}
          width="-webkit-fill-available"
        >
          <RegularText
            fontSize="20px"
            style={{
              textOverflow: 'ellipsis',
              textDecoration: isCardHover && 'underline solid 0.5px',
            }}
          >
            {title}
          </RegularText>
        </Box>
        {isEditionView ? (
          <Box ml="auto" onClick={onRemoveQuestionClick} width="24px">
            {removingQuestion ? (
              <SpinnerIcon size="30px" color="#ba0816" />
            ) : (
              isCardHover && <TrashCanIcon />
            )}
          </Box>
        ) : (
          <Box ml="auto" justifyContent="flex-end" width="70px">
            {isCorrect ? (
              <RegularText fontSize="20px" mediumWeight color="#81C784">
                {points.total}
              </RegularText>
            ) : (
              <RegularText fontSize="20px" mediumWeight>
                {base_point_value}
              </RegularText>
            )}
          </Box>
        )}
      </Flex>
    </Container>
  );
}
