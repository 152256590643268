import React from 'react';

export default function PrivateIcon(props) {
  return (
    <svg width={props.size || 30} height={props.size || 30} viewBox="10 12 30 20">
      <g transform="translate(9 6)">
        <g transform="matrix(1, 0, 0, 1, -9, -6)">
          <rect
            fill={props.inverted ? 'none' : '#535353'}
            width="32"
            height="32"
            transform="translate(9 6)"
          />
        </g>
        <g transform="translate(4 4)">
          <path
            fill={props.inverted ? '#535353' : '#1d1d1d'}
            d="M4.52,5.934,1.393,2.808,2.808,1.393l19.8,19.8-1.415,1.414-3.31-3.31A11,11,0,0,1,1.181,12a10.982,10.982,0,0,1,3.34-6.066ZM14.757,16.172l-1.464-1.464a3,3,0,0,1-4-4L7.828,9.243a5,5,0,0,0,6.929,6.929ZM7.974,3.76A11.01,11.01,0,0,1,22.819,12a10.947,10.947,0,0,1-2.012,4.592l-3.86-3.86a5,5,0,0,0-5.68-5.68L7.974,3.761Z"
          />
        </g>
      </g>
    </svg>
  );
}
