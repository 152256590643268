import React from 'react';
import { withTheme } from 'styled-components';
import { getDateComponents } from '../../libs/dates-lib';
import { RegularText } from 'boss-ui';

function CardDate({ date, theme, style }) {
  const dateObject = getDateComponents(date);
  return (
    <RegularText color={theme.color.text.light.color} {...style} mediumWeight>
      {`${dateObject.stringMonth.toUpperCase()}${dateObject.day},${dateObject.year}`}
    </RegularText>
  );
}

export default withTheme(CardDate);
