import React, { useState, useLayoutEffect } from 'react';
import { Box, Flex } from 'reflexbox';
import format from 'date-fns/format';
import {
  Button,
  ConfirmationModal,
  Input,
  Modal,
  RegularText,
  Table,
  TableBody,
  TableData,
  TableRow,
  UnregularButton,
} from 'boss-ui';
import { DEFAULT_BOX_BORDER_STYLE } from '../libs/constants';
import { useFormFields } from '../libs/hooks-lib';
import { getStyleMedia } from '../libs/team-lib';

const DEFAULT_POINTS_RECEIVER_LABEL = 'the team';
export default function DirectPointConceiver({
  onSend = () => {},
  pointsHistory = {},
  loading,
  receiverLabel = DEFAULT_POINTS_RECEIVER_LABEL,
  screenWidthSchema,
}) {
  const [fields, onFieldChange] = useFormFields({ reason: '', points: 0 });
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [activityModalVisible, setActivityModalVisible] = useState(false);

  const disableButtons = !Number(fields.points) || !fields.reason.length;

  const assignPoints = () => {
    setConfirmationModalVisible(false);
    onSend(fields);
  };

  useLayoutEffect(() => {
    // reset values after points request ends
    if (!loading) {
      onFieldChange({
        target: {
          value: 0,
          id: 'points',
        },
      });
      onFieldChange({
        target: {
          value: '',
          id: 'reason',
        },
      });
    }
  }, [loading]);

  return (
    <>
      <Flex mb="4px" alignItems="center">
        <RegularText fontSize="14px" mediumWeight>
          Point adjustment
        </RegularText>
        {pointsHistory && pointsHistory.activity && (
          <Flex ml="auto">
            <UnregularButton noPadding onClick={() => setActivityModalVisible(true)}>
              Activity
            </UnregularButton>
          </Flex>
        )}
      </Flex>
      <Flex
        style={DEFAULT_BOX_BORDER_STYLE}
        p="12px"
        flexDirection={getStyleMedia(screenWidthSchema).pointAdjustFlexDir}
        alignItems={getStyleMedia(screenWidthSchema).pointAdjustAlign}
      >
        <Box width="60px" mr="6px" py={getStyleMedia(screenWidthSchema).pointAdjustPy}>
          <Input
            value={fields.points}
            onChange={onFieldChange}
            height="28px"
            id="points"
            type="number"
          />
        </Box>
        <Box
          mr="6px"
          py={getStyleMedia(screenWidthSchema).pointAdjustPy}
          width={getStyleMedia(screenWidthSchema).pointAdjustAssignReasonWitdh}
        >
          <Input
            value={fields.reason}
            onChange={onFieldChange}
            placeholder="Enter a reason"
            height="28px"
            id="reason"
          />
        </Box>
        <Flex
          ml="auto"
          py={getStyleMedia(screenWidthSchema).pointAdjustPy}
          width={getStyleMedia(screenWidthSchema).pointAdjustAssignBtnWitdh}
        >
          <Button
            disabled={disableButtons || loading}
            onClick={() => setConfirmationModalVisible(true)}
          >
            ASSIGN
          </Button>
        </Flex>
      </Flex>
      {confirmationModalVisible && (
        <ConfirmationModal
          onCancel={() => setConfirmationModalVisible(false)}
          onAccept={assignPoints}
        >
          <RegularText>
            Are you sure applying {fields.points} points on{' '}
            {Number(fields.points) > 0 ? 'bonus' : 'penalty'} to {receiverLabel}?
          </RegularText>
        </ConfirmationModal>
      )}
      {activityModalVisible && (
        <Modal margin="10% auto auto auto" onBackgroundClick={() => setActivityModalVisible(false)}>
          <Flex
            px="40px"
            py="60px"
            width="640px"
            backgroundColor="#1D1D1D"
            flexDirection="column"
            maxHeight="460px"
          >
            <Flex height="0px">
              <Flex ml="auto" style={{ position: 'relative', top: '-40px', left: '30px' }}>
                <UnregularButton onClick={() => setActivityModalVisible(false)}>
                  Close Activity
                </UnregularButton>
              </Flex>
            </Flex>
            <RegularText fontSize="14px">Points Assigned History</RegularText>
            <Flex style={{ overflowY: 'auto' }} mt="6px" mb="24px">
              <Table>
                <TableBody>
                  {pointsHistory.activity.map((activity) => {
                    return (
                      <TableRow key={activity.description}>
                        <TableData>
                          <PointItem {...activity} />
                        </TableData>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Flex>
            <RegularText fontSize="14px">Total Points Assigned</RegularText>
            <Box style={DEFAULT_BOX_BORDER_STYLE} p="12px" mt="6px">
              <Flex mb="4px" alignItems="center">
                <RegularText fontSize="13px">Bonus: </RegularText>
                <RegularText fontSize="12px">{pointsHistory.total.bonus}</RegularText>
              </Flex>
              <Flex mb="4px" alignItems="center">
                <RegularText fontSize="13px">Penalty: </RegularText>
                <RegularText fontSize="12px">{pointsHistory.total.penalty}</RegularText>
              </Flex>
              <Flex mb="4px" alignItems="center">
                <RegularText fontSize="13px">Resultant Score: </RegularText>
                <RegularText fontSize="12px">{pointsHistory.total.total}</RegularText>
              </Flex>
            </Box>
          </Flex>
        </Modal>
      )}
    </>
  );
}

const PointItem = ({ score = {}, username, description, updatedAt = new Date() }) => {
  const isBonus = score.bonus > 0;
  return (
    <Box p="4px" style={{ whiteSpace: 'break-spaces' }}>
      <RegularText mediumWeight fontSize="13px" mb="6px">
        {isBonus ? 'BONUS' : 'PENALTY'}
      </RegularText>
      <Flex mb="4px" alignItems="center">
        <RegularText fontSize="13px">Assigned by: </RegularText>
        <RegularText fontSize="12px">{username}</RegularText>
      </Flex>
      <Flex mb="4px" alignItems="center">
        <RegularText fontSize="13px">Reason: </RegularText>
        <RegularText fontSize="12px">{description}</RegularText>
      </Flex>
      <Flex mb="4px" alignItems="center">
        <RegularText fontSize="13px">Time: </RegularText>
        <RegularText fontSize="12px">{format(new Date(updatedAt), 'PPpp')}</RegularText>
      </Flex>
      <Flex mb="4px" alignItems="center">
        <RegularText fontSize="13px">Assigned: </RegularText>
        <RegularText fontSize="12px">{isBonus ? score.bonus : score.penalty}</RegularText>
      </Flex>
    </Box>
  );
};
