import React from 'react';
import { Flex, Box } from 'reflexbox';
import { useTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { 
CopyClipboardIcon, 
QuestionCircleIcon, 
Tooltip, 
RegularText, 
LinkedText, 
ContextMenu, 
ContextMenuItem, 
CopyToClipboard,
} 
from 'boss-ui';
import { DEFAULT_BOX_BORDER_STYLE, RESOURCES_COLORS } from '../libs/constants';

const showCredentials = (res) => {
  if (
    res.username !== undefined &&
    res.username !== null &&
    res.username !== '' &&
    res.password !== undefined &&
    res.password !== null &&
    res.password !== ''
  ) {
    return true;
  }
  return false;
};

export default function ResourcesTable({
  loading = false,
  resources = [],
  ignoreTooltip = false,
  errorMsg = null,
  mobile,
}) {
  const SKELETON_STYLE = {
    COLOR: useTheme().color.skeleton.color,
    EFFECT: useTheme().color.skeleton.highlightColor,
  };  
  let resourceBlock = null;

  if (!loading && errorMsg && errorMsg !== null) {
    resourceBlock = (
      <Flex style={DEFAULT_BOX_BORDER_STYLE} p="8px" justifyContent="space-between">
        <RegularText mediumWeight fontSize="14px" color="#A0A0A0">
          {errorMsg}
        </RegularText>
      </Flex>
    );
  } else if (!loading && errorMsg === null && resources) {
    resourceBlock = resources.map((resource, idx) => {
      const showCredentialsInTable = showCredentials(resource);
      return (
        <Flex
          style={DEFAULT_BOX_BORDER_STYLE}
          p="8px"
          key={resource.name}
          className="ResourcesTable-resourceBox"
          flexDirection={mobile ? 'column' : 'row'}
          alignItems={mobile ? 'flex-end' : ''}
          width={1}
        >
          <RegularText
            width={1}
            mediumWeight
            fontSize="14px"
            color={RESOURCES_COLORS[idx]}
            textAlign="left"
            style={{ wordBreak: 'break-all' }}
          >
            <LinkedText onClick={() => window.open(resource.instance_url, '_blank')}>
              {resource.instance_url}
            </LinkedText>
          </RegularText>
          <Flex justifyContent="flex-end">
            {showCredentialsInTable ? (
              <Flex width={1}>
                {!mobile && (
                  <RegularText
                    mediumWeight
                    mr="10px"
                    fontSize="14px"
                    style={{
                      display: showCredentialsInTable ? '' : 'none',
                      wordBreak: 'break-all',
                    }}
                    color="#A5D6A7"
                    textAlign="right"
                    className="ResourcesTable-credentials"
                  >
                    {resource.username} / {resource.password}
                  </RegularText>
                )}
                <Flex width="35px">
                  <ContextMenu width="20px" mobile button={<CopyClipboardIcon />}>
                    <ContextMenuItem>
                      <Flex justifyContent="space-between" width={1}>
                        <RegularText mediumWeight fontSize="12px" light>
                          Username
                        </RegularText>
                        <CopyToClipboard value={resource.username} idx={idx} />
                      </Flex>
                    </ContextMenuItem>
                    <ContextMenuItem>
                      <Flex justifyContent="space-between" width={1}>
                        <RegularText mediumWeight fontSize="12px" light>
                          Password
                        </RegularText>
                        <CopyToClipboard value={resource.password} idx={idx} />
                      </Flex>
                    </ContextMenuItem>
                    <ContextMenuItem>
                      <Flex justifyContent="space-between" width={1}>
                        <RegularText mediumWeight fontSize="12px" light>
                          Url
                        </RegularText>
                        <CopyToClipboard value={resource.instance_url} idx={idx} />
                      </Flex>
                    </ContextMenuItem>
                  </ContextMenu>
                </Flex>
              </Flex>
            ) : (
              <Flex width="33px">
                <CopyToClipboard idx={idx} mobile={mobile} />
              </Flex>
            )}
          </Flex>
        </Flex>
      );
    });
  }

  return (
    <>
      <Flex mb="4px" alignItems="center">
        {!ignoreTooltip && (
          <>
            <Flex ml="6px" data-tip data-for="resources">
              <QuestionCircleIcon size="20px" />
            </Flex>
            <Tooltip id="resources" place="right">
              Use these resources to complete the scenario. In light green the username/password to
              login to the server.
            </Tooltip>
          </>
        )}
      </Flex>
      <Box>
        {loading ? (
          <SkeletonTheme color={SKELETON_STYLE.COLOR} highlightColor={SKELETON_STYLE.EFFECT}>
            <Skeleton count={3} />
          </SkeletonTheme>
        ) : (
          resourceBlock
        )}
      </Box>
    </>
  );
}
