import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useQuery } from '../../hooks';

export default function UnauthenticatedRoute({ children, validateSession = () => true, ...rest }) {
  const query = useQuery();
  const redirectRoute = query.get('redirect');

  return (
    <Route {...rest}>
      {!validateSession() ? (
        children
      ) : (
        <Redirect to={redirectRoute === '' || redirectRoute === null ? '/' : redirectRoute} />
      )}
    </Route>
  );
}
