import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import Button from '../Button';
import Modal from '../Modal';

const Background = styled(Flex)`
  background-color: ${(props) => props.theme.color.form.background};
  padding-top: ${(props) => props.theme.margin.m};
  padding-bottom: ${(props) => props.theme.margin.s};
  padding-left: ${(props) => props.theme.margin.s};
  padding-right: ${(props) => props.theme.margin.s};
`;

const DEFAULT_ACCEPT_LABEL = 'ACCEPT';
const DEFAULT_CANCEL_LABEL = 'CANCEL';

function ConfirmationModal(props) {
  const {
    onAccept,
    onCancel,
    children,
    loading,
    acceptLabel,
    cancelLabel,
    dangerDialog = false,
  } = props;
  return (
    <Modal active={!dangerDialog}>
      <Background justifyContent="center" flexDirection="column">
        {children}
        <Flex justifyContent="space-around" mt="40px">
          <Flex width={1 / 3}>
            <Button onClick={onCancel} secondary className="ConfirmarionModal-cancel">
              {cancelLabel || DEFAULT_CANCEL_LABEL}
            </Button>
          </Flex>
          <Flex width={1 / 3}>
            <Button
              onClick={onAccept}
              disabled={loading}
              danger={dangerDialog}
              className="ConfirmationModal-accept"
            >
              {acceptLabel || DEFAULT_ACCEPT_LABEL}
            </Button>
          </Flex>
        </Flex>
      </Background>
    </Modal>
  );
}
ConfirmationModal.displayName = 'ConfirmationModal';

export default ConfirmationModal;
