import React from 'react';
import { Flex } from 'reflexbox';
import Media from 'react-media';
import { QuestionInLine } from 'boss-ui';
import QuestionBigCard from './QuestionBigCard';

export default function ScenarioQuestions({
  questions,
  onQuestionCardClick = () => {},
  onQuestionCardClosed = () => {},
  onQuestionRemoved = () => {},
  focusedQuestionId,
  removingQuestionId,
  questionNavigation,
  isEditionView = false,
}) {
  return (
    <Media
      queries={{
        s: '(max-width: 1000px)',
        m: '(max-width: 1290px)',
        l: '(max-width: 1540px)',
        xl: '(min-width: 1880px)',
      }}
    >
      {(matches) => (
        <Flex width={1} flexDirection="column">
          {questions.map((q, idx) => {
            const isSelected = focusedQuestionId === q.question.question_id;
            const isQuestionBeingRemoving = removingQuestionId === q.question.question_id;
            return (
              <React.Fragment key={q.question.question_id}>
                <QuestionInLine
                  selectedCard={isSelected}
                  question={q.question}
                  answer={q.answer}
                  points={q.total}
                  onSelectCard={() => onQuestionCardClick(q.question)}
                  isEditionView={isEditionView}
                  onRemoveQuestion={() => onQuestionRemoved(q.question.question_id)}
                  removingQuestion={isQuestionBeingRemoving}
                />
                {isSelected &&
                  <QuestionBigCard
                    question={q.question}
                    answer={q.answer}
                    points={q.total}
                    media={matches}
                    onCloseCard={onQuestionCardClosed}
                    gridNavigation={questionNavigation(idx)}
                  />
                }
              </React.Fragment>
            );
          })}
        </Flex>
      )}
    </Media>
  );
}
