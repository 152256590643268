import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

const ToastContainerNotification = styled(ToastContainer)`
  .Toastify__toast-container {
  }
  .Toastify__toast {
    background: ${(props) => props.theme.color.toastNotification.toast.background};
    box-shadow: 0px 4px 10px ${(props) => props.theme.color.toastNotification.toast.boxShadow};
  }
  .Toastify__toast--error {
    justify-content: center;
  }
  .Toastify__toast--warning {
  }
  .Toastify__toast--success {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
    background: ${(props) => props.theme.color.toastNotification.progressBar.background};
  }
`;

export default ToastContainerNotification;
