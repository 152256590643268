import styled from 'styled-components';

const Form = styled.form`
  background: ${(props) => props.theme.color.form.background} 0% 0% no-repeat padding-box;
  border: ${(props) => (props.noBorder ? '' : `2px solid ${props.theme.color.form.border.main}`)};
  filter: ${(props) => (props.refreshing ? 'blur(1px)' : 'none')};
  height: ${(props) => props.height};
`;

Form.displayName = 'Form';

export default Form;
