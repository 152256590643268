import React, { useEffect, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { useMarked } from '../../hooks';
import './index.css';

const CreditsContainer = styled(Flex)`
  position: relative;
  justify-content: center;
  height: 80vh;
  font-family: 'Droid Sans', arial, verdana, sans-serif;
  font-weight: 700;
  color: #ff6;
  background-color: #000;
  overflow: hidden;
`;

const Title = styled.h1`
  position: absolute;
  width: 2.6em;
  left: 50%;
  top: 25%;
  font-size: 10em;
  text-align: center;
  margin-left: -1.3em;
  line-height: 0.8em;
  letter-spacing: -0.05em;
  color: #000;
  text-shadow: -2px -2px 0 #ff6, 2px -2px 0 #ff6, -2px 2px 0 #ff6, 2px 2px 0 #ff6;
  opacity: 0;
  z-index: 1;
  -webkit-animation: logo 5s ease-out 5s;
  -moz-animation: logo 5s ease-out 5s;
  -ms-animation: logo 5s ease-out 5s;
  -o-animation: logo 5s ease-out 5s;
  animation: logo 5s ease-out 5s;
`;

const CreditsLine = styled(Flex)`
  position: absolute;
  display: flex;
  height: 250%;
  bottom: 12%;
  font-size: 44px;
  text-align: justify;
  overflow: hidden;
  -webkit-transform-origin: 50% 100%;
  -moz-transform-origin: 50% 100%;
  -ms-transform-origin: 50% 100%;
  -o-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  -webkit-transform: perspective(350px) rotateX(25deg);
  -moz-transform: perspective(350px) rotateX(25deg);
  -ms-transform: perspective(350px) rotateX(25deg);
  -o-transform: perspective(350px) rotateX(25deg);
  transform: perspective(350px) rotateX(25deg);
  &::after {
    position: absolute;
    content: ' ';
    left: 0;
    right: 0;
    top: 0;
    bottom: 60%;
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 1) 0%, transparent 100%);
    background-image: -moz-linear-gradient(top, rgba(0, 0, 0, 1) 0%, transparent 100%);
    background-image: -ms-linear-gradient(top, rgba(0, 0, 0, 1) 0%, transparent 100%);
    background-image: -o-linear-gradient(top, rgba(0, 0, 0, 1) 0%, transparent 100%);
    background-image: linear-gradient(top, rgba(0, 0, 0, 1) 0%, transparent 100%);
    pointer-events: none;
  }
  p {
    text-align: justify;
    margin: 0.8em 0;
  }
  p.center {
    text-align: center;
  }
  a {
    color: #ff6;
    text-decoration: underline;
  }
`;

const StartLabel = styled.div`
  align-self: center;
  width: 16em;
  font-size: 200%;
  font-weight: 400;
  color: #4ee;
  opacity: 0;
  z-index: 1;
  -webkit-animation: intro 4s ease-out;
  -moz-animation: intro 4s ease-out;
  -ms-animation: intro 4s ease-out;
  -o-animation: intro 4s ease-out;
  animation: intro 4s ease-out;
`;

const TitleContent = styled.div`
  position: absolute;
  top: 100%;
  -webkit-animation: scroll 90s linear 6s infinite;
  -moz-animation: scroll 90s linear 6s infinite;
  -ms-animation: scroll 90s linear 6s infinite;
  -o-animation: scroll 90s linear 6s infinite;
  animation: scroll 90s linear 6s infinite;
`;

export default function Credits({ contentUrl = '', title = '' }) {
  const [creditsMd, setCreditsMd] = useState('');
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    const fetchCredits = async () => {
      try {
        const rq = await fetch(`https://${contentUrl}`);
        if (rq.ok) {
          setCreditsMd(await rq.text());
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchCredits();
  });

  useLayoutEffect(() => {
    setContainerWidth(window.innerWidth * 0.9);
  }, []);

  const creditsText = useMarked(creditsMd);

  return (
    <CreditsContainer width={`${containerWidth}px`}>
      <StartLabel>A short time ago in a Splunk instance very, very close&hellip;</StartLabel>
      <Title>{title}</Title>
      <CreditsLine width={`${containerWidth * 0.7}px`}>
        <TitleContent
          dangerouslySetInnerHTML={{
            __html: creditsText,
          }}
        />
      </CreditsLine>
    </CreditsContainer>
  );
}
