import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';
import { Tooltip, GameIcon } from 'boss-ui';
import { InviteOnlyIcon } from '../icons';
import { getImageURLSized } from '../../libs/utils-lib';
import CardDescription from './CardDescription';
import CardIconContainer from './CardIconContainer';
import CardTitle from './CardTitle';
import CardImage from './CardImage';
import CardDate from './CardDate';
import BossLabel from '../BossLabel';
import EventLabel from '../EventLabel';
import { isTodayBetween } from '../../libs/dates-lib';
import { getNoImageUrl, isNormalEventInsideActiveWindow } from '../../libs/event-lib';

const MainContainer = styled(Flex)`
  font-family: 'Roboto Mono';
  font-weight: normal;
  background-color: ${(props) => props.theme.color.card.classic.background.inactive};
  border: 1px solid ${(props) => props.theme.color.card.restricted.border};
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
MainContainer.displayName = 'LandscapeCard-MainContainer';

const Container = styled(Flex)`
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`;
Container.displayName = 'LandscapeCard-Container';

const ContainerBottom = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 62px;
  background-color: #121212;
  padding-left: 20px;
`;
Container.displayName = 'LandscapeCard-ContainerBottom';

const CardBody = styled(Flex)`
  &:hover {
    background-color: ${(props) => props.theme.color.card.classic.background.hover};
    cursor: pointer;
  }
  padding: ${(props) => props.theme.margin.s};
  padding-top: 0px;
  background-color: #1d1d1d;
`;
CardBody.displayName = 'LandscapeCard-CardBody';

const calculateDescriptionLines = (matches) => {
  if (!matches.schema3Boxes && !matches.schema4Boxes && !matches.schema5Boxes) {
    return 3;
  }
  return 4;
};

const getWidthSchema = (matches) => {
  if (matches.schema2Boxes) {
    return '690px';
  }
  if (matches.schema3Boxes) {
    return '1050px';
  }
  if (matches.schema4Boxes) {
    return '1415px';
  }
  if (matches.schema5Boxes) {
    return '1770px';
  }
  return '1050px';
};

/**
 * @param  {String} imageURL image url of card
 * @param  {String} type SOC/NOC for labels
 * @param  {String} title card title
 * @param  {String} description card description
 * @param  {function} onCardClick on click function
 * @param  {String} activeStartDate event active start date
 * @param  {String} startDate event start date
 * @param  {String} activeEndDate event active end date
 * @param  {String} registrationClosedDate event registration end date
 * @param  {String} registrationOpenDate event registration start date
 * @param  {String} registrationStatus OPEN / CLOSED
 * @param  {boolean} showDate
 * @param  {String} id event id
 * @param  {object} matches width schema
 * @param  {Boolean} enrolled
 */
export default function LandscapeCard(props) {
  const {
    imageURL,
    type,
    title,
    description,
    onCardClick,
    activeStartDate,
    startDate,
    activeEndDate,
    registrationClosedDate,
    registrationOpenDate,
    registrationStatus,
    showDate,
    id,
    matches,
    enrolled = false,
  } = props;
  const activeEvent = isTodayBetween(new Date(activeStartDate), new Date(activeEndDate));
  const imgSize = 'small';
  const imageSrc = getImageURLSized(imageURL, imgSize);
  const imageType = type;
  const isEventRunning = isNormalEventInsideActiveWindow(
    activeStartDate,
    activeEndDate,
    registrationOpenDate,
    registrationClosedDate,
    registrationStatus
  );

  useEffect(() => {
    if (!title && !description) {
      throw new Error('missing title/description');
    }
  });

  return (
    <MainContainer
      maxWidth={getWidthSchema(matches)}
      flexDirection="column"
      onClick={onCardClick}
      className="LandscapeCard-Container"
      active={activeEvent}
      mt="10px"
      mb="10px"
    >
      <Container>
        <CardImage
          src={imageSrc}
          alt="card image"
          onError={(e) => {
            e.target.src = getNoImageUrl(imageType, imgSize);
          }}
          style={{ marginTop: '-1px', marginLeft: '-1px' }}
        />
        <CardBody alignItems="start" flexDirection="column" height="185px">
          <div style={{ display: 'content' }}>
            <CardTitle restricted active={activeEvent} id={id}>
              {title}
            </CardTitle>
          </div>
          <Flex>
            <CardDescription restricted climpAfterLine={calculateDescriptionLines(matches)}>
              {description}
            </CardDescription>
          </Flex>
        </CardBody>
      </Container>
      <ContainerBottom>
        <Flex width="50%">
          <CardIconContainer>
            <GameIcon />
          </CardIconContainer>
          <Flex data-tip data-for="inviteOnlyIcon" px="8px">
            <InviteOnlyIcon />
          </Flex>
          <Tooltip id="inviteOnlyIcon">INVITE ONLY</Tooltip>
          <BossLabel type={type} ml="6px" fontSize="18px" />
          {showDate && startDate && (
            <Flex pl="55px">
              <CardDate date={startDate} style={{ color: '#D0D0D0' }} />
            </Flex>
          )}
        </Flex>
        <Flex
          width="50%"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          mt="-65px"
        >
          <EventLabel enrolled={enrolled} active={isEventRunning}>
            {enrolled ? 'Enrolled' : 'Private Event'}
          </EventLabel>
        </Flex>
      </ContainerBottom>
    </MainContainer>
  );
}
