import React, { useState, useMemo } from 'react';
import { Box, Flex } from 'reflexbox';
import { DEFAULT_BOX_BORDER_STYLE } from '../../libs/constants';
import { ConfirmationModal, Tooltip, RegularText, UnregularButton, InfoIcon } from 'boss-ui';

export default function BuyBasedHint({ hints = [], totalHints, onBuyHint, active = true }) {
  const [hintBuyInformation, setHintBuyInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const numberVisibleHints = useMemo(
    () => hints.reduce((sum, hint) => (hint.hint ? sum + 1 : sum), 0),
    [hints]
  );

  const buyHint = async () => {
    setLoading(true);
    await onBuyHint(hintBuyInformation.hint_id);
    setHintBuyInformation(null);
    setLoading(false);
  };

  return (
    <>
      {hintBuyInformation && (
        <ConfirmationModal
          onAccept={buyHint}
          onCancel={() => setHintBuyInformation(null)}
          loading={loading}
          acceptLabel="PURCHASE"
        >
          <Flex width="300px" alignItems="center" flexDirection="column">
            <RegularText light>Purchase hint?</RegularText>
            <RegularText light>Cost: {hintBuyInformation.hint_cost}</RegularText>
          </Flex>
        </ConfirmationModal>
      )}
      <Flex mb="4px">
        <RegularText mediumWeight fontSize="14px">
          {`Hints purchased [${numberVisibleHints} / ${totalHints}]`}
        </RegularText>
        <Flex data-tip data-for="hints" ml="6px">
          <InfoIcon />
          <Tooltip id="hints">Buy hints using your team points..</Tooltip>
        </Flex>
      </Flex>
      {hints.map((hint) => (
        <Flex width={1} key={hint.hint_id}>
          <Box p="10px" style={DEFAULT_BOX_BORDER_STYLE} width={1 / 5}>
            <RegularText color={hint.hint ? '#E06C75' : '#D0D0D0'} fontSize="14px">
              - {hint.hint_cost} points
            </RegularText>
          </Box>
          <Box p="10px" style={DEFAULT_BOX_BORDER_STYLE} width={4 / 5}>
            <RegularText fontSize="14px" color="#E5C07B">
              {hint.hint ? (
                hint.hint
              ) : (
                <UnregularButton
                  onClick={() => {
                    setHintBuyInformation(hint);
                  }}
                  disabled={!active}
                >
                  Purchase Hint
                </UnregularButton>
              )}
            </RegularText>
          </Box>
        </Flex>
      ))}
    </>
  );
}
