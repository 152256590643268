import React from 'react';
import styled, { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';

const CustomTooltip = (props) => {
  const theme = useTheme();

  return (
    <ReactTooltip
      textColor={theme.color.tooltip.textColor}
      backgroundColor={theme.color.tooltip.backgroundColor}
      borderColor={theme.color.tooltip.borderColor}
      arrowColor={theme.color.tooltip.arrowColor}
      border
      {...props}
    />
  );
};

const Tooltip = styled(CustomTooltip)`
  box-shadow: 0px 3px 6px #00000033 !important;
  font-family: ${(props) => props.theme.font.family};
  font-size: 12px;
  max-width: 340px;
`;
Tooltip.displayName = 'Tooltip';

export default Tooltip;
