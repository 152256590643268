import React from 'react';
import styled, { css } from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '24px';

const AddSVG = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: #373737;

  &:hover {
    fill: #66bb6a;
  }

  ${(props) =>
    props.hover &&
    css`
      fill: #66bb6a;
    `}
`;
AddSVG.displayName = 'AddIcon-AddSVG';

export default function AddIcon(props) {
  return (
    <AddSVG viewBox="0 0 512 512" {...props}>
      <g>
        <g>
          <path
            d="M256,0C114.84,0,0,114.84,0,256s114.84,256,256,256s256-114.84,256-256S397.16,0,256,0z M256,475.429
			c-120.997,0-219.429-98.432-219.429-219.429S135.003,36.571,256,36.571S475.429,135.003,475.429,256S376.997,475.429,256,475.429z
			"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M256,134.095c-10.1,0-18.286,8.186-18.286,18.286v207.238c0,10.1,8.186,18.286,18.286,18.286
			c10.1,0,18.286-8.186,18.286-18.286V152.381C274.286,142.281,266.1,134.095,256,134.095z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M359.619,237.714H152.381c-10.1,0-18.286,8.186-18.286,18.286c0,10.1,8.186,18.286,18.286,18.286h207.238
			c10.1,0,18.286-8.186,18.286-18.286C377.905,245.9,369.719,237.714,359.619,237.714z"
          />
        </g>
      </g>
    </AddSVG>
  );
}
