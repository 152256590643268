import compareAsc from 'date-fns/compareAsc';
import format from 'date-fns/format';
import { isDate } from 'lodash';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { getNewDate } from './utils-lib';

const MINUTES_IN_HOUR = 60;
const DAYS_IN_A_YEAR = 365;
const DAYS_IN_A_MONTH = 30;
const HOURS_IN_A_DAY = 24;

export const isTodayBetween = (start, end) => {
  if (!start || !end) {
    return false;
  }
  if (!isDate(start)) {
    start = new Date(start);
  }
  if (!isDate(end)) {
    end = new Date(end);
  }
  const today = getNewDate();
  if (compareAsc(start, today) === 1) {
    return false;
  }
  if (compareAsc(end, today) === -1) {
    return false;
  }
  return true;
};

export const isTodayAfterThan = (myDate) => {
  const today = new Date();
  if (!isDate(myDate)) {
    myDate = new Date(myDate);
  }
  if (compareAsc(today, myDate) === -1) {
    return false;
  }
  return true;
};

export const getDateComponents = (date) => {
  const d = new Date(date);
  const stringMonth = format(d, 'MMM');
  return {
    day: d.getDate(),
    month: d.getMonth() + 1,
    year: d.getFullYear(),
    stringMonth,
  };
};

export const formatMinutes = (minutesDiff) => {
  return {
    minutes: minutesDiff % MINUTES_IN_HOUR,
    hour: Math.floor(minutesDiff / MINUTES_IN_HOUR),
  };
};

export const getHourMinuteDifference = (start, end) => {
  if (!start || !end) {
    return false;
  }
  if (!isDate(start)) {
    start = new Date(start);
  }
  if (!isDate(end)) {
    end = new Date(end);
  }
  return formatMinutes(differenceInMinutes(start, end));
};

export const getIntervalInDays = ({ years = 0, months = 0, days = 0, hours = 0 }) => {
  let accumulatedDays = days;
  if (years > 0) {
    accumulatedDays += DAYS_IN_A_YEAR * years;
  }
  if (months > 0) {
    accumulatedDays += DAYS_IN_A_MONTH * months;
  }
  if (hours > HOURS_IN_A_DAY / 2) {
    accumulatedDays += 1;
  }
  return accumulatedDays;
};

export function getCurrentYear() {
  return new Date().getFullYear();
}