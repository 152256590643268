import React from 'react';
import { Flex } from 'reflexbox';
import styled, { css } from 'styled-components';
import { RegularText } from 'boss-ui';

const DEFAULT_NUMERIC_SCALE = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const NumberCircle = styled(Flex)`
  color: #898989;
  border: 2px solid #373737;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto Mono';
  font-size: 14px;
  padding-left: 1px;
  ${(props) =>
    props.filled &&
    css`
      color: #1d1d1d;
      background-color: #66bb6a;
      border-color: #66bb6a;
    `}
  &:hover {
    cursor: pointer;
    background-color: #2c2c2c;
  }
`;

export default function NumericScaleQuestion({
  value,
  options = DEFAULT_NUMERIC_SCALE,
  onChange = () => {},
}) {
  return (
    <Flex width="600px" justifyContent="space-evenly" alignItems="center">
      <RegularText color="#A5D6A7" mediumWeight fontSize="14px">
        Worst
      </RegularText>
      {options.map((o) => (
        <NumberCircle key={o} filled={value === o} onClick={() => onChange(o)}>
          {o}
        </NumberCircle>
      ))}
      <RegularText color="#A5D6A7" mediumWeight fontSize="14px">
        Best
      </RegularText>
    </Flex>
  );
}
