/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import styled from 'styled-components';
import { Flex } from 'reflexbox';

const SwitchBackgorund = styled(Flex)`
  width: 150px;
  cursor: pointer;
  height: 28px;
  background-color: ${(props) => (props.checked ? '#81C784' : '#FFCC66')};
  font-family: Roboto Mono;
  letter-spacing: 0px;
  color: #1d1d1d;
`;
SwitchBackgorund.displayName = 'SwitchButton-SwitchBackgorund';

const SwitchIcon = styled.div`
  width: 30px;
  height: 30px;
  top: -29px;
  cursor: pointer;
  left: ${(props) => (!props.checked ? '0px' : '120px')};
  position: relative;
  z-index: 1;
  transition: all 300ms ease;
`;
SwitchIcon.displayName = 'SwitchButton-SwitchIcon';

function SwitchButton(props) {
  const { checked, handleToggle, id, onIcon, offIcon, onText, offText } = props;
  const changeInput = () => {
    handleToggle({
      target: {
        value: !checked,
        id,
      },
    });
  };
  return (
    <div onClick={changeInput} aria-hidden>
      <SwitchBackgorund checked={checked} alignItems="center" justifyContent="center" mt="6px">
        {checked ? onText : offText}
      </SwitchBackgorund>
      <SwitchIcon checked={checked}>{checked ? onIcon : offIcon}</SwitchIcon>
    </div>
  );
}
export default SwitchButton;
