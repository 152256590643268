import React from 'react';
import { useTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import TableRow from '../TableRow';
import TableData from '../TableData';

export default function TableItemSkeleton({ numberOfCells = 1, height = '25px' }) {
  const theme = useTheme();

  return (
    <TableRow>
      <TableData colspan={numberOfCells}>
        <SkeletonTheme
          color={theme.color.skeleton.color}
          highlightColor={theme.color.skeleton.highlightColor}
        >
          <Skeleton height={height} />
        </SkeletonTheme>
      </TableData>
    </TableRow>
  );
}
