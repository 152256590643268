import React, { useState } from 'react';
import { Flex } from 'reflexbox';
import styled from 'styled-components';
import TableData from '../TableData';
import TableRow from '../TableRow';
import PencilIcon from '../../icons/PencilIcon';
import TrashCanIcon from '../../icons/TrashCanIcon';
import TableItemSkeleton from '../TableItemSkeleton';
import EditableInput from '../EditableInput';
import { Checkbox } from 'boss-ui';

const HovereableFlex = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;

export default function EditableTableData({ itemData, itemRegExp, isChecked, onItemDelete, onItemChanged, onSelectionChange }) {
  const [editionActive, setActiveEdition] = useState(false);
  const [loadingItem, setLoadingItem] = useState(false);

  if (loadingItem) {
    return <TableItemSkeleton numberOfCells={3} />;
  }

  const onItemDeleteClicked = (...args) => {
    setLoadingItem(true);
    onItemDelete(...args);
  };

  const onItemEdited = (...args) => {
    setLoadingItem(true);
    onItemChanged(...args);
  };

  if (editionActive) {
    return (
      <TableRow>
        <TableData colspan={3}>
          <EditableInput
            valueObject={itemData}
            edit
            onValueChanged={onItemEdited}
            setEditionActive={setActiveEdition}
            matchRegExp={itemRegExp}
          />
        </TableData>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableData width="20px" style={{ paddingLeft: '10px' }}>
        <label>
          <Checkbox checked={isChecked} onChange={onSelectionChange} />
        </label>
      </TableData>
      <TableData>
        <EditableInput
          valueObject={itemData}
          onValueChanged={onItemEdited}
          setEditionActive={setActiveEdition}
          matchRegExp={itemRegExp}
        />
      </TableData>
      <TableData>
        <Flex alignItems="center" width={1} justifyContent="flex-end">
          <HovereableFlex onClick={() => setActiveEdition(true)}>
            <PencilIcon fill="#898989" size="18px" />
          </HovereableFlex>
          <HovereableFlex ml="8px" onClick={onItemDeleteClicked}>
            <TrashCanIcon size="22px" />
          </HovereableFlex>
        </Flex>
      </TableData>
    </TableRow>
  );
}
