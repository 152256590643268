import React from 'react';
import { Flex, Box } from 'reflexbox';
import format from 'date-fns/format';
import { RegularText } from 'boss-ui';

export default function QuestionAnswerItem({ text, createdAt, state, username }) {
  return (
    <Box p="4px" style={{ whiteSpace: 'break-spaces' }}>
      <Flex mb="6px" alignItems="center">
        <RegularText mediumWeight fontSize="13px">
          Time:{' '}
        </RegularText>
        <RegularText fontSize="12px">{format(new Date(createdAt), 'PPpp')}</RegularText>
      </Flex>
      <Flex mb="6px" alignItems="center">
        <RegularText mediumWeight fontSize="13px">
          User:{' '}
        </RegularText>
        <RegularText fontSize="12px">{username}</RegularText>
      </Flex>
      <Flex mb="6px" alignItems="start">
        <RegularText mediumWeight fontSize="13px" minWidth="68px">
          Answer:{' '}
        </RegularText>
        <RegularText fontSize="12px" style={{ wordBreak: 'break-all' }}>
          {text}
        </RegularText>
      </Flex>
      <Flex mb="6px" alignItems="center">
        <RegularText mediumWeight fontSize="13px">
          Result:{' '}
        </RegularText>
        <RegularText fontSize="12px">{state}</RegularText>
      </Flex>
    </Box>
  );
}
