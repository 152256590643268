import React from 'react';
import styled, { css } from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '24px';

const MinusSVG = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: #373737;

  &:hover {
    fill: #e06c75;
  }

  ${(props) =>
    props.hover &&
    css`
      fill: #e06c75;
    `}
`;
MinusSVG.displayName = 'MinusIcon-MinusSVG';

export default function MinusIcon(props) {
  return (
    <MinusSVG viewBox="0 0 512 512" {...props}>
      <path d="m256 512c-141.164062 0-256-114.835938-256-256s114.835938-256 256-256 256 114.835938 256 256-114.835938 256-256 256zm0-480c-123.519531 0-224 100.480469-224 224s100.480469 224 224 224 224-100.480469 224-224-100.480469-224-224-224zm0 0" />
      <path d="m368 272h-224c-8.832031 0-16-7.167969-16-16s7.167969-16 16-16h224c8.832031 0 16 7.167969 16 16s-7.167969 16-16 16zm0 0" />
    </MinusSVG>
  );
}
