import React from 'react';

const WIDTH = 30;
const HEIGHT = 40;
function BotsIcon(props) {
  return (
    <svg
      width={props.size * (2 / 3) || WIDTH}
      height={props.size || HEIGHT}
      viewBox="0 0 308.8658752 384.1937561"
    >
      <g id="BOTS">
        <g id="Shield_17_">
          <linearGradient
            id="SVGID_1_"
            gradientUnits="userSpaceOnUse"
            x1="44.59478"
            y1="289.3757935"
            x2="288.3210449"
            y2="38.7516212"
          >
            <stop offset="0%" style={{ stopColor: '#ED0080' }} />
            <stop offset="6%" style={{ stopColor: '#ED0D72' }} />
            <stop offset="34%" style={{ stopColor: '#EF4538' }} />
            <stop offset="47%" style={{ stopColor: '#F05A22' }} />
            <stop offset="99%" style={{ stopColor: '#F99D1C' }} />
          </linearGradient>
          <path
            style={{ fill: 'url(#SVGID_1_)' }}
            d="M154.1914215,384.1937561c-4.8541565,0-9.4589844-4.3339844-9.4589844-4.3339844
			c-8.5078125-7.7949219-27.5136719-14.9316406-44.2822266-21.2285156
			c-17.7988281-6.6835938-36.1948242-13.5908203-49.4165039-23.0527344
			c-20.1577168-13.296875-30.3793964-29.3115234-30.3793964-47.6005859V45.829483l14.6513691,0.6821289
			c34.1162109,1.590332,85.4516602-11.5727539,108.8535156-35.6113281l10.0307617-10.3027344l10.0317383,10.3017578
			c23.4042969,24.0351562,74.7324219,37.2021484,108.8554535,35.6123047l14.6503906-0.6821289v242.1484528
			c0,18.2734375-10.2207031,34.28125-30.3789062,47.5820312c-13.2031097,9.4550781-31.6113129,16.3740234-49.4160004,23.0664062
			c-16.7929688,6.3115234-35.8046875,13.4580078-44.2832031,21.2304688
			C163.6494293,379.856842,159.045578,384.1937561,154.1914215,384.1937561z"
          />
          <path
            fill="#FFFFFF"
            d="M154.1899567,375.6400452l-3.0405273-2.7851562
			c-9.8642578-9.0380859-28.9282227-16.1962891-47.3642578-23.1191406
			c-17.2866211-6.4912109-35.1611328-13.203125-47.3686523-21.9873047
			c-17.6713867-11.6123047-26.2622089-24.6220703-26.2622089-39.7705078V55.7821198l4.7094746,0.2192383
			c36.9790039,1.7226562,90.5209961-12.1962891,116.1025391-38.4746094l3.2241211-3.3115234l3.2246094,3.3115234
			c25.5839844,26.2739258,79.1054688,40.1953125,116.1035004,38.4746094l4.7089844-0.2192383v232.195816
			c0,15.1337891-8.5917969,28.1376953-26.2636566,39.7539062c-12.1914062,8.7783203-30.0810547,15.5019531-47.3818359,22.0048828
			c-18.4433594,6.9326172-37.515625,14.1005859-47.3520508,23.1171875L154.1899567,375.6400452z"
          />
          <path
            d="M154.1907806,20.6660767c25.5326233,26.2215652,78.8926392,41.7213516,119.5364838,39.8306122v227.4815216
			c0,13.5227966-7.9538269,25.3136902-24.3159332,36.0464172c-24.7911377,17.9065857-75.3698273,27.3158264-95.2217102,45.512207
			c-19.8999329-18.2325134-70.3897324-27.5717773-95.2212372-45.4962463
			c-16.3611565-10.730072-24.3142204-22.5266418-24.3142204-36.0623779V60.4966888
			C75.2896881,62.3900986,128.6676331,46.8842773,154.1907806,20.6660767"
          />
        </g>
        <g id="Banner_6_">
          <polygon
            fill="#363C44"
            points="0.5164844,279.240387 309.2883911,217.1207275 309.2883911,116.8810043 0.5164844,179.0006409 		"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M73.8512802,217.6542969c-1.5776367-1.7671509-3.4988708-3.0991669-5.7375946-3.982254
			c3.1208267-2.2997742,5.3433075-5.1035919,6.6260986-8.3661804c1.6253891-4.1387939,2.0403519-8.3445282,1.2340546-12.503006
			c-1.0627518-5.4727783-4.1151581-9.6696472-9.0725555-12.4724884c-4.8771515-2.7585297-11.4968529-3.3502045-19.6745033-1.7642059
			l-28.2375889,5.4786835l-0.0103359,80.4484406l32.5949326-6.3253174c6.888958-1.335968,12.5207253-3.5924072,16.7397499-6.7053528
			c4.2731705-3.1523438,7.264534-6.9485168,8.8894348-11.2802734c1.6199799-4.3179626,1.9832535-8.8653107,1.0804749-13.5170288
			C77.6194153,223.2432404,76.1284027,220.2119904,73.8512802,217.6542969z M37.4163513,244.3802032l-0.0669441-16.9765167
			l11.8448715-2.2987823c3.1301804-0.6074371,5.4929543-0.4459686,7.0169411,0.4794464
			c1.4983902,0.9126129,2.4115028,2.3066559,2.7910233,4.2618561c0.5355644,2.760498,0.1629333,5.2414093-1.1385574,7.5825195
			c-1.2355347,2.2190399-4.1013794,3.7794495-8.5173035,4.6369476L37.4163513,244.3802032z M55.1400948,195.1872864
			c1.320694,0.7944794,2.119606,1.9896545,2.4420242,3.6524506c0.4479446,2.3086243,0.0708847,4.4302063-1.1543121,6.4858093
			c-1.1725235,1.9689789-3.8335953,3.368927-7.9098701,4.1604462l-11.1616364,2.1658783l0.0300255-14.6413116l11.9605484-2.3204346
			C51.8361549,194.2047729,53.7844543,194.3740997,55.1400948,195.1872864z"
          />
          <path
            fill="#FFFFFF"
            d="M142.7462769,167.1490631c-6.6743469-4.4725342-14.9174652-5.7966766-24.4980164-3.9349976
			c-6.6910706,1.2985382-12.4385147,3.8109436-17.0833359,7.4663544c-4.6310272,3.644577-8.3218765,8.1643524-10.9701538,13.4323578
			c-2.6310425,5.2305908-4.3046722,10.9425812-4.9736328,16.9784851c-0.666008,6.0053864-0.4036407,12.1446533,0.7797165,18.2465057
			c2.0280457,10.4483795,6.4602127,18.012207,13.1739273,22.4788208c4.7506485,3.1602173,10.2632904,4.7472076,16.4571991,4.7472076
			c2.5473709,0,5.2099152-0.2687683,7.9822388-0.8062897c6.6910782-1.2985535,12.4385147-3.8109589,17.0833359-7.4663696
			c4.6260986-3.6386719,8.3169556-8.1584473,10.9706421-13.4323578c2.6315308-5.2355042,4.3046722-10.9475098,4.9731445-16.9784851
			c0.6664886-6.0053711,0.4046173-12.1436615-0.7792206-18.2455292
			C153.8345642,179.1873779,149.4216003,171.621582,142.7462769,167.1490631z"
          />
          <path
            fill="#FFFFFF"
            d="M216.3786163,186.051239c-4.026062-3.0154877-9.8852539-4.7865906-17.4116669-5.2630768l-6.4848175-0.4253082
			c-3.0199127-0.1772003-5.2935944-0.6566467-6.7609711-1.4275055c-1.2453766-0.6507416-1.9975281-1.724823-2.2992706-3.2822876
			c-0.4804382-2.4730225,0.1161652-4.5650635,1.8242493-6.3952332c1.8345947-1.9689789,4.2298584-3.2182922,7.3221283-3.8188324
			c2.8727417-0.5572205,5.3950043-0.2992706,7.7105255,0.7875977c2.1678314,1.0179596,3.5879669,3.0686493,4.3420868,6.2702026
			l0.3332367,1.4147186l18.2681732-3.5461273l-0.3047028-1.4954376c-1.6824951-8.260849-5.5662994-14.0713043-11.5441132-17.2708893
			c-5.8773956-3.144455-13.0331421-3.9251556-21.2693787-2.3292999c-5.4294434,1.0534058-10.3228455,3.024353-14.544342,5.8586884
			c-4.2707062,2.8727417-7.4978638,6.4730225-9.5923615,10.7004089c-2.1284637,4.2953186-2.6955261,9.1143951-1.6849518,14.3233185
			c1.1233063,5.7888031,3.8429565,10.1727295,8.0821686,13.0306854c4.1186066,2.781189,9.8222351,4.4311829,16.9592896,4.9037476
			l6.5640717,0.4036407c4.1712799,0.305191,6.095459,1.0986786,6.9746094,1.7100525
			c1.1877899,0.8259888,1.9325409,2.0910492,2.2766266,3.8670654c0.5207977,2.6797791-0.1777039,4.969696-2.1348572,7.0016785
			c-2.0698853,2.1501312-4.9253998,3.5904388-8.4862976,4.2815399c-3.2000732,0.6222076-6.1407471,0.3278351-8.7452087-0.8722534
			c-2.4882965-1.1449585-4.0979309-3.4309387-4.9199829-6.986908l-0.3288116-1.4216003l-18.2730865,3.5451355l0.3032227,1.4954376
			c1.7514038,8.6270752,5.8370209,14.6846313,12.1426697,18.0043335c4.1072845,2.1629181,8.7855835,3.247818,13.9762878,3.247818
			c2.6399078,0,5.413208-0.2805786,8.3095856-0.8427124c5.7927246-1.1242981,10.9676819-3.1867981,15.3831177-6.1304169
			c4.4676056-2.9800415,7.8581848-6.7023926,10.0772247-11.0636749c2.2569427-4.4341431,2.8742065-9.3999023,1.8345947-14.7584686
			C223.1322021,193.6662598,220.4745789,189.1179199,216.3786163,186.051239z"
          />
          <path
            fill="#FFFFFF"
            d="M291.3774414,186.5454407c-1.1454468-5.9020081-3.8035889-10.4493561-7.8990479-13.5150452
			c-4.026062-3.0154877-9.8852539-4.7865906-17.4116516-5.2630768l-6.4848328-0.4253082
			c-3.0194397-0.1772003-5.2931061-0.6566467-6.7604828-1.4275055c-1.2458801-0.6517334-1.9980164-1.724823-2.2997742-3.2822723
			c-0.4799347-2.4730377,0.1161804-4.5650787,1.8237762-6.396225c1.835083-1.9680023,4.2303467-3.2173157,7.3226318-3.8178558
			c2.8722229-0.558197,5.394989-0.3002625,7.710022,0.7866058c2.168335,1.0189514,3.5884399,3.0696411,4.3425598,6.2702179
			l0.333252,1.4147034l18.268158-3.5441589l-0.3046875-1.4964142c-1.6820068-8.2598572-5.5662842-14.0713043-11.5440979-17.2708893
			c-5.8778992-3.144455-13.0336609-3.9261322-21.2693787-2.3292999c-5.4289703,1.0534058-10.3218842,3.024353-14.5443573,5.8586884
			c-4.2707062,2.8727417-7.4978638,6.4720306-9.5923615,10.6994324c-2.1284637,4.2962952-2.6950378,9.1153717-1.6849518,14.3233185
			c1.1233063,5.7878113,3.8424683,10.1727142,8.0821533,13.031662c4.1186066,2.781189,9.8222504,4.4311829,16.9597931,4.9037476
			l6.5640564,0.4026489c4.1712952,0.3071594,6.095459,1.0996704,6.9736328,1.7100525
			c1.1882935,0.8269806,1.9330444,2.092041,2.2771301,3.8680573c0.5207825,2.6797791-0.1777039,4.969696-2.1348572,7.0016785
			c-2.0698853,2.1501312-4.925415,3.5904388-8.4862976,4.2815399c-3.2000732,0.6222076-6.1412354,0.3268585-8.7452087-0.8722534
			c-2.4882965-1.1449585-4.0979309-3.4309387-4.9199829-6.9878998l-0.3288269-1.4216003l-18.2730865,3.5461273l0.3032227,1.4954376
			c1.7514038,8.6270752,5.8370361,14.6846313,12.1426849,18.0033417c4.1067963,2.1639099,8.7860718,3.2488098,13.9772797,3.2488098
			c2.6399078,0,5.4122162-0.2805786,8.3085785-0.8427124c5.7902832-1.1242981,10.9657288-3.1867981,15.3831177-6.1304169
			c4.4676208-2.9800415,7.8581848-6.7023926,10.07724-11.0636749
			C291.7997742,196.8697815,292.4170532,191.9049988,291.3774414,186.5454407z"
          />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M97.3587341,312.4327087c-2.2363281,0.4428711-4.0361328,0.1826172-5.4003906-0.7802734
			c-1.3632812-0.9633789-2.25-2.4853516-2.6621094-4.5649414c-0.2402344-1.2167969-0.2685547-2.4135742-0.0839844-3.5922852
			c0.1835938-1.1777344,0.5644531-2.2680664,1.1416016-3.2695312c0.5761719-1.0009766,1.3408203-1.8500977,2.2929688-2.5483398
			c0.9521484-0.6987305,2.0761719-1.1757812,3.3710938-1.4326172c2.2363281-0.4428711,4.0341797-0.1879883,5.3964844,0.7661133
			c1.3613281,0.9541016,2.25,2.4804688,2.6660156,4.5791016c0.2412109,1.2167969,0.2685547,2.4150391,0.0839844,3.5917969
			c-0.1845703,1.1787109-0.5644531,2.2685547-1.1416016,3.2695312s-1.3408203,1.8515625-2.2929688,2.5488281
			C99.7767029,311.698822,98.6526794,312.1758728,97.3587341,312.4327087z M96.9720154,308.7779236
			c0.8027344-0.1591797,1.4580078-0.5327148,1.9628906-1.1225586c0.5058594-0.5888672,0.8447266-1.3242188,1.015625-2.2041016
			c0.1728516-0.8803711,0.1582031-1.8305664-0.0439453-2.8505859c-0.2060547-1.0405273-0.6054688-1.796875-1.1982422-2.2705078
			c-0.59375-0.4736328-1.3613281-0.6171875-2.3027344-0.4306641c-0.8046875,0.1591797-1.4589844,0.5332031-1.9648438,1.1225586
			c-0.5058594,0.5888672-0.84375,1.3251953-1.015625,2.2041016c-0.171875,0.8803711-0.1572266,1.8310547,0.0449219,2.8505859
			c0.2021484,1.0209961,0.5996094,1.7724609,1.1962891,2.2553711
			C95.2610779,308.8160095,96.0296326,308.964447,96.9720154,308.7779236z"
          />
          <path
            fill="#FFFFFF"
            d="M108.1194763,315.8067322l-0.0673828-18.2451172l-2.3242188,0.4604492l-0.0283203-3.3891602
			l2.3535156-0.4663086l-0.0048828-0.487793c-0.0039062-2.1816406,0.4521484-3.7905273,1.3681641-4.8286133
			c0.9150391-1.0371094,2.2558594-1.730957,4.0224609-2.0805664c0.4501953-0.0888672,0.8964844-0.1518555,1.3378906-0.1884766
			s0.8242188-0.0566406,1.1503906-0.0595703l0.0185547,3.1767578l-1.1181641,0.2216797
			c-1.0214844,0.2021484-1.7148438,0.527832-2.0849609,0.9785156c-0.3701172,0.4501953-0.5576172,1.1757812-0.5634766,2.1762695
			l-0.0058594,0.2758789l3.2080078-0.6352539l0.0576172,3.3833008l-3.2373047,0.6411133l0.0380859,18.2504883
			L108.1194763,315.8067322z"
          />
          <path
            fill="#FFFFFF"
            d="M124.5296326,301.2398376l-0.0458984-6.8720703l-2.3535156,0.4663086l-0.0175781-3.3291016
			l1.5009766-0.2973633c0.3925781-0.0776367,0.6640625-0.1977539,0.8154297-0.3608398
			c0.1513672-0.1625977,0.2519531-0.4736328,0.3046875-0.9316406l0.4248047-3.7236328l3.4140625-0.6762695l0.0205078,4.5830078
			l3.1484375-0.6235352l0.0410156,3.4477539l-3.1787109,0.6293945l0.0458984,6.871582
			c0,0.3071289,0.0214844,0.5678711,0.0634766,0.7832031c0.0976562,0.4902344,0.3193359,0.8139648,0.6660156,0.9692383
			c0.3466797,0.15625,0.8632812,0.1655273,1.5498047,0.0292969l1.1777344-0.2329102l-0.0058594,3.3647461
			c-0.3222656,0.1259766-0.6708984,0.2446289-1.0458984,0.3603516c-0.3740234,0.1152344-0.7587891,0.2109375-1.1503906,0.2890625
			c-1.4326172,0.2836914-2.5908203,0.2226562-3.4765625-0.1826172c-0.8857422-0.4057617-1.4648438-1.2949219-1.7373047-2.668457
			c-0.0546875-0.2749023-0.0966797-0.5722656-0.1308594-0.8920898
			C124.528656,301.9239197,124.5179138,301.5899353,124.5296326,301.2398376z"
          />
          <path
            fill="#FFFFFF"
            d="M134.9964294,304.6724548l-0.078125-21.8515625l4.1201172-0.8164062l0.0224609,7.519043
			c0.3808594-0.6455078,0.8896484-1.1894531,1.5263672-1.6323242c0.6357422-0.4414062,1.3554688-0.7426758,2.1601562-0.9018555
			c1.3349609-0.2641602,2.4648438-0.0649414,3.3916016,0.5976562c0.9257812,0.6625977,1.5371094,1.7392578,1.8320312,3.2299805
			c0.109375,0.5498047,0.1689453,1.1591797,0.1787109,1.8300781l0.0302734,9.4140625l-4.1201172,0.815918l-0.0244141-9.0786133
			c0-0.4072266-0.0292969-0.7685547-0.0917969-1.0825195c-0.1279297-0.6479492-0.3964844-1.1196289-0.8007812-1.4165039
			c-0.4052734-0.2973633-0.9130859-0.3842773-1.5205078-0.2641602c-0.7851562,0.1552734-1.4042969,0.5527344-1.859375,1.1933594
			c-0.453125,0.640625-0.6787109,1.4296875-0.6777344,2.3671875l0.03125,9.2607422L134.9964294,304.6724548z"
          />
          <path
            fill="#FFFFFF"
            d="M165.3333435,293.861908c-0.0673828,0.890625-0.3378906,1.769043-0.8085938,2.637207
			c-0.4697266,0.8686523-1.1484375,1.6342773-2.0371094,2.2998047c-0.8867188,0.6645508-2.0078125,1.1313477-3.3613281,1.3994141
			c-2.2763672,0.4511719-4.0888672,0.1787109-5.4375-0.8188477c-1.3496094-0.996582-2.21875-2.4760742-2.6074219-4.4379883
			c-0.3535156-1.784668-0.3046875-3.4458008,0.1445312-4.9833984c0.4501953-1.5366211,1.2236328-2.8266602,2.3222656-3.8696289
			s2.4423828-1.722168,4.03125-2.0366211c2.0205078-0.4003906,3.7158203-0.1542969,5.0849609,0.7358398
			c1.3691406,0.890625,2.2402344,2.277832,2.6132812,4.1616211c0.1005859,0.5107422,0.1533203,1.0341797,0.1582031,1.5742188
			c0.0048828,0.5395508-0.03125,1.0209961-0.1113281,1.4433594l-10.1240234,2.0053711
			c0.2578125,1.1933594,0.6787109,2.0327148,1.2636719,2.5170898c0.5859375,0.4863281,1.2714844,0.6518555,2.0566406,0.4960938
			c0.7646484-0.1513672,1.3720703-0.4301758,1.8212891-0.8349609c0.4501953-0.4052734,0.75-0.8984375,0.8994141-1.4785156
			L165.3333435,293.861908z M158.0384216,287.1416931c-0.9414062,0.1865234-1.6582031,0.6337891-2.1503906,1.3427734
			c-0.4921875,0.7094727-0.7822266,1.5615234-0.8691406,2.5576172l5.2382812-1.0375977
			c0.3125-0.0620117,0.5273438-0.1704102,0.6396484-0.3251953c0.1113281-0.1547852,0.1328125-0.4086914,0.0634766-0.762207
			c-0.1367188-0.6855469-0.4716797-1.190918-1.0048828-1.5131836
			C159.4231873,287.0811462,158.7835388,286.9937439,158.0384216,287.1416931z"
          />
          <path
            fill="#FFFFFF"
            d="M173.8587341,290.4302673l4.1787109-0.8276367c0.2568359,1.0908203,0.7822266,1.8173828,1.5791016,2.1801758
			c0.7949219,0.3618164,1.6728516,0.4477539,2.6347656,0.2573242c1.0595703-0.2099609,1.9003906-0.6411133,2.5263672-1.2958984
			c0.6240234-0.652832,0.8515625-1.4121094,0.6806641-2.2753906c-0.1171875-0.5888672-0.3818359-1.0253906-0.7958984-1.3105469
			c-0.4121094-0.2841797-1.1152344-0.4619141-2.1064453-0.5302734l-1.7949219-0.1035156
			c-1.8789062-0.1171875-3.3525391-0.5336914-4.421875-1.2495117c-1.0703125-0.7163086-1.7509766-1.8100586-2.0419922-3.28125
			c-0.2646484-1.3334961-0.1269531-2.5483398,0.4111328-3.644043c0.5351562-1.0952148,1.3574219-2.0166016,2.4638672-2.765625
			c1.1044922-0.7490234,2.375-1.2661133,3.8066406-1.5498047c2.1572266-0.4272461,4-0.2363281,5.5263672,0.5717773
			c1.5253906,0.8100586,2.5107422,2.28125,2.9541016,4.4155273l-4.1787109,0.828125
			c-0.2373047-0.9921875-0.7099609-1.6538086-1.4169922-1.9829102c-0.7070312-0.3286133-1.4931641-0.4077148-2.3564453-0.2368164
			c-0.921875,0.1826172-1.6591797,0.5737305-2.2138672,1.1728516c-0.5546875,0.5986328-0.7519531,1.3012695-0.5927734,2.1049805
			c0.109375,0.5498047,0.390625,0.9428711,0.8457031,1.1787109c0.453125,0.2363281,1.1240234,0.378418,2.0107422,0.4267578
			l1.7646484,0.1088867c1.9804688,0.1181641,3.4902344,0.5634766,4.5302734,1.3359375s1.7109375,1.9145508,2.0097656,3.425293
			c0.2724609,1.3735352,0.1210938,2.6254883-0.4482422,3.7578125c-0.5703125,1.1337891-1.4365234,2.0898438-2.5976562,2.8701172
			c-1.1601562,0.7802734-2.5058594,1.3212891-4.0361328,1.6245117c-2.2558594,0.4467773-4.1914062,0.2490234-5.8046875-0.5932617
			C175.3587341,294.2002869,174.3206482,292.6631775,173.8587341,290.4302673z"
          />
          <path
            fill="#FFFFFF"
            d="M202.4964294,291.7305603c-2.4912109,0.4936523-4.6113281,0.1640625-6.3583984-0.9887695
			c-1.7470703-1.1518555-2.8925781-3.1010742-3.4365234-5.8486328c-0.3222656-1.6274414-0.3974609-3.2431641-0.2265625-4.8481445
			c0.1728516-1.6040039,0.6044922-3.1010742,1.2978516-4.4916992c0.6923828-1.3916016,1.6474609-2.5693359,2.8632812-3.5341797
			c1.2148438-0.9643555,2.7060547-1.6220703,4.4716797-1.9716797c2.5107422-0.4970703,4.6347656-0.168457,6.3720703,0.9858398
			c1.7382812,1.1542969,2.8789062,3.1049805,3.4228516,5.8515625c0.3222656,1.628418,0.3974609,3.2446289,0.2265625,4.8486328
			c-0.171875,1.6035156-0.6035156,3.1005859-1.2978516,4.4921875c-0.6923828,1.390625-1.6474609,2.5693359-2.8632812,3.5341797
			C205.7522888,290.7232361,204.2620544,291.3809509,202.4964294,291.7305603z M202.1507263,287.8228455
			c1.2548828-0.2485352,2.2617188-0.8598633,3.0146484-1.8354492c0.7558594-0.9750977,1.2480469-2.1689453,1.4794922-3.5800781
			c0.2294922-1.4111328,0.1953125-2.8720703-0.1044922-4.3837891c-0.2988281-1.5102539-0.8837891-2.6328125-1.7529297-3.3676758
			s-1.9804688-0.96875-3.3339844-0.7006836c-1.2558594,0.2490234-2.2617188,0.8598633-3.015625,1.8354492
			c-0.7558594,0.9750977-1.2490234,2.1640625-1.4814453,3.565918c-0.2314453,1.4018555-0.1962891,2.8681641,0.1074219,4.3979492
			c0.2988281,1.5107422,0.8828125,2.6333008,1.7519531,3.3681641
			C199.6858826,287.85849,200.7972107,288.0909119,202.1507263,287.8228455z"
          />
          <path
            fill="#FFFFFF"
            d="M227.2132263,278.9444275l4.3837891-0.8681641c-0.0322266,1.4750977-0.34375,2.8662109-0.9306641,4.1757812
			c-0.5869141,1.3085938-1.4560547,2.4296875-2.6064453,3.3603516c-1.1523438,0.9321289-2.5908203,1.5683594-4.3164062,1.9101562
			c-2.4326172,0.4814453-4.5087891,0.1435547-6.2255859-1.0151367c-1.71875-1.1577148-2.8583984-3.1586914-3.421875-6.0043945
			c-0.3339844-1.6865234-0.4101562-3.3330078-0.2294922-4.9399414c0.1816406-1.6049805,0.6191406-3.078125,1.3115234-4.4179688
			c0.6933594-1.3413086,1.6357422-2.4750977,2.828125-3.4042969s2.6210938-1.5595703,4.2890625-1.8901367
			c1.7070312-0.3378906,3.1933594-0.2958984,4.4599609,0.1259766c1.265625,0.4228516,2.3007812,1.1455078,3.1054688,2.1679688
			c0.8046875,1.0234375,1.3535156,2.28125,1.6494141,3.7719727l-4.3564453,0.862793
			c-0.3349609-1.1777344-0.8769531-2.0336914-1.6240234-2.5683594c-0.7490234-0.534668-1.6416016-0.6992188-2.6826172-0.4931641
			c-0.9414062,0.1865234-1.7441406,0.5888672-2.4052734,1.2099609c-0.6630859,0.6210938-1.1708984,1.3935547-1.5283203,2.3212891
			s-0.5693359,1.9375-0.6376953,3.0317383c-0.0693359,1.0952148,0.0087891,2.2099609,0.234375,3.3481445
			c0.2998047,1.5107422,0.8642578,2.6171875,1.6982422,3.3183594c0.8320312,0.7021484,1.8261719,0.9375,2.9833984,0.7080078
			c1.2363281-0.2451172,2.1904297-0.8095703,2.859375-1.6972656
			C226.7210388,281.0713806,227.1087341,280.0664978,227.2132263,278.9444275z"
          />
        </g>
        <path
          id="Logo_18_"
          fill="#FFFFFF"
          d="M100.6281662,107.128952c0,0.9661255-0.2053757,1.8580017-0.6135559,2.6774368
		c-0.4089203,0.8199081-0.9794083,1.514389-1.7098618,2.0842056c-0.7304916,0.5707245-1.5991058,1.016983-2.6069107,1.3387833
		c-1.0080261,0.3213501-2.1111908,0.4810715-3.3089447,0.4810715c-1.4328156,0-2.7174454-0.1956863-3.8575058-0.5882187
		c-1.139122-0.3933029-2.2772903-1.0418625-3.4166489-1.9453354l1.8840179-3.0633621
		c0.9053345,0.7583771,1.7226257,1.313736,2.4533386,1.6641922c0.7292786,0.3511581,1.4751129,0.5248566,2.2356415,0.5248566
		c0.9344254,0,1.6859589-0.2394562,2.256012-0.7224197c0.5697632-0.4827194,0.8551483-1.1332016,0.8551483-1.9514694
		c0-0.3509064-0.0518036-0.6814728-0.1535873-0.987442c-0.1017609-0.3066711-0.2915192-0.6218567-0.5693207-0.943634
		c-0.2765961-0.3209152-0.655426-0.671814-1.1374588-1.0515976c-0.4817581-0.3799896-1.1017151-0.8487473-1.8596573-1.4034424
		c-0.583519-0.4095993-1.1523438-0.8333817-1.7065353-1.2716141c-0.5551224-0.4389114-1.0581741-0.9072418-1.5106201-1.4046021
		c-0.4519424-0.4969254-0.8165817-1.0515518-1.094635-1.665596c-0.2770691-0.6156998-0.4147873-1.3168335-0.4147873-2.1055374
		c0-0.9060287,0.1890488-1.7325821,0.5679016-2.4788818c0.3781281-0.745369,0.8951569-1.3806992,1.5520172-1.9067459
		c0.6554642-0.5267181,1.4412994-0.9358292,2.3600922-1.2278519c0.9179001-0.2931519,1.9157715-0.4396362,2.9933472-0.4396362
		c1.1381607,0,2.2391815,0.1526489,3.3066025,0.4574127c1.0650635,0.3052139,2.0513382,0.7472763,2.9573822,1.3276901
		l-1.7089462,2.7512817c-1.16819-0.8175888-2.3951416-1.2266998-3.6811905-1.2266998
		c-0.7874908,0-1.4386597,0.2053909-1.949295,0.6145325c-0.5113678,0.4095993-0.7664642,0.9204788-0.7664642,1.5356903
		c0,0.5851746,0.225296,1.1126175,0.6772385,1.5802002c0.4493408,0.4680481,1.2282944,1.1265793,2.3357239,1.9739227
		c1.1145096,0.8199081,2.0383224,1.5361633,2.7711563,2.1507111c0.7334976,0.6145325,1.3120728,1.1930542,1.7372665,1.7332535
		c0.4259262,0.5418854,0.7191162,1.091095,0.880249,1.6464615
		C100.5458374,105.8426514,100.6281662,106.4564743,100.6281662,107.128952 M126.1319504,101.7440643
		c0-1.6355591-0.2347031-3.1253815-0.7023087-4.4696579c-0.4687653-1.3449783-1.1352921-2.4982986-1.9973602-3.4639511
		c-0.8641663-0.9645081-1.8884888-1.7172089-3.0732574-2.2574005c-1.1855011-0.5402451-2.4938049-0.8109436-3.9284973-0.8109436
		c-1.5799179,0-2.9708557,0.2993469-4.1709824,0.8979797c-1.1994629,0.5994034-2.3111572,1.5418854-3.3362045,2.826973
		l-0.0305252-3.1986694h-5.6319504v32.865921h5.6319504v-13.8909073c0.5567703,0.6431656,1.091774,1.1852417,1.6047821,1.6241989
		c0.512764,0.4401245,1.0333786,0.7985992,1.5607758,1.0764313c0.5281754,0.2768631,1.084465,0.4744339,1.6710968,0.5924759
		c0.586319,0.1167068,1.217186,0.1739349,1.8915634,0.1739349c1.4950256,0,2.8878021-0.2993469,4.1773987-0.8968277
		c1.2910385-0.5993576,2.4039001-1.4393768,3.3428421-2.5214691c0.938446-1.0802002,1.6705399-2.3444901,2.1984787-3.7912064
		C125.8681183,105.054924,126.1319504,103.4687881,126.1319504,101.7440643 M120.0069046,101.9660187
		c0,0.8492203-0.0894547,1.7630615-0.265274,2.7431793c-0.1767349,0.9798813-0.4782104,1.8875656-0.9046173,2.7209473
		c-0.4268494,0.8331909-0.9855652,1.5203705-1.6767044,2.0617218c-0.6923676,0.5411682-1.5662613,0.8107147-2.6248932,0.8107147
		c-1.7654877,0-3.1556854-0.7004166-4.1707993-2.1062241c-1.014183-1.4034348-1.5217285-3.3340759-1.5217285-5.7914429
		c0-2.5145798,0.515152-4.5112686,1.5449448-5.9889908c1.0293045-1.4777222,2.4263458-2.2159882,4.1913452-2.2159882
		c1.6767502,0,3.0001755,0.6956711,3.9706116,2.0844116C119.5213242,97.6743088,120.0069046,99.5682602,120.0069046,101.9660187
		 M128.6385498,81.0077896v32.161972h5.7799377v-32.161972H128.6385498L128.6385498,81.0077896z M153.3250732,113.1787796h5.7799377
		V91.2359772h-5.7799377v11.8085403c0,1.0539322-0.0431366,1.8653564-0.132309,2.4353561
		c-0.0881958,0.5712128-0.2350311,1.0906219-0.440506,1.5588913c-0.8209381,1.8147125-2.3183746,2.7212296-4.4913635,2.7212296
		c-1.7024384,0-2.8926392-0.6137924-3.567215-1.8447495c-0.2646027-0.4663925-0.4482574-0.9942856-0.5510101-1.5787735
		c-0.1027374-0.5858841-0.1540985-1.4332275-0.1540985-2.5470123V91.2359772h-5.7794647v12.466835
		c0,0.8492813,0.0072632,1.5667419,0.0222931,2.1514206c0.0145264,0.5851746,0.0513611,1.1045685,0.1095123,1.5586548
		c0.0586548,0.4543228,0.1245422,0.8561096,0.1982117,1.2070312c0.0726929,0.3511353,0.1822205,0.688324,0.3290405,1.0096817
		c0.5263062,1.3459549,1.3821259,2.3634338,2.5674744,3.0517807c1.1863251,0.6864548,2.6416168,1.029068,4.3677979,1.029068
		c1.551712,0,2.9125061-0.2694855,4.0833282-0.8108978c1.1708069-0.541893,2.3120728-1.4270782,3.4242401-2.6561432
		L153.3250732,113.1787796L153.3250732,113.1787796z M178.253891,113.1690369h5.7809143v-12.439888
		c0-0.8468552-0.008728-1.5693207-0.0232544-2.1684418c-0.0145416-0.5986328-0.0499115-1.1168747-0.1095276-1.5551224
		c-0.0586243-0.4377289-0.1308441-0.8236771-0.2195282-1.1601486c-0.0867462-0.3360214-0.1904449-0.6639557-0.3072357-0.9865189
		c-0.5267792-1.3137207-1.3830872-2.3274307-2.5679626-3.043251c-1.1863251-0.7155304-2.6420898-1.073555-4.3697205-1.073555
		c-1.5507507,0-2.9125061,0.2706985-4.08284,0.8128357c-1.1717834,0.5406799-2.3120728,1.4275665-3.4252167,2.6554184
		l-0.0106659-2.9346466h-5.8298645v21.8933182h5.8298645v-11.8210983c0-1.0213089,0.0363464-1.8168411,0.1090393-2.3861694
		c0.0736542-0.5688324,0.212738-1.1009903,0.4196777-1.5979004c0.3804169-0.8748093,0.9609833-1.5392685,1.7382812-1.9914474
		c0.7782898-0.452446,1.7111664-0.6786575,2.7976532-0.6786575c1.7029266,0,2.8921661,0.6133194,3.567215,1.8386154
		c0.2621765,0.4670715,0.4472961,0.993782,0.548584,1.5766373c0.103714,0.583252,0.1545868,1.4301224,0.1545868,2.5389557
		V113.1690369L178.253891,113.1690369z M202.5769653,113.6129913l5.3903198-1.8845062l-8.8092499-10.9536362l7.4499207-7.9745636
		l-4.3823395-1.8837662l-7.7542419,8.9816132h-0.6130371V81.0139389h-5.8293762v32.1612625h5.8293762v-11.6554718
		L202.5769653,113.6129913L202.5769653,113.6129913z M212.9708862,112.5758286l17.5269012-8.7090225v-3.5882111
		l-17.5269012-8.7956085v3.9381638l13.5826416,6.6082458l-13.5826416,6.6955795V112.5758286L212.9708862,112.5758286z
		 M214.3001709,87.7026291v-0.7431946c0.0605621-0.0090103,0.1400299-0.01754,0.2539215-0.01754
		c0.3935089,0,0.5335541,0.1919022,0.5335541,0.3845291c0,0.2714005-0.2451935,0.3762054-0.5335541,0.3762054H214.3001709
		L214.3001709,87.7026291z M214.3001709,88.9181824v-0.9448471h0.2447052c0.2791595,0,0.419693,0.105545,0.4720306,0.3852539
		c0.0440826,0.2884598,0.0964355,0.489563,0.1487579,0.5595932h0.3668671
		c-0.0339203-0.0700302-0.0867615-0.2011185-0.1386108-0.542572c-0.0440826-0.3229904-0.175415-0.498085-0.3682861-0.533371
		v-0.0170059c0.236496-0.0700684,0.4288788-0.2451553,0.4288788-0.5333557c0-0.2103729-0.0702667-0.3585205-0.2011261-0.4548111
		c-0.1318054-0.0965271-0.3498688-0.1665649-0.682312-0.1665649c-0.2621918,0-0.4371338,0.0262604-0.6207886,0.0525284v2.1951523
		H214.3001709L214.3001709,88.9181824z M214.6670074,86.110878h0.0101929c0.873764,0,1.5725403,0.7432556,1.5725403,1.6703568
		c0,0.9266129-0.6987762,1.6703033-1.5725403,1.6613388c-0.8756866,0-1.584198-0.734726-1.584198-1.6698685
		C213.0930023,86.8541336,213.8015137,86.110878,214.6670074,86.110878L214.6670074,86.110878z M214.6772003,85.8049088h-0.0101929
		c-1.0753326,0-1.9490814,0.8748016-1.9490814,1.9677963c0,1.1102448,0.8737488,1.9757996,1.9490814,1.9757996
		c1.0850372,0,1.942337-0.8655548,1.942337-1.9757996C216.6093445,86.6797104,215.7520447,85.8049088,214.6772003,85.8049088
		L214.6772003,85.8049088z"
        />
        <polygon
          fill="#ED0080"
          points="150.9542542,191.598465 122.9864578,255.1326752 114.6809998,267.1361084 114.7703323,254.7952881 
		118.451683,221.1851959 92.0922318,225.9848633 107.5346985,171.443161 135.011322,167.3153992 127.6015472,195.6269836 	"
        />
      </g>
    </svg>
  );
}

export default BotsIcon;
