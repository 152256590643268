import styled, { css } from 'styled-components';
import { Flex } from 'reflexbox';

const EventLabel = styled(Flex)`
  font-family: 'Roboto Mono';
  font-weight: normal;
  font-size: 16px;
  width: 160px;
  height: 36px;
  background-color: #ffffff;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 8px;
  ${(props) =>
    props.enrolled &&
    props.active &&
    css`
      background-color: ${props.theme.color.card.restricted.active};
    `};
  ${(props) =>
    props.enrolled &&
    !props.active &&
    css`
      background-color: ${props.theme.color.card.restricted.inactive};
    `};
`;
EventLabel.displayName = 'EventLabel';

export default EventLabel;
