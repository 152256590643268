import React from 'react';
import styled from 'styled-components';
import { withRouter, NavLink } from 'react-router-dom';
import { Box } from 'reflexbox';

function IconLink(props) {
  const { children, ...rest } = props;
  return <NavLink {...rest}>{children}</NavLink>;
}

const MenuLink = styled(IconLink)`
  position: relative;
  align-items: center;
  display: block;
  padding: 14px 10px 14px 10px;
  margin-bottom: 8px;
  font-family: Roboto Mono;
  font-size: 14px;
  letter-spacing: 0.72px;
  text-decoration: none;
  background-color: #2c2c2c;
  color: #a0a0a0;
  border-left: 1px solid transparent;
  box-shadow: 0px 3px 6px #00000029;

  &.active {
    color: #66bb6a;
    border-left-color: #66bb6a;
  }

  &:hover,
  &:focus {
    background-color: #373737;
    border-left-color: #66bb6a;
  }
`;

const MenuContainer = styled(Box)`
  overflow: hidden;
  width: 100%;
`;

function SideMenu(props) {
  const { options } = props;
  return (
    <MenuContainer justifyContent="center" align="center">
      {options.map((opt) => (
        <MenuLink key={opt.key} exact to={opt.route}>
          {opt.label}
        </MenuLink>
      ))}
    </MenuContainer>
  );
}

export default withRouter(SideMenu);
