import React from 'react';

export default function GameIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={props.fillColor || '#d0d0d0'}
        d="M7.05,13.406l3.534,3.536L9.171,18.356l1.415,1.415L9.172,21.185,6.7,18.71,3.868,21.539,2.454,20.125,5.283,17.3,2.808,14.821l1.414-1.414L5.636,14.82l1.413-1.414ZM3,3,6.546,3,18.363,14.821l1.415-1.414,1.414,1.414L18.718,17.3l2.828,2.829-1.414,1.414L17.3,18.71l-2.475,2.475-1.414-1.414,1.414-1.415L3,6.531ZM17.457,3,21,3l0,3.523-4.053,4.052L13.413,7.043Z"
      />
    </svg>
  );
}
