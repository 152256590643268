import React, { useState } from 'react';
import { UnregularButton } from 'boss-ui';
import { Flex } from 'reflexbox';

export default function CollapsableSection({
  children,
  justify = 'left',
  expanded = true,
  showLabel = 'Show more',
  hideLabel = 'Show less',
}) {
  const [visible, setVisible] = useState(expanded);
  return (
    <Flex flexDirection="column" width={1}>
      {visible && <>{children}</>}
      <Flex mt="12px" justifyContent={justify} width={1}>
        <UnregularButton onClick={() => setVisible((visible) => !visible)}>
          {visible ? hideLabel : showLabel}
        </UnregularButton>
      </Flex>
    </Flex>
  );
}
