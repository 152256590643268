import React from 'react';
import styled from 'styled-components';
import svgBase from '../StyledSVGBase';

const SIZE = '24px';

const NextSVG = styled(svgBase)`
  width: ${(props) => props.size || SIZE};
  height: ${(props) => props.size || SIZE};
  fill: #898989;
  &:hover {
    fill: #66bb6a;
  }
`;

export default function NextIcon() {
  return (
    <NextSVG viewBox="0 0 24 24">
      <g>
        <path fill="none" d="M0,0H24V24H0Z" />
        <path d="M13.172,12,8.222,7.05,9.636,5.636,16,12,9.636,18.364,8.222,16.95Z" />
      </g>
    </NextSVG>
  );
}
