import styled from 'styled-components';

const Item = styled.div`
  padding: 10px 12px 10px 12px;
  color: ${(props) => props.theme.color.text.regular.color};
  &:hover {
    cursor: pointer;
    color: ${(props) => props.theme.color.text.light.color};
  }
`;
Item.displayName = 'Item';

export default Item;
