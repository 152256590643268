import { useCallback } from 'react';
import NominalScaleQuestion from '../questiontypes/NominalScaleQuestion';
import MixScaleQuestion from '../questiontypes/MixScaleQuestion';
import OpenQuestion from '../questiontypes/OpenQuestion';
import NumericScaleQuestion from '../questiontypes/NumericScaleQuestion';
import StarQuestion from '../questiontypes/StarQuestion';
import SmileQuestion from '../questiontypes/SmileQuestion';
import { SURVEY_QUESTIONS_TYPE } from '../../../libs/constants';
import {
  MainContainer,
  SecondaryContainer,
  RequiredContainer,
  LineSep,
  QuestionCount,
  Star,
  QuestionTitle,
  NumericScaleQuestionContainer,
  StarQuestionContainer,
  SmileQuestionContainer,
  NominalScaleQuestionContainer,
  MixScaleQuestionContainer,
  OpenQuestionContainer,
  WarningIconWrapper,
  Required
} from './styles';

export default function QuestionContainer({ scrolled, question, idx, answers, onAnswerQuestion }) {
  const onQuestionChange = useCallback(change => onAnswerQuestion(idx, change), [idx]);

  return (
    <MainContainer question={question}>
      <SecondaryContainer>
        {scrolled && <LineSep />}
        <QuestionCount align="left" mediumWeight id="test-Survey-CurrentPageLabel" question={question}>
          Q {idx + 1}/{answers.length}
          {question.required && (
            <Star align="center" mediumWeight>*</Star>
          )}
        </QuestionCount>
        <QuestionTitle align="left" mediumWeight>{question.text}</QuestionTitle>
        {question.type[0] === SURVEY_QUESTIONS_TYPE.NUMERIC_SCALE && (
          <NumericScaleQuestionContainer>
            <NumericScaleQuestion
              value={question.answer}
              options={question.options}
              onChange={onQuestionChange}
            />
          </NumericScaleQuestionContainer>
        )}
        {question.type[0] === SURVEY_QUESTIONS_TYPE.STAR_SCALE && (
          <StarQuestionContainer>
            <StarQuestion
              value={question.answer}
              onChange={onQuestionChange}
            />
          </StarQuestionContainer>
        )}
        {question.type[0] === SURVEY_QUESTIONS_TYPE.SMILE_SCALE && (
          <SmileQuestionContainer>
            <SmileQuestion
              value={question.answer}
              onChange={onQuestionChange}
            />
          </SmileQuestionContainer>
        )}
        {question.type[0] === SURVEY_QUESTIONS_TYPE.JUST_OPTIONS &&
          question.options && (
            <NominalScaleQuestionContainer>
              <NominalScaleQuestion
                options={question.options}
                value={question.answer}
                onChange={onQuestionChange}
              />
            </NominalScaleQuestionContainer>
          )}
        {question.type[0] === SURVEY_QUESTIONS_TYPE.OPTIONS_AND_TEXT &&
          question.options && (
            <MixScaleQuestionContainer>
              <MixScaleQuestion
                options={question.options}
                value={question.answer}
                onChange={onQuestionChange}
              />
            </MixScaleQuestionContainer>
          )}
        {question.type[0] === SURVEY_QUESTIONS_TYPE.JUST_TEXT && (
          <OpenQuestionContainer>
            <OpenQuestion
              value={question.answer}
              onChange={onQuestionChange}
            />
          </OpenQuestionContainer>
        )}
        {question.showQuestionWarning && (
          <RequiredContainer>
            <WarningIconWrapper />
            <Required align="center" mediumWeight>
              This question is required
            </Required>
          </RequiredContainer>
        )}
      </SecondaryContainer>
    </MainContainer>
  )
}