import React from 'react';
import { useTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { TeamIcon, Item, ListItem } from 'boss-ui';
import { SKELTELON_STYLE } from '../../libs/constants';

export default function TeamListItemSkeleton({ mobile = false, transparentBackground = false }) {
  const themeColors = useTheme().color;

  return (
    <SkeletonTheme color={SKELTELON_STYLE.COLOR} highlightColor={SKELTELON_STYLE.EFFECT}>
      <ListItem style={{ display: 'flex' }}>
        {!mobile && (
          <Item noBorder width="10%">
            <TeamIcon
              backgroundColor={
                transparentBackground ? themeColors.background : themeColors.form.background
              }
            />
          </Item>
        )}
        <Item noBorder width="100%">
          <Skeleton width="100%" height="30px" />
        </Item>
      </ListItem>
    </SkeletonTheme>
  );
}
