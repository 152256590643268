import React from 'react';

const TopArrow = ({ color }) => (
  <svg width="45.884" height="48.271" viewBox="0 0 45.884 48.271">
    <g transform="translate(1.489 1.518)">
      <g transform="matrix(0.891, -0.454, 0.454, 0.891, -405.631, -151.72)">
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2px"
          d="M8245.836-1287.49c21.407-8.244,27.031-23.4,26.964-33.679"
          transform="translate(-7960 1653.93)"
        />
        <path
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeWidth="2px"
          strokeLinejoin="round"
          d="M8268.6-1316.858l9-4.648,5.514,8.608"
          transform="translate(-7964.738 1654)"
        />
      </g>
    </g>
  </svg>
);

const LeftArrow = ({ color }) => (
  <svg width="103.292" height="113.602" viewBox="0 0 103.292 113.602">
    <g transform="translate(1.68 1.532)">
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2px"
        d="M-13.436,96.138C33.916,84.041,41.93,16.413,41.823,0"
        transform="matrix(-0.899, -0.438, 0.438, -0.899, 45.765, 104.743)"
      />
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeWidth="2px"
        strokeLinejoin="round"
        d="M0,7.422,14.364,0l8.8,13.746"
        transform="matrix(-0.899, -0.438, 0.438, -0.899, 20.824, 93.051)"
      />
    </g>
  </svg>
);

function CurveArrow({ color = '#535353', direction = 'top' }) {
  switch (direction) {
    case 'top':
      return <TopArrow color={color} />;
    case 'left':
      return <LeftArrow color={color} />;
    default:
      return <TopArrow color={color} />;
  }
}
export default CurveArrow;
