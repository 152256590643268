import React from 'react';

const SIZE = '24px';

const CreateIcon = (props) => (
  <svg width={props.size || SIZE} height={props.size || SIZE} viewBox="0 0 24 24">
    <path fill="none" d="M0,0H24V24H0Z" />
    <path
      fill="#898989"
      d="M4,3H20a1,1,0,0,1,1,1V20a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V4A1,1,0,0,1,4,3Zm7,8H7v2h4v4h2V13h4V11H13V7H11Z"
    />
  </svg>
);

export default CreateIcon;
