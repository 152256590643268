import { USER_ROLES } from './constants';

export function getUserChanges(baseUser, newUser) {
  const changes = {};
  if (newUser.name !== baseUser.name) {
    changes.displayName = newUser.name;
  }
  if (newUser.isAdmin !== baseUser.isAdmin) {
    changes.role = newUser.isAdmin ? USER_ROLES.ADMIN : USER_ROLES.PLAYER;
  }
  return changes;
}

export function getUserRoleInEvent(dynamoUser = {}, eventRole) {
  if (dynamoUser.role === USER_ROLES.SUPERADMIN) {
    return USER_ROLES.SUPERADMIN;
  }
  return eventRole;
}

export function validateCognitoUserExists(user) {
  if (!user) {
    return false;
  }
  return true;
}

export function validateDynamoUserExists(user) {
  if (!user || !user.dynamoUser) {
    return false;
  }
  return true;
}

export function validateUserSession(user) {
  return validateCognitoUserExists(user) && validateDynamoUserExists(user);
}
