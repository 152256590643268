import React from 'react';
import styled from 'styled-components';
import svgBase from './StyledSVGBase';

const ShareSVG = styled(svgBase)`
  width: ${(props) => props.size || '20px'};
  height: ${(props) => props.size || '20px'};
  fill: #898989;
  &:hover {
    fill: #66bb6a;
  }
`;

export default function ShareIcon(props) {
  return (
    <ShareSVG viewBox="0 0 515.556 515.556" {...props}>
      <path d="m354.445 161.111v64.444h32.222v225.556h-257.778v-225.555h32.222v-64.444h-96.667v354.445h386.667v-354.446z" />
      <path d="m225.556 123.351v166.649h64.444v-166.65l41.662 41.662 45.564-45.564-119.448-119.448-119.449 119.449 45.564 45.564z" />
    </ShareSVG>
  );
}
