import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { EVENT_QUESTION_STATUS } from 'boss-ui';

export function updateQuestionsWithAnswer(question, answerResponse) {
  return {
    question: question.question,
    answer: answerResponse.answer,
    total: answerResponse.total,
  };
}
/**
 * @param {Array} questions: array of questions
 * @param {object} updatedQuestion: updated question
 * @param {String} id: question id of the question to update
 * @param {Function} helper: function that takes the question from the first param and the updated from the second as parameters and creates a new object
 */
export function updateQuestionBy(questions, updatedQuestion, id, helper = () => updatedQuestion) {
  const newQuestionArray = [];
  questions.forEach((question) => {
    if (question.question.question_id !== id) {
      newQuestionArray.push(question);
      return;
    }
    const updated = helper(question, updatedQuestion);
    if (updated) {
      newQuestionArray.push(updated);
    }
  });
  return newQuestionArray;
}

export function updateQuestionByWS(previousQuestion, updatedQuestion) {
  const answer = get(updatedQuestion, 'answer', {});
  if (isEmpty(answer)) {
    return updatedQuestion;
  }
  return {
    ...updatedQuestion,
    answer: { ...answer, isWebSocketAnswer: true },
  };
}

export function getAnswersStatus(questions) {
  return questions.reduce(
    (score, question) => {
      if (isEmpty(question.answer)) {
        return {
          ...score,
          unanswered: score.unanswered + 1,
          total: score.total + 1,
        };
      }
      if (question.answer.state === EVENT_QUESTION_STATUS.INCORRECT) {
        return {
          ...score,
          incorrect: score.incorrect + 1,
          total: score.total + 1,
        };
      }
      return {
        ...score,
        correct: score.correct + 1,
        total: score.total + 1,
      };
    },
    {
      unanswered: 0,
      incorrect: 0,
      correct: 0,
      total: 0,
    }
  );
}
