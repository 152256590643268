
import { Flex } from 'reflexbox';
import styled from 'styled-components';

const CenteredFlex = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export default CenteredFlex;
