import React from 'react';

export default function SpyIcon(props) {
  return (
    <svg width={props.size || 50} height={props.size || 50} viewBox="-10 -10 50 50">
      <circle cx="15.5" cy="15.5" r="23" fill="#2C2C2C" />
      <path
        fill="#1d1d1d"
        d="M21.5,16a5.2,5.2,0,1,1,0,10.4,5.256,5.256,0,0,1-5.2-5.2H13.7a5.2,5.2,0,1,1-.7-2.6H17A5.2,5.2,0,0,1,21.5,16ZM2,14.7V12.1H4.6V8.2A5.2,5.2,0,0,1,9.8,3H20.2a5.2,5.2,0,0,1,5.2,5.2v3.9H28v2.6Z"
        transform="translate(0.6 0.9)"
      />
    </svg>
  );
}
