import { formDataBody, sendWarning, parseJwt } from './utils-lib';
import { getMessageError } from './error-lib';
import config from '../config';

function formatLoggedInUser(cognitoUser) {
  let user = {};
  try {
    // when app gets the authenticated user from local storage(refresh action), it gets the session in the token form
    if (cognitoUser.token) {
      const parsedToken = parseJwt(cognitoUser.token);
      user = {
        username: parsedToken['cognito:username'],
        attributes: { email: parsedToken.email },
      };
    } else {
      user = {
        username: cognitoUser.signInUserSession.idToken.payload['cognito:username'],
        attributes: { email: cognitoUser.signInUserSession.idToken.payload.email },
      };
    }
  } catch (e) {
    throw new Error('malformed cognito user');
  }
  return user;
}

async function refreshSAMLTokens() {
  // eslint-disable-next-line no-console
  console.log('refreshing saml session');
  const bodyObject = {
    grant_type: 'refresh_token',
    client_id: config.cognito.saml.APP_CLIENT_ID,
    refresh_token: localStorage.getItem('rt'),
  };
  try {
    const tokenRq = await fetch(`https://${config.cognito.saml.DOMAIN}/oauth2/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: formDataBody(bodyObject),
    });
    const tokens = await tokenRq.json();
    const { id_token, expires_in } = tokens;
    return {
      token: id_token,
      expires_at: expires_in * 1000 + new Date().getTime(), // the expiration timestamp
    };
  } catch (err) {
    sendWarning(`Refresh token Error ${getMessageError(err)}`, true);
    return null;
  }
}

export { formatLoggedInUser, refreshSAMLTokens };
