import React from 'react';
import styled from 'styled-components';

import Base from '../StyledSVGBase';

const Export = styled(Base)`
  fill: ${(props) => props.color || '#a0a0a0'};
  &:hover {
    fill: ${(props) => props.hoverColor || '#66bb6a'};
    cursor: pointer;
  }
`;
Export.displayName = 'ExportIcon-Export';

export default function ExportIcon(props) {
  return (
    <svg width={props.size || 24} height={props.size || 24} viewBox="0 0 24 24">
      <Export {...props}>
        <path fill="none" d="M0,0H24V24H0Z" />
        <path d="M4,19H20V12h2v8a1,1,0,0,1-1,1H3a1,1,0,0,1-1-1V12H4ZM14,9v6H10V9H5l7-7,7,7Z" />
      </Export>
    </svg>
  );
}
