import styled, { css } from 'styled-components';

const StyledText = styled.div`
  color: ${(props) =>
    props.light
      ? props.theme.color.text.light.color
      : props.medium
      ? props.theme.color.text.medium.color
      : props.theme.color.text.default.color};

  ${(props) =>
    props.color &&
    css`
      color: ${props.color};
    `};

  ${(props) =>
    props.size &&
    css`
      font-size: ${props.size};
    `};
`;

export default StyledText;
