import React from 'react';
import { Flex } from 'reflexbox';

const SCROLL_MARGIN = 10;

export default function ScrolleableContainer({
  topMargin = SCROLL_MARGIN,
  children,
  setScrolled = () => {},
}) {
  const handleScroll = (e) => {
    if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + topMargin) {
      setScrolled(true);
    }
  };

  return (
    <Flex maxHeight="600px" onScroll={handleScroll} style={{ overflowX: 'auto' }}>
      {children}
    </Flex>
  );
}
