import React from 'react';
import styled, { css } from 'styled-components';
import svgBase from './StyledSVGBase';

const WarningSVG = styled(svgBase)`
  width: ${(props) => props.size || '24px'};
  height: ${(props) => props.size || '24px'};
  fill: #898989;
  ${(props) =>
    props.hover &&
    css`
      cursor: pointer;
    `};
`;
WarningSVG.displayName = 'WarningSVG';

export default function WarningIcon(props) {
  return (
    <WarningSVG viewBox="0 0 24 24" {...props}>
      <path fill="none" d="M0,0H24V24H0Z" />
      <path
        fill={props.color ? props.color : '#e06c75'}
        d="M12.866,3l9.526,16.5a1,1,0,0,1-.866,1.5H2.474a1,1,0,0,1-.866-1.5L11.134,3a1,1,0,0,1,1.732,0ZM11,16v2h2V16Zm0-7v5h2V9Z"
      />
    </WarningSVG>
  );
}
